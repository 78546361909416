import React, { useState } from "react";
import MuiCard from "@mui/material/Card";
import { useLocation, useNavigate } from "react-router-dom"; // Import useNavigate
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  [theme.breakpoints.up("sm")]: {
    width: "450px",
  },
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}));

const ResetPassword = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("token");
  const email = params.get("email");

  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State for visibility toggle
  const navigate = useNavigate(); // Initialize navigate

  const handleResetPassword = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(
        "https://eduevaluator.com/api/users/reset-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, token, newPassword }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to reset password");
      }

      // Show success toast
      toast.success("Your password has been reset successfully!");

      // Navigate to /signin after 3 seconds
      setTimeout(() => {
        navigate("/signin");
      }, 3000); // Change to desired time in milliseconds
    } catch (err) {
      toast.error(err.message || "An error occurred.");
    }
  };

  return (
    <Card variant="outlined">
      <ToastContainer />
      <Typography
        component="h1"
        variant="h4"
        sx={{
          width: "100%",
          fontSize: "clamp(2rem, 10vw, 2.15rem)",
          color: "#4286F0",
        }}
      >
        Reset Password
      </Typography>
      <Box
        component="form"
        onSubmit={handleResetPassword}
        noValidate
        sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 2 }}
      >
        <FormLabel htmlFor="password">New Password</FormLabel>

        <TextField
          name="newPassword"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          fullWidth
          margin="normal"
          required
          type={showPassword ? "text" : "password"} // Toggle between text and password
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          style={{
            marginTop: 10,
            backgroundColor: "#4286F0",
            color: "white",
            borderRadius: 20,
          }}
          type="submit"
        >
          Reset Password
        </Button>
      </Box>
    </Card>
  );
};

export default ResetPassword;
