import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Switch,
  FormControlLabel,
  Button,
  Grid,
  Box,
  Container,
  Divider,
} from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import Cookies from "js-cookie";

const email = Cookies.get("email");

// Plans array with separate links for monthly and yearly
const plans = [
  {
    title: "Basic",
    link: {
      //monthly: "https://buy.stripe.com/test_14kcPY28jaNNe6A5km",
      // PROD
      monthly: "https://buy.stripe.com/5kA03V7Ym0kc0wwcMO",
      yearly: "https://buy.stripe.com/5kA2c30vUd6Yfrq9AD",
    },
    priceId: {
      //monthly: "price_1QGlU3AWjtEIPJW0e20jfDEy",
      // PROD
      monthly: "price_1QQUvEAWjtEIPJW0VXgdL9Cs",
      yearly: "price_1QQUw4AWjtEIPJW0Mj9zEyMg",
    },
    price: { monthly: 9.99, yearly: 95.0 },
    duration: { monthly: "/month", yearly: "/year" },
    description: [
      "1 users included",
      "Unlimited usage of our quick tools",
      "Help center access",
      "Email support",
    ],
  },
  {
    title: "Elite",
    link: {
      //monthly: "https://buy.stripe.com/test_dR6aHQ9AL4ppbYs6or",
      // live
      monthly: "https://buy.stripe.com/00gaIz0vU9UMcfe8ww",

      // live
      yearly: "https://buy.stripe.com/5kAbMDbay3wogvu001",
      //yearly: "https://buy.stripe.com/test_cN217g9AL6xxbYs7sx",
    },
    priceId: {
      // PROD
      //monthly: "price_1QGnUAAWjtEIPJW0EwTuwJL0",
      //yearly: "price_1QGnX0AWjtEIPJW0NhbdYmJE",
      // LIVE
      monthly: "price_1QQUnFAWjtEIPJW0gr9KAhup",
      yearly: "price_1QQUreAWjtEIPJW0FB9Q6IEn",
    },
    price: { monthly: 25.0, yearly: 270 },
    duration: { monthly: "/month", yearly: "/year" },
    description: [
      "1 users included",
      "Help center access",
      "Curriculum Edge full suit",
      "Access to training and new features",
      "Priority email support",
    ],
  },
  {
    title: "Enterprise",
    link: { monthly: "", yearly: "" },
    priceId: { monthly: "", yearly: "" },
    price: { monthly: null, yearly: null },
    duration: { monthly: "/month", yearly: "/year" },
    description: [
      "Platform customizations for schools",
      "Discounts for full schools",
      "Everything in the other tiers ",
      "Phone & email support",
      "Unlimited AI generated reports",
    ],
  },
];

const StripeSubscription = () => {
  const [isYearly, setIsYearly] = useState(false);

  const handleToggle = () => {
    setIsYearly((prev) => !prev);
  };

  const handleSubscribe = (plan) => {
    const selectedLink = isYearly ? plan.link.yearly : plan.link.monthly;
    const priceId = isYearly ? plan.priceId.yearly : plan.priceId.monthly;

    if (plan.title === "Enterprise") {
      window.location.href = "mailto:sales@company.com";
    } else if (selectedLink) {
      window.location.href = `${selectedLink}?prefilled_email=${email}&price_id=${priceId}`;
    }
  };

  return (
    <div>
      <Container
        id="pricing"
        sx={{
          pb: { xs: 8, sm: 16 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              textTransform: "uppercase",
              color: "#f8af05",
              fontWeight: "bold",
            }}
          >
            Pricing
          </Typography>
          <Typography variant="body1" sx={{ color: "text.secondary" }}>
            We believe in providing transparent and flexible pricing options
            that cater to the diverse needs of educators and stakeholders in the
            education sector.
          </Typography>
        </Box>
        <FormControlLabel
          control={
            <Switch
              checked={isYearly}
              onChange={handleToggle}
              sx={{
                "&.Mui-checked": {
                  color: "#f8af05",
                },
                "&.Mui-checked + .MuiSwitch-track": {
                  backgroundColor: "#d3d3d3",
                },
              }}
            />
          }
          label={
            <Typography
              variant="body1"
              sx={{
                fontWeight: "bold",
                color: "black",
              }}
            >
              {isYearly ? "Yearly" : "Monthly"}
            </Typography>
          }
          sx={{
            mb: 2,
            p: 1,
            backgroundColor: "#f7f7f7",
          }}
        />
        <Grid
          container
          spacing={3}
          sx={{ alignItems: "center", justifyContent: "center", width: "100%" }}
        >
          {plans.map((plan) => (
            <Grid item xs={12} sm={6} md={4} key={plan.title}>
              <Card
                sx={{
                  p: 4,
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                  backgroundColor: plan.title === "Elite" ? "#4286F0" : "white",
                  boxShadow:
                    plan.title === "Elite"
                      ? `0 8px 12px hsla(220, 20%, 42%, 0.2)`
                      : undefined,
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      mb: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      component="h3"
                      variant="h6"
                      style={{
                        color: plan.title === "Elite" ? "white" : "black",
                      }}
                    >
                      {plan.title}
                    </Typography>
                  </Box>
                  {plan.price[isYearly ? "yearly" : "monthly"] !== null ? (
                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                      <Typography
                        component="h3"
                        variant="h2"
                        style={{
                          color: plan.title === "Elite" ? "white" : "black",
                        }}
                      >
                        $
                        {plan.price[isYearly ? "yearly" : "monthly"].toFixed(2)}
                      </Typography>
                      <Typography
                        component="h3"
                        variant="h6"
                        style={{
                          color: plan.title === "Elite" ? "white" : "black",
                        }}
                      >
                        &nbsp; {plan.duration[isYearly ? "yearly" : "monthly"]}
                      </Typography>
                    </Box>
                  ) : (
                    <Typography
                      component="h3"
                      variant="h6"
                      style={{
                        color: plan.title === "Elite" ? "white" : "black",
                      }}
                    >
                      Contact us for pricing
                    </Typography>
                  )}
                  <Divider
                    sx={{ my: 2, opacity: 0.8, borderColor: "divider" }}
                  />
                  {plan.description.map((line) => (
                    <Box
                      key={line}
                      sx={{ py: 1, display: "flex", alignItems: "center" }}
                    >
                      <CheckCircleRoundedIcon
                        sx={{
                          width: 20,
                          color: plan.title === "Elite" ? "#FCCC5B" : "#4286F0",
                        }}
                      />
                      <Typography
                        variant="subtitle2"
                        component={"span"}
                        style={{
                          color: plan.title === "Elite" ? "white" : "black",
                        }}
                      >
                        {line}
                      </Typography>
                    </Box>
                  ))}
                </CardContent>
                <Button
                  fullWidth
                  sx={{
                    backgroundColor: "#FCCC5B",
                    color: "black",
                    "&:hover": {
                      backgroundColor: "#F0B72E",
                    },
                  }}
                  onClick={() => handleSubscribe(plan)}
                >
                  {plan.title === "Enterprise" ? "Contact Us" : "Subscribe Now"}
                </Button>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default StripeSubscription;
