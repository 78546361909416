import React, { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Cookies from "js-cookie";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Grid,
  Modal,
  FormControl,
  FormLabel,
  TextField,
  Button,
  Box,
  Typography,
  Chip,
  MenuItem,
  Select,
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Divider,
} from "@mui/material";
import { format, getDaysInMonth } from "date-fns";
import AddIcon from "@mui/icons-material/Add";

const Calendar = () => {
  const [open, setOpen] = useState(false); // Add event modal
  const [viewEventsOpen, setViewEventsOpen] = useState(false); // View events popup
  const [selectedDate, setSelectedDate] = useState(null);
  const [eventText, setEventText] = useState("");
  const [eventTime, setEventTime] = useState(""); // New event time state
  const [events, setEvents] = useState({});
  const [selectedColor, setSelectedColor] = useState("default");
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [teacherEmail, setTeacherEmail] = useState("");
  const maxEventsToShow = 3; // Maximum number of events to show before "See More"

  // Pastel color definitions
  const pastelColors = {
    default: "#ffc570",
    primary: "#dce775",
    secondary: "#ea8f8f",
    success: "#83c3f7",
  };

  const handleOpen = (date) => {
    setSelectedDate(date);
    setEventText("");
    setEventTime(""); // Reset event time input
    setSelectedColor("default");
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleDeleteEvent = async (eventId) => {
    try {
      await axios.delete(
        `https://eduevaluator.com/api/events/delete-event/${eventId}`
      );

      fetchEvents(); // Refresh events after deletion
      setViewEventsOpen(false); // Close the modal
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  const handleSaveEvent = async () => {
    const email = Cookies.get("email");

    if (eventText.trim() !== "" && eventTime.trim() !== "") {
      const newEvent = {
        date: selectedDate,
        eventText,
        eventTime,
        sendTeacherEmail: teacherEmail,
        color: selectedColor,
        email: email,
      };

      // Save to backend using axios
      try {
        const response = await axios.post(
          "https://eduevaluator.com/api/events/add-event",
          newEvent,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        fetchEvents();
        setOpen(false);
      } catch (error) {
        console.error("Error saving event:", error);
      }
    }
    handleClose();
  };

  const handleViewEventsPopup = (date) => {
    setSelectedDate(date);
    setViewEventsOpen(true);
  };

  useEffect(() => {
    const email = Cookies.get("email");
    fetchEvents();
  }, [currentMonth, currentYear]);

  const fetchEvents = async () => {
    const email = Cookies.get("email");
    try {
      const response = await axios.get(
        "https://eduevaluator.com/api/events/get-events",
        {
          params: {
            year: currentYear,
            month: currentMonth,
            email: email,
          },
        }
      );

      if (response.status === 200) {
        console.log(response.data);

        const eventsData = response.data.reduce((acc, event) => {
          const dateKey = event.date; // Ensure the date is in "YYYY-MM-DD" format
          if (!acc[dateKey]) {
            acc[dateKey] = [];
          }
          acc[dateKey].push({
            _id: event._id, // Add this line to store the ID
            event: event.eventText,
            time: event.eventTime,
            color: event.color,
            sendTeacherEmail: event.sendTeacherEmail,
            email: event.email,
          });
          return acc;
        }, {});

        setEvents(eventsData); // Update state with transformed events
        console.log(eventsData); // Log the transformed data for verification
      } else {
        throw new Error("Failed to fetch events");
      }
    } catch (error) {
      console.error("Error fetching events:", error);
      console.log(error);
    }
  };

  const handleCloseViewEventsPopup = () => setViewEventsOpen(false);

  const handlePreviousMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const handleNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  // Sort events by time for the popup
  const sortedEvents = (dateKey) => {
    return (
      events[dateKey]?.slice().sort((a, b) => (a.time > b.time ? 1 : -1)) || []
    );
  };

  const generateDays = (daysInMonth) => {
    let daysArray = [];
    for (let day = 1; day <= daysInMonth; day++) {
      const dateKey = `${currentYear}-${currentMonth + 1}-${day}`;
      const eventsForDay = events[dateKey] || [];
      const displayEvents = eventsForDay.slice(0, maxEventsToShow);
      const hasMoreEvents = eventsForDay.length > maxEventsToShow;

      daysArray.push(
        <Grid item xs={12 / 7} key={day}>
          <Box
            sx={{
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: 1,
              minHeight: "120px",
              textAlign: "center",
              cursor: "pointer",
              backgroundColor: "#f9f9f9",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              position: "relative",
              "&:hover": {
                backgroundColor: "#e0f7fa",
              },
            }}
            onClick={(e) => {
              // Open view events popup if clicked outside chips
              if (
                !e.target.closest(".MuiChip-root") &&
                !e.target.closest(".MuiIconButton-root")
              ) {
                handleViewEventsPopup(dateKey);
              }
            }}
          >
            <Typography variant="body1">{day}</Typography>

            {/* Add Event Button */}
            <Tooltip title="Add Event">
              <IconButton
                size="small"
                sx={{ position: "absolute", top: "5px", right: "5px" }}
                onClick={() => handleOpen(dateKey)}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            {/* Display event chips */}
            {displayEvents.map((event, index) => (
              <Chip
                key={index}
                label={`${event.event} (${event.time})`}
                sx={{
                  backgroundColor: pastelColors[event.color],
                  marginTop: "5px",
                  width: "100%",
                  color: "#000",
                }}
                size="small"
              />
            ))}

            {/* "See More" Chip if there are extra events */}
            {hasMoreEvents && (
              <Chip
                label="See More"
                color="default"
                size="small"
                sx={{ marginTop: "5px", width: "100%" }}
                onClick={() => handleViewEventsPopup(dateKey)}
              />
            )}
          </Box>
        </Grid>
      );
    }
    return daysArray;
  };

  const daysInMonth = getDaysInMonth(new Date(currentYear, currentMonth));

  return (
    <div>
      <Box sx={{ width: "100%", maxWidth: "1200px", margin: "auto" }}>
        {/* Month navigation */}
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginBottom: 2 }}
        >
          <Button
            onClick={handlePreviousMonth}
            color="primary" // Make the button color more vibrant
            startIcon={<ArrowBackIcon />} // Add an icon for clarity
            style={{
              backgroundColor: "#FCCC5B", // Yellow background
              color: "grey",
              width: 150,
              borderRadius: 20,
            }}
          >
            Previous
          </Button>

          <Typography variant="h6">
            {format(new Date(currentYear, currentMonth), "MMMM yyyy")}
          </Typography>

          <Button
            onClick={handleNextMonth}
            color="primary" // Make the button color more vibrant
            endIcon={<ArrowForwardIcon />} // Add an icon for clarity
            style={{
              backgroundColor: "#FCCC5B", // Yellow background
              color: "grey",
              width: 150,
              borderRadius: 20,
            }}
          >
            Next
          </Button>
        </Grid>

        {/* Calendar Grid */}
        <Grid container spacing={1}>
          {generateDays(daysInMonth)}
        </Grid>

        {/* Modal for adding event */}
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              backgroundColor: "white",
              padding: 4,
              boxShadow: 24,
              borderRadius: "16px", // Add this line to round the corners
            }}
          >
            <Typography variant="h6">Add Event for {selectedDate}</Typography>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <FormControl>
                  <FormLabel htmlFor="email">Evalutation Name</FormLabel>
                  <TextField
                    autoFocus
                    required
                    fullWidth
                    style={{ width: 325 }}
                    variant="outlined"
                    value={eventText}
                    onChange={(e) => setEventText(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12}>
                <FormControl>
                  <FormLabel htmlFor="email">Teacher Email</FormLabel>
                  <TextField
                    fullWidth
                    style={{ width: 325 }}
                    value={teacherEmail}
                    onChange={(e) => setTeacherEmail(e.target.value)}
                    autoFocus
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12}>
                <FormControl>
                  <FormLabel htmlFor="email">Evalutation Time</FormLabel>
                  <TextField
                    fullWidth
                    style={{ width: 325 }}
                    value={eventTime}
                    onChange={(e) => setEventTime(e.target.value)}
                    autoFocus
                    type="time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12}>
                <Select
                  value={selectedColor}
                  onChange={(e) => setSelectedColor(e.target.value)}
                  style={{ width: 325 }}
                  displayEmpty
                  sx={{ marginBottom: 2 }}
                >
                  {Object.entries(pastelColors).map(([key, color]) => (
                    <MenuItem
                      key={key}
                      value={key}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Box
                        sx={{
                          backgroundColor: color,
                          borderRadius: "50%",
                          height: 20,
                          width: 20,
                          marginRight: 1, // Space between circle and text
                        }}
                      />
                      {key.charAt(0).toUpperCase() + key.slice(1)}{" "}
                      {/* Capitalize the color name */}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item sm={12}>
                <Button
                  onClick={handleSaveEvent}
                  style={{
                    backgroundColor: "#4286F0",
                    color: "white",

                    borderRadius: 20,
                  }}
                  fullWidth
                >
                  Save Event
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        {/* Modal for viewing events on a selected date */}
        <Modal open={viewEventsOpen} onClose={handleCloseViewEventsPopup}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              backgroundColor: "white",
              padding: 4,
              boxShadow: 24,
              borderRadius: "16px", // Add this line to round the corners
            }}
          >
            <Typography variant="h6" gutterBottom>
              Events for {selectedDate}
            </Typography>

            <Card>
              <CardContent>
                <List>
                  {sortedEvents(selectedDate).map((event, index) => (
                    <div key={index}>
                      <ListItem>
                        <ListItemText
                          primary={`${event.event} - ${event.time}`}
                          secondary={`Teacher email: ${event.sendTeacherEmail}`}
                        />
                        <IconButton
                          onClick={() => handleDeleteEvent(event._id)}
                          color="error"
                        >
                          <DeleteIcon /> {/* Import and use a delete icon */}
                        </IconButton>
                      </ListItem>
                      {index < sortedEvents(selectedDate).length - 1 && (
                        <Divider />
                      )}
                    </div>
                  )) || <Typography>No events</Typography>}
                </List>
              </CardContent>
            </Card>
          </Box>
        </Modal>
      </Box>
    </div>
  );
};

export default Calendar;
