import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeSubscription from "./StripeSubscription";
const stripePromise = loadStripe(
  "pk_test_51QBRAPAWjtEIPJW0C5k8uNixTKegcBSeWjFLrFsOE24QiYUAXqgzCCOJrPd7OC2pxyke5U7SaNBVfsB7zlEcH0KM00HzCIXAa6"
); // Ensure this is set in your .env file
function SubscriptionPageForm() {
  return (
    <div>
      <Elements stripe={stripePromise}>
        <StripeSubscription />
      </Elements>
    </div>
  );
}

export default SubscriptionPageForm;
