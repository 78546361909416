import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  Chip,
  MenuItem,
  Select,
  Paper,
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
} from "@mui/material";
import OpenAI from "openai";
import ContentCopyIcon from "@mui/icons-material/ContentCopy"; // Import the clipboard icon
import SchoolIcon from "@mui/icons-material/School";
import AssistantIcon from "@mui/icons-material/Assistant";
import { useDropzone } from "react-dropzone";
import LoadingModal from "./LoadingModal";
import axios from "axios";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function SchoolTripPlannerForm() {
  // State variables
  const [ageGroup, setAgeGroup] = useState("");
  const [destination, setDestination] = useState("");
  const [location, setLocation] = useState("");
  const [budget, setBudget] = useState("");
  const [studentToAdultRatio, setStudentToAdultRatio] = useState("");
  const [tripLength, setTripLength] = useState("");
  const [includeActivities, setIncludeActivities] = useState("");
  const [specialNeeds, setSpecialNeeds] = useState("");
  const [subject, setSubject] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [levelOfUnderstanding, setLevelOfUnderstanding] = useState("");
  const [subjectDifficulty, setSubjectDifficulty] = useState("");
  const [description, setDescription] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false); // State for loading
  const [modalOpen, setModalOpen] = useState(false); // State for modal
  const [summary, setSummary] = useState(""); // State for API response
  const [healthAndSaftey, setHealthAndSaftey] = useState("");
  const [localorInter, setLocalorInter] = useState("");
  // File upload handling
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const fileURLs = acceptedFiles.map((file) => URL.createObjectURL(file));
      setUploadedFiles((prevFiles) => [...prevFiles, ...fileURLs]);
    },
  });

  // Mock handler functions
  const saveDraft = () => alert("Draft saved!");
  // Function to log all form data
  // Function to log all form data
  const handleSubmit = async () => {
    setLoading(true); // Start loading
    try {
      const email = Cookies.get("email");
      const content = `Our school is planning a field trip for students in ${ageGroup}. The destination is ${destination}, located in ${location}. The trip has a budget of $${budget} and will include a student-to-adult ratio of ${studentToAdultRatio}. The planned length of the trip is ${tripLength} days, and we will ${
        includeActivities === "Yes" ? "include" : "not include"
      } additional activities during the trip. Special requirements for the trip include: ${specialNeeds}. This is a ${localorInter} trip. Please ${
        healthAndSaftey === "Yes" ? "include" : "not include"
      } health and saftey recommendations. Be detailed in the activities suggested`;
      const tokenResponse = await axios.post(
        "https://eduevaluator.com/api/users/decreaseToken",
        { email, content }
      );
      if (tokenResponse.data.message === "OpenAI response generated") {
        const summaries = tokenResponse.data.summary;
        setSummary(summaries); // Save summary for modal
        setModalOpen(true); // Open modal
      } else {
        toast.error("You are out of credits");
      }
    } catch (error) {
      console.error("Error while fetching completion:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(summary)
      .then(() => {
        toast.success("Text copied to clipboard!"); // Optional: Show a message to the user
      })
      .catch((error) => {
        toast.error("Could not copy text: ", error);
      });
  };

  const understandingOptions = [
    "Beginner",
    "Intermediate",
    "Developing",
    "Expert",
  ];

  const subjectOptions = [
    "Math",
    "Science",
    "English",
    "History",
    "Art",
    "Music",
    // Add more subjects as needed
  ];

  const gradeOptions = [
    "1st Grade",
    "2nd Grade",
    "3rd Grade",
    "4th Grade",
    "5th Grade",
    "6th Grade",
    "7th Grade",
    "8th Grade",
    "9th Grade",
    "10th Grade",
    "11th Grade",
    "12th Grade",
  ];

  return (
    <Paper elevation={3} sx={{ padding: 3, borderRadius: 2, width: "100%" }}>
      <ToastContainer />
      <LoadingModal loading={loading} message="Generating response..." />{" "}
      {/* Render loading modal */}
      <Container sx={{ width: "100%", maxWidth: "1200px" }}>
        <Box sx={{ marginTop: 3 }}>
          <Accordion
            expanded={true}
            sx={{
              width: "100%",
              backgroundColor: "white",
              color: "black",
              marginBottom: 2,
              borderRadius: 2,
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              "&:before": { display: "none" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#e0e0e0",
                padding: "16px",
                borderRadius: "4px 4px 0 0",
                color: "black",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              <AssistantIcon sx={{ marginRight: 1 }} />
              School Trip Planner
            </Box>
            <Container>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  marginTop: 4,
                  marginBottom: 4,
                  backgroundColor: "#f0f0f0",
                  padding: "16px",
                  borderRadius: "8px",
                }}
              >
                <Chip
                  label="What does this do?"
                  sx={{
                    backgroundColor: "#1976d2", // Blue color
                    color: "#ffffff", // This sets the text color to white
                    "& .MuiChip-label": {
                      color: "#ffffff", // Ensures the label is white
                    },
                    marginRight: "16px",
                  }}
                />
                <Typography variant="body1">
                  I provide tailored school trip plans with destination ideas,
                  logistics, and educational activities, focusing on safety,
                  budget, and engagement, while guiding teachers from prep to
                  post-trip reflections.
                </Typography>
              </Box>
              <Container>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <FormControl fullWidth>
                      <FormLabel>Age Group</FormLabel>
                      <Select
                        value={ageGroup}
                        onChange={(e) => setAgeGroup(e.target.value)}
                        displayEmpty
                        inputProps={{ "aria-label": "Age Group" }}
                        sx={{ backgroundColor: "white", borderRadius: 2 }}
                      >
                        <MenuItem value="" disabled>
                          Select Age Group
                        </MenuItem>
                        {[
                          "Elementary",
                          "Middle",
                          "High School",
                          "University",
                        ].map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <FormControl fullWidth>
                      <FormLabel>Destination of Trip</FormLabel>
                      <TextField
                        value={destination}
                        onChange={(e) => setDestination(e.target.value)}
                        variant="outlined"
                        sx={{ borderRadius: 2 }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <FormControl fullWidth>
                      <FormLabel>Current Location</FormLabel>
                      <TextField
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        variant="outlined"
                        sx={{ borderRadius: 2 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl fullWidth>
                      <FormLabel>
                        Is this trip local or international?
                      </FormLabel>
                      <Select
                        value={localorInter}
                        onChange={(e) => setLocalorInter(e.target.value)}
                        displayEmpty
                        inputProps={{ "aria-label": "Age Group" }}
                        sx={{ backgroundColor: "white", borderRadius: 2 }}
                      >
                        <MenuItem value="" disabled></MenuItem>
                        {["Local", "International"].map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl fullWidth>
                      <FormLabel>Field Trip Budget</FormLabel>
                      <TextField
                        type="number"
                        value={budget}
                        onChange={(e) => setBudget(e.target.value)}
                        variant="outlined"
                        sx={{ borderRadius: 2 }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <FormControl fullWidth>
                      <FormLabel>Number of Students to Adults</FormLabel>
                      <TextField
                        type="number"
                        value={studentToAdultRatio}
                        onChange={(e) => setStudentToAdultRatio(e.target.value)}
                        variant="outlined"
                        sx={{ borderRadius: 2 }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <FormControl fullWidth>
                      <FormLabel>Days Length of Trip (in days) </FormLabel>
                      <TextField
                        type="number"
                        value={tripLength}
                        onChange={(e) => setTripLength(e.target.value)}
                        variant="outlined"
                        sx={{ borderRadius: 2 }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <FormControl fullWidth>
                      <FormLabel>Include Activities</FormLabel>
                      <Select
                        value={includeActivities}
                        onChange={(e) => setIncludeActivities(e.target.value)}
                        displayEmpty
                        inputProps={{ "aria-label": "Include Activities" }}
                        sx={{ backgroundColor: "white", borderRadius: 2 }}
                      >
                        <MenuItem value="" disabled>
                          Select Option
                        </MenuItem>
                        {["Yes", "No"].map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <FormControl fullWidth>
                      <FormLabel>Special Needs/Requirements</FormLabel>
                      <TextField
                        value={specialNeeds}
                        onChange={(e) => setSpecialNeeds(e.target.value)}
                        variant="outlined"
                        sx={{ borderRadius: 2 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl fullWidth>
                      <FormLabel>
                        In need recommendations for health and safety issues
                        with this trip?
                      </FormLabel>
                      <Select
                        value={healthAndSaftey}
                        onChange={(e) => setHealthAndSaftey(e.target.value)}
                        displayEmpty
                        inputProps={{ "aria-label": "Age Group" }}
                        sx={{ backgroundColor: "white", borderRadius: 2 }}
                      >
                        <MenuItem value="" disabled></MenuItem>
                        {["Yes", "No"].map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Container>

              <Box>
                {uploadedFiles.map((file, index) => (
                  <Chip
                    key={index}
                    label={file}
                    onDelete={() => {
                      setUploadedFiles(
                        uploadedFiles.filter((_, i) => i !== index)
                      );
                    }}
                    sx={{ margin: 1 }}
                  />
                ))}
              </Box>

              <Button
                type="button" // Change this to "button" instead of "submit"
                onClick={handleSubmit} // Call handleSubmit on click
                style={{
                  marginTop: 10,
                  width: 100,
                  backgroundColor: "#4286F0",
                  color: "white",
                  borderRadius: 20,
                }}
              >
                Ask AI
              </Button>
            </Container>
          </Accordion>
        </Box>
        {/* Modal for displaying results */}
        <Dialog
          open={modalOpen}
          onClose={() => {}} // Disable closing by clicking outside
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>AI Response</DialogTitle>
          <DialogContent>
            <pre>{summary}</pre>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCopyToClipboard}
              startIcon={<ContentCopyIcon />}
            />
            <Button onClick={() => setModalOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Paper>
  );
}

export default SchoolTripPlannerForm;
