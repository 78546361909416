import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Paper,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Cookies from "js-cookie";
const LessonPlanFormWebView = () => {
  const dateGenerated = new Date().toLocaleDateString();
  const { formId } = useParams();
  const [comments, setComments] = useState("");
  const [commentText, setCommentText] = useState("");
  const [formData, setFormData] = useState(null);
  // Function to fetch comments
  const fetchComments = async () => {
    try {
      const response = await axios.get(
        `https://eduevaluator.com/api/lessonPlan/comments/${formId}`
      );
      setComments(response.data.comments || []);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  // Function to post a comment
  const handlePostComment = async () => {
    const email = Cookies.get("email");
    const name = Cookies.get("name");
    try {
      await axios.post(
        `https://eduevaluator.com/api/lessonPlan/add-comment/${formId}`,
        {
          name: name, // Replace with the actual name if available in state or context
          email: email, // Replace with the actual email if available in state or context
          comment: commentText, // Send the comment text as required by backend
        }
      );
      setComments("");
      fetchComments(); // Fetch comments again to show the new one
      setCommentText("");
    } catch (error) {
      console.error("Error posting comment:", error);
    }
  };

  // Fetch form data and comments when the component loads
  useEffect(() => {
    if (formId) {
      axios
        .get(`https://eduevaluator.com/api/lessonPlan/get-form/${formId}`)
        .then((response) => {
          setFormData(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching form data:", error);
        });

      fetchComments(); // Fetch comments
    }
  }, [formId]);

  if (!formData) {
    return <div>Loading...</div>; // Show a loading message while data is being fetched
  }

  return (
    <Paper elevation={3} sx={{ padding: 3, borderRadius: 2 }}>
      <Box
        sx={{ padding: 2, color: "#333", overflowY: "auto", flex: "1 1 auto" }}
      >
        {/* Header Section */}
        <Box sx={{ textAlign: "center", marginBottom: 4 }}>
          <Typography variant="h4" sx={{ color: "#333" }}>
            Lesson Plan
          </Typography>
          <Typography variant="body2" sx={{ color: "#666" }}>
            Generated on: <span style={{ color: "#333" }}>{dateGenerated}</span>
          </Typography>
        </Box>

        {/* General Information Section */}
        <Box sx={{ marginBottom: 3 }}>
          <Typography
            variant="h6"
            sx={{
              backgroundColor: "#000",
              color: "#fff",
              padding: "8px",
              fontSize: "14px",
            }}
          >
            General Information
          </Typography>
          <Grid container spacing={2}>
            {[
              { label: "Subject", value: formData.subject },
              { label: "Grade Level", value: formData.gradeLevel },
              { label: "Lesson Start Time", value: formData.lessonTime },
              {
                label: "Lesson Date",
                value: formData.lessonDate || " No Date A",
              },
              { label: "Lesson Title/Topic", value: formData.lessonTitle },
              {
                label: "Key Vocabulary / Concepts",
                value: formData.keyTerms?.join(", "),
              },
              { label: "Standards", value: formData.standards },
              { label: "Materials Needed", value: formData.materials },
            ].map(({ label, value }, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Box
                  sx={{
                    padding: 1,
                    border: "1px solid #ccc",
                    backgroundColor: "#fff",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "10px",
                      color: "black",
                    }}
                  >
                    {label}
                  </Typography>
                  <Typography sx={{ fontSize: "8px", color: "#333" }}>
                    {value || ""}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Middle of the Lesson Section */}
        <Box sx={{ marginBottom: 3 }}>
          <Typography
            variant="h6"
            sx={{
              backgroundColor: "#000",
              color: "#fff",
              padding: "8px",
              fontSize: "14px",
            }}
          >
            Middle of the Lesson
          </Typography>
          <Grid container spacing={2}>
            {[
              {
                label: "Instruction / Teaching",
                value: formData.middleInstuction,
              },
              { label: "Guided Practice", value: formData.guidedPractice },
              {
                label: "Independent Practice",
                value: formData.independentPractice,
              },
              {
                label: "Differentiation / Scaffolding",
                value: formData.differentiation,
              },
              {
                label: "Students Needing Accommodations",
                value: formData.studentsNeedingAccomodations,
              },
              {
                label: "Assistive Technology",
                value: formData.assistiveTechnology,
              },
              {
                label: "Assessment / Evaluation",
                value: formData.assesmentType,
              },
              { label: "Time Allocation", value: formData.timeAllocation },
            ].map(({ label, value }, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Box
                  sx={{
                    padding: 1,
                    border: "1px solid #ccc",
                    backgroundColor: "#fff",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "10px",
                      color: "black",
                    }}
                  >
                    {label}
                  </Typography>
                  <Typography sx={{ fontSize: "8px", color: "#333" }}>
                    {value || ""}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* End of the Lesson Section */}
        <Box sx={{ marginBottom: 3 }}>
          <Typography
            variant="h6"
            sx={{
              backgroundColor: "#000",
              color: "#fff",
              padding: "8px",
              fontSize: "14px",
            }}
          >
            End of the Lesson
          </Typography>
          <Grid container spacing={2}>
            {[
              {
                label: "Summary / Closing",
                value: formData.summary,
              },
              {
                label: "Connection to Future Content",
                value: formData.connectionToFutureContent,
              },
              {
                label: "End of Lesson Assessment",
                value: formData.summativeAssessment,
              },
              { label: "Reflection", value: formData.reflection },
            ].map(({ label, value }, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Box
                  sx={{
                    padding: 1,
                    border: "1px solid #ccc",
                    backgroundColor: "#fff",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "10px",
                      color: "black",
                    }}
                  >
                    {label}
                  </Typography>
                  <Typography sx={{ fontSize: "8px", color: "#333" }}>
                    {value || ""}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <Box sx={{ paddingTop: 2, borderTop: "1px solid #ddd" }}>
        <Typography variant="h6" sx={{ marginBottom: 2, fontWeight: "bold" }}>
          Comments
        </Typography>

        {comments.length > 0 ? (
          comments.map((comment, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "flex-start",
                marginBottom: 2,
                padding: 2,
                border: "1px solid #ccc",
                borderRadius: 2,
              }}
            >
              {/* Avatar Circle */}
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  backgroundColor: "#4286F0",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  fontWeight: "bold",
                  fontSize: "16px",
                  marginRight: 2,
                }}
              >
                {comment.name?.charAt(0).toUpperCase()}
              </Box>

              {/* Comment Details */}
              <Box sx={{ flexGrow: 1 }}>
                {/* Name and Date */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 1,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", color: "#333", marginRight: 1 }}
                  >
                    {comment.name}
                  </Typography>
                  <Typography variant="caption" sx={{ color: "#999" }}>
                    {new Date(comment.date).toLocaleDateString()}
                  </Typography>
                </Box>

                {/* Comment Type */}

                {/* Comment Text */}
                <Typography variant="body2" sx={{ color: "#555" }}>
                  {comment.comment}
                </Typography>
              </Box>
            </Box>
          ))
        ) : (
          <Typography variant="body2" sx={{ color: "#666" }}>
            No comments available.
          </Typography>
        )}

        <textarea
          style={{
            width: "100%",
            padding: "8px",
            fontSize: "16px",
            borderRadius: "4px",
            border: "1px solid #ccc",
            resize: "vertical",
            minHeight: "80px",
          }}
          placeholder="Write a comment..."
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
        />
        <Button
          style={{
            backgroundColor: "#4286F0",
            color: "white",
            width: 150,
            borderRadius: 20,
            marginRight: 4,
          }}
          onClick={handlePostComment}
        >
          Post
        </Button>
      </Box>
    </Paper>
  );
};

export default LessonPlanFormWebView;
