import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import SchoolIcon from "@mui/icons-material/School";
import StudentConcernPDF from "./StudentConcernPDF";
import LessonPlanFormPDF from "./LessonPlanFormPDF"; // Adjust the import path if necessary
import {
  Box,
  Button,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Container,
  Typography,
  Accordion,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
  Chip,
  InputAdornment,
  Autocomplete,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Divider,
  CircularProgress,
  Alert,
} from "@mui/material";
function StudentOfConcernForm() {
  const pdfRef = useRef(null);
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false); // Close the alert by setting the state to false
  };
  const navigate = useNavigate();
  const { formId } = useParams(); // Get formId from URL parameter
  const steps = ["Student Information", "Step 1", "Step 2", "Step 3"];
  // Getters and Setters     attendancePunctuality
  const [
    physicalAndMentalHealthEvaluation,
    setPhysicalAndMentalHealthEvaluation,
  ] = useState("");
  const [physicalAndMentalHealthEvidence, setPhysicalAndMentalHealthEvidence] =
    useState("");
  const [
    socialandFamilySupportNetworksEvaluation,
    setsocialandFamilySupportNetworksEvaluation,
  ] = useState("");
  const [
    setsocialandFamilySupportNetworksEvidence,
    setsetsocialandFamilySupportNetworksEvidence,
  ] = useState("");
  const [
    communitySocialIntegrationEvaluation,
    setcommunitySocialIntegrationEvaluation,
  ] = useState("");
  const [
    communitySocialIntegrationEvidence,
    setcommunitySocialIntegrationEvidence,
  ] = useState("");
  const [
    learningEngagementMotivationEvaluation,
    setlearningEngagementMotivationEvaluation,
  ] = useState("");
  const [
    learningEngagementMotivationEvidence,
    setlearningEngagementMotivationEvidence,
  ] = useState("");
  //Attendance and Punctuality
  const [attendancePunctualityEvaluation, setattendancePunctualityEvaluation] =
    useState("");
  const [attendancePunctualitynEvidence, setattendancePunctualityEvidence] =
    useState("");
  //Financial Stability and Resources financialStabilityResources
  const [
    financialStabilityResourcesEvaluation,
    setfinancialStabilityResourcesEvaluation,
  ] = useState("");
  const [
    financialStabilityResourcesEvidence,
    setfinancialStabilityResourcesEvidence,
  ] = useState("");
  //    Academic Progress and Performance academicProgressPerformance
  const [
    academicProgressPerformanceEvaluation,
    setacademicProgressPerformanceEvaluation,
  ] = useState("");
  const [
    academicProgressPerformanceEvidence,
    setacademicProgressPerformanceEvidence,
  ] = useState("");
  //Career Goals and Program Alignment
  const [careerGoalsEvaluation, setcareerGoalsEvaluation] = useState("");
  const [careerGoalsEvidence, setcareerGoalsEvidence] = useState("");

  // Time Management timeManagement
  const [timeManagementEvaluation, settimeManagementEvaluation] = useState("");
  const [timeManagementEvidence, settimeManagementEvidence] = useState("");

  // Digital Literacy
  const [digitalLiteracyEvaluation, setdigitalLiteracyEvaluation] =
    useState("");
  const [digitalLiteracyEvidence, setdigitalLiteracyEvidence] = useState("");

  //Problem-Solving
  const [problemSolvingEvaluation, setproblemSolvingEvaluation] = useState("");
  const [problemSolvingEvidence, setproblemSolvingEvidence] = useState("");

  //Cultural and Language Considerations languageConsiderations
  const [
    languageConsiderationsEvaluation,
    setlanguageConsiderationsEvaluation,
  ] = useState("");
  const [languageConsiderationsEvidence, setlanguageConsiderationsEvidence] =
    useState("");

  //Self-Efficacy and Confidence selfEfficacy
  const [selfEfficacyEvaluation, setselfEfficacyEvaluation] = useState("");
  const [selfEfficacyEvidence, setselfEfficacyEvidence] = useState("");

  // Student Details
  const [studentFirstName, setStudentFirstName] = useState("");
  const [studentLastName, setStudentLastName] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [subject, setSubject] = useState("");
  const [generalComments, setGeneralComments] = useState("");
  const email = Cookies.get("email");
  // FormData
  const formData = {
    formId,
    email: email,
    physicalAndMentalHealthEvaluation,
    physicalAndMentalHealthEvidence,
    socialandFamilySupportNetworksEvaluation,
    setsocialandFamilySupportNetworksEvidence,
    communitySocialIntegrationEvaluation,
    communitySocialIntegrationEvidence,
    learningEngagementMotivationEvaluation,
    learningEngagementMotivationEvidence,
    attendancePunctualityEvaluation,
    attendancePunctualitynEvidence,
    financialStabilityResourcesEvaluation,
    financialStabilityResourcesEvidence,
    academicProgressPerformanceEvaluation,
    academicProgressPerformanceEvidence,
    careerGoalsEvaluation,
    careerGoalsEvidence,
    timeManagementEvaluation,
    timeManagementEvidence,
    digitalLiteracyEvaluation,
    digitalLiteracyEvidence,
    problemSolvingEvaluation,
    problemSolvingEvidence,
    languageConsiderationsEvaluation,
    languageConsiderationsEvidence,
    selfEfficacyEvaluation,
    selfEfficacyEvidence,
    studentFirstName,
    studentLastName,
    gradeLevel,
    subject,
    generalComments,
    isDraft: true,
  };

  const formDataSubmit = {
    formId,
    email: email,
    physicalAndMentalHealthEvaluation,
    physicalAndMentalHealthEvidence,
    socialandFamilySupportNetworksEvaluation,
    setsocialandFamilySupportNetworksEvidence,
    communitySocialIntegrationEvaluation,
    communitySocialIntegrationEvidence,
    learningEngagementMotivationEvaluation,
    learningEngagementMotivationEvidence,
    attendancePunctualityEvaluation,
    attendancePunctualitynEvidence,
    financialStabilityResourcesEvaluation,
    financialStabilityResourcesEvidence,
    academicProgressPerformanceEvaluation,
    academicProgressPerformanceEvidence,
    careerGoalsEvaluation,
    careerGoalsEvidence,
    timeManagementEvaluation,
    timeManagementEvidence,
    digitalLiteracyEvaluation,
    digitalLiteracyEvidence,
    problemSolvingEvaluation,
    problemSolvingEvidence,
    languageConsiderationsEvaluation,
    languageConsiderationsEvidence,
    selfEfficacyEvaluation,
    selfEfficacyEvidence,
    studentFirstName,
    studentLastName,
    gradeLevel,
    subject,
    generalComments,
    isDraft: false,
  };

  const handleSubmit = async () => {
    try {
      // Send data to the database
      await axios.post(
        "https://eduevaluator.com/api/studentConcern/update-draft",
        formDataSubmit
      );
      pdfRef.current.click();
      navigate("/studentOfConcern");
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  // Auto-save on page load or when formData changes
  useEffect(() => {
    console.log(formId);
    if (formId) {
      axios
        .get(`https://eduevaluator.com/api/studentConcern/get-form/${formId}`)
        .then((response) => {
          const formDataResponse = response.data.data; // Assuming the returned data is inside 'data'
          console.log(formDataResponse.data);

          // Check if form data needs to be updated
          if (formDataResponse) {
            console.log(formDataResponse.physicalAndMentalHealthEvaluation);
            setPhysicalAndMentalHealthEvaluation(
              formDataResponse.physicalAndMentalHealthEvaluation ?? ""
            );
            setPhysicalAndMentalHealthEvidence(
              formDataResponse.physicalAndMentalHealthEvidence ?? ""
            );
            setsocialandFamilySupportNetworksEvaluation(
              formDataResponse.socialandFamilySupportNetworksEvaluation ?? ""
            );
            setsetsocialandFamilySupportNetworksEvidence(
              formDataResponse.setsocialandFamilySupportNetworksEvidence ?? ""
            );
            setcommunitySocialIntegrationEvaluation(
              formDataResponse.communitySocialIntegrationEvaluation ?? ""
            );
            setcommunitySocialIntegrationEvidence(
              formDataResponse.communitySocialIntegrationEvidence ?? ""
            );
            setlearningEngagementMotivationEvaluation(
              formDataResponse.learningEngagementMotivationEvaluation ?? ""
            );
            setlearningEngagementMotivationEvidence(
              formDataResponse.learningEngagementMotivationEvidence ?? ""
            );
            setattendancePunctualityEvaluation(
              formDataResponse.attendancePunctualityEvaluation ?? ""
            );
            setattendancePunctualityEvidence(
              formDataResponse.attendancePunctualitynEvidence ?? ""
            );
            setfinancialStabilityResourcesEvaluation(
              formDataResponse.financialStabilityResourcesEvaluation ?? ""
            );
            setfinancialStabilityResourcesEvidence(
              formDataResponse.financialStabilityResourcesEvidence ?? ""
            );
            setacademicProgressPerformanceEvaluation(
              formDataResponse.academicProgressPerformanceEvaluation ?? ""
            );
            setacademicProgressPerformanceEvidence(
              formDataResponse.academicProgressPerformanceEvidence ?? ""
            );
            setcareerGoalsEvaluation(
              formDataResponse.careerGoalsEvaluation ?? ""
            );
            setcareerGoalsEvidence(formDataResponse.careerGoalsEvidence ?? "");
            settimeManagementEvaluation(
              formDataResponse.timeManagementEvaluation
            );
            settimeManagementEvidence(
              formDataResponse.timeManagementEvidence ?? ""
            );
            setdigitalLiteracyEvaluation(
              formDataResponse.digitalLiteracyEvidence ?? ""
            );
            setproblemSolvingEvaluation(
              formDataResponse.problemSolvingEvaluation ?? ""
            );
            setproblemSolvingEvidence(
              formDataResponse.problemSolvingEvidence ?? ""
            );
            setlanguageConsiderationsEvaluation(
              formDataResponse.languageConsiderationsEvaluation ?? ""
            );
            //
            setlanguageConsiderationsEvidence(
              formDataResponse.languageConsiderationsEvidence ?? ""
            );
            setselfEfficacyEvaluation(
              formDataResponse.selfEfficacyEvaluation ?? ""
            );
            setselfEfficacyEvidence(
              formDataResponse.selfEfficacyEvidence ?? ""
            );
            setStudentFirstName(formDataResponse.studentFirstName ?? "");
            setStudentLastName(formDataResponse.studentLastName ?? "");
            setGradeLevel(formDataResponse.gradeLevel ?? "");
            //
            setSubject(formDataResponse.subject ?? "");
            setGeneralComments(formDataResponse.generalComments ?? "");
          }
        })
        .catch((error) => {
          console.error("Error fetching form data:", error);
        });
    }
  }, [formId]); // Only trigger when formId changes
  // Save draft
  const saveDraft = async () => {
    try {
      // Send data to the backend (adjust URL as needed)
      const response = await axios.post(
        "https://eduevaluator.com/api/studentConcern/update-draft",
        formData
      );
      console.log("Data saved to database:", response.data);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  // Define step-specific content
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <FormControl>
                  <FormLabel>Student First Name</FormLabel>
                  <TextField
                    autoFocus
                    required
                    fullWidth
                    variant="outlined"
                    value={studentFirstName}
                    onChange={(event) =>
                      setStudentFirstName(event.target.value)
                    }
                    sx={{
                      backgroundColor: "white",
                      borderRadius: 2,
                      width: "500px",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl>
                  <FormLabel>Student Last Name</FormLabel>
                  <TextField
                    autoFocus
                    required
                    fullWidth
                    variant="outlined"
                    value={studentLastName}
                    onChange={(event) => setStudentLastName(event.target.value)}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: 2,
                      width: "500px",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl>
                  <FormLabel>Grade Level</FormLabel>
                  <TextField
                    autoFocus
                    required
                    fullWidth
                    variant="outlined"
                    value={gradeLevel}
                    onChange={(event) => setGradeLevel(event.target.value)}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: 2,
                      width: "500px",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl>
                  <FormLabel>Subject</FormLabel>
                  <TextField
                    autoFocus
                    required
                    fullWidth
                    variant="outlined"
                    value={subject}
                    onChange={(event) => setSubject(event.target.value)}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: 2,
                      width: "500px",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormControl>
                  <FormLabel>General Comments</FormLabel>
                  <textarea
                    fullWidth
                    rows={4} // Adjust the number of rows as needed
                    value={generalComments}
                    onChange={(e) => setGeneralComments(e.target.value)}
                    style={{
                      width: "500px",
                      borderRadius: "8px", // Similar style as Material-UI
                      padding: "10px",
                      border: "1px solid #ccc",
                      boxSizing: "border-box", // Ensures padding is included in width
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
        );
      case 1:
        return (
          <div>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <Typography
                  variant="subtitle2"
                  sx={{ marginBottom: 2, fontWeight: "bold", marginTop: 3 }}
                >
                  Physical and Mental Health
                </Typography>
                <p>
                  Chronic health conditions, mental health challenges, or lack
                  of access to healthcare that impacts learning.
                </p>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Instructor Evaluation</FormLabel>
                  <Select
                    value={physicalAndMentalHealthEvaluation}
                    onChange={(event) =>
                      setPhysicalAndMentalHealthEvaluation(event.target.value)
                    }
                    sx={{ borderRadius: 2 }}
                  >
                    <MenuItem value="">No option selected</MenuItem>
                    <MenuItem value="No Concerns">No Concerns</MenuItem>
                    <MenuItem value="Some Concerns ">Some Concerns </MenuItem>
                    <MenuItem value="High Concerns">High Concerns</MenuItem>
                    <MenuItem value="Not applicable">Not applicable</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Evidence/Notes</FormLabel>
                  <textarea
                    rows={4} // Adjust the number of rows as needed
                    value={physicalAndMentalHealthEvidence}
                    onChange={(e) =>
                      setPhysicalAndMentalHealthEvidence(e.target.value)
                    }
                    placeholder="Document specific observations, such as absences, fatigue, or reports of stress or mental health struggles."
                    style={{
                      width: "100%",
                      borderRadius: "8px", // Similar style as Material-UI
                      padding: "10px",
                      border: "1px solid #ccc",
                      boxSizing: "border-box", // Ensures padding is included in width
                    }}
                  />
                </FormControl>
                <Divider />
              </Grid>
              <Grid item sm={12}>
                <Typography
                  variant="subtitle2"
                  sx={{ marginBottom: 2, fontWeight: "bold", marginTop: 3 }}
                >
                  Social and Family Support Networks
                </Typography>
                <p>
                  Limited family support, caring for dependents, or balancing
                  family responsibilities that compete with studies.
                </p>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Instructor Evaluation</FormLabel>
                  <Select
                    value={socialandFamilySupportNetworksEvaluation}
                    onChange={(event) =>
                      setsocialandFamilySupportNetworksEvaluation(
                        event.target.value
                      )
                    }
                    sx={{ borderRadius: 2 }}
                  >
                    <MenuItem value="">No option selected</MenuItem>
                    <MenuItem value="No Concerns">No Concerns</MenuItem>
                    <MenuItem value="Some Concerns ">Some Concerns </MenuItem>
                    <MenuItem value="High Concerns">High Concerns</MenuItem>
                    <MenuItem value="Not applicable">Not applicable</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Evidence/Notes</FormLabel>
                  <textarea
                    rows={4} // Adjust the number of rows as needed
                    value={setsocialandFamilySupportNetworksEvidence}
                    onChange={(e) =>
                      setsetsocialandFamilySupportNetworksEvidence(
                        e.target.value
                      )
                    }
                    placeholder="Note any mention of family challenges or lack of support affecting the student's attendance or focus."
                    style={{
                      width: "100%",
                      borderRadius: "8px", // Similar style as Material-UI
                      padding: "10px",
                      border: "1px solid #ccc",
                      boxSizing: "border-box", // Ensures padding is included in width
                    }}
                  />
                </FormControl>
                <Divider />
              </Grid>
              <Grid item sm={12}>
                <Typography
                  variant="subtitle2"
                  sx={{ marginBottom: 2, fontWeight: "bold", marginTop: 3 }}
                >
                  Community and Social Integration
                </Typography>
                <p>
                  Limited connection with peers, mentors, or local community
                  resources; reports feelings of isolation.
                </p>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Instructor Evaluation</FormLabel>
                  <Select
                    value={communitySocialIntegrationEvaluation}
                    onChange={(event) =>
                      setcommunitySocialIntegrationEvaluation(
                        event.target.value
                      )
                    }
                    sx={{ borderRadius: 2 }}
                  >
                    <MenuItem value="">No option selected</MenuItem>
                    <MenuItem value="No Concerns">No Concerns</MenuItem>
                    <MenuItem value="Some Concerns ">Some Concerns </MenuItem>
                    <MenuItem value="High Concerns">High Concerns</MenuItem>
                    <MenuItem value="Not applicable">Not applicable</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Evidence/Notes</FormLabel>
                  <textarea
                    rows={4} // Adjust the number of rows as needed
                    value={communitySocialIntegrationEvidence}
                    onChange={(e) =>
                      setcommunitySocialIntegrationEvidence(e.target.value)
                    }
                    placeholder="Observe student engagement in group activities or their utilization of peer networks and community resources."
                    style={{
                      width: "100%",
                      borderRadius: "8px", // Similar style as Material-UI
                      padding: "10px",
                      border: "1px solid #ccc",
                      boxSizing: "border-box", // Ensures padding is included in width
                    }}
                  />
                </FormControl>
                <Divider />
              </Grid>
              <Grid item sm={12}>
                <Typography
                  variant="subtitle2"
                  sx={{ marginBottom: 2, fontWeight: "bold", marginTop: 3 }}
                >
                  Learning Engagement and Motivation
                </Typography>
                <p>
                  Low participation in class, reluctance to seek help,
                  disinterest in assignments, or difficulty staying motivated.
                </p>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Instructor Evaluation</FormLabel>
                  <Select
                    value={learningEngagementMotivationEvaluation}
                    onChange={(event) =>
                      setlearningEngagementMotivationEvaluation(
                        event.target.value
                      )
                    }
                    sx={{ borderRadius: 2 }}
                  >
                    <MenuItem value="">No option selected</MenuItem>
                    <MenuItem value="No Concerns">No Concerns</MenuItem>
                    <MenuItem value="Some Concerns ">Some Concerns </MenuItem>
                    <MenuItem value="High Concerns">High Concerns</MenuItem>
                    <MenuItem value="Not applicable">Not applicable</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Evidence/Notes</FormLabel>
                  <textarea
                    rows={4} // Adjust the number of rows as needed
                    value={learningEngagementMotivationEvidence}
                    onChange={(e) =>
                      setlearningEngagementMotivationEvidence(e.target.value)
                    }
                    placeholder="Assess based on participation in class discussions, assignment submissions, and proactive learning behaviors."
                    style={{
                      width: "100%",
                      borderRadius: "8px", // Similar style as Material-UI
                      padding: "10px",
                      border: "1px solid #ccc",
                      boxSizing: "border-box", // Ensures padding is included in width
                    }}
                  />
                </FormControl>
                <Divider />
              </Grid>
            </Grid>
          </div>
        );
      case 2:
        return (
          <div>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <Typography
                  variant="subtitle2"
                  sx={{ marginBottom: 2, fontWeight: "bold", marginTop: 3 }}
                >
                  Attendance and Punctuality
                </Typography>
                <p>
                  Frequent absences or tardiness; inconsistency in attendance
                  that could impact progression and comprehension.
                </p>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Instructor Evaluation</FormLabel>
                  <Select
                    value={attendancePunctualityEvaluation}
                    onChange={(event) =>
                      setattendancePunctualityEvaluation(event.target.value)
                    }
                    sx={{ borderRadius: 2 }}
                  >
                    <MenuItem value="">No option selected</MenuItem>
                    <MenuItem value="No Concerns">No Concerns</MenuItem>
                    <MenuItem value="Some Concerns ">Some Concerns </MenuItem>
                    <MenuItem value="High Concerns">High Concerns</MenuItem>
                    <MenuItem value="Not applicable">Not applicable</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Evidence/Notes</FormLabel>
                  <textarea
                    rows={4} // Adjust the number of rows as needed
                    value={attendancePunctualitynEvidence}
                    onChange={(e) =>
                      setattendancePunctualityEvidence(e.target.value)
                    }
                    placeholder="Track attendance records and any patterns of lateness or absences that disrupt learning continuity."
                    style={{
                      width: "100%",
                      borderRadius: "8px", // Similar style as Material-UI
                      padding: "10px",
                      border: "1px solid #ccc",
                      boxSizing: "border-box", // Ensures padding is included in width
                    }}
                  />
                </FormControl>
                <Divider />
              </Grid>
              <Grid item sm={12}>
                <Typography
                  variant="subtitle2"
                  sx={{ marginBottom: 2, fontWeight: "bold", marginTop: 3 }}
                >
                  Financial Stability and Resources
                </Typography>
                <p>
                  Financial hardships affecting ability to afford program costs,
                  materials, or time management between work/study.
                </p>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Instructor Evaluation</FormLabel>
                  <Select
                    value={financialStabilityResourcesEvaluation}
                    onChange={(event) =>
                      setfinancialStabilityResourcesEvaluation(
                        event.target.value
                      )
                    }
                    sx={{ borderRadius: 2 }}
                  >
                    <MenuItem value="">No option selected</MenuItem>
                    <MenuItem value="No Concerns">No Concerns</MenuItem>
                    <MenuItem value="Some Concerns ">Some Concerns </MenuItem>
                    <MenuItem value="High Concerns">High Concerns</MenuItem>
                    <MenuItem value="Not applicable">Not applicable</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Evidence/Notes</FormLabel>
                  <textarea
                    rows={4} // Adjust the number of rows as needed
                    value={financialStabilityResourcesEvidence}
                    onChange={(e) =>
                      setfinancialStabilityResourcesEvidence(e.target.value)
                    }
                    placeholder="Record mentions of financial difficulties, missed opportunities due to employment conflicts, etc."
                    style={{
                      width: "100%",
                      borderRadius: "8px", // Similar style as Material-UI
                      padding: "10px",
                      border: "1px solid #ccc",
                      boxSizing: "border-box", // Ensures padding is included in width
                    }}
                  />
                </FormControl>
                <Divider />
              </Grid>
              <Grid item sm={12}>
                <Typography
                  variant="subtitle2"
                  sx={{ marginBottom: 2, fontWeight: "bold", marginTop: 3 }}
                >
                  Academic Progress and Performance
                </Typography>
                <p>
                  Falling behind in assignments, poor grades, or requiring
                  frequent remedial support to meet program standards.
                </p>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Instructor Evaluation</FormLabel>
                  <Select
                    value={academicProgressPerformanceEvaluation}
                    onChange={(event) =>
                      setacademicProgressPerformanceEvaluation(
                        event.target.value
                      )
                    }
                    sx={{ borderRadius: 2 }}
                  >
                    <MenuItem value="">No option selected</MenuItem>
                    <MenuItem value="No Concerns">No Concerns</MenuItem>
                    <MenuItem value="Some Concerns ">Some Concerns </MenuItem>
                    <MenuItem value="High Concerns">High Concerns</MenuItem>
                    <MenuItem value="Not applicable">Not applicable</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Evidence/Notes</FormLabel>
                  <textarea
                    rows={4} // Adjust the number of rows as needed
                    value={academicProgressPerformanceEvidence}
                    onChange={(e) =>
                      setacademicProgressPerformanceEvidence(e.target.value)
                    }
                    placeholder="Evaluate based on performance in assessments, tests, and instructor feedback on academic progress.."
                    style={{
                      width: "100%",
                      borderRadius: "8px", // Similar style as Material-UI
                      padding: "10px",
                      border: "1px solid #ccc",
                      boxSizing: "border-box", // Ensures padding is included in width
                    }}
                  />
                </FormControl>
                <Divider />
              </Grid>
              <Grid item sm={12}>
                <Typography
                  variant="subtitle2"
                  sx={{ marginBottom: 2, fontWeight: "bold", marginTop: 3 }}
                >
                  Career Goals and Program Alignment
                </Typography>
                <p>
                  Lack of clarity on career goals or perceived disconnect
                  between program content and career aspirations.
                </p>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Instructor Evaluation</FormLabel>
                  <Select
                    value={careerGoalsEvaluation}
                    onChange={(event) =>
                      setcareerGoalsEvaluation(event.target.value)
                    }
                    sx={{ borderRadius: 2 }}
                  >
                    <MenuItem value="">No option selected</MenuItem>
                    <MenuItem value="No Concerns">No Concerns</MenuItem>
                    <MenuItem value="Some Concerns ">Some Concerns </MenuItem>
                    <MenuItem value="High Concerns">High Concerns</MenuItem>
                    <MenuItem value="Not applicable">Not applicable</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Evidence/Notes</FormLabel>
                  <textarea
                    rows={4} // Adjust the number of rows as needed
                    value={careerGoalsEvidence}
                    onChange={(e) => setcareerGoalsEvidence(e.target.value)}
                    placeholder="Discuss career goals with the student to ensure alignment with program content and outcomes."
                    style={{
                      width: "100%",
                      borderRadius: "8px", // Similar style as Material-UI
                      padding: "10px",
                      border: "1px solid #ccc",
                      boxSizing: "border-box", // Ensures padding is included in width
                    }}
                  />
                </FormControl>
                <Divider />
              </Grid>
            </Grid>
          </div>
        );
      case 3:
        return (
          <div>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <Typography
                  variant="subtitle2"
                  sx={{ marginBottom: 2, fontWeight: "bold", marginTop: 3 }}
                >
                  Time Management and Organizational Skills
                </Typography>
                <p>
                  Difficulty balancing coursework with personal
                  responsibilities, deadlines, or maintaining personal
                  organization.
                </p>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Instructor Evaluation</FormLabel>
                  <Select
                    value={timeManagementEvaluation}
                    onChange={(event) =>
                      settimeManagementEvaluation(event.target.value)
                    }
                    sx={{ borderRadius: 2 }}
                  >
                    <MenuItem value="">No option selected</MenuItem>
                    <MenuItem value="No Concerns">No Concerns</MenuItem>
                    <MenuItem value="Some Concerns ">Some Concerns </MenuItem>
                    <MenuItem value="High Concern">High Concerns</MenuItem>
                    <MenuItem value="Not applicable">Not applicable</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Evidence/Notes</FormLabel>
                  <textarea
                    rows={4} // Adjust the number of rows as needed
                    value={timeManagementEvidence}
                    onChange={(e) => settimeManagementEvidence(e.target.value)}
                    placeholder="Observe student’s adherence to deadlines, punctuality, and ability to manage workload effectively."
                    style={{
                      width: "100%",
                      borderRadius: "8px", // Similar style as Material-UI
                      padding: "10px",
                      border: "1px solid #ccc",
                      boxSizing: "border-box", // Ensures padding is included in width
                    }}
                  />
                </FormControl>
                <Divider />
              </Grid>
              <Grid item sm={12}>
                <Typography
                  variant="subtitle2"
                  sx={{ marginBottom: 2, fontWeight: "bold", marginTop: 3 }}
                >
                  Digital Literacy and Access
                </Typography>
                <p>
                  Limited digital skills or lack of access to required
                  technology, hindering online participation and assignments.
                </p>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Instructor Evaluation</FormLabel>
                  <Select
                    value={digitalLiteracyEvaluation}
                    onChange={(event) =>
                      setdigitalLiteracyEvaluation(event.target.value)
                    }
                    sx={{ borderRadius: 2 }}
                  >
                    <MenuItem value="">No option selected</MenuItem>
                    <MenuItem value="No Concerns">No Concerns</MenuItem>
                    <MenuItem value="Some Concerns ">Some Concerns </MenuItem>
                    <MenuItem value="High Concerns">High Concerns</MenuItem>
                    <MenuItem value="Not applicable">Not applicable</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Evidence/Notes</FormLabel>
                  <textarea
                    rows={4} // Adjust the number of rows as needed
                    value={digitalLiteracyEvidence}
                    onChange={(e) => setdigitalLiteracyEvidence(e.target.value)}
                    placeholder="Document any observed challenges with using digital tools, accessing online materials, or basic tech skills."
                    style={{
                      width: "100%",
                      borderRadius: "8px", // Similar style as Material-UI
                      padding: "10px",
                      border: "1px solid #ccc",
                      boxSizing: "border-box", // Ensures padding is included in width
                    }}
                  />
                </FormControl>
                <Divider />
              </Grid>
              <Grid item sm={12}>
                <Typography
                  variant="subtitle2"
                  sx={{ marginBottom: 2, fontWeight: "bold", marginTop: 3 }}
                >
                  Problem-Solving and Resilience
                </Typography>
                <p>
                  Struggles to navigate setbacks or lacks persistence when faced
                  with challenges.
                </p>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Instructor Evaluation</FormLabel>
                  <Select
                    value={problemSolvingEvaluation}
                    onChange={(event) =>
                      setproblemSolvingEvaluation(event.target.value)
                    }
                    sx={{ borderRadius: 2 }}
                  >
                    <MenuItem value="">No option selected</MenuItem>
                    <MenuItem value="No Concerns">No Concerns</MenuItem>
                    <MenuItem value="Some Concerns ">Some Concerns </MenuItem>
                    <MenuItem value="High Concerns">High Concerns</MenuItem>
                    <MenuItem value="Not applicable">Not applicable</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Evidence/Notes</FormLabel>
                  <textarea
                    rows={4} // Adjust the number of rows as needed
                    value={problemSolvingEvidence}
                    onChange={(e) => setproblemSolvingEvidence(e.target.value)}
                    placeholder="Observe reactions to difficult tasks or setbacks, e.g., needing substantial encouragement to continue."
                    style={{
                      width: "100%",
                      borderRadius: "8px", // Similar style as Material-UI
                      padding: "10px",
                      border: "1px solid #ccc",
                      boxSizing: "border-box", // Ensures padding is included in width
                    }}
                  />
                </FormControl>
                <Divider />
              </Grid>
              <Grid item sm={12}>
                <Typography
                  variant="subtitle2"
                  sx={{ marginBottom: 2, fontWeight: "bold", marginTop: 3 }}
                >
                  Cultural and Language Considerations
                </Typography>
                <p>
                  Cultural barriers or language proficiency issues that impact
                  understanding, confidence, or participation.
                </p>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Instructor Evaluation</FormLabel>
                  <Select
                    value={languageConsiderationsEvaluation}
                    onChange={(event) =>
                      setlanguageConsiderationsEvaluation(event.target.value)
                    }
                    sx={{ borderRadius: 2 }}
                  >
                    <MenuItem value="">No option selected</MenuItem>
                    <MenuItem value="No Concerns">No Concerns</MenuItem>
                    <MenuItem value="Some Concerns ">Some Concerns </MenuItem>
                    <MenuItem value="High Concerns">High Concerns</MenuItem>
                    <MenuItem value="Not applicable">Not applicable</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Evidence/Notes</FormLabel>
                  <textarea
                    rows={4} // Adjust the number of rows as needed
                    value={languageConsiderationsEvidence}
                    onChange={(e) =>
                      setlanguageConsiderationsEvidence(e.target.value)
                    }
                    placeholder="Assess for any language or cultural challenges that might impede comprehension or comfort in class."
                    style={{
                      width: "100%",
                      borderRadius: "8px", // Similar style as Material-UI
                      padding: "10px",
                      border: "1px solid #ccc",
                      boxSizing: "border-box", // Ensures padding is included in width
                    }}
                  />
                </FormControl>
                <Divider />
              </Grid>
              <Grid item sm={12}>
                <Typography
                  variant="subtitle2"
                  sx={{ marginBottom: 2, fontWeight: "bold", marginTop: 3 }}
                >
                  Self-Efficacy and Confidence
                </Typography>
                <p>
                  Low confidence in academic abilities or doubts about success
                  in the program, which could impact persistence.
                </p>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Instructor Evaluation</FormLabel>
                  <Select
                    value={selfEfficacyEvaluation}
                    onChange={(event) =>
                      setselfEfficacyEvaluation(event.target.value)
                    }
                    sx={{ borderRadius: 2 }}
                  >
                    <MenuItem value="">No option selected</MenuItem>
                    <MenuItem value="No Concerns">No Concerns</MenuItem>
                    <MenuItem value="Some Concerns ">Some Concerns </MenuItem>
                    <MenuItem value="High Concerns">High Concerns</MenuItem>
                    <MenuItem value="Not applicable">Not applicable</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Evidence/Notes</FormLabel>
                  <textarea
                    rows={4} // Adjust the number of rows as needed
                    value={selfEfficacyEvidence}
                    onChange={(e) => setselfEfficacyEvidence(e.target.value)}
                    placeholder="Note student’s expressed self-doubts or visible lack of confidence during interactions or presentations."
                    style={{
                      width: "100%",
                      borderRadius: "8px", // Similar style as Material-UI
                      padding: "10px",
                      border: "1px solid #ccc",
                      boxSizing: "border-box", // Ensures padding is included in width
                    }}
                  />
                </FormControl>
                <Divider />
              </Grid>
            </Grid>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {" "}
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          borderRadius: 2,
          minWidth: "100%",
          minHeight: "100%",
        }}
      >
        <Stepper activeStep={activeStep} sx={{ marginBottom: 3 }}>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Container>
          <Button
            onClick={saveDraft}
            style={{
              backgroundColor: "#4286F0",
              color: "white",
              borderRadius: 20,
              marginBottom: 10,
            }}
          >
            Save Progress
          </Button>
          {open && (
            <Alert
              severity="warning"
              onClose={handleClose}
              style={{ marginBottom: 10 }}
            >
              We recommend using general information and ensuring that all data
              shared adheres to your institution's privacy guidelines. All data
              is processed securely, but to protect student privacy, please
              refrain from submitting any information that could compromise
              confidentiality.
            </Alert>
          )}

          <Box sx={{ marginBottom: 3 }}>
            <Accordion
              expanded={true}
              sx={{
                backgroundColor: "white",
                color: "black",
                marginBottom: 2,
                borderRadius: 2,
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#e0e0e0",
                  padding: "16px",
                  borderRadius: "4px 4px 0 0",
                  color: "black",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                <SchoolIcon sx={{ marginRight: 1 }} />
                Student of Concern
              </Box>
              <Container>
                {/* Render step-specific content */}
                {renderStepContent(activeStep)}
              </Container>
            </Accordion>
          </Box>

          {/* Navigation buttons */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 2,
            }}
          >
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              style={{
                backgroundColor: "#4286F0",
                color: "white",
                borderRadius: 20,
                marginTop: 5,
              }}
              color="secondary"
            >
              Back
            </Button>
            {activeStep === steps.length - 1 ? (
              <Button
                onClick={handleSubmit}
                style={{
                  backgroundColor: "#4286F0",
                  color: "white",
                  borderRadius: 20,
                  marginTop: 5,
                }}
              >
                Submit
              </Button>
            ) : (
              <Button
                onClick={handleNext}
                style={{
                  backgroundColor: "#4286F0",
                  color: "white",
                  borderRadius: 20,
                  marginTop: 5,
                }}
              >
                Next
              </Button>
            )}
          </Box>
        </Container>
        <PDFDownloadLink
          document={<StudentConcernPDF formData={formData} />}
          fileName="LessonPlan.pdf"
        >
          {({ loading, blob, url, error }) => (
            <a ref={pdfRef} style={{ display: "none" }} href={url} download>
              Download PDF
            </a>
          )}
        </PDFDownloadLink>
      </Paper>
    </div>
  );
}

export default StudentOfConcernForm;
