import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie"; // Import cookies-js

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = Cookies.get("email"); // Get the token from cookies
    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  const login = (token) => {
    localStorage.setItem("token", token);
    setIsAuthenticated(true);
    navigate("/dashboard");
  };

  const logout = () => {
    Cookies.remove("email"); // Remove the token from cookies
    Cookies.remove("id");
    Cookies.remove("name");
    setIsAuthenticated(false);
    navigate("/");
  };

  const register = (token) => {
    Cookies.set("email", token); // Set the token in cookies
    setIsAuthenticated(true);
    navigate("/dashboard");
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
