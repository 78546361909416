const categories_general = [
  {
    title: "Domain 1: Planning and Preparation",
    description:
      "Prepare effective lessons that align with curriculum goals, student needs, and instructional objectives.",
    categoryDetails: [
      {
        name: "1a: Lesson Planning",
        options: {
          Ineffective: [
            "Lesson plans are incomplete or do not align with instructional goals.",
            "Learning objectives are unclear or inappropriate for students' developmental levels.",
            "The teacher does not take into account the varying needs of students.",
          ],
          Developing: [
            "Lesson plans include basic instructional goals but lack depth or differentiation.",
            "Objectives are present but may not be clearly communicated to students.",
            "Some efforts are made to address students' diverse needs, but consistency is lacking.",
          ],
          Effective: [
            "Lesson plans are complete, clearly aligned with curriculum goals, and consider diverse student needs.",
            "Learning objectives are well-defined and communicated effectively to students.",
            "Lessons are differentiated to meet the needs of all students in the classroom.",
          ],
          HighlyEffective: [
            "Lesson plans are thorough, innovative, and tailored to meet individual student needs.",
            "Learning objectives are clearly articulated and inspire student engagement.",
            "The teacher anticipates student needs and adapts plans to ensure optimal learning outcomes for each student.",
          ],
        },
      },
      {
        name: "1b: Use of Instructional Resources",
        options: {
          Ineffective: [
            "Instructional materials are inadequate or inappropriate for the lesson.",
            "The teacher does not utilize available resources effectively.",
            "Students have limited access to learning resources.",
          ],
          Developing: [
            "Some instructional materials are used, but they may not fully support learning objectives.",
            "The teacher uses resources inconsistently or without a clear purpose.",
            "Access to resources is inconsistent, affecting student engagement.",
          ],
          Effective: [
            "Instructional materials are appropriate, well-prepared, and effectively support learning objectives.",
            "The teacher integrates available resources into lessons to enhance student understanding.",
            "Students have consistent access to quality learning resources.",
          ],
          HighlyEffective: [
            "Instructional materials are carefully selected and used to enhance student understanding and engagement.",
            "The teacher utilizes a variety of resources, including technology, to create rich learning experiences.",
            "Students actively use a wide range of resources to deepen their understanding and pursue their learning interests.",
          ],
        },
      },
    ],
  },
  {
    title: "Domain 2: Classroom Environment",
    description:
      "Create and maintain a safe, respectful, and supportive learning environment.",
    categoryDetails: [
      {
        name: "2a: Classroom Culture",
        options: {
          Ineffective: [
            "The classroom environment is unwelcoming or unsafe.",
            "There is little sense of community or mutual respect among students.",
            "The teacher fails to promote positive behavior.",
          ],
          Developing: [
            "The classroom environment is generally safe but lacks warmth or consistency.",
            "The teacher attempts to foster a sense of community but does so inconsistently.",
            "Behavioral expectations are unclear or inconsistently enforced.",
          ],
          Effective: [
            "The classroom is safe, respectful, and conducive to learning.",
            "The teacher promotes a positive classroom culture where students feel valued.",
            "Behavioral expectations are clearly communicated and consistently enforced.",
          ],
          HighlyEffective: [
            "The classroom is an inviting, inclusive, and supportive space for all learners.",
            "The teacher establishes a strong community where students respect and support one another.",
            "Students actively contribute to maintaining a positive and productive classroom culture.",
          ],
        },
      },
      {
        name: "2b: Classroom Management",
        options: {
          Ineffective: [
            "The teacher struggles to manage student behavior, resulting in a disruptive environment.",
            "Classroom routines and procedures are unclear or not established.",
            "Students frequently exhibit off-task behavior.",
          ],
          Developing: [
            "The teacher has established some classroom routines, but they are not consistently followed.",
            "Behavior management strategies are in place but lack effectiveness.",
            "Some students are engaged, while others are frequently off-task.",
          ],
          Effective: [
            "The teacher effectively manages student behavior, resulting in a productive learning environment.",
            "Classroom routines and procedures are well-established and consistently followed.",
            "Most students are engaged and on-task throughout the lesson.",
          ],
          HighlyEffective: [
            "The teacher maintains a well-managed, orderly classroom with minimal disruptions.",
            "Routines are efficient, and students take an active role in maintaining them.",
            "Students are consistently engaged, self-disciplined, and take responsibility for their behavior.",
          ],
        },
      },
    ],
  },
  {
    title: "Domain 3: Instruction",
    description:
      "Deliver high-quality, engaging instruction that supports students' understanding and growth.",
    categoryDetails: [
      {
        name: "3a: Instructional Delivery",
        options: {
          Ineffective: [
            "Instruction lacks clarity and fails to engage students.",
            "The teacher's explanations are confusing or incomplete.",
            "Students demonstrate a lack of understanding of the lesson content.",
          ],
          Developing: [
            "The teacher's instruction is generally clear but may lack engagement.",
            "Some students understand the lesson, but others need additional support.",
            "The teacher occasionally checks for understanding but does not adjust instruction based on feedback.",
          ],
          Effective: [
            "Instruction is clear, engaging, and supports students' learning.",
            "The teacher checks for understanding and adjusts instruction as needed.",
            "Most students demonstrate an understanding of the lesson content.",
          ],
          HighlyEffective: [
            "Instruction is dynamic, engaging, and deeply supports student understanding.",
            "The teacher skillfully adjusts instruction in real-time to meet the needs of all students.",
            "Students are highly engaged and take an active role in their learning.",
          ],
        },
      },
      {
        name: "3b: Questioning and Discussion Techniques",
        options: {
          Ineffective: [
            "The teacher uses low-level questions that do not promote critical thinking.",
            "Discussion is dominated by the teacher, with little student participation.",
            "Student responses are minimal and lack depth.",
          ],
          Developing: [
            "The teacher uses a mix of low- and high-level questions but does not always encourage deep discussion.",
            "Student participation is uneven, and discussions are teacher-led.",
            "Some students contribute to discussions, but many are passive.",
          ],
          Effective: [
            "The teacher uses high-level questions that encourage critical thinking.",
            "Discussions involve many students, with the teacher facilitating rather than dominating.",
            "Students are engaged in meaningful discussion, building on each other's ideas.",
          ],
          HighlyEffective: [
            "The teacher uses probing questions that inspire deep critical thinking and inquiry.",
            "Students lead discussions, asking thoughtful questions of one another.",
            "Discussions are rich and student-centered, with all students actively participating.",
          ],
        },
      },
    ],
  },
  {
    title: "Domain 4: Professional Responsibilities",
    description:
      "Fulfill professional responsibilities and contribute to the school community.",
    categoryDetails: [
      {
        name: "4a: Professional Growth",
        options: {
          Ineffective: [
            "The teacher shows little interest in professional development or improving practices.",
            "Is resistant to feedback and rarely reflects on teaching effectiveness.",
            "Fails to engage in opportunities for professional growth.",
          ],
          Developing: [
            "The teacher participates in professional development but implementation of new practices is inconsistent.",
            "Accepts feedback but rarely takes action on it.",
            "Reflects on teaching practices occasionally but lacks consistency.",
          ],
          Effective: [
            "The teacher actively participates in professional development and applies new learning.",
            "Reflects on feedback and implements strategies for improvement.",
            "Engages in regular reflection to improve teaching practices.",
          ],
          HighlyEffective: [
            "The teacher is a leader in professional growth, sharing knowledge with colleagues.",
            "Actively seeks feedback and uses it to make significant improvements.",
            "Reflects deeply on practices, continually striving for excellence.",
          ],
        },
      },
      {
        name: "4b: Collaboration and Contribution",
        options: {
          Ineffective: [
            "Does not collaborate with colleagues or contribute to the school community.",
            "Fails to communicate effectively with families.",
            "Isolates themselves from the broader school environment.",
          ],
          Developing: [
            "Collaborates occasionally but not consistently.",
            "Communicates with families only when required.",
            "Participates in school activities but does not take an active role.",
          ],
          Effective: [
            "Collaborates regularly with colleagues to improve student learning.",
            "Communicates openly and effectively with families.",
            "Actively contributes to the school community.",
          ],
          HighlyEffective: [
            "Leads collaborative efforts, fostering a culture of teamwork.",
            "Builds strong relationships with families, engaging them in their children's education.",
            "Takes a leadership role in school initiatives and community-building activities.",
          ],
        },
      },
    ],
  },
];
export default categories_general;
