import * as React from "react";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import LockIcon from "@mui/icons-material/Lock"; // Import the lock icon
import Chip from "@mui/material/Chip";
import Cookies from "js-cookie";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import { styled } from "@mui/material/styles";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import RssFeedRoundedIcon from "@mui/icons-material/RssFeedRounded";
import { Link } from "react-router-dom";

const cardData = [
  {
    img: "https://cdn.midjourney.com/5f2f8dd8-4562-46f0-b9bb-1c2bd78b6504/0_2.png",
    tag: "Professional Development and Leadership",
    title: "EduLetters Pro",
    link: "/eduLetters",
    description:
      " A communication tool crafted for educators, administrators, and school staff. ",
    authors: [
      { name: "Curriculum Edge", avatar: "/static/images/avatar/7.jpg" },
    ],
  },
  {
    img: "https://cdn.midjourney.com/cd776c0e-a549-46c2-9e31-ee6356bb9f47/0_3.png",
    tag: "Curriculum and Lesson Planning",
    title: "Concept Simplification",
    link: "/conceptSimplification",
    description: "Can improved unsteanding of a topic or subject.",
    authors: [
      { name: "Curriculum Edge", avatar: "/static/images/avatar/1.jpg" },
    ],
    accessRequired: false, // Set to true if access is required
  },
  {
    img: "https://cdn.midjourney.com/56710752-50d2-4dae-9608-0d16f7a4f928/0_2.png",
    tag: "Curriculum and Lesson Planning",
    title: "FlexiPace ",
    link: "/aiCourseCondensor",
    description:
      "FlexiPace Course Condenser is an advanced GPT tool designed to adjust course durations by condensing materials into shorter formats or expanding them for in-depth study.",
    authors: [
      { name: "Curriculum Edge", avatar: "/static/images/avatar/6.jpg" },
    ],
    accessRequired: false,
  },
  {
    img: "https://cdn.midjourney.com/8faeef55-044d-429f-a771-2c7d85a29f8e/0_1.png",
    tag: "Curriculum and Lesson Planning",
    title: "AI Lesson Plans",
    link: "/aiLessonPlan",
    description:
      "ocuses on the alignment,of objectives, course assessment, and online presence.",
    authors: [
      { name: "Curriculum Edge", avatar: "/static/images/avatar/7.jpg" },
    ],
    accessRequired: false,
  },
  {
    img: "https://cdn.midjourney.com/338afce4-b592-4781-b1b2-e6a8d88d08b4/0_2.png",
    tag: "Strategic Analysis and Evaluation",
    title: "School Trip Planner",
    link: "/schoolTripPlanner",
    description:
      "It helps plan excursions by suggesting destinations, activities, and itineraries suitable for different age groups and educational goals",
    authors: [
      { name: "Curriculum Edge", avatar: "/static/images/avatar/7.jpg" },
    ],
  },
  {
    img: "https://cdn.midjourney.com/09b750fe-d3c1-4dfc-b80c-3910fb4338b0/0_2.png",
    tag: "Curriculum and Lesson Planning",
    title: "Substitute lesson plan",
    link: "/subsituteLessonPlan",
    description:
      "Expert in designing lesson plans tailored for cover supervisors managing classrooms in the absence of the regular teacher. ",
    authors: [
      { name: "Curriculum Edge", avatar: "/static/images/avatar/7.jpg" },
    ],
    accessRequired: false,
  },
  {
    img: "https://cdn.midjourney.com/4ce28bb8-def3-41f1-b0cd-ead3ac30d6f4/0_0.png",
    tag: "Professional Development and Leadership",
    title: " AI Literacy Explorer",
    link: "/literacyExplorer",
    description:
      "It is designed to pull information from the latest global standards and guidelines, ensuring that its insights are up-to-date and aligned with current international practices. ",
    authors: [
      { name: "Curriculum Edge", avatar: "/static/images/avatar/7.jpg" },
    ],
    accessRequired: false,
  },
  {
    img: "https://cdn.midjourney.com/7fb8b07f-3aec-4eca-9b04-158c3bd0ece8/0_1.png",
    tag: "Professional Development and Leadership",
    title: "Education Navigator",
    link: "/educationNav",
    description:
      "Assist parents in understanding and navigating the intricacies of 504 educational plans and Individualized Education Programs (IEPs).",
    authors: [
      { name: "Curriculum Edge", avatar: "/static/images/avatar/7.jpg" },
    ],
    accessRequired: false,
  },
  {
    img: "https://cdn.midjourney.com/828e5f9b-5e21-4382-9c1f-e42c094aa02d/0_2.png",
    tag: "Curriculum and Lesson Planning",
    title: "Quiz Maker",
    link: "/quizMaker",
    description: "Assists teachers creating quiz material.",
    authors: [
      { name: "Curriculum Edge", avatar: "/static/images/avatar/7.jpg" },
    ],
    accessRequired: false,
  },
  {
    img: "https://cdn.midjourney.com/29acef9f-8274-4058-8d19-e5053c8827ac/0_0.png ",
    tag: "Educational Content and Learning Support",
    title: "Assistive Tech Companion",
    link: "/assistiveTechCompanion",
    description:
      "Enhances assistive technology access with language-powered solutions.",
    authors: [
      { name: "Curriculum Edge", avatar: "/static/images/avatar/7.jpg" },
    ],
    accessRequired: false,
  },

  {
    img: "https://cdn.midjourney.com/26fc41ca-f512-456c-b9f1-688cf8a8a274/0_1.png",
    tag: "Curriculum and Lesson Planning",
    title: "Designs competency frameworks",
    link: "/competencyFrameworks",
    description: "Designs competency frameworks tailored to subjects. ",
    authors: [
      { name: "Curriculum Edge", avatar: "/static/images/avatar/7.jpg" },
    ],
    accessRequired: false,
  },
  {
    img: "https://cdn.midjourney.com/780a8ed5-89b9-42a9-82d7-83c1556aa5d8/0_1.png ",
    tag: "Curriculum and Lesson Planning",
    title: "TVET Lesson Plan Creator",
    link: "/tvetLessonPlan",
    description:
      "Assists teachers in creating detailed lesson plans for Technical and Vocational Education and Training (TVET) courses ",
    authors: [
      { name: "Curriculum Edge", avatar: "/static/images/avatar/7.jpg" },
    ],
    accessRequired: false,
  },
  {
    img: "https://cdn.midjourney.com/6bb52456-88f1-485d-91b2-7debd96338e7/0_0.png ",
    tag: "Strategic Analysis and Evaluation",
    title: "Educational AI SWOT Analyst",
    link: "/swot",
    description:
      "Assist users in refining their SWOT (Strengths, Weaknesses, Opportunities, Threats) analyses specifically targeted towards AI in education ",
    authors: [
      { name: "Curriculum Edge", avatar: "/static/images/avatar/7.jpg" },
    ],
    accessRequired: false,
  },
  {
    img: "https://cdn.midjourney.com/9f3b85e3-5c09-4a4c-96ca-7c49e220caaa/0_2.png",
    tag: "Professional Development and Leadership",
    title: "Workplace Navigator",
    link: "/workPlaceNav",
    description:
      "Workplace Navigator is a friendly guide for employees and middle managers in toxic work environments",
    authors: [
      { name: "Curriculum Edge", avatar: "/static/images/avatar/7.jpg" },
    ],
    accessRequired: false,
  },
  {
    img: "https://cdn.midjourney.com/26fc41ca-f512-456c-b9f1-688cf8a8a274/0_3.png ",
    tag: "Strategic Analysis and Evaluation",
    title: "Leadership Assistant",
    link: "/leadershipAssistant",
    description:
      " Leadership Assistant is a dynamic AI tailored for leaders, blending analytical acumen, empathetic guidance, and creative thinking",
    authors: [
      { name: "Curriculum Edge", avatar: "/static/images/avatar/7.jpg" },
    ],
    accessRequired: false,
  },
  {
    img: "https://cdn.midjourney.com/0e9d9d3b-cfc3-4e30-92eb-6d95f4bbe040/0_1.png",
    tag: "Professional Development and Leadership",
    title: "Learning Theory Guide",
    link: "/learningTheory",
    description:
      "Your role is to focus on theories used by instructional designers, spanning from the era of John Dewey to the present.",
    authors: [
      { name: "Curriculum Edge", avatar: "/static/images/avatar/7.jpg" },
    ],
    accessRequired: false,
  },
  {
    img: "https://cdn.midjourney.com/75258868-0d25-4537-98db-ec358c19e44b/0_3.png",
    tag: "Educational Content and Learning Support",
    title: "Course Content Evaluator",
    link: "/courseContent",
    description:
      "Specialize in modernizing and humanizing AI-generated educational material.",
    authors: [
      { name: "Curriculum Edge", avatar: "/static/images/avatar/7.jpg" },
    ],
    accessRequired: false,
  },

  {
    img: "https://cdn.midjourney.com/cdfef921-02b3-44fb-9287-a427d00f5381/0_1.png",
    tag: "Professional Development and Leadership",
    title: "AI Framework Guide",
    link: "/aiFramework",
    description:
      " It offers up-to-date information, guidance, and analysis on AI-related topics, ensuring adherence to the highest standards of ethical practice, safety protocols, and design excellence",
    authors: [
      { name: "Curriculum Edge", avatar: "/static/images/avatar/7.jpg" },
    ],
    accessRequired: false,
  },
  {
    img: "https://cdn.midjourney.com/32ed89e0-b1eb-4856-96cb-4721cc6232a2/0_0.png",
    tag: "Image Generator",
    title: "  Visual Prompt Wizard",
    link: "/visualPrompt",
    description:
      "This is a specialize in prompt engineering, focusing on generating effective prompts for images and graphs, including those with historical and current contexts.",
    authors: [
      { name: "Curriculum Edge", avatar: "/static/images/avatar/7.jpg" },
    ],
    accessRequired: false,
  },

  {
    img: "https://cdn.midjourney.com/a638b526-fb3b-437a-978e-dac5489ac566/0_1.png",
    tag: "Educational Content and Learning Support",
    title: "  Global IEP writer ",
    link: "/globalIEP",
    description:
      "This GPT assists educators, special education professionals, and administrators in drafting Individualized Education Programs (IEPs) that meet both international standards and local regulations. It provides templates, guidance, and sample goals while ensuring accessibility and compliance with best practices for special education.",
    authors: [
      { name: "Curriculum Edge", avatar: "/static/images/avatar/7.jpg" },
    ],
    accessRequired: false,
  },
  {
    img: "https://cdn.midjourney.com/4683fcac-36a4-467c-8c5a-fe35a862cc80/0_2.png",
    tag: "Educational Content and Learning Support",
    title: " PowerPoint Summarizer ",
    link: "/powerpointCondesnor",
    description:
      "Condense lengthy PowerPoint presentations into concise summaries",
    authors: [
      { name: "Curriculum Edge", avatar: "/static/images/avatar/7.jpg" },
    ],
    accessRequired: false,
  },

  {
    img: "https://cdn.midjourney.com/6aefd00e-d74c-41bb-b44b-4ba51ee08759/0_2.png",
    tag: "Strategic Analysis and Evaluation",
    title: "Educational Institution System Evaluation",
    link: "/institutionSystemEvaluation",
    description:
      "This framework allows you to rate each component of your system comprehensively.",
    authors: [
      { name: "Curriculum Edge", avatar: "/static/images/avatar/7.jpg" },
    ],
    accessRequired: false,
  },
  {
    img: "https://cdn.midjourney.com/588556b3-a80d-4899-be7c-64eae043024e/0_2.png",
    tag: "Educational Content and Learning Support",
    title: "PowerPoint Creator ",
    link: "/powerpointCreator",
    description:
      "This tool creates a PowerPoint deck based on given information.",
    authors: [
      { name: "Curriculum Edge", avatar: "/static/images/avatar/7.jpg" },
    ],
    accessRequired: false,
  },
];

const StyledCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: 0,
  maxWidth: 500, // Set a max width for the card
  height: "100%",
  backgroundColor: theme.palette.background.paper,
  "&:hover": {
    backgroundColor: "transparent",
    cursor: "pointer",
  },
  "&:focus-visible": {
    outline: "3px solid",
    outlineColor: "hsla(210, 98%, 48%, 0.5)",
    outlineOffset: "2px",
  },
}));

const StyledCardContent = styled(CardContent)({
  display: "flex",
  flexDirection: "column",
  gap: 4,
  padding: 16,
  flexGrow: 1,
  "&:last-child": {
    paddingBottom: 16,
  },
});

const StyledTypography = styled(Typography)({
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  overflow: "hidden",
  textOverflow: "ellipsis",
});

function Author({ authors }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        alignItems: "center",
        justifyContent: "space-between",
        padding: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          alignItems: "center",
        }}
      >
        <AvatarGroup max={3}>
          {authors.map((author, index) => (
            <Avatar key={index} alt={author.name} src={author.avatar} />
          ))}
        </AvatarGroup>
        <Typography variant="caption">
          {authors.map((author) => author.name).join(", ")}
        </Typography>
      </Box>
      <Typography variant="caption">2024</Typography>
    </Box>
  );
}

Author.propTypes = {
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export function Search({ onSearchChange }) {
  return (
    <FormControl sx={{ width: { xs: "100%", md: "25ch" } }} variant="outlined">
      <OutlinedInput
        size="small"
        id="search"
        placeholder="Search…"
        sx={{ flexGrow: 1 }}
        startAdornment={
          <InputAdornment position="start" sx={{ color: "text.primary" }}>
            <SearchRoundedIcon fontSize="small" />
          </InputAdornment>
        }
        inputProps={{
          "aria-label": "search",
        }}
        onChange={(e) => onSearchChange(e.target.value)}
      />
    </FormControl>
  );
}

export default function FormMaker() {
  const [userAccess, setUserAccess] = useState({});
  const [hasAccess, setHasAccess] = useState(false);
  const [focusedCardIndex, setFocusedCardIndex] = React.useState(null);
  const [searchTerm, setSearchTerm] = React.useState(""); // State for search input
  const [activeTag, setActiveTag] = React.useState("All categories"); // State for selected chip

  const handleFocus = (index) => {
    setFocusedCardIndex(index);
  };

  const handleBlur = () => {
    setFocusedCardIndex(null);
  };

  const handleChipClick = (tag) => {
    setActiveTag(tag); // Set active tag based on chip clicked
  };

  useEffect(() => {
    async function fetchAccessStatus() {
      const email = Cookies.get("email");
      try {
        const response = await fetch(
          "https://eduevaluator.com/api/users/access-status",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: email }), // Replace with actual user email
          }
        );

        const result = await response.json();
        setHasAccess(result.hasAccess);
        console.log(result);
      } catch (error) {
        console.error("Error fetching access status:", error);
      }
    }

    fetchAccessStatus();
  }, []);

  // Filter cards based on search term and active tag
  const filteredCards = cardData.filter((card) => {
    const matchesSearch = card.title
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesTag = activeTag === "All categories" || card.tag === activeTag;
    return matchesSearch && matchesTag;
  });

  return (
    <Container>
      <div style={{ width: "100%" }}>
        <div>
          <Typography variant="h1" gutterBottom>
            CurriculumEdge Tools
          </Typography>
        </div>
        <Box
          sx={{
            display: { xs: "flex", sm: "none" },
            flexDirection: "row",
            gap: 1,
            width: { xs: "100%", md: "fit-content" },
            overflow: "auto",
          }}
        >
          <Search onSearchChange={setSearchTerm} />
          <IconButton size="small" aria-label="RSS feed">
            <RssFeedRoundedIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
            width: "100%",
            justifyContent: "space-between",
            alignItems: { xs: "start", md: "center" },
            gap: 4,
            mt: 2,
            mb: 5,
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "inline-flex",
              flexDirection: "row",
              gap: 3,
              flexWrap: "wrap", // Add this line to allow chips to wrap onto the next row
              overflow: "auto",
            }}
          >
            <Chip
              onClick={() => handleChipClick("All categories")}
              size="medium"
              label="All categories"
              clickable
              color={activeTag === "All categories" ? "primary" : "default"}
            />
            <Chip
              onClick={() => handleChipClick("Curriculum and Lesson Planning")}
              size="medium"
              label="Curriculum and Lesson Planning"
              clickable
              color={
                activeTag === "Curriculum and Lesson Planning"
                  ? "primary"
                  : "default"
              }
            />
            <Chip
              onClick={() =>
                handleChipClick("Educational Content and Learning Support")
              }
              size="medium"
              label="Educational Content and Learning Support"
              clickable
              color={
                activeTag === "Educational Content and Learning Support"
                  ? "primary"
                  : "default"
              }
            />
            <Chip
              onClick={() => handleChipClick("Image Generator")}
              size="medium"
              label="Image Generator"
              clickable
              color={activeTag === "Image Generator" ? "primary" : "default"}
            />
            <Chip
              onClick={() =>
                handleChipClick("Strategic Analysis and Evaluation")
              }
              size="medium"
              label="Strategic Analysis and Evaluation"
              clickable
              color={
                activeTag === "Strategic Analysis and Evaluation"
                  ? "primary"
                  : "default"
              }
            />
            <Chip
              onClick={() =>
                handleChipClick("Professional Development and Leadership")
              }
              size="medium"
              label="Professional Development and Leadership"
              clickable
              color={
                activeTag === "Professional Development and Leadership"
                  ? "primary"
                  : "default"
              }
            />
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              flexDirection: "row",
              gap: 1,
              width: { xs: "100%", md: "fit-content" },
              overflow: "auto",
            }}
          >
            <Search onSearchChange={setSearchTerm} />
          </Box>
        </Box>
      </div>
      <div style={{ width: "100%" }}>
        <Grid container spacing={2} sx={{ width: "100%" }}>
          {filteredCards.map((card, index) => (
            <Grid item xs={4} md={4} sm={4} key={index}>
              <Link
                to={card.link}
                style={{
                  textDecoration: "none",
                  pointerEvents:
                    !hasAccess && card.accessRequired ? "none" : "auto",
                  color: !hasAccess && card.accessRequired ? "gray" : "inherit", // Optional: style the link differently when disabled
                }}
                onClick={(e) => {
                  if (!hasAccess && card.accessRequired) {
                    e.preventDefault(); // Prevent navigation if access is restricted
                  }
                }}
              >
                <StyledCard
                  variant="outlined"
                  onFocus={() => handleFocus(index)}
                  onBlur={handleBlur}
                  tabIndex={0}
                  className={focusedCardIndex === index ? "Mui-focused" : ""}
                >
                  <CardMedia
                    component="img"
                    alt={card.title}
                    image={card.img}
                    sx={{
                      maxWidth: "100%",
                      maxHeight: 200,
                      objectFit: "cover",
                      borderBottom: "1px solid",
                      borderColor: "divider",
                    }}
                  />
                  <StyledCardContent>
                    <Typography gutterBottom variant="caption" component="div">
                      {card.tag}
                    </Typography>
                    <Typography gutterBottom variant="h6" component="div">
                      {card.title}
                    </Typography>
                    <StyledTypography variant="body2" color="text.secondary">
                      {card.description}
                    </StyledTypography>
                    {!hasAccess && card.accessRequired && (
                      <LockIcon sx={{ marginLeft: "auto", color: "#c69a25" }} />
                    )}
                  </StyledCardContent>

                  <Author authors={card.authors} />
                </StyledCard>
              </Link>
            </Grid>
          ))}
        </Grid>
      </div>
    </Container>
  );
}
