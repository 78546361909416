import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/system";
import blopBack from "../Images/blopBack.png";
import StarIcon from "@mui/icons-material/Star"; // Import star icon

const userTestimonials = [
  {
    avatar:
      "https://images.pexels.com/photos/3786525/pexels-photo-3786525.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    name: "Remy Sharp",
    occupation: "School Principal",
    testimonial:
      "This AI-driven software has revolutionized how I create teaching evaluation reports. It's incredibly intuitive, saving me hours of time and allowing me to focus more on supporting my teachers. The detailed insights it provides are unmatched.",
  },
  {
    avatar:
      "https://images.pexels.com/photos/3777943/pexels-photo-3777943.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    name: "Travis Howard",
    occupation: "Assistant Principal",
    testimonial:
      "The software’s ability to streamline the evaluation process is a game-changer. It not only simplifies report creation but also ensures that every aspect of teaching performance is thoroughly analyzed. It's a must-have tool for any school administration.",
  },
  {
    avatar:
      "https://images.pexels.com/photos/762020/pexels-photo-762020.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    name: "Cindy Baker",
    occupation: "Director of Education",
    testimonial:
      "This software has made our teacher evaluations more accurate and comprehensive. The AI’s suggestions have helped us identify areas of improvement that we might have otherwise missed. It's a valuable resource for enhancing educational outcomes.",
  },
  {
    avatar:
      "https://media.istockphoto.com/id/1286810719/photo/smiling-cheerful-young-adult-african-american-ethnicity-man-looking-at-camera-standing-at.jpg?s=1024x1024&w=is&k=20&c=4D2eIvgmf9p4-fzjBTTUkRO_dYNIJKzXcmKzLRjifeM=",
    name: "John Stewart",
    occupation: "Education Administrator",
    testimonial:
      "The AI-powered insights provided by this software are incredibly detailed and actionable. It's clear that this tool was designed with the needs of educators in mind, making the evaluation process more effective and efficient.",
  },
  {
    avatar:
      "https://images.pexels.com/photos/2834009/pexels-photo-2834009.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    name: "James Smith",
    occupation: "Principal",
    testimonial:
      "This software stands out for its ability to create thorough and meaningful teaching evaluation reports. The AI not only simplifies the process but also adds a level of depth to the evaluations that I haven't seen in other tools. Highly recommend it!",
  },
  {
    avatar:
      "https://images.pexels.com/photos/3767392/pexels-photo-3767392.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    name: "Jessica Lewis",
    occupation: "Principal",
    testimonial:
      "It has completely transformed the way I work with AI. The tools are incredibly intuitive, and I was able to jump in without any steep learning curve!The support team is fantastic too! This AI toolset has truly made my projects more efficient and powerful. Highly recommend it!",
  },
];

const Testimonials = React.forwardRef((props, ref) => {
  const theme = useTheme();

  return (
    <div ref={ref}>
      <Box
        sx={{
          minHeight: "100vh",
          backgroundImage: `url(${blopBack})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container
          id="testimonials"
          sx={{
            pb: { xs: 8, sm: 16 },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: { xs: 3, sm: 6 },
          }}
        >
          <Box
            sx={{
              width: { sm: "100%", md: "60%" },
              textAlign: { sm: "left", md: "center" },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                textTransform: "uppercase",
                color: "#f8af05",
                fontWeight: "bold",
              }}
            >
              Testimonials
            </Typography>
            <Typography variant="body1" sx={{ color: "text.secondary" }}>
              See what our customers love about our products. Discover how we
              excel in efficiency, durability, and satisfaction. Join us for
              quality, innovation, and reliable support.
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {userTestimonials.map((testimonial, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={index}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Card
                  variant="outlined"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    flexGrow: 1,
                    backdropFilter: "blur(10px)",
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    borderRadius: "12px",
                    padding: "16px",
                    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        alt={testimonial.name}
                        src={testimonial.avatar}
                        sx={{ width: 56, height: 56 }} // Bigger avatar
                      />
                    }
                    title={
                      <Typography variant="h6" sx={{ textAlign: "center" }}>
                        {testimonial.name}
                      </Typography>
                    }
                    subheader={
                      <Typography
                        variant="body2"
                        sx={{ textAlign: "center", color: "text.secondary" }}
                      >
                        {testimonial.occupation}
                      </Typography>
                    }
                    sx={{ textAlign: "center" }} // Center header
                  />
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "text.secondary" }}
                    >
                      {testimonial.testimonial}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {[...Array(5)].map((_, starIndex) => (
                        <StarIcon key={starIndex} sx={{ color: "#FFD700" }} />
                      ))}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </div>
  );
});
export default Testimonials;
