import * as React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Breadcrumbs, { breadcrumbsClasses } from "@mui/material/Breadcrumbs";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import { useLocation } from "react-router-dom"; // Import useLocation

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  [`& .${breadcrumbsClasses.separator}`]: {
    color: theme.palette.action.disabled,
    margin: 1,
  },
  [`& .${breadcrumbsClasses.ol}`]: {
    alignItems: "center",
  },
}));

export default function NavbarBreadcrumbs() {
  const location = useLocation(); // Get the current location

  // Function to create breadcrumb labels based on the pathname
  const getBreadcrumbs = () => {
    const path = location.pathname;

    // Conditional rendering based on the path
    if (path === "/dashboard") {
      return ["Reports", "Home"]; // For the dashboard path
    } else if (path === "/teacherMenu") {
      return ["Teacher Menu", "Home"]; // For the AI Lessons path
    } else if (path === "/scheduler") {
      return ["Scheduler", "Home"]; // For the AI Lessons path
    } else if (path === "/curriculumEdgeTools") {
      return ["Tools", "Home"]; // For the AI Lessons path
    } else if (path === "/teacherMenu") {
      return ["Teacher Menu", "Home"]; // For the AI Lessons path
    } else if (path === "/settings") {
      return ["Settings", "Home"]; // For the AI Lessons path
    } else if (path === "/feedback") {
      return ["Feedback", "Home"]; // For the AI Lessons path
    } else {
      return ["Tools"]; // Default to Dashboard for other paths
    }
  };

  const breadcrumbs = getBreadcrumbs();

  return (
    <StyledBreadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextRoundedIcon fontSize="small" />}
    >
      {breadcrumbs.map((label, index) => (
        <Typography
          key={index}
          variant="body1"
          sx={{
            color: "text.primary",
            fontWeight: index === breadcrumbs.length - 1 ? 600 : 400,
          }} // Make the last breadcrumb bold
        >
          {label}
        </Typography>
      ))}
    </StyledBreadcrumbs>
  );
}
