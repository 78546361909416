import React from "react";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import LogoutIcon from "@mui/icons-material/Logout"; // Import Logout icon
import ToggleColorMode from "./components/ToggleColorMode";
import getMPTheme from "./theme/getMPTheme";
import { Typography, Link } from "@mui/material";
import { useLocation } from "react-router-dom"; // Import useLocation hook
import { AuthContext } from "./context/AuthContext"; // Import AuthContext

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexShrink: 0,
  borderBottom: "1px solid",
  borderColor: theme.palette.divider,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
  backgroundImage: "none",
  zIndex: theme.zIndex.drawer + 1,
  flex: "0 0 auto",
}));

function TemplateFrame({
  showCustomTheme,
  toggleCustomTheme,
  mode,
  toggleColorMode,
  children,
}) {
  const location = useLocation(); // Get the current route
  const { logout } = React.useContext(AuthContext); // Access the logout function from AuthContext

  const buttonText =
    location.pathname === "/dashboard"
      ? "Logout"
      : location.pathname === "/report-view" ||
        location.pathname === "/addReport"
      ? "Back to Dashboard"
      : location.pathname === "/SignUp" || location.pathname === "/SignIn"
      ? "Back to Home"
      : location.pathname === "/"
      ? "Register"
      : "Back to Home";

  const buttonLink =
    location.pathname === "/dashboard"
      ? "/"
      : location.pathname === "/report-view" ||
        location.pathname === "/addReport"
      ? "/dashboard"
      : location.pathname === "/SignUp" || location.pathname === "/SignIn"
      ? "/"
      : location.pathname === "/"
      ? "/SignUp"
      : "/";

  const handleChange = (event) => {
    toggleCustomTheme(event.target.value === "custom");
  };

  const MPTheme = createTheme(getMPTheme(mode));

  return (
    <ThemeProvider theme={MPTheme}>
      <Box sx={{ height: "100dvh", display: "flex", flexDirection: "column" }}>
        <StyledAppBar>
          <Toolbar
            variant="dense"
            disableGutters
            sx={{
              backgroundColor: "#FCCC5B", // Yellow background
              color: "black",
              display: "flex",
              justifyContent: "center", // Center the text
              textAlign: "center", // Ensure text is centered
              width: "100%",
              p: "8px 12px",
            }}
          >
            <Typography variant="body1" component="div">
              {location.pathname === "/join-us"
                ? "Launching soon, sign up for updates"
                : "Is your university or institution looking for a partnership?"}
              &nbsp;
              {location.pathname !== "/join-us" && (
                <Link
                  href="mailto:eng@curriculumedge.com"
                  sx={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "black",
                  }}
                >
                  Contact Us
                </Link>
              )}
            </Typography>
          </Toolbar>
        </StyledAppBar>

        <Box sx={{ flex: "1 1", overflow: "auto" }}>{children}</Box>
      </Box>
    </ThemeProvider>
  );
}

TemplateFrame.propTypes = {
  children: PropTypes.node,
  mode: PropTypes.oneOf(["dark", "light"]).isRequired,
  showCustomTheme: PropTypes.bool.isRequired,
  toggleColorMode: PropTypes.func.isRequired,
  toggleCustomTheme: PropTypes.func.isRequired,
};

export default TemplateFrame;
