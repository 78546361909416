import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Tooltip from "@mui/material/Tooltip";

import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import QuizMakerPDF from "../QuizMakerPDF";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LessonPlanFormPDF from "./LessonPlanFormPDF"; // Adjust the import path if necessary
import {
  Box,
  Button,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stepper,
  Step,
  StepLabel,
  Container,
  Typography,
  Accordion,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
  Chip,
  InputAdornment,
  Autocomplete,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Divider,
  CircularProgress,
} from "@mui/material";
import html2pdf from "html2pdf.js";
import OpenAI from "openai";
import InfoIcon from "@mui/icons-material/Info";
import InfoDialog from "./InfoDialog";
import SchoolIcon from "@mui/icons-material/School";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import LearningObjectivesModal from "./LearningObjectivesModal";
import QuizMaker from "../pages/QuizMaker";
import LoadingModal from "./LoadingModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import axios from "axios";
const QuizMakerForm = () => {
  const location = useLocation();
  // Get the form ID.
  const { formId } = useParams(); // Get formId from URL parameters
  const pdfRef = useRef(null);
  const [loading, setLoading] = useState(false); // State for loading
  const [subject, setSubject] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [quizDate, setQuiz] = useState("");
  const [quizDifficulty, setQuizDifficulty] = useState("");
  const [numberOfQuestions, setNumberOfQuestions] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [lessonTitle, setLessonTitle] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [materials, setMaterials] = useState("");
  // Set Questions and answer
  const [formQuestion, setFormQuestion] = useState([]);
  const [quizGenerated, setQuizGenerated] = useState(false);
  const [formAnswers, setFormAnswers] = useState([]);
  const [keyTerms, setKeyTerms] = useState([]);
  const [modalOpen, setModalOpen] = useState(false); // State for modal
  useState("");
  useState("");
  // Modal Logic
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const userEmail = Cookies.get("email");
  const navigate = useNavigate();
  // Learning Objectives
  const [open, setOpen] = useState(false);
  const [domain, setDomain] = useState("");
  const [subDomain, setSubDomain] = useState("");
  const [starter, setStarter] = useState("");
  const [startersList, setStartersList] = useState([]);
  const [selectionPath, setSelectionPath] = useState([]);
  const dialogContentRef = useRef(null);
  const handleCopyToClipboard = () => {
    const content = dialogContentRef.current; // Get the content of the DialogContent
    if (content) {
      const text = content.innerText; // Get the text inside the dialog content
      navigator.clipboard
        .writeText(text) // Copy the text to clipboard
        .then(() => {
          toast.success("Text copied to clipboard!"); // Show success message
        })
        .catch((error) => {
          toast.error("Could not copy text: ", error);
        });
    }
  };

  var formData = {
    gradeLevel,
    quizDate,
    lessonTitle,
  };
  // Function to log all form data
  // Function to log all form data
  const handleSubmit = async () => {
    setLoading(true); // Start loading

    try {
      const email = Cookies.get("email");
      const content = `Please create a ${numberOfQuestions}-question quiz for ${subject} ${gradeLevel} grade. The quiz topic is "${lessonTitle}", and the level of difficulty is ${quizDifficulty}. 
      Here is additional information: "${additionalInfo}".
      Provide the quiz in the following structured JSON format:
      
      {
        "questions": [
          {
            "question": "Question text here",
            "options": ["Option 1", "Option 2", "Option 3", "Option 4"],
            "correctAnswer": "Correct option text here"
          },
          ...
        ]
      }
      `;
      const tokenResponse = await axios.post(
        "https://eduevaluator.com/api/users/decreaseToken",
        { email, content }
      );
      if (tokenResponse.data.message === "OpenAI response generated") {
        const responseContent = tokenResponse.data.summary;
        // Parse the JSON response
        const summaries = JSON.parse(responseContent);

        // Separate questions and answers
        const questions = summaries.questions.map((q, index) => ({
          id: index + 1,
          question: q.question,
          options: q.options,
        }));

        const answers = summaries.questions.map((q) => q.correctAnswer);

        console.log("Questions:", questions);
        setFormAnswers(answers);
        setFormQuestion(questions);
        console.log("Answers:", answers);
        setQuizGenerated(true);
        setModalOpen(true);
        // Trigger PDF download
        pdfRef.current.click();
        setModalOpen(true); // Open modal
      } else {
        toast.error("You are out of credits");
      }

      // Now `questions` contains all questions and options, while `answers` contains the correct answers
    } catch (error) {
      console.error("Error while fetching completion:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Paper
      elevation={3}
      sx={{ padding: 3, borderRadius: 2, minWidth: "100%", minHeight: "100%" }}
    >
      <ToastContainer />
      <LoadingModal loading={loading} message="Generating response..." />{" "}
      <Container>
        <Box sx={{ marginBottom: 3 }}>
          <Accordion
            expanded={true}
            sx={{
              backgroundColor: "white",
              color: "black",
              marginBottom: 2,
              borderRadius: 2,
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#e0e0e0",
                padding: "16px",
                borderRadius: "4px 4px 0 0",
                color: "black",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              <SchoolIcon sx={{ marginRight: 1 }} />
              AI Quiz Generator
            </Box>
            <Container>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  marginTop: 4,
                  marginBottom: 4,
                  backgroundColor: "#f0f0f0",
                  padding: "16px",
                  borderRadius: "8px",
                }}
              >
                <Chip
                  label="What does this do?"
                  sx={{
                    backgroundColor: "#1976d2", // Blue color
                    color: "#ffffff", // This sets the text color to white
                    "& .MuiChip-label": {
                      color: "#ffffff", // Ensures the label is white
                    },
                    marginRight: "16px",
                  }}
                />
                <Typography variant="body1">
                  Generates question banks for user-specified topics with
                  answers and justifications.
                </Typography>
              </Box>
              <Grid container spacing={2} style={{ marginTop: 10 }}>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="email">Subject</FormLabel>
                    <TextField
                      autoFocus
                      required
                      fullWidth
                      variant="outlined"
                      value={subject}
                      onChange={(event) => setSubject(event.target.value)}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="email">Grade Level</FormLabel>
                    <TextField
                      autoFocus
                      required
                      variant="outlined"
                      value={gradeLevel}
                      onChange={(event) => setGradeLevel(event.target.value)}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <FormLabel htmlFor="date">Quiz Date</FormLabel>
                  <TextField
                    id="date"
                    type="date"
                    required
                    fullWidth
                    variant="outlined"
                    value={quizDate}
                    onChange={(event) => setQuiz(event.target.value)}
                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="email">Lesson Title/Topic</FormLabel>
                    <TextField
                      autoFocus
                      required
                      fullWidth
                      variant="outlined"
                      value={lessonTitle}
                      onChange={(event) => setLessonTitle(event.target.value)}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="email">
                      Quiz Level of Difficulty
                    </FormLabel>
                    <Select
                      labelId="subject-label"
                      value={quizDifficulty}
                      onChange={(event) =>
                        setQuizDifficulty(event.target.value)
                      }
                      label="Subject"
                      sx={{ borderRadius: 2 }}
                    >
                      <MenuItem value="Easy">Easy</MenuItem>
                      <MenuItem value="Intermidiate ">Intermidiate </MenuItem>
                      <MenuItem value="Hard">Hard</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="email">Number of Questions</FormLabel>
                    <Select
                      labelId="subject-label"
                      value={numberOfQuestions}
                      onChange={(event) =>
                        setNumberOfQuestions(event.target.value)
                      }
                      label="Subject"
                      sx={{ borderRadius: 2 }}
                    >
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="5">5</MenuItem>
                      <MenuItem value="7 ">7 </MenuItem>
                      <MenuItem value="10">10</MenuItem>
                      <MenuItem value="15">15</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Divider style={{ color: "red" }} />
                <Grid item xs={12} style={{ marginBottom: 20 }}>
                  <FormControl fullWidth>
                    <FormLabel>Additional Notes</FormLabel>
                    <textarea
                      rows={4} // Adjust the number of rows as needed
                      value={additionalInfo}
                      onChange={(e) => setAdditionalInfo(e.target.value)}
                      style={{
                        width: "100%",
                        borderRadius: "8px", // Similar style as Material-UI
                        padding: "10px",
                        border: "1px solid #ccc",
                        boxSizing: "border-box", // Ensures padding is included in width
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Container>
          </Accordion>
        </Box>

        {/* Navigation buttons */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 2,
          }}
        >
          <Button
            onClick={handleSubmit}
            style={{
              backgroundColor: "#4286F0",
              color: "white",
              borderRadius: 20,
              marginTop: 5,
            }}
          >
            Submit
          </Button>
        </Box>
        <InfoDialog
          open={openModal}
          onClose={handleCloseModal}
          message={modalMessage}
        />
        <Dialog
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>Quiz Generated</DialogTitle>
          <DialogContent ref={dialogContentRef}>
            <Typography variant="body1" sx={{ marginBottom: 2 }}>
              Your quiz has been generated with {formQuestion.length} questions.
            </Typography>
            {quizGenerated && (
              <Tooltip
                title="Your quiz has been generated. Download it here!"
                arrow
              >
                <PDFDownloadLink
                  document={
                    <QuizMakerPDF
                      questions={formQuestion}
                      answers={formAnswers}
                    />
                  }
                  fileName="QuizAndAnswers.pdf"
                >
                  {({ loading, blob, url, error }) =>
                    loading ? (
                      "Loading PDF..."
                    ) : (
                      <a href={url} download>
                        {" "}
                        Download PDF
                      </a>
                    )
                  }
                </PDFDownloadLink>
              </Tooltip>
            )}
            {/* Display Questions & Answers */}
            {formQuestion.map((question, index) => (
              <Box
                sx={{
                  marginBottom: 2,
                  padding: 2,
                  backgroundColor: "#f5f5f5",
                  borderRadius: 1,
                }}
                key={index}
              >
                <Typography variant="h6">
                  {index + 1}. {question.question}
                </Typography>
                <Typography variant="body1">
                  <strong>Options:</strong> {question.options.join(", ")}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "green", marginTop: 1 }}
                >
                  <strong>Answer:</strong> {formAnswers[index]}
                </Typography>
              </Box>
            ))}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCopyToClipboard}
              startIcon={<ContentCopyIcon />}
            >
              Copy to Clipboard
            </Button>
            <Button onClick={() => setModalOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Paper>
  );
};

export default QuizMakerForm;
