import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CustomizedTreeView from "./CustomizedTreeView";
import TeacherDataGrid from "./TeacherDataGrid";
import CustomizedDataGrid from "./CustomizedDataGrid";
import AddIcon from "@mui/icons-material/Add";
import HighlightedCard from "./HighlightedCard";
import PageViewsBarChart from "./PageViewsBarChart";
import SessionsChart from "./SessionsChart";
import StatCard from "./StatCard";
import { Button } from "@mui/material";
import Cookies from "js-cookie";
const data = [
  {
    title: "Users",
    value: "14k",
    interval: "Last 30 days",
    trend: "up",
    data: [
      200,
      24,
      220,
      260,
      240,
      380,
      100,
      240,
      280,
      240,
      300,
      340,
      320,
      360,
      340,
      380,
      360,
      400,
      380,
      420,
      400,
      640,
      340,
      460,
      440,
      480,
      460,
      600,
      880,
      920,
    ],
  },
  {
    title: "Conversions",
    value: "325",
    interval: "Last 30 days",
    trend: "down",
    data: [
      1640,
      1250,
      970,
      1130,
      1050,
      900,
      720,
      1080,
      900,
      450,
      920,
      820,
      840,
      600,
      820,
      780,
      800,
      760,
      380,
      740,
      660,
      620,
      840,
      500,
      520,
      480,
      400,
      360,
      300,
      220,
    ],
  },
  {
    title: "Event count",
    value: "200k",
    interval: "Last 30 days",
    trend: "neutral",
    data: [
      500,
      400,
      510,
      530,
      520,
      600,
      530,
      520,
      510,
      730,
      520,
      510,
      530,
      620,
      510,
      530,
      520,
      410,
      530,
      520,
      610,
      530,
      520,
      610,
      530,
      420,
      510,
      430,
      520,
      510,
    ],
  },
];

export default function TeacherMenu() {
  const name = Cookies.get("name");
  const email = Cookies.get("email");
  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
      {/* cards */}
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
            Welcome {name}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
            Teacher Profiles
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <TeacherDataGrid />
        </Grid>
      </Grid>
    </Box>
  );
}
