import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import logo from "../Images/logo.png";
import AutoFixHighRoundedIcon from "@mui/icons-material/AutoFixHighRounded";
import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded";
import SettingsSuggestRoundedIcon from "@mui/icons-material/SettingsSuggestRounded";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";

import { SitemarkIcon } from "./CustomIcons";

const items = [
  {
    icon: <SettingsSuggestRoundedIcon sx={{ color: "text.secondary" }} />,
    title: "Customizable Reporting",
    description:
      "Effortlessly create customizable teaching reports from a variety of models that expand daily. Tailor reports to your specific needs with ease, saving you time and effort while ensuring relevance and effectiveness.",
  },
  {
    icon: <ConstructionRoundedIcon sx={{ color: "text.secondary" }} />,
    title: "Robust Data Handling",
    description:
      "Managing reviews with confidence, knowing it’s securely stored and easily accessible.",
  },
  {
    icon: <ThumbUpAltRoundedIcon sx={{ color: "text.secondary" }} />,
    title: "User-Friendly Interface",
    description:
      "Navigate the app effortlessly with an interface designed for busy administrators and teachers.",
  },
  {
    icon: <AutoFixHighRoundedIcon sx={{ color: "text.secondary" }} />,
    title: "Advanced Reporting Tools",
    description:
      "Utilize over 20 cutting-edge AI tools, with new options added daily, to effortlessly generate insightful reports. Harness the power of AI to transform data into meaningful information and enhance your reporting process.",
  },
];

export default function Content() {
  return (
    <Stack
      sx={{
        flexDirection: "column",
        alignSelf: "center",
        gap: 4,
        maxWidth: 450,
      }}
    >
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <Box
          component="img"
          sx={{
            height: 100, // Restrict the height to avoid enlarging the AppBar
            maxHeight: "100%", // Ensure the logo doesn't exceed the AppBar height
            maxWidth: "150px", // Maintain aspect ratio with a max width
            objectFit: "contain", // Make sure the image is scaled correctly
          }}
          alt="Logo"
          src={logo}
        />
      </Box>
      {items.map((item, index) => (
        <Stack key={index} direction="row" sx={{ gap: 2 }}>
          {item.icon}
          <div>
            <Typography gutterBottom sx={{ fontWeight: "medium" }}>
              {item.title}
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {item.description}
            </Typography>
          </div>
        </Stack>
      ))}
    </Stack>
  );
}
