import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  Chip,
  MenuItem,
  Paper,
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Select,
  InputLabel,
  DialogActions,
} from "@mui/material";
import OpenAI from "openai";
import ContentCopyIcon from "@mui/icons-material/ContentCopy"; // Import the clipboard icon
import SchoolIcon from "@mui/icons-material/School";
import AssistantIcon from "@mui/icons-material/Assistant";
import { useDropzone } from "react-dropzone";
import LoadingModal from "./LoadingModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Cookies from "js-cookie";
import axios from "axios";

function CompetencyFrameworkForm() {
  // Effect States
  const [universalCompetencies, setUniversalCompetencies] = useState("");
  const [purpose, setPurpose] = useState("");
  const [discipline, setDiscipline] = useState("");
  const [resourcesMethods, setResourcesMethods] = useState("");
  const [milestonesPathways, setMilestonesPathways] = useState("");
  const [assessmentTools, setAssessmentTools] = useState("");
  const [keyPerformance, setKeyPerformance] = useState("");
  const [levelsProficiency, setLevelsProficiency] = useState("");
  const [disciplinaryCompetencies, setdisciplinaryCompetencies] = useState([]);
  const options = [
    "Technical/Disciplinary Competencies: (e.g., specialized skills for the field)",
    "Behavioral/Soft Skills: (e.g., communication, teamwork, adaptability)",
    "Leadership/Strategic Competencies: (e.g., vision-setting, management, decision-making)",
    "Cross-Functional Competencies: (e.g., digital literacy, data analysis)",
  ];
  // State variables
  const [ageGroup, setAgeGroup] = useState("");
  const [destination, setDestination] = useState("");
  const [location, setLocation] = useState("");
  const [budget, setBudget] = useState("");
  const [studentToAdultRatio, setStudentToAdultRatio] = useState("");
  const [tripLength, setTripLength] = useState("");
  const [includeActivities, setIncludeActivities] = useState("");
  const [specialNeeds, setSpecialNeeds] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false); // State for loading
  const [modalOpen, setModalOpen] = useState(false); // State for modal
  const [summary, setSummary] = useState(""); // State for API response
  const [healthAndSaftey, setHealthAndSaftey] = useState("");
  const [localorInter, setLocalorInter] = useState("");
  // File upload handling
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const fileURLs = acceptedFiles.map((file) => URL.createObjectURL(file));
      setUploadedFiles((prevFiles) => [...prevFiles, ...fileURLs]);
    },
  });

  const handleChange = (event) => {
    const { value } = event.target;
    setdisciplinaryCompetencies(
      typeof value === "string" ? value.split(",") : value
    );
  };

  // Mock handler functions
  const saveDraft = () => alert("Draft saved!");
  // Function to log all form data
  // Function to log all form data
  const handleSubmit = async () => {
    setLoading(true); // Start loading

    try {
      const email = Cookies.get("email");
      const content = `Develop a competency framework for ${discipline} that ensures mastery. Focus on defining purpose and scope, identifying technical, behavioral, and leadership competencies, and outlining core skills like critical thinking. Include proficiency levels ${levelsProficiency} and measurable KPIs ${keyPerformance} for assessment. Link competencies to learning pathways  and suggest assessment tools (e.g., self-assessments, scenarios). Specify behavioral indicators for each competency and ensure adaptability for evolving needs. Align the framework with industry or accreditation standards, include strategies for stakeholder input, and propose plans for implementation and maintenance.
            Here is some additional information:
            - primary purpose of this competency: ${purpose}
            - discipline, industry, or profession: ${discipline}
            Key areas of competency to incldude: ${disciplinaryCompetencies}
              universal competencies are essential: ${universalCompetencies}
              Level of proficiency: ${levelsProficiency}
               resources or methods will help individuals :${resourcesMethods}
                Are there specific milestones or pathways to achieving: ${milestonesPathways}
                 What tools will you use to evaluate competency levels: ${assessmentTools}
                 `;
      const tokenResponse = await axios.post(
        "https://eduevaluator.com/api/users/decreaseToken",
        { email, content }
      );
      if (tokenResponse.data.message === "OpenAI response generated") {
        const summaries = tokenResponse.data.summary;
        setSummary(summaries); // Save summary for modal
        setModalOpen(true); // Open modal
      } else {
        toast.error("You are out of credits");
      }
    } catch (error) {
      console.error("Error while fetching completion:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(summary)
      .then(() => {
        toast.success("Text copied to clipboard!"); // Optional: Show a message to the user
      })
      .catch((error) => {
        toast.error("Could not copy text: ", error);
      });
  };

  const understandingOptions = [
    "Beginner",
    "Intermediate",
    "Developing",
    "Expert",
  ];

  const subjectOptions = [
    "Math",
    "Science",
    "English",
    "History",
    "Art",
    "Music",
    // Add more subjects as needed
  ];

  const gradeOptions = [
    "1st Grade",
    "2nd Grade",
    "3rd Grade",
    "4th Grade",
    "5th Grade",
    "6th Grade",
    "7th Grade",
    "8th Grade",
    "9th Grade",
    "10th Grade",
    "11th Grade",
    "12th Grade",
  ];

  return (
    <Paper elevation={3} sx={{ padding: 3, borderRadius: 2, width: "100%" }}>
      <ToastContainer />
      <LoadingModal loading={loading} message="Generating response..." />{" "}
      {/* Render loading modal */}
      <Container sx={{ width: "100%", maxWidth: "1200px" }}>
        <Box sx={{ marginTop: 3 }}>
          <Accordion
            expanded={true}
            sx={{
              width: "100%",
              backgroundColor: "white",
              color: "black",
              marginBottom: 2,
              borderRadius: 2,
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              "&:before": { display: "none" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#e0e0e0",
                padding: "16px",
                borderRadius: "4px 4px 0 0",
                color: "black",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              <AssistantIcon sx={{ marginRight: 1 }} />
              Competency Frameworks
            </Box>
            <Container>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  marginTop: 4,
                  marginBottom: 4,
                  backgroundColor: "#f0f0f0",
                  padding: "16px",
                  borderRadius: "8px",
                }}
              >
                <Chip
                  label="What does this do?"
                  sx={{
                    backgroundColor: "#1976d2", // Blue color
                    color: "#ffffff", // This sets the text color to white
                    "& .MuiChip-label": {
                      color: "#ffffff", // Ensures the label is white
                    },
                    marginRight: "16px",
                  }}
                />
                <Typography variant="body1">
                  Designs competency frameworks tailored to subjects.
                </Typography>
              </Box>
              <Container>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                      Purpose and Scope
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl fullWidth>
                      <FormLabel>
                        What is the primary purpose of this competency
                        framework?
                      </FormLabel>
                      <TextField
                        value={purpose}
                        onChange={(e) => setPurpose(e.target.value)}
                        variant="outlined"
                        sx={{ borderRadius: 2 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl fullWidth>
                      <FormLabel>
                        What specific discipline/profession is the framework
                        focused on?
                      </FormLabel>
                      <TextField
                        value={discipline}
                        onChange={(e) => setDiscipline(e.target.value)}
                        variant="outlined"
                        sx={{ borderRadius: 2 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                      Competency Categories
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="email">
                        What are the key areas of competency to include?
                      </FormLabel>
                      <Select
                        multiple
                        value={disciplinaryCompetencies}
                        onChange={handleChange}
                        renderValue={(selected) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5,
                              maxHeight: 100, // Limit height to avoid excessive overflow
                              overflowY: "auto", // Add scrollbar when necessary
                            }}
                          >
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={value}
                                sx={{
                                  maxWidth: "100px", // Set a maximum width for chips
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap", // Truncate long text
                                }}
                              />
                            ))}
                          </Box>
                        )}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 48 * 4.5 + 8, // Limit menu height
                              width: 250, // Adjust menu width if needed
                            },
                          },
                        }}
                      >
                        {options.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                      Core Competencies and Proficiency Levels
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl fullWidth>
                      <FormLabel sx={{ fontSize: 13 }}>
                        What universal competencies are essential across all
                        roles in this discipline?
                      </FormLabel>
                      <TextField
                        value={universalCompetencies}
                        onChange={(e) =>
                          setUniversalCompetencies(e.target.value)
                        }
                        variant="outlined"
                        sx={{ borderRadius: 2 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl fullWidth>
                      <FormLabel>Define the levels of proficiency</FormLabel>
                      <Select
                        value={levelsProficiency}
                        onChange={(e) => setLevelsProficiency(e.target.value)}
                        displayEmpty
                        inputProps={{ "aria-label": "Age Group" }}
                        sx={{ backgroundColor: "white", borderRadius: 2 }}
                      >
                        <MenuItem value="" disabled></MenuItem>
                        {[
                          "Beginner",
                          "Intermediate",
                          "Advanced",
                          "Expert/Master",
                        ].map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                      Learning and Development Pathways
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl fullWidth>
                      <FormLabel>
                        What resources or methods will help individuals develop
                        these competencies? (e.g., certifications)
                      </FormLabel>
                      <TextField
                        value={resourcesMethods}
                        onChange={(e) => setResourcesMethods(e.target.value)}
                        variant="outlined"
                        sx={{ borderRadius: 2 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl fullWidth>
                      <FormLabel>
                        Are there specific milestones or pathways to achieving
                        higher proficiency levels?
                      </FormLabel>
                      <TextField
                        value={milestonesPathways}
                        onChange={(e) => setMilestonesPathways(e.target.value)}
                        variant="outlined"
                        sx={{ borderRadius: 2 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                      Assessment Tools and KPIs
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl fullWidth>
                      <FormLabel>
                        What tools will you use to evaluate competency levels?
                        (e.g., self-assessments, peer reviews, simulations)
                      </FormLabel>
                      <TextField
                        value={assessmentTools}
                        onChange={(e) => setAssessmentTools(e.target.value)}
                        variant="outlined"
                        sx={{ borderRadius: 2 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl fullWidth>
                      <FormLabel>
                        What key performance indicators (KPIs) will measure
                        competency? (e.g., task completion rates, client
                        feedback, observable behaviors)
                      </FormLabel>
                      <TextField
                        value={keyPerformance}
                        onChange={(e) => setKeyPerformance(e.target.value)}
                        variant="outlined"
                        sx={{ borderRadius: 2 }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Container>

              <Box>
                {uploadedFiles.map((file, index) => (
                  <Chip
                    key={index}
                    label={file}
                    onDelete={() => {
                      setUploadedFiles(
                        uploadedFiles.filter((_, i) => i !== index)
                      );
                    }}
                    sx={{ margin: 1 }}
                  />
                ))}
              </Box>

              <Button
                type="button" // Change this to "button" instead of "submit"
                onClick={handleSubmit} // Call handleSubmit on click
                style={{
                  marginTop: 10,
                  width: 100,
                  backgroundColor: "#4286F0",
                  color: "white",
                  borderRadius: 20,
                }}
              >
                Ask AI
              </Button>
            </Container>
          </Accordion>
        </Box>
        {/* Modal for displaying results */}
        <Dialog
          open={modalOpen}
          onClose={() => {}} // Disable closing by clicking outside
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>AI Response</DialogTitle>
          <DialogContent>
            <pre>{summary}</pre>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCopyToClipboard}
              startIcon={<ContentCopyIcon />}
            />
            <Button onClick={() => setModalOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Paper>
  );
}

export default CompetencyFrameworkForm;
