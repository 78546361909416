import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  Chip,
  MenuItem,
  Select,
  Paper,
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
} from "@mui/material";
import OpenAI from "openai";
import ContentCopyIcon from "@mui/icons-material/ContentCopy"; // Import the clipboard icon
import SchoolIcon from "@mui/icons-material/School";
import AssistantIcon from "@mui/icons-material/Assistant";
import { useDropzone } from "react-dropzone";
import LoadingModal from "./LoadingModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import axios from "axios";
import FileUploadIcon from "@mui/icons-material/FileUpload";

function EduFormComponent() {
  const [moreInfro, setMoreInfro] = useState("");
  const [subject, setSubject] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [levelOfUnderstanding, setLevelOfUnderstanding] = useState("");
  const [subjectDifficulty, setSubjectDifficulty] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false); // State for loading
  const [modalOpen, setModalOpen] = useState(false); // State for modal
  const [summary, setSummary] = useState(""); // State for API response
  const [possibleAssesment, setPossibleAssesment] = useState("");
  const [selectedRole, setSelectedRole] = useState(""); // To track selected role (Admin or Teacher)
  const [selectedOptions, setSelectedOptions] = useState([]); // For multiple select options
  // File upload handling

  const handleRoleSelection = (role) => {
    setSelectedRole(role);
    setSelectedOptions([]); // Reset multiple select options when role is changed
  };

  const handleOptionChange = (event) => {
    setSelectedOptions(event.target.value);
  };

  const handleSubjectChange = (e) => setSubject(e.target.value);
  const handleGradeLevelChange = (e) => setGradeLevel(e.target.value);
  const handleLevelOfUnderstandingChange = (e) =>
    setLevelOfUnderstanding(e.target.value);
  const handleSubjectDifficultyChange = (e) =>
    setSubjectDifficulty(e.target.value);
  const handleLocationChange = (e) => setLocation(e.target.value);
  const handleDescriptionChange = (e) => setDescription(e.target.value);
  const saveDraft = () => alert("Draft saved!");
  // Function to log all form data
  // Function to log all form data
  const handleSubmit = async () => {
    const formData = {
      subject,
      gradeLevel,
      levelOfUnderstanding,
      subjectDifficulty,
      location,
      description,
    };
    console.log("Form Data:", formData);

    setLoading(true); // Start loading

    try {
      const email = Cookies.get("email");
      const content = `Create a ${selectedOptions} letter to ${gradeLevel}. With this letter subject ${subject}. Here are additional details ${moreInfro}`;
      const tokenResponse = await axios.post(
        "https://eduevaluator.com/api/users/decreaseToken",
        { email, content }
      );

      if (tokenResponse.data.message === "OpenAI response generated") {
        const summaries = tokenResponse.data.summary;
        setSummary(summaries); // Save summary for modal
        setModalOpen(true); // Open modal
      } else {
        toast.error("You are out of credits");
      }
    } catch (error) {
      console.error("Error while fetching completion:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(summary)
      .then(() => {
        toast.success("Text copied to clipboard!"); // Optional: Show a message to the user
      })
      .catch((error) => {
        toast.error("Could not copy text: ", error);
      });
  };

  // Admin options
  const adminOptions = [
    "Admin Teacher Recommendation Letter",
    "Parent Notification Letter",
    "Letter of Employment Verification",
    "Disciplinary Action Letter",
    "Warning or Performance Improvement Letter",
    "Request for Leave Approval Letter",
    "Letter of Appointment/Offer Letter",
    "Letter of Dismissal/Termination",
    "Parent Invitation for Meeting",
    "Transfer Request Letter",
    "Professional Development Invitation Letter",
    "Approval for Funding or Resources",
  ];

  // Teacher options
  const teacherOptions = [
    "Letter of Recommendation for Students",
    "Student Behavioral Concern Letter",
    "Request for Professional Development",
    "Parent Communication Letter",
    "Request for Classroom Supplies",
    "Field Trip Approval Request Letter",
    "Letter to Schedule Parent-Teacher Conferences",
    "Letter of Resignation",
    "Letter for Extra-Curricular Activity Approval",
    "Letter for Student Recognition",
    "Student Recommendation for Awards or Honors",
    "Request for Substitute Teacher",
  ];

  return (
    <Paper elevation={3} sx={{ padding: 3, borderRadius: 2, width: "100%" }}>
      <ToastContainer />
      <LoadingModal loading={loading} message="Generating response..." />
      <Container sx={{ width: "100%", maxWidth: "1200px" }}>
        <Box sx={{ marginTop: 3 }}>
          <Accordion
            expanded={true}
            sx={{
              width: "100%",
              backgroundColor: "white",
              color: "black",
              marginBottom: 2,
              borderRadius: 2,
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              "&:before": { display: "none" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#e0e0e0",
                padding: "16px",
                borderRadius: "4px 4px 0 0",
                color: "black",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              <AssistantIcon sx={{ marginRight: 1 }} />
              EduLetters Pro
            </Box>
            <Container>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  marginTop: 4,
                  marginBottom: 4,
                  backgroundColor: "#f0f0f0",
                  padding: "16px",
                  borderRadius: "8px",
                }}
              >
                <Chip
                  label="What does this do?"
                  sx={{
                    backgroundColor: "#1976d2",
                    color: "#ffffff",
                    "& .MuiChip-label": { color: "#ffffff" },
                    marginRight: "16px",
                  }}
                />
                <Typography variant="body1">
                  EduLetters Pro is a communication tool crafted for educators,
                  administrators, and school staff.
                </Typography>
              </Box>
              <Container>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <FormLabel>Are you a teacher or an admin?</FormLabel>
                    {/* Admin/Teacher Role Selection */}
                    <Box sx={{ marginTop: 3 }}>
                      <Button
                        onClick={() => handleRoleSelection("admin")}
                        variant={selectedRole === "admin" ? "" : "outlined"} // Change variant when selected
                        sx={{
                          marginRight: 2,
                          backgroundColor:
                            selectedRole === "admin" ? "#1976d2" : "outline", // Custom background color for selected
                          color: selectedRole === "admin" ? "#fff" : "inherit", // Custom text color for selected
                          "&:hover": {
                            backgroundColor:
                              selectedRole === "admin" ? "#1976d2" : "outline", // Hover effect when selected
                          },
                          borderColor:
                            selectedRole === "admin" ? "#1976d2" : "#ccc", // Border color for selected
                        }}
                      >
                        Admin
                      </Button>
                      <Button
                        onClick={() => handleRoleSelection("teacher")}
                        variant={selectedRole === "teacher" ? "jc" : "outlined"} // Change variant when selected
                        sx={{
                          backgroundColor:
                            selectedRole === "teacher" ? "#1976d2" : "outline", // Custom background color for selected
                          color:
                            selectedRole === "teacher" ? "#fff" : "inherit", // Custom text color for selected
                          "&:hover": {
                            backgroundColor:
                              selectedRole === "teacher"
                                ? "#1565c0"
                                : "outline", // Hover effect when selected
                          },
                          borderColor:
                            selectedRole === "teacher" ? "#1976d2" : "#ccc", // Border color for selected
                        }}
                      >
                        Teacher
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl fullWidth>
                      <FormLabel>Letter Subject</FormLabel>
                      <TextField
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        variant="outlined"
                        sx={{ borderRadius: 2 }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <FormControl fullWidth>
                      <FormLabel>
                        Letter Recipient(Do not enter sensitive information)
                      </FormLabel>
                      <TextField
                        value={gradeLevel}
                        onChange={(e) => setGradeLevel(e.target.value)}
                        variant="outlined"
                        sx={{ borderRadius: 2 }}
                      />
                    </FormControl>
                  </Grid>

                  {/* Conditionally render the multiple select box based on role selection */}
                  {selectedRole && (
                    <Grid item sm={6} xs={12}>
                      <FormControl fullWidth>
                        <FormLabel>Select a Letter Prompt</FormLabel>
                        <Select
                          value={selectedOptions}
                          onChange={handleOptionChange}
                          displayEmpty
                          inputProps={{ "aria-label": "Select Options" }}
                          sx={{ backgroundColor: "white", borderRadius: 2 }}
                        >
                          {/* Admin-specific options */}
                          {selectedRole === "admin"
                            ? adminOptions.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                  {option}
                                </MenuItem>
                              ))
                            : // Teacher-specific options
                              teacherOptions.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormLabel>Additional Details</FormLabel>
                      <textarea
                        rows={4} // Adjust the number of rows as needed
                        value={moreInfro}
                        onChange={(e) => setMoreInfro(e.target.value)}
                        style={{
                          width: "100%",
                          borderRadius: "8px", // Similar style as Material-UI
                          padding: "10px",
                          border: "1px solid #ccc",
                          boxSizing: "border-box", // Ensures padding is included in width
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Container>

              <Button
                type="button"
                onClick={handleSubmit}
                style={{
                  marginTop: 10,
                  width: 100,
                  backgroundColor: "#4286F0",
                  color: "white",
                  borderRadius: 20,
                }}
              >
                Ask AI
              </Button>
            </Container>
          </Accordion>
        </Box>

        {/* Modal for displaying results */}
        <Dialog open={modalOpen} onClose={() => {}} maxWidth="lg" fullWidth>
          <DialogTitle>AI Response</DialogTitle>
          <DialogContent>
            <pre>{summary}</pre>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCopyToClipboard}
              startIcon={<ContentCopyIcon />}
            />
            <Button onClick={() => setModalOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Paper>
  );
}

export default EduFormComponent;
