import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  Chip,
  MenuItem,
  Select,
  Paper,
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
} from "@mui/material";
import OpenAI from "openai";
import ContentCopyIcon from "@mui/icons-material/ContentCopy"; // Import the clipboard icon
import SchoolIcon from "@mui/icons-material/School";
import AssistantIcon from "@mui/icons-material/Assistant";
import { useDropzone } from "react-dropzone";
import LoadingModal from "./LoadingModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Cookies from "js-cookie";
import axios from "axios";
function AIFrameworkForm() {
  // State variables
  const [additionalQuestion, setAdditionalQuestion] = useState("");
  const [selectedPrompt, setSelectedPrompt] = useState("");
  const [subject, setSubject] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [levelOfUnderstanding, setLevelOfUnderstanding] = useState("");
  const [subjectDifficulty, setSubjectDifficulty] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false); // State for loading
  const [modalOpen, setModalOpen] = useState(false); // State for modal
  const [summary, setSummary] = useState(""); // State for API response

  // File upload handling
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const fileURLs = acceptedFiles.map((file) => URL.createObjectURL(file));
      setUploadedFiles((prevFiles) => [...prevFiles, ...fileURLs]);
    },
  });

  const saveDraft = () => alert("Draft saved!");
  // Function to log all form data
  // Function to log all form data
  const handleSubmit = async () => {
    const formData = {
      subject,
      gradeLevel,
      levelOfUnderstanding,
      subjectDifficulty,
      location,
      description,
    };
    console.log("Form Data:", formData);

    setLoading(true); // Start loading

    try {
      const email = Cookies.get("email");
      const content = `AI Framework Guide serves as an extensive resource for understanding and navigating the complex landscape of artificial intelligence, focusing on ethics, safety, design, and regulatory considerations. It offers up-to-date information, guidance, and analysis on AI-related topics, ensuring adherence to the highest standards of ethical practice, safety protocols, and design excellence. This GPT assists in evaluating AI strategies and products, providing informed advice that is ethical and compliant with current regulations. A question is ${selectedPrompt}, and here is additonal info ${additionalQuestion}.
The GPT avoids outdated or potentially harmful advice and does not provide legal counsel. Instead, it informs users about relevant regulations and standards, clarifying complex topics where needed. Communicating in a professional and informative tone, it adapts its responses to various user needs regarding AI's ethical, safety, design, and regulatory aspects.
`;
      const tokenResponse = await axios.post(
        "https://eduevaluator.com/api/users/decreaseToken",
        { email, content }
      );
      if (tokenResponse.data.message === "OpenAI response generated") {
        const summaries = tokenResponse.data.summary;
        setSummary(summaries); // Save summary for modal
        setModalOpen(true); // Open modal
      } else {
        toast.error("You are out of credits");
      }
    } catch (error) {
      console.error("Error while fetching completion:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(summary)
      .then(() => {
        toast.success("Text copied to clipboard!"); // Optional: Show a message to the user
      })
      .catch((error) => {
        toast.error("Could not copy text: ", error);
      });
  };

  const understandingOptions = [
    "Beginner",
    "Intermediate",
    "Developing",
    "Expert",
  ];

  const subjectOptions = [
    "Math",
    "Science",
    "English",
    "History",
    "Art",
    "Music",
    // Add more subjects as needed
  ];

  const gradeOptions = [
    "1st Grade",
    "2nd Grade",
    "3rd Grade",
    "4th Grade",
    "5th Grade",
    "6th Grade",
    "7th Grade",
    "8th Grade",
    "9th Grade",
    "10th Grade",
    "11th Grade",
    "12th Grade",
  ];

  return (
    <Paper elevation={3} sx={{ padding: 3, borderRadius: 2, width: "100%" }}>
      <ToastContainer />
      <LoadingModal loading={loading} message="Generating response..." />{" "}
      {/* Render loading modal */}
      <Container sx={{ width: "100%", maxWidth: "1200px" }}>
        <Box sx={{ marginTop: 3 }}>
          <Accordion
            expanded={true}
            sx={{
              width: "100%",
              backgroundColor: "white",
              color: "black",
              marginBottom: 2,
              borderRadius: 2,
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              "&:before": { display: "none" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#e0e0e0",
                padding: "16px",
                borderRadius: "4px 4px 0 0",
                color: "black",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              <AssistantIcon sx={{ marginRight: 1 }} />
              AI Framework Guide
            </Box>
            <Container>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  marginTop: 4,
                  marginBottom: 4,
                  backgroundColor: "#f0f0f0",
                  padding: "16px",
                  borderRadius: "8px",
                }}
              >
                <Chip
                  label="What does this do?"
                  sx={{
                    backgroundColor: "#1976d2", // Blue color
                    color: "#ffffff", // This sets the text color to white
                    "& .MuiChip-label": {
                      color: "#ffffff", // Ensures the label is white
                    },
                    marginRight: "16px",
                  }}
                />
                <Typography variant="body1">
                  Serves as a comprehensive guide to understanding the
                  complexities of artificial intelligence, emphasizing ethics,
                  safety, design, and regulatory considerations. It provides
                  up-to-date information and analysis on AI topics.
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Prompt Options</FormLabel>
                    <Select
                      value={selectedPrompt}
                      onChange={(e) => setSelectedPrompt(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Prompt Options" }}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    >
                      <MenuItem value="" disabled>
                        Select a Prompt
                      </MenuItem>
                      <MenuItem value="What ethical considerations should be taken into account when developing AI systems?">
                        What ethical considerations should be taken into account
                        when developing AI systems?
                      </MenuItem>
                      <MenuItem value="In what ways can AI practitioners ensure ethical compliance in their projects?">
                        In what ways can AI practitioners ensure ethical
                        compliance in their projects?
                      </MenuItem>
                      <MenuItem value="What are the key safety protocols recommended for AI development?">
                        What are the key safety protocols recommended for AI
                        development?
                      </MenuItem>
                      <MenuItem value="How can organizations assess the safety of their AI systems?">
                        How can organizations assess the safety of their AI
                        systems?
                      </MenuItem>
                      <MenuItem value="What measures should be in place to mitigate risks associated with AI implementation?">
                        What measures should be in place to mitigate risks
                        associated with AI implementation?
                      </MenuItem>
                      <MenuItem value="What principles of design excellence are highlighted in the AI Framework Guide?">
                        What principles of design excellence are highlighted in
                        the AI Framework Guide?
                      </MenuItem>
                      <MenuItem value="How does the framework guide the design process of AI solutions?">
                        How does the framework guide the design process of AI
                        solutions?
                      </MenuItem>
                      <MenuItem value="What role does user-centered design play in ethical AI development?">
                        What role does user-centered design play in ethical AI
                        development?
                      </MenuItem>
                      <MenuItem value="What current regulations impact AI development and deployment?">
                        What current regulations impact AI development and
                        deployment?
                      </MenuItem>
                      <MenuItem value="How can practitioners stay informed about evolving regulatory standards in AI?">
                        How can practitioners stay informed about evolving
                        regulatory standards in AI?
                      </MenuItem>
                      <MenuItem value="What are the implications of non-compliance with AI regulations?">
                        What are the implications of non-compliance with AI
                        regulations?
                      </MenuItem>
                      <MenuItem value="How can I assess the effectiveness of my AI strategy using this framework?">
                        How can I assess the effectiveness of my AI strategy
                        using this framework?
                      </MenuItem>
                      <MenuItem value="What criteria should be used to evaluate AI products for ethical compliance?">
                        What criteria should be used to evaluate AI products for
                        ethical compliance?
                      </MenuItem>
                      <MenuItem value="How does the GPT assist in comparing different AI strategies?">
                        How does the GPT assist in comparing different AI
                        strategies?
                      </MenuItem>
                      <MenuItem value="What resources are available for further learning about AI ethics and safety?">
                        What resources are available for further learning about
                        AI ethics and safety?
                      </MenuItem>
                      <MenuItem value="How can organizations implement the recommendations from the AI Framework Guide?">
                        How can organizations implement the recommendations from
                        the AI Framework Guide?
                      </MenuItem>
                      <MenuItem value="In what scenarios might this GPT provide tailored advice regarding AI practices?">
                        In what scenarios might this GPT provide tailored advice
                        regarding AI practices?
                      </MenuItem>
                      <MenuItem value="">
                        Enter Prompt In Additional Details
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Additional Details</FormLabel>
                    <textarea
                      rows={4} // Adjust the number of rows as needed
                      value={additionalQuestion}
                      onChange={(e) => setAdditionalQuestion(e.target.value)}
                      style={{
                        width: "100%",
                        borderRadius: "8px", // Similar style as Material-UI
                        padding: "10px",
                        border: "1px solid #ccc",
                        boxSizing: "border-box", // Ensures padding is included in width
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Box>
                {uploadedFiles.map((file, index) => (
                  <Chip
                    key={index}
                    label={file}
                    onDelete={() => {
                      setUploadedFiles(
                        uploadedFiles.filter((_, i) => i !== index)
                      );
                    }}
                    sx={{ margin: 1 }}
                  />
                ))}
              </Box>

              <Button
                type="button" // Change this to "button" instead of "submit"
                onClick={handleSubmit} // Call handleSubmit on click
                style={{
                  marginTop: 10,
                  width: 100,
                  backgroundColor: "#4286F0",
                  color: "white",
                  borderRadius: 20,
                }}
              >
                Ask AI
              </Button>
            </Container>
          </Accordion>
        </Box>
        {/* Modal for displaying results */}
        <Dialog
          open={modalOpen}
          onClose={() => {}} // Disable closing by clicking outside
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>AI Response</DialogTitle>
          <DialogContent>
            <pre>{summary}</pre>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCopyToClipboard}
              startIcon={<ContentCopyIcon />}
            />
            <Button onClick={() => setModalOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Paper>
  );
}

export default AIFrameworkForm;
