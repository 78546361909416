import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  Chip,
  MenuItem,
  Select,
  Paper,
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
} from "@mui/material";
import OpenAI from "openai";
import ContentCopyIcon from "@mui/icons-material/ContentCopy"; // Import the clipboard icon
import SchoolIcon from "@mui/icons-material/School";
import AssistantIcon from "@mui/icons-material/Assistant";
import { useDropzone } from "react-dropzone";
import LoadingModal from "./LoadingModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Cookies from "js-cookie";
import axios from "axios";
function GlobalIEPForm() {
  // State variables
  const [workplacePrompt, setWorkplacePrompt] = useState("");
  const [context, setContext] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [teacherFirstName, setTeacherFirstName] = useState("");
  const [teacherLastName, setTeacherLastName] = useState("");
  const [subject, setSubject] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [levelOfUnderstanding, setLevelOfUnderstanding] = useState("");
  const [subjectDifficulty, setSubjectDifficulty] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false); // State for loading
  const [modalOpen, setModalOpen] = useState(false); // State for modal
  const [summary, setSummary] = useState(""); // State for API response

  // File upload handling
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const fileURLs = acceptedFiles.map((file) => URL.createObjectURL(file));
      setUploadedFiles((prevFiles) => [...prevFiles, ...fileURLs]);
    },
  });

  // Mock handler functions
  const handleTeacherFirstNameChange = (e) =>
    setTeacherFirstName(e.target.value);
  const handleTeacherLastNameChange = (e) => setTeacherLastName(e.target.value);
  const handleSubjectChange = (e) => setSubject(e.target.value);
  const handleGradeLevelChange = (e) => setGradeLevel(e.target.value);
  const handleLevelOfUnderstandingChange = (e) =>
    setLevelOfUnderstanding(e.target.value);
  const handleSubjectDifficultyChange = (e) =>
    setSubjectDifficulty(e.target.value);
  const handleLocationChange = (e) => setLocation(e.target.value);
  const handleDescriptionChange = (e) => setDescription(e.target.value);
  const saveDraft = () => alert("Draft saved!");
  // Function to log all form data
  const handleSubmit = async () => {
    const formData = {
      subject,
      gradeLevel,
      levelOfUnderstanding,
      subjectDifficulty,
      location,
      description,
    };
    console.log("Form Data:", formData);

    setLoading(true); // Start loading

    try {
      const email = Cookies.get("email");
      const content = `This GPT assists educators, special education professionals, and administrators in drafting Individualized Education Programs (IEPs) that meet both international standards and local regulations. It provides templates, guidance, and sample goals while ensuring accessibility and compliance with best practices for special education. This tool also supports teachers by offering advice on accommodations, modifications, measurable goals, and strategies that are culturally and contextually relevant across diverse educational settings. Users can specify regions or countries, and the GPT adapts accordingly, addressing local laws or guidelines when provided.${workplacePrompt}, here is the context${context}Give an overview of the learning situation you are trying to address.`;
      const tokenResponse = await axios.post(
        "https://eduevaluator.com/api/users/decreaseToken",
        { email, content }
      );
      if (tokenResponse.data.message === "OpenAI response generated") {
        const summaries = tokenResponse.data.summary;
        setSummary(summaries); // Save summary for modal
        setModalOpen(true); // Open modal
      } else {
        toast.error("You are out of credits");
      }
    } catch (error) {
      console.error("Error while fetching completion:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(summary)
      .then(() => {
        toast.success("Text copied to clipboard!"); // Optional: Show a message to the user
      })
      .catch((error) => {
        toast.error("Could not copy text: ", error);
      });
  };

  const understandingOptions = [
    "Beginner",
    "Intermediate",
    "Developing",
    "Expert",
  ];

  const subjectOptions = [
    "Math",
    "Science",
    "English",
    "History",
    "Art",
    "Music",
    // Add more subjects as needed
  ];

  const gradeOptions = [
    "1st Grade",
    "2nd Grade",
    "3rd Grade",
    "4th Grade",
    "5th Grade",
    "6th Grade",
    "7th Grade",
    "8th Grade",
    "9th Grade",
    "10th Grade",
    "11th Grade",
    "12th Grade",
  ];

  return (
    <Paper elevation={3} sx={{ padding: 3, borderRadius: 2, width: "100%" }}>
      <ToastContainer />
      <LoadingModal loading={loading} message="Generating response..." />{" "}
      {/* Render loading modal */}
      <Container sx={{ width: "100%", maxWidth: "1200px" }}>
        <Box sx={{ marginTop: 3 }}>
          <Accordion
            expanded={true}
            sx={{
              width: "100%",
              backgroundColor: "white",
              color: "black",
              marginBottom: 2,
              borderRadius: 2,
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              "&:before": { display: "none" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#e0e0e0",
                padding: "16px",
                borderRadius: "4px 4px 0 0",
                color: "black",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              <AssistantIcon sx={{ marginRight: 1 }} />
              Global IEP
            </Box>
            <Container>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  marginTop: 4,
                  marginBottom: 4,
                  backgroundColor: "#f0f0f0",
                  padding: "16px",
                  borderRadius: "8px",
                }}
              >
                <Chip
                  label="What does this do?"
                  sx={{
                    backgroundColor: "#1976d2", // Blue color
                    color: "#ffffff", // This sets the text color to white
                    "& .MuiChip-label": {
                      color: "#ffffff", // Ensures the label is white
                    },
                    marginRight: "16px",
                  }}
                />
                <Typography variant="body1">
                  I help educators and administrators draft IEPs that meet both
                  international and local standards.
                </Typography>
              </Box>
              <Grid container spacing={2}>
                {/* Full width dropdown for workplace navigator prompts */}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>IEP Suggested Prompts</FormLabel>
                    <Select
                      value={workplacePrompt}
                      onChange={(e) => setWorkplacePrompt(e.target.value)}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Workplace Navigator Prompts",
                      }}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    >
                      <MenuItem value="" disabled>
                        Select a Prompt
                      </MenuItem>
                      {[
                        "Develop measurable academic goals for a student struggling with reading comprehension.",
                        "What modifications can support a student with ADHD in a general education classroom?",
                        "Suggest IEP goals for a student working on social skills in a special education setting.",
                        "Provide an IEP template for a student who requires speech therapy.",
                        "Draft a transition plan for a high school student with autism preparing for post-secondary",
                        "How can I integrate culturally relevant goals into an IEP for a bilingual student?",
                        "Generate IEP accommodations to support a student with anxiety.",
                        "Suggest strategies for a middle school student with dysgraphia working on writing skills.",
                        "How should I structure IEP goals for a student with behavioral challenges?",
                        "Draft short-term objectives for a student developing fine motor skills in a kindergarten setting.",
                      ].map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Full width textarea for Context */}
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormLabel>Context</FormLabel>
                      <textarea
                        rows={4} // Adjust the number of rows as needed
                        value={context}
                        onChange={(e) => setContext(e.target.value)}
                        style={{
                          width: "100%",
                          borderRadius: "8px", // Similar style as Material-UI
                          padding: "10px",
                          border: "1px solid #ccc",
                          boxSizing: "border-box", // Ensures padding is included in width
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Box>
                {uploadedFiles.map((file, index) => (
                  <Chip
                    key={index}
                    label={file}
                    onDelete={() => {
                      setUploadedFiles(
                        uploadedFiles.filter((_, i) => i !== index)
                      );
                    }}
                    sx={{ margin: 1 }}
                  />
                ))}
              </Box>

              <Button
                type="button" // Change this to "button" instead of "submit"
                onClick={handleSubmit} // Call handleSubmit on click
                style={{
                  marginTop: 10,
                  width: 100,
                  backgroundColor: "#4286F0",
                  color: "white",
                  borderRadius: 20,
                }}
              >
                Ask AI
              </Button>
            </Container>
          </Accordion>
        </Box>
        {/* Modal for displaying results */}
        <Dialog
          open={modalOpen}
          onClose={() => {}} // Disable closing by clicking outside
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>AI Response</DialogTitle>
          <DialogContent>
            <pre>{summary}</pre>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCopyToClipboard}
              startIcon={<ContentCopyIcon />}
            />
            <Button onClick={() => setModalOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Paper>
  );
}

export default GlobalIEPForm;
