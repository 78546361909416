import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import OpenAI from "openai";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SchoolIcon from "@mui/icons-material/School";
import StudentConcernPDF from "./StudentConcernPDF";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LessonPlanFormPDF from "./LessonPlanFormPDF"; // Adjust the import path if necessary
import {
  Box,
  Button,
  Paper,
  Stepper,
  Dialog,
  DialogTitle,
  DialogContent,
  Step,
  StepLabel,
  Container,
  Typography,
  Accordion,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControlLabel,
  DialogActions,
  FormControl,
  FormLabel,
  TextField,
  Chip,
  InputAdornment,
  Autocomplete,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Divider,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import LoadingModal from "./LoadingModal";
function InstitutionSystemForm() {
  const pdfRef = useRef(null);
  const navigate = useNavigate();
  const { formId } = useParams(); // Get formId from URL parameter
  const steps = ["Step 1", "Step 2", "Step 3"];
  // Getters and Setters     attendancePunctuality
  const [curriculumDesign1, setCurriculumDesign1] = useState("");
  const [curriculumDesign2, setCurriculumDesign2] = useState("");
  const [curriculumDesign3, setCurriculumDesign3] = useState("");
  // API LOAD
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  // Teaching and Learning
  const [teachingAndLearning, setTeachingAndLearning] = useState("");
  const [teachingAndLearning2, setTeachingAndLearning2] = useState("");
  const [teachingAndLearning3, setTeachingAndLearning3] = useState("");

  // Assessment and Feedback
  const [assessmentFeedback, setAssessmentFeedback] = useState("");
  const [assessmentFeedback2, setAssessmentFeedback2] = useState("");
  const [assessmentFeedback3, setAssessmentFeedback3] = useState("");

  //  Inclusion and Equity
  const [inclusionEquity, setInclusionEquity] = useState("");
  const [inclusionEquity2, setInclusionEquity2] = useState("");
  const [inclusionEquity3, setInclusionEquity3] = useState("");

  // Leadership and Governance
  const [leadershipGovernance, setLeadershipGovernance] = useState("");
  const [leadershipGovernance2, setLeadershipGovernance2] = useState("");
  const [leadershipGovernance3, setLeadershipGovernance3] = useState("");

  // Safeguarding and Wellbeing
  const [safeguardingWellbeing, setSafeguardingWellbeing] = useState("");
  const [safeguardingWellbeing2, setSafeguardingWellbeing2] = useState("");
  const [safeguardingWellbeing3, setSafeguardingWellbeing3] = useState("");

  // Use of Technology
  const [useTechnology, setUseTechnology] = useState("");
  const [useTechnology2, setUseTechnology2] = useState("");
  const [useTechnology3, setUseTechnology3] = useState("");

  // Continuous Improvement and Innovation
  const [continuousImprovement, setContinuousImprovement] = useState("");
  const [continuousImprovement2, setContinuousImprovement2] = useState("");
  const [continuousImprovement3, setContinuousImprovement3] = useState("");

  // Inspection and Accountability
  const [inspectionAccountability, setInspectionAccountability] = useState("");
  const [inspectionAccountability2, setInspectionAccountability2] =
    useState("");
  const [inspectionAccountability3, setInspectionAccountability3] =
    useState("");
  const email = Cookies.get("email");
  // FormData

  const handleSubmit = async () => {
    setLoading(true); // Start loading

    try {
      const email = Cookies.get("email");
      const content = `
            Use the following information to give an overview of the school system using International School Standards,
            Curriculum is broad, ambitious, and prepares students for future challenges:${curriculumDesign1},
            Sequential knowledge building ensures progression and deep
            understanding:${curriculumDesign2},
            Curriculum aligns with local, workplace, and global needs:${curriculumDesign3},
            Teachers are skilled in research-based pedagogy to engage students :${teachingAndLearning},
            Lessons are differentiated to support learners of all levels :${teachingAndLearning2},
            Students actively participate and develop critical thinking skills :${teachingAndLearning3},
          Formative and summative assessments monitor progress
                    effectively:${assessmentFeedback},
            Feedback is timely and constructive to guide improvement: ${assessmentFeedback2},
           Testing prioritizes understanding over excessive exam
                    pressure: ${assessmentFeedback3}    
                Digital literacy is integrated into the curriculum: ${useTechnology},
            Blended learning combines traditional and digital methods
                    effectively:${useTechnology2},
                Assistive technologies support students with additional
                    needs:${useTechnology3}     
             Data-driven decision-making improves practices and outcomes:${continuousImprovement},
             Innovative teaching methods :${continuousImprovement2}
              AI is responsibly incorporated into teaching and learning:${continuousImprovement3}
                 Regular evaluations ensure educational quality and
                    leadership effectiveness :${inspectionAccountability},
                  Reporting on outcomes is transparent and accessible:${inspectionAccountability2},
                     Mechanisms for accountability drive systemic improvement: ${inspectionAccountability3}


                 `;
      const tokenResponse = await axios.post(
        "https://eduevaluator.com/api/users/decreaseToken",
        { email, content }
      );
      if (tokenResponse.data.message === "OpenAI response generated") {
        const summaries = tokenResponse.data.summary;
        setSummary(summaries); // Save summary for modal
        setModalOpen(true); // Open modal
      } else {
        toast.error("You are out of credits");
      }
    } catch (error) {
      console.error("Error while fetching completion:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(summary)
      .then(() => {
        toast.success("Text copied to clipboard!"); // Optional: Show a message to the user
      })
      .catch((error) => {
        toast.error("Could not copy text: ", error);
      });
  };

  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  // Define step-specific content
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  Curriculum Design
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>
                    Curriculum is broad, ambitious, and prepares students for
                    future challenges
                  </FormLabel>
                  <Select
                    value={curriculumDesign1}
                    onChange={(e) => setCurriculumDesign1(e.target.value)}
                    displayEmpty
                    inputProps={{ "aria-label": "Age Group" }}
                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                  >
                    <MenuItem value="" disabled></MenuItem>
                    {["Present", "Developing", "Absent", "N.A"].map(
                      (option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>
                    Sequential knowledge building ensures progression and deep
                    understanding
                  </FormLabel>
                  <Select
                    value={curriculumDesign2}
                    onChange={(e) => setCurriculumDesign2(e.target.value)}
                    displayEmpty
                    inputProps={{ "aria-label": "Age Group" }}
                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                  >
                    <MenuItem value="" disabled></MenuItem>
                    {["Present", "Developing", "Absent", "N.A"].map(
                      (option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>
                    Curriculum aligns with local, workplace, and global needs
                    (e.g., sustainability, digital literacy)
                  </FormLabel>
                  <Select
                    value={curriculumDesign3}
                    onChange={(e) => setCurriculumDesign3(e.target.value)}
                    displayEmpty
                    inputProps={{ "aria-label": "Age Group" }}
                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                  >
                    <MenuItem value="" disabled></MenuItem>
                    {["Present", "Developing", "Absent", "N.A"].map(
                      (option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  Teaching and Learning
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>
                    Teachers are skilled in research-based pedagogy to engage
                    students
                  </FormLabel>
                  <Select
                    value={teachingAndLearning}
                    onChange={(e) => setTeachingAndLearning(e.target.value)}
                    displayEmpty
                    inputProps={{ "aria-label": "Age Group" }}
                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                  >
                    <MenuItem value="" disabled></MenuItem>
                    {["Present", "Developing", "Absent", "N.A"].map(
                      (option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>
                    Lessons are differentiated to support learners of all levels
                  </FormLabel>
                  <Select
                    value={teachingAndLearning2}
                    onChange={(e) => setTeachingAndLearning2(e.target.value)}
                    displayEmpty
                    inputProps={{ "aria-label": "Age Group" }}
                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                  >
                    <MenuItem value="" disabled></MenuItem>
                    {["Present", "Developing", "Absent", "N.A"].map(
                      (option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>
                    Students actively participate and develop critical thinking
                    skills
                  </FormLabel>
                  <Select
                    value={teachingAndLearning3}
                    onChange={(e) => setTeachingAndLearning3(e.target.value)}
                    displayEmpty
                    inputProps={{ "aria-label": "Age Group" }}
                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                  >
                    <MenuItem value="" disabled></MenuItem>
                    {["Present", "Developing", "Absent", "N.A"].map(
                      (option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  Assessment and Feedback
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>
                    Formative and summative assessments monitor progress
                    effectively
                  </FormLabel>
                  <Select
                    value={assessmentFeedback}
                    onChange={(e) => setAssessmentFeedback(e.target.value)}
                    displayEmpty
                    inputProps={{ "aria-label": "Age Group" }}
                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                  >
                    <MenuItem value="" disabled></MenuItem>
                    {["Present", "Developing", "Absent", "N.A"].map(
                      (option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>
                    Feedback is timely and constructive to guide improvement
                  </FormLabel>
                  <Select
                    value={assessmentFeedback2}
                    onChange={(e) => setAssessmentFeedback2(e.target.value)}
                    displayEmpty
                    inputProps={{ "aria-label": "Age Group" }}
                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                  >
                    <MenuItem value="" disabled></MenuItem>
                    {["Present", "Developing", "Absent", "N.A"].map(
                      (option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>
                    Testing prioritizes understanding over excessive exam
                    pressure
                  </FormLabel>
                  <Select
                    value={assessmentFeedback3}
                    onChange={(e) => setAssessmentFeedback3(e.target.value)}
                    displayEmpty
                    inputProps={{ "aria-label": "Age Group" }}
                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                  >
                    <MenuItem value="" disabled></MenuItem>
                    {["Present", "Developing", "Absent", "N.A"].map(
                      (option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        );
      case 1:
      case 2:
        return (
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  Use of Technology
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>
                    Digital literacy is integrated into the curriculum
                  </FormLabel>
                  <Select
                    value={useTechnology}
                    onChange={(e) => setUseTechnology(e.target.value)}
                    displayEmpty
                    inputProps={{ "aria-label": "Age Group" }}
                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                  >
                    <MenuItem value="" disabled></MenuItem>
                    {["Present", "Developing", "Absent", "N.A"].map(
                      (option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>
                    Blended learning combines traditional and digital methods
                    effectively
                  </FormLabel>
                  <Select
                    value={useTechnology2}
                    onChange={(e) => setUseTechnology2(e.target.value)}
                    displayEmpty
                    inputProps={{ "aria-label": "Age Group" }}
                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                  >
                    <MenuItem value="" disabled></MenuItem>
                    {["Present", "Developing", "Absent", "N.A"].map(
                      (option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>
                    Assistive technologies support students with additional
                    needs
                  </FormLabel>
                  <Select
                    value={useTechnology3}
                    onChange={(e) => setUseTechnology3(e.target.value)}
                    displayEmpty
                    inputProps={{ "aria-label": "Age Group" }}
                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                  >
                    <MenuItem value="" disabled></MenuItem>
                    {["Present", "Developing", "Absent", "N.A"].map(
                      (option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  Continuous Improvement and Innovation
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>
                    Data-driven decision-making improves practices and outcomes
                  </FormLabel>
                  <Select
                    value={continuousImprovement}
                    onChange={(e) => setContinuousImprovement(e.target.value)}
                    displayEmpty
                    inputProps={{ "aria-label": "Age Group" }}
                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                  >
                    <MenuItem value="" disabled></MenuItem>
                    {["Present", "Developing", "Absent", "N.A"].map(
                      (option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>
                    Innovative teaching methods (e.g., STEAM, project-based
                    learning) are encouraged
                  </FormLabel>
                  <Select
                    value={continuousImprovement2}
                    onChange={(e) => setContinuousImprovement2(e.target.value)}
                    displayEmpty
                    inputProps={{ "aria-label": "Age Group" }}
                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                  >
                    <MenuItem value="" disabled></MenuItem>
                    {["Present", "Developing", "Absent", "N.A"].map(
                      (option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>
                    AI is responsibly incorporated into teaching and learning
                  </FormLabel>
                  <Select
                    value={continuousImprovement3}
                    onChange={(e) => setContinuousImprovement3(e.target.value)}
                    displayEmpty
                    inputProps={{ "aria-label": "Age Group" }}
                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                  >
                    <MenuItem value="" disabled></MenuItem>
                    {["Present", "Developing", "Absent", "N.A"].map(
                      (option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  Inspection and Accountability
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>
                    Regular evaluations ensure educational quality and
                    leadership effectiveness
                  </FormLabel>
                  <Select
                    value={inspectionAccountability}
                    onChange={(e) =>
                      setInspectionAccountability(e.target.value)
                    }
                    displayEmpty
                    inputProps={{ "aria-label": "Age Group" }}
                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                  >
                    <MenuItem value="" disabled></MenuItem>
                    {["Present", "Developing", "Absent", "N.A"].map(
                      (option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>
                    Reporting on outcomes is transparent and accessible
                  </FormLabel>
                  <Select
                    value={inspectionAccountability2}
                    onChange={(e) =>
                      setInspectionAccountability2(e.target.value)
                    }
                    displayEmpty
                    inputProps={{ "aria-label": "Age Group" }}
                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                  >
                    <MenuItem value="" disabled></MenuItem>
                    {["Present", "Developing", "Absent", "N.A"].map(
                      (option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>
                    Mechanisms for accountability drive systemic improvement
                  </FormLabel>
                  <Select
                    value={inspectionAccountability3}
                    onChange={(e) =>
                      setInspectionAccountability3(e.target.value)
                    }
                    displayEmpty
                    inputProps={{ "aria-label": "Age Group" }}
                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                  >
                    <MenuItem value="" disabled></MenuItem>
                    {["Present", "Developing", "Absent", "N.A"].map(
                      (option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        );

        return <div></div>;
      default:
        return null;
    }
  };

  return (
    <div>
      {" "}
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          borderRadius: 2,
          minWidth: "100%",
          minHeight: "100%",
        }}
      >
        <ToastContainer />
        <LoadingModal loading={loading} message="Generating response..." />{" "}
        <Stepper activeStep={activeStep} sx={{ marginBottom: 3 }}>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Container>
          <Box sx={{ marginBottom: 3 }}>
            <Accordion
              expanded={true}
              sx={{
                backgroundColor: "white",
                color: "black",
                marginBottom: 2,
                borderRadius: 2,
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#e0e0e0",
                  padding: "16px",
                  borderRadius: "4px 4px 0 0",
                  color: "black",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                <SchoolIcon sx={{ marginRight: 1 }} />
                Educational Institution System Evaluation
              </Box>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  marginTop: 4,
                  marginBottom: 4,
                  backgroundColor: "#f0f0f0",
                  padding: "16px",
                  borderRadius: "8px",
                }}
              >
                <Chip
                  label="What does this do?"
                  sx={{
                    backgroundColor: "#1976d2", // Blue color
                    color: "#ffffff", // This sets the text color to white
                    "& .MuiChip-label": {
                      color: "#ffffff", // Ensures the label is white
                    },
                    marginRight: "16px",
                  }}
                />
                <Typography variant="body1">
                  This framework allows you to rate each component of your
                  system comprehensively. After completing it, I can help
                  analyze the results and suggest strategies for development in
                  weaker areas. Let me know if you'd like a filled example or
                  additional clarification!
                </Typography>
              </Box>
              <Container>
                {/* Render step-specific content */}
                {renderStepContent(activeStep)}
              </Container>
            </Accordion>
          </Box>

          {/* Navigation buttons */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 2,
            }}
          >
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              style={{
                backgroundColor: "#4286F0",
                color: "white",
                borderRadius: 20,
                marginTop: 5,
              }}
              color="secondary"
            >
              Back
            </Button>
            {activeStep === steps.length - 1 ? (
              <Button
                onClick={handleSubmit}
                style={{
                  backgroundColor: "#4286F0",
                  color: "white",
                  borderRadius: 20,
                  marginTop: 5,
                }}
              >
                Submit
              </Button>
            ) : (
              <Button
                onClick={handleNext}
                style={{
                  backgroundColor: "#4286F0",
                  color: "white",
                  borderRadius: 20,
                  marginTop: 5,
                }}
              >
                Next
              </Button>
            )}
          </Box>
          {/* Modal for displaying results */}
          <Dialog
            open={modalOpen}
            onClose={() => {}} // Disable closing by clicking outside
            maxWidth="lg"
            fullWidth
          >
            <DialogTitle>AI Response</DialogTitle>
            <DialogContent>
              <pre>{summary}</pre>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCopyToClipboard}
                startIcon={<ContentCopyIcon />}
              />
              <Button onClick={() => setModalOpen(false)}>Close</Button>
            </DialogActions>
          </Dialog>
        </Container>
      </Paper>
    </div>
  );
}

export default InstitutionSystemForm;
