import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Bar, Doughnut } from "react-chartjs-2";
import { CircularProgress, Typography } from "@mui/material";
import { ArcElement } from "chart.js";
import dayjs from "dayjs";
import OpenAI from "openai";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import LoadingModal from "./LoadingModal";
import AssistantIcon from "@mui/icons-material/Assistant";
import {
  Accordion,
  AccordionDetails,
  Button,
  Container,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  Chip,
  MenuItem,
  Select,
  Paper,
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextareaAutosize,
} from "@mui/material";
// Register the required components with Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement, // Add this line
  Title,
  Tooltip,
  Legend
);

const StudentDetailComponent = () => {
  const openai = new OpenAI({
    apiKey:
      "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", // Store your API key in environment variables for security
  });
  const { studentId } = useParams();
  const [attendance, setAttendance] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [studentName, setStudentName] = useState("");
  const [loadingText, setLoadingText] = useState(false); // For generating text
  const [additionalInfo, setAdditionalInfo] = useState("");
  // Modal Opening
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [openAdminModal, setOpenAdminModal] = useState(false);
  const [openAITipsModal, setOpenAITipsModal] = useState(false);
  const toneOptions = ["Formal", "Friendly", "Encouraging", "Informative"];
  // Email
  const [email, setEmail] = useState("");
  const [tone, setTone] = useState([]);
  const [generatedText, setGeneratedText] = useState("");
  const [generatedTips, setGeneratedTips] = useState("");
  useEffect(() => {
    const timer = setTimeout(() => {
      fetchStudentData();
    }, 0);
    return () => clearTimeout(timer);
  }, []);

  const fetchStudentData = async () => {
    try {
      const response = await axios.get(
        `https://eduevaluator.com/api/attendance/student/${studentId}`
      );
      setAttendance(response.data.attendance || []);
      setStudentName(response.data.studentName);
      console.log(response);
    } catch (err) {
      console.error("Error fetching attendance data:", err);
      setError("Failed to load attendance data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateText = async () => {
    setLoadingText(true); // Start loading for text generation
    try {
      const completion = await openai.chat.completions.create({
        model: "gpt-4",
        messages: [
          {
            role: "user",
            content: `My Student ${studentName} has missed over ${missedPercentage.toFixed(
              2
            )} percent of classes. Please write an email to ${email} in a ${tone} tone to inform them of their absence and to ask for increased attendance.`,
          },
        ],
        max_tokens: 500,
      });

      const summaries = completion.choices[0].message.content;
      setGeneratedText(summaries);
      console.log(summaries);
    } catch (error) {
      console.error("Error while fetching completion:", error);
    } finally {
      setLoadingText(false); // Stop loading after response is generated
    }
  };

  const handleGenerateTips = async () => {
    setLoadingText(true); // Start loading for text generation
    try {
      const completion = await openai.chat.completions.create({
        model: "gpt-4",
        messages: [
          {
            role: "user",
            content: `My Student ${studentName} has missed over ${missedPercentage.toFixed(
              2
            )} percent of classes. Please provide me on some tips on how I can improve this. Here is some additional context .`,
          },
        ],
        max_tokens: 500,
      });

      const summaries = completion.choices[0].message.content;
      setGeneratedTips(summaries);
      console.log(summaries);
    } catch (error) {
      console.error("Error while fetching completion:", error);
    } finally {
      setLoadingText(false); // Stop loading after response is generated
    }
  };

  const missedDates = attendance.filter((rec) => rec.status === "A");
  const missedPercentage = attendance.length
    ? (missedDates.length / attendance.length) * 100
    : 0;

  const presentPercentage = 100 - missedPercentage;
  const data = {
    labels: missedDates.map((rec) => dayjs(rec.date).format("MM/DD/YYYY")),
    datasets: [
      {
        label: "Missed Attendance Days",
        data: missedDates.map(() => 1),
        backgroundColor: "red",
      },
    ],
  };

  const doughnutData = {
    labels: ["Present", "Missed"],
    datasets: [
      {
        data: [presentPercentage, missedPercentage],
        backgroundColor: ["#4CAF50", "#F44336"], // green for present, red for missed
        hoverOffset: 4,
      },
    ],
  };

  const doughnutOptions = {
    responsive: true, // Keep this false for fixed size
    maintainAspectRatio: false, // Allow the chart to resize freely
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) =>
            `${tooltipItem.label}: ${tooltipItem.raw.toFixed(2)}%`,
        },
      },
    },
    cutout: "70%",
  };

  const options = {
    scales: {
      x: {
        type: "category",
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Missed Days",
        },
      },
    },
  };

  return (
    <Paper elevation={3} sx={{ padding: 3, borderRadius: 2, width: "100%" }}>
      <div>
        <LoadingModal loading={loading} message="Generating response..." />
        <h2>{studentName} Attendance Details</h2>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : attendance.length === 0 ? (
          <Typography>
            No attendance data available for this student.
          </Typography>
        ) : (
          <>
            <LoadingModal loading={loading} message="Generating response..." />{" "}
            {/* Render loading modal */}
            <Container sx={{ width: "100%", maxWidth: "1200px" }}>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <Box sx={{ marginTop: 3 }}>
                    <Accordion
                      expanded={true}
                      sx={{
                        width: "100%",
                        backgroundColor: "white",
                        color: "black",
                        marginBottom: 2,
                        borderRadius: 2,
                        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                        "&:before": { display: "none" },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#e0e0e0",
                          padding: "16px",
                          borderRadius: "4px 4px 0 0",
                          color: "black",
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                      >
                        <AssistantIcon sx={{ marginRight: 1 }} />
                        Missed Days Overview
                      </Box>
                      <Container>
                        <Box
                          display="flex"
                          alignItems="center"
                          sx={{
                            marginTop: 4,
                            marginBottom: 4,
                            backgroundColor: "#f0f0f0",
                            padding: "16px",
                            borderRadius: "8px",
                          }}
                        >
                          <Bar data={data} options={options} />
                        </Box>
                      </Container>
                    </Accordion>
                  </Box>

                  <Box sx={{ marginTop: 3 }}>
                    <Accordion
                      expanded={true}
                      sx={{
                        width: "100%",
                        backgroundColor: "white",
                        color: "black",
                        marginBottom: 2,
                        borderRadius: 2,
                        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                        "&:before": { display: "none" },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#e0e0e0",
                          padding: "16px",
                          borderRadius: "4px 4px 0 0",
                          color: "black",
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                      >
                        <AssistantIcon sx={{ marginRight: 1 }} />
                        Possible Actions
                      </Box>
                      <Container>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{
                            marginTop: 4,
                            marginBottom: 4,
                            backgroundColor: "#f0f0f0",
                            padding: "16px",
                            borderRadius: "8px",
                          }}
                        >
                          <Button
                            onClick={() => setOpenEmailModal(true)}
                            sx={{
                              flexGrow: 1,
                              backgroundColor: "transparent",
                              border: "2px dotted #73a5f4",
                              color: "black",
                              fontWeight: "bold",
                              height: 100,
                              padding: "10px 20px",
                              marginRight: 2,
                              "&:hover": {
                                backgroundColor: "rgba(173, 216, 230, 0.2)",
                              },
                            }}
                          >
                            Send Email
                          </Button>

                          <Button
                            onClick={() => setOpenAITipsModal(true)}
                            sx={{
                              flexGrow: 1,
                              backgroundColor: "transparent",
                              border: "2px dotted #73a5f4",
                              color: "black",
                              fontWeight: "bold",
                              height: 100,
                              padding: "10px 20px",
                              "&:hover": {
                                backgroundColor: "rgba(173, 216, 230, 0.2)",
                              },
                            }}
                          >
                            Generate AI Tips
                          </Button>
                        </Box>
                      </Container>
                    </Accordion>
                  </Box>
                </Grid>

                <Grid item sm={6}>
                  <Box sx={{ marginTop: 3 }}>
                    <Accordion
                      expanded={true}
                      sx={{
                        width: "100%",
                        backgroundColor: "white",
                        color: "black",
                        marginBottom: 2,
                        borderRadius: 2,
                        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                        "&:before": { display: "none" },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#e0e0e0",
                          padding: "16px",
                          borderRadius: "4px 4px 0 0",
                          color: "black",
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                      >
                        <AssistantIcon sx={{ marginRight: 1 }} />
                        Total Attendance Breakdown
                      </Box>
                      <Container>
                        <h3>
                          Missed Attendance Percentage:{" "}
                          {missedPercentage.toFixed(2)}%
                        </h3>
                        <Box
                          display="flex"
                          justifyContent="center" // Center horizontally
                          alignItems="center" // Center vertically
                          sx={{
                            marginTop: 4,
                            marginBottom: 4,
                            backgroundColor: "#f0f0f0",
                            padding: "16px",
                            borderRadius: "8px",
                            height: 400,
                          }}
                        >
                          <Doughnut
                            data={doughnutData}
                            options={doughnutOptions}
                          />
                        </Box>
                      </Container>
                    </Accordion>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </>
        )}
        {/* Email Parent/Admin Modal */}
        <Dialog
          open={openEmailModal || openAdminModal}
          onClose={() => {
            setOpenEmailModal(false);
            setOpenAdminModal(false);
          }}
        >
          <DialogTitle>
            {openEmailModal ? "Generate Email" : "Email Admin"}
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth margin="dense">
              {loadingText && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 2,
                  }}
                >
                  <CircularProgress size={24} sx={{ marginRight: 2 }} />
                  <Typography>We are fetching your email...</Typography>
                </Box>
              )}
              <FormLabel>
                {openEmailModal ? "Email Recipient name" : "Admin Email"}
              </FormLabel>
              <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl fullWidth margin="dense">
              <FormLabel>Email Tone</FormLabel>
              <Select
                multiple
                value={tone}
                onChange={(e) => setTone(e.target.value)}
                renderValue={(selected) => selected.join(", ")}
              >
                {toneOptions.map((tone) => (
                  <MenuItem key={tone} value={tone}>
                    {tone}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              style={{
                marginTop: 10,
                width: 100,
                backgroundColor: "#4286F0",
                color: "white",
                borderRadius: 20,
              }}
              onClick={handleGenerateText}
            >
              Generate
            </Button>
            <TextareaAutosize
              minRows={5}
              value={generatedText}
              placeholder="Generated email content will appear here."
              style={{
                width: "100%",
                marginTop: "10px",
                maxHeight: "200px", // Set your desired maximum height
                overflow: "auto", // Enable scrolling when content overflows
              }}
            />
          </DialogContent>
          <DialogActions>
            {/* <Button
              style={{
                marginTop: 10,
                width: 100,
                backgroundColor: "#4286F0",
                color: "white",
                borderRadius: 20,
              }}
              onClick={() => console.log("Send Email Logic Here")}
            >
              Send Email
            </Button> */}

            <Button
              onClick={() => {
                setOpenEmailModal(false);
                setOpenAdminModal(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* AI Tips Modal */}
        <Dialog
          open={openAITipsModal}
          onClose={() => setOpenAITipsModal(false)}
        >
          <DialogTitle>Generate AI Tips</DialogTitle>

          <DialogContent style={{ width: 500 }}>
            {loadingText && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 2,
                }}
              >
                <CircularProgress size={24} sx={{ marginRight: 2 }} />
                <Typography>We are fetching your tips...</Typography>
              </Box>
            )}
            <Button
              style={{
                marginTop: 10,
                width: 100,
                backgroundColor: "#4286F0",
                color: "white",
                borderRadius: 20,
                marginBottom: 10,
              }}
              onClick={handleGenerateTips}
            >
              Generate
            </Button>
            <textarea
              rows={6} // Set the number of rows for the textarea
              style={{
                width: "100%", // Make it responsive
                height: "200px", // Set a specific height
                padding: "8px", // Add some padding for aesthetics
                fontSize: "16px", // Increase font size if desired
                borderRadius: "4px",
                border: "1px solid #ccc", // Standard border
              }}
              placeholder="Generated tips will go here."
              value={generatedTips} // Bind the value of the textarea to state
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenAITipsModal(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    </Paper>
  );
};

export default StudentDetailComponent;
