import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  Chip,
  MenuItem,
  Select,
  Paper,
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
} from "@mui/material";
import OpenAI from "openai";
import ContentCopyIcon from "@mui/icons-material/ContentCopy"; // Import the clipboard icon
import SchoolIcon from "@mui/icons-material/School";
import AssistantIcon from "@mui/icons-material/Assistant";
import { useDropzone } from "react-dropzone";
import LoadingModal from "./LoadingModal";

import FileUploadIcon from "@mui/icons-material/FileUpload";

function EduCraftForm() {
  // State variables
  const [activeStep, setActiveStep] = useState(0);
  const [teacherFirstName, setTeacherFirstName] = useState("");
  const [teacherLastName, setTeacherLastName] = useState("");
  const [subject, setSubject] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [levelOfUnderstanding, setLevelOfUnderstanding] = useState("");
  const [subjectDifficulty, setSubjectDifficulty] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false); // State for loading
  const [modalOpen, setModalOpen] = useState(false); // State for modal
  const [summary, setSummary] = useState(""); // State for API response
  const openai = new OpenAI({
    apiKey:
      "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", // Store your API key in environment variables for security
  });
  // File upload handling
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const fileURLs = acceptedFiles.map((file) => URL.createObjectURL(file));
      setUploadedFiles((prevFiles) => [...prevFiles, ...fileURLs]);
    },
  });

  // Mock handler functions
  const handleTeacherFirstNameChange = (e) =>
    setTeacherFirstName(e.target.value);
  const handleTeacherLastNameChange = (e) => setTeacherLastName(e.target.value);
  const handleSubjectChange = (e) => setSubject(e.target.value);
  const handleGradeLevelChange = (e) => setGradeLevel(e.target.value);
  const handleLevelOfUnderstandingChange = (e) =>
    setLevelOfUnderstanding(e.target.value);
  const handleSubjectDifficultyChange = (e) =>
    setSubjectDifficulty(e.target.value);
  const handleLocationChange = (e) => setLocation(e.target.value);
  const handleDescriptionChange = (e) => setDescription(e.target.value);
  const saveDraft = () => alert("Draft saved!");
  // Function to log all form data
  // Function to log all form data
  const handleSubmit = async () => {
    const formData = {
      subject,
      gradeLevel,
      levelOfUnderstanding,
      subjectDifficulty,
      location,
      description,
    };
    console.log("Form Data:", formData);

    setLoading(true); // Start loading

    try {
      const completion = await openai.chat.completions.create({
        model: "gpt-4", // Use the appropriate model
        messages: [
          {
            role: "user",
            content: `My student in ${formData.gradeLevel} is having difficulty with the subject ${formData.subject}. The student's level of understanding is ${formData.levelOfUnderstanding}, and the subject difficulty is ${formData.subjectDifficulty}. Our location is ${formData.location}. Here is the description ${formData.description}`,
          },
        ],
        max_tokens: 500,
      });

      const summaries = completion.choices[0].message.content;
      console.log(summaries);
      setSummary(summaries); // Save summary for modal
      setModalOpen(true); // Open modal
    } catch (error) {
      console.error("Error while fetching completion:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(summary)
      .then(() => {
        alert("Text copied to clipboard!"); // Optional: Show a message to the user
      })
      .catch((error) => {
        console.error("Could not copy text: ", error);
      });
  };

  const understandingOptions = [
    "Beginner",
    "Intermediate",
    "Developing",
    "Expert",
  ];

  const subjectOptions = [
    "Math",
    "Science",
    "English",
    "History",
    "Art",
    "Music",
    // Add more subjects as needed
  ];

  const gradeOptions = [
    "1st Grade",
    "2nd Grade",
    "3rd Grade",
    "4th Grade",
    "5th Grade",
    "6th Grade",
    "7th Grade",
    "8th Grade",
    "9th Grade",
    "10th Grade",
    "11th Grade",
    "12th Grade",
  ];

  return (
    <Paper elevation={3} sx={{ padding: 3, borderRadius: 2, width: "100%" }}>
      <LoadingModal loading={loading} message="Generating response..." />{" "}
      {/* Render loading modal */}
      <Container sx={{ width: "100%", maxWidth: "1200px" }}>
        <Box sx={{ marginTop: 3 }}>
          <Accordion
            expanded={true}
            sx={{
              width: "100%",
              backgroundColor: "white",
              color: "black",
              marginBottom: 2,
              borderRadius: 2,
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              "&:before": { display: "none" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#e0e0e0",
                padding: "16px",
                borderRadius: "4px 4px 0 0",
                color: "black",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              <AssistantIcon sx={{ marginRight: 1 }} />
              Concept Simplification
            </Box>
            <Container>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  marginTop: 4,
                  marginBottom: 4,
                  backgroundColor: "#f0f0f0",
                  padding: "16px",
                  borderRadius: "8px",
                }}
              >
                <Chip
                  label="What does this do?"
                  sx={{
                    backgroundColor: "#1976d2", // Blue color
                    color: "#ffffff", // This sets the text color to white
                    "& .MuiChip-label": {
                      color: "#ffffff", // Ensures the label is white
                    },
                    marginRight: "16px",
                  }}
                />
                <Typography variant="body1">
                  Can improve understanding of a topic or subject, can explain
                  concepts similarly, can suggest practice questions, and can
                  suggest approaches for solving.
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Level of Understanding</FormLabel>
                    <Select
                      value={levelOfUnderstanding}
                      onChange={handleLevelOfUnderstandingChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    >
                      <MenuItem value="" disabled>
                        Select Level
                      </MenuItem>
                      {understandingOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Subject Difficulty</FormLabel>
                    <Select
                      value={subjectDifficulty}
                      onChange={handleSubjectDifficultyChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    >
                      <MenuItem value="" disabled>
                        Select Difficulty
                      </MenuItem>
                      {understandingOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth style={{ marginTop: 7 }}>
                    <FormLabel>Subject</FormLabel>
                    <Select
                      value={subject}
                      onChange={handleSubjectChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    >
                      <MenuItem value="" disabled>
                        Select Subject
                      </MenuItem>
                      {subjectOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth style={{ marginTop: 7 }}>
                    <FormLabel>Grade Level</FormLabel>
                    <Select
                      value={gradeLevel}
                      onChange={handleGradeLevelChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    >
                      <MenuItem value="" disabled>
                        Select Grade
                      </MenuItem>
                      {gradeOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth style={{ marginTop: 7 }}>
                    <FormLabel>Location</FormLabel>
                    <TextField
                      variant="outlined"
                      value={location}
                      onChange={handleLocationChange}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth style={{ marginTop: 7 }}>
                    <FormLabel>Description of Subject Struggle</FormLabel>
                    <TextField
                      rows={4}
                      variant="outlined"
                      value={description}
                      onChange={handleDescriptionChange}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Box>
                {uploadedFiles.map((file, index) => (
                  <Chip
                    key={index}
                    label={file}
                    onDelete={() => {
                      setUploadedFiles(
                        uploadedFiles.filter((_, i) => i !== index)
                      );
                    }}
                    sx={{ margin: 1 }}
                  />
                ))}
              </Box>

              <Button
                type="button" // Change this to "button" instead of "submit"
                onClick={handleSubmit} // Call handleSubmit on click
                style={{
                  marginTop: 10,
                  width: 100,
                  backgroundColor: "#4286F0",
                  color: "white",
                  borderRadius: 20,
                }}
              >
                Ask AI
              </Button>
            </Container>
          </Accordion>
        </Box>
        {/* Modal for displaying results */}
        <Dialog
          open={modalOpen}
          onClose={() => {}} // Disable closing by clicking outside
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>AI Response</DialogTitle>
          <DialogContent>
            <pre>{summary}</pre>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCopyToClipboard}
              startIcon={<ContentCopyIcon />}
            />
            <Button onClick={() => setModalOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Paper>
  );
}

export default EduCraftForm;
