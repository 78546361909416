import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "jspdf-autotable";
import { v4 as uuidv4 } from "uuid";
import {
  Accordion,
  AccordionDetails,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Select,
  Grid,
  Checkbox,
  Button,
  Paper,
  Tooltip,
  Divider,
  TextField,
  Box,
  Stepper,
  Step,
  StepLabel,
  Container,
  Alert,
} from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { useDropzone } from "react-dropzone";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";
import OpenAI from "openai";
import SchoolIcon from "@mui/icons-material/School";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SendIcon from "@mui/icons-material/Send";
import jsPDF from "jspdf";
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import category_danielson from "./categories";
import FileUploadComponent from "./FileUploadComponent";
import category_marzano from "./dashboardComponents/categories_marzano";
import categories_montessori from "../components/categories_montessori.js";
import LoadingModal from "./LoadingModal.js";
import categories_general from "./categories_general.js";
function ReportFormContainer() {
  const [selectedFramework, setSelectedFramework] = useState("danielson");
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false); // Close the alert by setting the state to false
  };
  const categories =
    selectedFramework === "danielson"
      ? category_danielson
      : selectedFramework === "marzano"
      ? category_marzano
      : selectedFramework === "montessori"
      ? categories_montessori
      : categories_general; // Optional default if none match
  // Get the form ID.
  const location = useLocation();
  const [isFormValid, setIsFormValid] = useState(false);
  const { formId, pageType } = location.state || {};
  const [draftLoaded, setDraftLoaded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [observationTime, setObservedTime] = useState("");
  const [observationType, setObservationType] = useState("");
  const [observationTimeEnd, setObservedTimeEnd] = useState(""); // Option 1 for time picker
  const [evaluationDate, setSelectedDate] = useState("");
  const [teacherId, setTeacherId] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [isStepComplete, setIsStepComplete] = useState(false);
  const navigate = useNavigate();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]); // Store uploaded files
  const apiKey =
    "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";
  const [evaluationResult, setEvaluationResult] = useState("");
  const [teacherFirstName, setTeacherFirstName] = useState("");
  const [teacherLastName, setTeacherLastName] = useState("");
  const [subject, setSubject] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [period, setPeriod] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  // Initialize OpenAI client with your API key
  const openai = new OpenAI({
    apiKey: apiKey,
    dangerouslyAllowBrowser: true,
  });
  const handleTeacherFirstNameChange = (event) => {
    setTeacherFirstName(event.target.value);
  };

  const validateStep = () => {
    let isValid = true;
    if (activeStep === 0) {
      isValid =
        teacherFirstName &&
        teacherLastName &&
        subject &&
        gradeLevel &&
        period &&
        schoolName &&
        evaluationDate &&
        observationType &&
        observationTime &&
        observationTimeEnd;
    }
    // Additional validation for other steps as needed
    setIsNextDisabled(!isValid);
  };

  const handleGradeLevelChange = (event) => {
    setGradeLevel(event.target.value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleFileUpload = async (files) => {
    const formData = new FormData();
    formData.append("file", files[0]); // Assuming single file upload at a time

    try {
      const response = await axios.post(
        "https://eduevaluator.com/api/s3/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        const uploadedFile = response.data.s3FilePath;
        setUploadedFiles((prev) => {
          const updatedFiles = [...prev, uploadedFile];
          console.log("Updated Files: ", updatedFiles); // Log the correct updated state here
          return updatedFiles;
        });
      }
    } catch (error) {
      console.error("File upload failed:", error);
    }
  };

  useEffect(() => {
    validateStep();
  }, [
    teacherFirstName,
    teacherLastName,
    subject,
    gradeLevel,
    period,
    schoolName,
    evaluationDate,
    observationType,
    observationTime,
    observationTimeEnd,
  ]);
  const handleReportFileUpload = async (files) => {
    const formData = new FormData();
    formData.append("file", files[0]); // Assuming single file upload at a time

    try {
      const response = await axios.post(
        "https://eduevaluator.com/api/s3/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        const uploadedFile = response.data.s3FilePath;
        // Return the uploaded file URL
        return uploadedFile; // Return the URL here
      }
    } catch (error) {
      console.error("File upload failed:", error);
      return null; // Return null in case of failure
    }
  };

  const onDrop = (acceptedFiles) => {
    setSelectedFiles(acceptedFiles);
    handleFileUpload(acceptedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false, // For single file upload
  });

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleSchoolChange = (event) => {
    setSchoolName(event.target.value);
  };
  const handlePeriodChange = (event) => {
    setPeriod(event.target.value);
  };

  const handleTimeChange = (event) => {
    setObservedTime(event.target.value);
  };
  const [selectedValues, setSelectedValues] = useState(
    (categories || []).map((category) =>
      (category.categoryDetails || []).map(() => ({
        rating: "",
        secondDropdown: [],
        context: "",
      }))
    )
  );
  // Update selectedValues whenever the framework changes
  useEffect(() => {
    setSelectedValues(
      categories.map((category) =>
        category.categoryDetails.map(() => ({
          rating: "",
          secondDropdown: [],
          context: "",
        }))
      )
    );
    setActiveStep(0); // Reset the active step when framework changes
  }, [selectedFramework, categories]);

  const steps = [
    "Teacher Information",
    ...categories.map((category) => category.title),
  ];

  useEffect(() => {
    console.log(pageType);
    if (pageType === "add") {
      console.log("add do nothing");
      return;
    }
    console.log(formId);
    if (formId && !draftLoaded) {
      console.log(formId);
      const fetchFormData = async () => {
        try {
          const response = await axios.get(
            `https://eduevaluator.com/api/form/get-form-data/${formId}`
          );
          const data = response.data;

          // Log the entire data object to inspect its structure
          console.log("Fetched data:", data);
          setSelectedFramework(data.selectedFramework || "danielson");
          setTeacherFirstName(data.teacherFirstName || "");
          setTeacherLastName(data.teacherLastName || "");
          setSubject(data.subject || "");
          setGradeLevel(data.gradeLevel || "");
          setPeriod(data.period || "");
          setSchoolName(data.schoolName || "");
          setSelectedDate(data.evaluationDate || "");
          setObservationType(data.observationType || "");
          setObservedTime(data.observationTime || "");
          setObservedTimeEnd(data.observationTimeEnd || "");
          if (Array.isArray(data.fileArtifacts)) {
            setUploadedFiles(data.fileArtifacts); // Update state with fetched file artifacts
          } else {
            setUploadedFiles([]); // Reset to empty if no fileArtifacts
          }
          // Check if data.categories is an array
          if (Array.isArray(data.categories)) {
            console.log("Categories data:", data.categories);

            // Handle nested arrays within categories
            const updatedValues = data.categories.map((categoryArray) => {
              // Check if categoryArray is indeed an array
              if (Array.isArray(categoryArray)) {
                return categoryArray.map((detail) => ({
                  rating: detail?.rating || "", // Check if detail exists before accessing rating
                  secondDropdown: detail?.secondDropdown || [],
                  context: detail?.context || "",
                }));
              } else {
                // Handle the case where categoryArray is not an array
                console.error("categoryArray is not an array:", categoryArray);
                return [];
              }
            });
            console.log("Updated Values");
            console.log(updatedValues);
            setSelectedValues(updatedValues);
          } else {
            console.error("categories is not an array:", data);
            // Handle the case where categories is not an array
            setSelectedValues([]);
          }

          setDraftLoaded(true);
        } catch (error) {
          console.error("Error fetching form data:", error);
        }
      };

      fetchFormData();
      console.log("This is selected");
      console.log(selectedValues);
    }
  }, [formId, draftLoaded]);

  /*
  useEffect(() => {
    saveDraft();
    if (formId && draftLoaded) {
      console.log(formId);
      const interval = setInterval(() => {
        saveDraft();
      }, 120000); // 120000 milliseconds = 2 minutes

      return () => clearInterval(interval);
    }
  }, [formId, draftLoaded]);
  */
  const handleTeacherLastNameChange = (event) => {
    setTeacherLastName(event.target.value);
  };
  const [activeStep, setActiveStep] = useState(0);

  const handleFrameworkChange = (event) => {
    setSelectedFramework(event.target.value);
    setActiveStep(0); // Reset to the first step after changing framework
  };

  const handleRatingChange = (categoryIndex, itemIndex, event) => {
    const updatedValues = [...selectedValues];
    updatedValues[categoryIndex][itemIndex].rating = event.target.value;
    updatedValues[categoryIndex][itemIndex].secondDropdown = ""; // Reset the second dropdown
    setSelectedValues(updatedValues);
  };
  const handleSecondDropdownChange = (stepIndex, itemIndex, event) => {
    const { value } = event.target;

    const updatedValues = [...selectedValues];

    // Ensure the value is stored as an array
    updatedValues[stepIndex][itemIndex].secondDropdown =
      typeof value === "string" ? value.split(",") : value;

    setSelectedValues(updatedValues);
  };

  const handleContextChange = (categoryIndex, itemIndex, event) => {
    const updatedValues = [...selectedValues];
    updatedValues[categoryIndex][itemIndex].context = event.target.value;
    setSelectedValues(updatedValues);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
  };
  const handleNext = () => {
    if (!isNextDisabled) {
      setActiveStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      alert("Please fill out all fields before submitting.");
      return;
    }
    setLoading(true);
    const email = Cookies.get("email");
    const formData = {
      selectedFramework: selectedFramework,
      formId: formId,
      isDraft: false,
      userEmail: email,
      period: period,
      schoolName: schoolName,
      gradeLevel: gradeLevel,
      subject: subject,
      observationTime: observationTime,
      observationTimeEnd: observationTimeEnd,
      evaluationDate: evaluationDate,
      observationType: observationType,
      teacherFirstName: teacherFirstName,
      fileArtifacts: uploadedFiles,
      teacherLastName: teacherLastName,
      categories: categories.map((category, categoryIndex) => {
        return category.categoryDetails.map((detail, itemIndex) => {
          return {
            name: detail.name,
            rating: selectedValues[categoryIndex][itemIndex].rating,
            secondDropdown:
              selectedValues[categoryIndex][itemIndex].secondDropdown,
            context: selectedValues[categoryIndex][itemIndex].context,
          };
        });
      }),
    };
    // Prepare data for OpenAI
    const categoryData = formData.categories.flatMap((category) =>
      category.map((detail) => ({
        category: detail.name,
        rating: detail.rating || "No rating provided",
        dropdown: Array.isArray(detail.secondDropdown)
          ? detail.secondDropdown.join(", ")
          : "No options selected",
        context: detail.context || "No context provided9",
      }))
    );
    try {
      const prompt = categoryData
        .map(
          (detail) =>
            `Category: ${detail.category}, Rating: ${detail.rating}, Dropdown: ${detail.dropdown}, Context: ${detail.context}`
        )
        .join("\n\n");

      console.log(prompt);

      const completion = await openai.chat.completions.create({
        model: "gpt-4", // Use the appropriate model (GPT-4 or GPT-3.5-turbo)
        messages: [
          {
            role: "user",
            content: `For each of the categorys use the dropdown and the context to create a summary for that section based on the danielson model. Output as a list for each section seperate the sections by the word NEXT. Each catergory needs it seperate summary response. Do not group them togther.Make sure every category has a correlating response. 1a- 4e.Limit to 14 words per summary. \n${prompt}`,
          },
        ],
        max_tokens: 500,
      });

      const summaries = completion.choices[0].message.content.split("NEXT"); // Assuming responses are split by double newline
      console.log(summaries);
      // Log and display the summaries
      console.log("Summaries received from ChatGPT:", summaries);

      // Add the summaries back to the formData
      formData.categories = formData.categories.map((category, index) => {
        return category.map((detail, itemIndex) => ({
          ...detail,
          summary: summaries[index * category.length + itemIndex], // Ensure correct mapping
        }));
      });

      // Continue with PDF generation and submission
      const doc = new jsPDF();

      // Add title
      doc.setFontSize(18);
      doc.text("Evaluation Summary", 70, 30);

      // Add form data to the PDF
      const dataLines = [
        { label: "Teacher's First Name:", value: teacherFirstName },
        { label: "Teacher's Last Name:", value: teacherLastName },
        { label: "Subject:", value: subject },
        { label: "Grade Level:", value: gradeLevel },
        { label: "School Name:", value: schoolName },
        { label: "Period:", value: period },
        { label: "Observation Time:", value: observationTime },
        { label: "Observation Time End:", value: observationTimeEnd },
        { label: "Evaluation Date:", value: evaluationDate },
        { label: "Observation Type:", value: observationType },
      ];

      const startY = 50;
      dataLines.forEach((line, index) => {
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);
        doc.text(line.label, 14, startY + index * 10);
        doc.text(line.value, 120, startY + index * 10);
      });

      // Add categories and summaries to the PDF
      const tableData = [];
      const tableHeaders = [
        "Category",
        "Rating",
        "Dropdown",
        "Context",
        "Summary",
      ];

      formData.categories.forEach((category) => {
        category.forEach((detail) => {
          tableData.push([
            detail.name,
            detail.rating,
            detail.secondDropdown,
            detail.context,
            detail.summary, // Add the summary generated by OpenAI
          ]);
        });
      });

      // Add table to the PDF
      doc.autoTable({
        head: [tableHeaders],
        body: tableData,
        startY: startY + dataLines.length * 10 + 20,
        theme: "grid",
        headStyles: { fillColor: "#4286F0" },
        styles: { cellPadding: 5, fontSize: 10 },
      });
      try {
        // Create the PDF
        const pdfBlob = doc.output("blob");
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const pdfFileName = `report_${uuidv4()}.pdf`;
        const pdfFile = new File([pdfBlob], pdfFileName, {
          type: "application/pdf",
        });

        // Upload the PDF to S3 and wait for the upload to finish
        const s3Url = await handleReportFileUpload([pdfFile]);

        console.log("THIS IS URL");
        console.log(s3Url);
        // Open the PDF in a new window
        window.open(pdfUrl);

        // Proceed only if the S3 URL is successfully obtained
        if (s3Url) {
          formData.reportURL = s3Url;

          // Call the save API only after the PDF upload is complete
          const response = await axios.post(
            "https://eduevaluator.com/api/form/submit-form",
            formData
          );

          if (response.status === 200) {
            // Navigate to the dashboard if the response is successful
            navigate("/dashboard");
          }
        }
      } catch (error) {
        console.error("Error uploading PDF or saving form data:", error);
        // Handle error (e.g., show a message to the user)
      }
    } catch (error) {
      console.error(
        "Error with OpenAI or submitting form:",
        error.message || error.response
      );
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    for (
      let categoryIndex = 0;
      categoryIndex < categories.length;
      categoryIndex++
    ) {
      for (
        let itemIndex = 0;
        itemIndex < categories[categoryIndex].categoryDetails.length;
        itemIndex++
      ) {
        const detail = selectedValues[categoryIndex][itemIndex];

        // Check if rating, secondDropdown, and context are filled
        if (
          !detail.rating ||
          !detail.secondDropdown ||
          detail.secondDropdown.length === 0 ||
          !detail.context
        ) {
          return false;
        }
      }
    }
    return true;
  };

  useEffect(() => {
    setIsFormValid(validateForm());
  }, [selectedValues]);

  const loadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous"; // Handle CORS
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const aspectRatio = img.height / img.width; // Calculate the aspect ratio
        const desiredWidth = 10; // Desired width for the logo
        const desiredHeight = desiredWidth * aspectRatio; // Maintain the aspect ratio

        canvas.width = desiredWidth; // Set canvas width
        canvas.height = desiredHeight; // Set canvas height
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, desiredWidth, desiredHeight); // Draw the image with new dimensions
        const dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = (error) => {
        reject(error);
      };
      img.src = url;
    });
  };

  const saveDraft = async () => {
    setIsSaving(true);
    const email = Cookies.get("email");

    const formData = {
      isDraft: true,
      userEmail: email,
      period: period,
      schoolName: schoolName,
      gradeLevel: gradeLevel,
      subject: subject,
      observationTime: observationTime,
      observationTimeEnd: observationTimeEnd,
      evaluationDate: evaluationDate,
      observationType: observationType,
      teacherFirstName: teacherFirstName,
      fileArtifacts: uploadedFiles,
      teacherLastName: teacherLastName,
      selectedFramework: selectedFramework,
      categories: categories.map((category, categoryIndex) => {
        return category.categoryDetails.map((detail, itemIndex) => {
          return {
            name: detail.name,
            rating: selectedValues[categoryIndex][itemIndex].rating,
            secondDropdown:
              selectedValues[categoryIndex][itemIndex].secondDropdown,
            context: selectedValues[categoryIndex][itemIndex].context,
          };
        });
      }),
    };

    try {
      // Replace with the actual formId you are working with
      console.log(formId);
      // Send a PUT request to update the draft with formId
      await axios.put(
        `https://eduevaluator.com/api/form/update-draft/${formId}`,
        formData
      );

      console.log("Draft updated successfully");
    } catch (error) {
      console.error(
        "Error updating draft:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setIsSaving(false); // Hide the loading spinner once save is done
    }
  };

  return (
    <Paper elevation={3} sx={{ padding: 3, borderRadius: 2 }}>
      {/* Teacher Info Section */}
      {loading && (
        <LoadingModal
          loading={true}
          message="Please wait while we process your request..."
        />
      )}{" "}
      {/* Loading spinner */}
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel>{step}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Container>
        {isSaving && (
          <div>
            <CircularProgress size={24} />
            <span>Saving data...</span>
          </div>
        )}
        <Button
          onClick={saveDraft}
          style={{
            backgroundColor: "#4286F0",
            color: "white",
            borderRadius: 20,
          }}
        >
          Save Progress
        </Button>
        {open && (
          <Alert
            severity="warning"
            onClose={handleClose}
            style={{ marginBottom: 4, marginTop: 4 }}
          >
            Please complete <strong>all</strong> required fields before
            submitting the form.We recommend using general information and
            ensuring that all data shared adheres to your institution's privacy
            guidelines. All data is processed securely, but to protect student
            privacy, please refrain from submitting any information that could
            compromise confidentiality.
          </Alert>
        )}
        <Box sx={{ marginTop: 3 }} style={{ minHeight: "100%" }}>
          {activeStep === 0 ? (
            // Teacher Information Step
            <Accordion
              expanded={true}
              sx={{
                width: "100%",

                backgroundColor: "white",
                color: "black",
                marginBottom: 2,
                borderRadius: 2,
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                "&:before": { display: "none" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#e0e0e0",
                  padding: "16px",
                  borderRadius: "4px 4px 0 0",
                  color: "black",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                <SchoolIcon sx={{ marginRight: 1 }} />
                Report Information
              </Box>
              <Container>
                <FormControl component="fieldset">
                  <Typography variant="h6" gutterBottom>
                    Select Evaluation Framework
                  </Typography>
                  <RadioGroup
                    row
                    value={selectedFramework}
                    onChange={handleFrameworkChange}
                  >
                    <FormControlLabel
                      value="danielson"
                      control={<Radio />}
                      label="Danielson"
                    />
                    <FormControlLabel
                      value="marzano"
                      control={<Radio />}
                      label="Marzano"
                    />
                    <FormControlLabel
                      value="montessori"
                      control={<Radio />}
                      label="Montessori"
                    />
                    <FormControlLabel
                      value="general"
                      control={<Radio />}
                      label="General"
                    />
                  </RadioGroup>
                </FormControl>

                <Typography
                  variant="subtitle1"
                  sx={{ marginBottom: 2, fontWeight: "bold" }}
                >
                  Teacher Information
                </Typography>

                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <FormControl>
                      <FormLabel htmlFor="email">Teacher First Name</FormLabel>
                      <TextField
                        autoFocus
                        style={{ width: "500px" }}
                        required
                        fullWidth
                        variant="outlined"
                        value={teacherFirstName}
                        onChange={handleTeacherFirstNameChange}
                        sx={{ backgroundColor: "white", borderRadius: 2 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl>
                      <FormLabel htmlFor="email">Teacher Last</FormLabel>
                      <TextField
                        autoFocus
                        style={{ width: "500px" }}
                        required
                        fullWidth
                        variant="outlined"
                        value={teacherLastName}
                        onChange={handleTeacherLastNameChange}
                        sx={{ backgroundColor: "white", borderRadius: 2 }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Typography
                  variant="subtitle1"
                  sx={{ marginBottom: 2, fontWeight: "bold" }}
                >
                  Class Information
                </Typography>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <FormControl>
                      <FormLabel htmlFor="email">Subject</FormLabel>
                      <TextField
                        autoFocus
                        style={{ width: "500px" }}
                        required
                        fullWidth
                        variant="outlined"
                        value={subject}
                        onChange={handleSubjectChange}
                        sx={{ backgroundColor: "white", borderRadius: 2 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl>
                      <FormLabel htmlFor="email">Grade Level</FormLabel>
                      <TextField
                        autoFocus
                        style={{ width: "500px" }}
                        required
                        fullWidth
                        variant="outlined"
                        value={gradeLevel}
                        onChange={handleGradeLevelChange}
                        sx={{ backgroundColor: "white", borderRadius: 2 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl>
                      <FormLabel htmlFor="email">Class Period</FormLabel>
                      <TextField
                        autoFocus
                        style={{ width: "500px" }}
                        required
                        fullWidth
                        variant="outlined"
                        value={period}
                        onChange={handlePeriodChange}
                        sx={{ backgroundColor: "white", borderRadius: 2 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl>
                      <FormLabel htmlFor="email">School Name</FormLabel>
                      <TextField
                        autoFocus
                        style={{ width: "500px" }}
                        required
                        fullWidth
                        variant="outlined"
                        value={schoolName}
                        onChange={handleSchoolChange}
                        sx={{ backgroundColor: "white", borderRadius: 2 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl>
                      <FormLabel htmlFor="date">Evaluation Date</FormLabel>
                      <TextField
                        id="date"
                        type="date"
                        style={{ width: "500px" }}
                        required
                        fullWidth
                        variant="outlined"
                        value={evaluationDate}
                        onChange={handleDateChange}
                        sx={{ backgroundColor: "white", borderRadius: 2 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <FormLabel htmlFor="email">Observation Type</FormLabel>
                      <Select
                        value={observationType}
                        onChange={(e) => setObservationType(e.target.value)}
                        label="Rating"
                        sx={{ borderRadius: 2, width: 500 }}
                      >
                        <MenuItem value="formal">Formal(full period)</MenuItem>
                        <MenuItem value="informal">
                          Informal ( 15 minutes minimum)
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl>
                      <FormLabel htmlFor="observed-time">
                        Observation Time Start
                      </FormLabel>
                      <TextField
                        id="observed-time"
                        type="time"
                        style={{ width: "500px" }}
                        required
                        fullWidth
                        variant="outlined"
                        value={observationTime}
                        onChange={handleTimeChange}
                        sx={{ backgroundColor: "white", borderRadius: 2 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 minutes
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl>
                      <FormLabel htmlFor="observed-time">
                        Observation Time End{" "}
                        <i class="fas fa-expand-arrows    "></i>
                      </FormLabel>
                      <TextField
                        id="observed-time"
                        type="time"
                        style={{ width: "500px" }}
                        required
                        fullWidth
                        variant="outlined"
                        value={observationTimeEnd}
                        onChange={(e) => setObservedTimeEnd(e.target.value)}
                        sx={{ backgroundColor: "white", borderRadius: 2 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 minutes
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Typography
                  variant="subtitle1"
                  sx={{ marginBottom: 2, fontWeight: "bold" }}
                >
                  Class Atrifacts
                </Typography>
                <div
                  {...getRootProps()}
                  style={{
                    marginBottom: "20px",
                    border: "2px dashed #B0BEC5", // Softer grey
                    borderRadius: "12px", // Larger border-radius for smooth corners
                    padding: "30px", // More padding for breathing room
                    textAlign: "center",
                    cursor: "pointer",
                    backgroundColor: "#F5F5F5", // Softer, neutral background color
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "180px", // Slightly taller for a cleaner look
                    transition: "background-color 0.3s, border-color 0.3s", // Smooth transition for hover effect
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#E3F2FD")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "#F5F5F5")
                  }
                >
                  <input {...getInputProps()} />
                  <FileUploadIcon
                    style={{
                      fontSize: "50px",
                      color: "#90A4AE",
                      marginBottom: "10px",
                    }}
                  />
                  <p
                    style={{
                      color: "#546E7A",
                      fontSize: "16px",
                      fontWeight: "500",
                      margin: "0",
                    }}
                  >
                    Drag & drop files here, or click to select files
                  </p>
                </div>

                {/* Display uploaded files */}
                <div style={{ marginTop: "20px" }}>
                  <h4>Uploaded Files:</h4>
                  {uploadedFiles.length === 0 ? (
                    <p>No files uploaded yet.</p>
                  ) : (
                    <ul>
                      {uploadedFiles.map((file, index) => (
                        <li key={index}>
                          <a
                            href={file}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {file}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </Container>
            </Accordion>
          ) : (
            // Existing Category Mapping Steps
            <Accordion
              expanded={true}
              sx={{
                backgroundColor: "white",
                color: "black",
                marginBottom: 2,
                borderRadius: 2,
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                "&:before": { display: "none" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#e0e0e0",
                  padding: "16px",
                  borderRadius: "4px 4px 0 0",
                  color: "black",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                <SchoolIcon sx={{ marginRight: 1 }} />
                {categories[activeStep - 1].title}
              </Box>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ marginBottom: 2 }}
                >
                  {categories[activeStep - 1].description}
                </Typography>
                <Grid container spacing={2}>
                  {categories[activeStep - 1].categoryDetails.map(
                    (detail, itemIndex) => (
                      <Grid item xs={12} key={itemIndex}>
                        <Typography
                          variant="subtitle1"
                          sx={{ marginBottom: 2, fontWeight: "bold" }}
                        >
                          {detail.name}
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <FormControl fullWidth variant="outlined">
                              <FormLabel htmlFor="email">
                                Category Rating
                              </FormLabel>
                              <Select
                                value={
                                  selectedValues[activeStep - 1][itemIndex]
                                    .rating
                                }
                                onChange={(e) =>
                                  handleRatingChange(
                                    activeStep - 1,
                                    itemIndex,
                                    e
                                  )
                                }
                                label="Rating"
                                sx={{ borderRadius: 2 }}
                              >
                                <MenuItem value="Ineffective">
                                  Ineffective
                                </MenuItem>
                                <MenuItem value="Developing">
                                  Developing
                                </MenuItem>
                                <MenuItem value="Effective">Effective</MenuItem>
                                <MenuItem value="HighlyEffective">
                                  Highly Effective
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl fullWidth variant="outlined">
                              <FormLabel htmlFor="email">
                                Supporting Evidence
                              </FormLabel>
                              <Select
                                multiple
                                value={
                                  selectedValues[activeStep - 1][itemIndex]
                                    .secondDropdown || [] // Ensure it defaults to an empty array if undefined
                                }
                                onChange={(e) =>
                                  handleSecondDropdownChange(
                                    activeStep - 1,
                                    itemIndex,
                                    e
                                  )
                                }
                                label="Second Dropdown"
                                sx={{ borderRadius: 2 }}
                                renderValue={(selected) => selected.join(", ")} // Display as a comma-separated string
                              >
                                {selectedValues[activeStep - 1][itemIndex]
                                  .rating &&
                                  detail.options[
                                    selectedValues[activeStep - 1][itemIndex]
                                      .rating
                                  ].map((option, i) => (
                                    <MenuItem key={i} value={option}>
                                      <Checkbox
                                        checked={
                                          selectedValues[activeStep - 1][
                                            itemIndex
                                          ].secondDropdown.indexOf(option) > -1
                                        }
                                      />
                                      {option}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl>
                              <FormLabel htmlFor="email">Extra Notes</FormLabel>
                              <TextField
                                autoFocus
                                style={{ width: "300px" }}
                                required
                                fullWidth
                                variant="outlined"
                                value={
                                  selectedValues[activeStep - 1][itemIndex]
                                    .context
                                }
                                onChange={(e) =>
                                  handleContextChange(
                                    activeStep - 1,
                                    itemIndex,
                                    e
                                  )
                                }
                                sx={{ borderRadius: 2 }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
          {/* Navigation Buttons */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 2,
            }}
          >
            <Button
              style={{
                backgroundColor: "#4286F0",
                color: "white",
                borderRadius: 20,
              }}
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              Back
            </Button>
            {activeStep === steps.length - 1 ? (
              <Button
                onClick={handleSubmit}
                style={{
                  width: 100,
                  backgroundColor: "#4286F0",
                  color: "white",
                  borderRadius: 20,
                }}
                startIcon={<SendIcon />}
                disabled={!isFormValid}
              >
                Submit
              </Button>
            ) : (
              <Button
                disabled={isNextDisabled}
                onClick={handleNext}
                style={{
                  backgroundColor: "#4286F0",
                  color: "white",
                  borderRadius: 20,
                }}
              >
                Next
              </Button>
            )}
          </Box>
        </Box>
      </Container>
    </Paper>
  );
}

export default ReportFormContainer;
