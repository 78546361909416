import React, { useEffect, useState, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Chip,
  IconButton,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { PDFDownloadLink } from "@react-pdf/renderer"; // Add this import at the top of your file
import LessonPlanFormPDF from "./LessonPlanFormPDF"; // Assuming this is the PDF component

import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

export default function MyLessonPlanTable() {
  const navigate = useNavigate();
  const pdfRef = useRef(null);
  const [rows, setRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // Add state for search query
  const userEmail = Cookies.get("email");
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleViewReport = (rowData) => {
    // Assuming rowData contains the formId (or you can modify to suit your structure)
    const formId = rowData._id;
    navigate(`/lessonPlanFeedback/${formId}`);
  };

  const handleDeleteClick = (rowData) => {
    setSelectedRow(rowData); // Store the row to be deleted
    setOpen(true); // Open the confirmation dialog
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(
        `https://eduevaluator.com/api/lessonPlan/delete/${selectedRow._id}`
      );
      if (response.status === 200) {
        console.log("Form deleted successfully:", selectedRow);
        setRows(rows.filter((row) => row._id !== selectedRow._id)); // Update the table to remove deleted row
        setOpen(false); // Close the modal
        setSelectedRow(null); // Clear the selected row
      } else {
        console.error("Error deleting form:", response.data.message);
      }
    } catch (error) {
      console.error("Error deleting form:", error);
    }
  };

  const handleClose = () => {
    setOpen(false); // Close the modal without deleting
    setSelectedRow(null); // Clear the selected row
  };

  const handleEditReport = (rowData) => {
    navigate(`/lessonPlanner/${rowData._id}`);
  };

  const handleDelete = async (rowData) => {
    try {
      const response = await axios.delete(
        `https://eduevaluator.com/api/form/delete-form/${rowData._id}`
      );
      if (response.status === 200) {
        console.log("Form deleted successfully:", rowData);
        setRows(rows.filter((row) => row._id !== rowData._id)); // Update the table to remove deleted row
      } else {
        console.error("Error deleting form:", response.data.message);
      }
    } catch (error) {
      console.error("Error deleting form:", error);
    }
  };
  const columns = [
    { field: "lessonDate", headerName: "Lesson Date", flex: 1 },
    { field: "lessonTime", headerName: "Lesson Time", flex: 1 },
    { field: "subject", headerName: "Subject", flex: 1 },
    { field: "numberComments", headerName: "Total Comments", flex: 1 },

    {
      field: "isDraft",
      headerName: "Lesson Plan Status",
      flex: 1,
      renderCell: (params) =>
        params.row.isDraft ? (
          <Chip label="Draft" color="error" />
        ) : (
          <Chip label="Submitted" color="success" />
        ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 2,
      renderCell: (params) => (
        <div>
          {!params.row.isDraft && (
            <Button
              variant="outlied"
              style={{ marginRight: 10 }}
              onClick={() => handleViewReport(params.row)}
            >
              View Feedback
            </Button>
          )}
          {params.row.isDraft && (
            <IconButton
              color="secondary"
              style={{ marginRight: 4 }}
              onClick={() => handleEditReport(params.row)}
            >
              <EditIcon />
            </IconButton>
          )}

          {/* Add PDF Download Link */}
          <PDFDownloadLink
            document={<LessonPlanFormPDF formData={params.row} />}
            fileName={`LessonPlan_${params.row.lessonDate}.pdf`}
          >
            {({ loading }) =>
              loading ? (
                <Button variant="outlined" disabled>
                  Generating PDF...
                </Button>
              ) : (
                <Button
                  style={{
                    backgroundColor: "#4286F0",
                    color: "white",
                    width: 150,
                    borderRadius: 20,
                    marginRight: 4,
                  }}
                >
                  Download PDF
                </Button>
              )
            }
          </PDFDownloadLink>
          <IconButton
            color="error"
            onClick={() => handleDeleteClick(params.row)}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const encodedEmail = encodeURIComponent(userEmail);
        const response = await axios.get(
          "https://eduevaluator.com/api/lessonPlan/get-email",
          {
            params: { userEmail: encodedEmail },
          }
        );
        console.log(response.data);
        const data = response.data.data.map((item, index) => ({
          id: index + 1,
          _id: item._id,
          lessonDate: item.lessonDate || "No data available",
          lessonTime: item.lessonTime || "No data available",
          subject: item.subject || "No data available",
          isDraft: item.isDraft,
          numberComments: item.totalComments,
        }));
        console.log(data);
        setRows(data);
      } catch (error) {
        console.error("Error fetching form data:", error);
      }
    };

    fetchData();
  }, [userEmail]);

  // Filter rows based on the search query

  return (
    <div style={{ width: "100%" }}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this report? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            style={{ color: "red" }}
            onClick={handleConfirmDelete}
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <FormControl
        sx={{ width: { xs: "100%", md: "50ch" }, mb: 4 }}
        variant="outlined"
      >
        <OutlinedInput
          size="small"
          id="search"
          placeholder="Search…"
          sx={{ flexGrow: 1 }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          startAdornment={
            <InputAdornment position="start" sx={{ color: "text.primary" }}>
              <SearchRoundedIcon fontSize="small" />
            </InputAdornment>
          }
          inputProps={{
            "aria-label": "search",
          }}
        />
      </FormControl>
      <DataGrid
        autoHeight
        rows={rows} // Use filtered rows here
        columns={columns}
        pageSizeOptions={[10, 20, 50]}
        disableColumnResize
        density="compact"
        rowHeight={80}
      />
    </div>
  );
}
