import React from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

function BlueSection() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        backgroundColor: "#4286F0",
        height: { xs: "auto", sm: "250px" }, // Responsive height
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: { xs: 2, sm: 0 }, // Padding for mobile
      }}
    >
      <Grid
        container
        sx={{ maxWidth: "1200px" }}
        justifyContent="space-between"
      >
        {/* Section 1 */}
        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: isSmallScreen ? 2 : 0, // Margin bottom for mobile spacing
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "2.5rem", sm: "4rem" }, // Responsive font size
            }}
          >
            800
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ opacity: 0.7, textAlign: "center" }}
          >
            Lesson Plans Generated
          </Typography>
        </Grid>

        {/* Section 2 */}
        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderLeft: { sm: "2px solid rgba(255, 255, 255, 0.2)" }, // Vertical divider only for larger screens
            mb: isSmallScreen ? 2 : 0,
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "2.5rem", sm: "4rem" },
            }}
          >
            24/7
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ opacity: 0.7, textAlign: "center" }}
          >
            Support and Resources
          </Typography>
        </Grid>

        {/* Section 3 */}
        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderLeft: { sm: "2px solid rgba(255, 255, 255, 0.2)" },
            mb: isSmallScreen ? 2 : 0,
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "2.5rem", sm: "4rem" },
            }}
          >
            30%
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ opacity: 0.7, textAlign: "center" }}
          >
            Time Saved in Planning
          </Typography>
        </Grid>

        {/* Section 4 */}
        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderLeft: { sm: "2px solid rgba(255, 255, 255, 0.2)" },
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "2.5rem", sm: "4rem" },
            }}
          >
            50
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ opacity: 0.7, textAlign: "center" }}
          >
            Classrooms Empowered
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default BlueSection;
