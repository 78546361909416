import React, { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Cookies from "js-cookie";
import Checkbox from "@mui/material/Checkbox";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import logo from "../Images/logo.png";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { Select, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MuiCard from "@mui/material/Card";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import toastify CSS
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import getSignInSideTheme from "../theme/getSignInSideTheme";
import {
  GoogleIcon,
  FacebookIcon,
  SitemarkIcon,
} from "../components/CustomIcons";
import TemplateFrame from "../TemplateFrame";

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: "auto",
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  [theme.breakpoints.up("sm")]: {
    width: "450px",
  },
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}));

const SignUpContainer = styled(Stack)(({ theme }) => ({
  height: "100%",
  padding: 4,
  backgroundImage:
    "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
  backgroundRepeat: "no-repeat",
  ...theme.applyStyles("dark", {
    backgroundImage:
      "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))",
  }),
}));

export default function NewsLetterForm() {
  const { login } = useContext(AuthContext);
  const [mode, setMode] = React.useState("light");
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const defaultTheme = createTheme({ palette: { mode } });
  const SignUpTheme = createTheme(getSignInSideTheme(mode));
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState("");
  const [referralCode, setReferralCode] = React.useState("");
  const [role, setRole] = React.useState("Teacher");
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState("");
  const [nameError, setNameError] = React.useState(false);
  const [nameErrorMessage, setNameErrorMessage] = React.useState("");
  const [roleError, setRoleError] = React.useState(false);
  const [roleErrorMessage, setRoleErrorMessage] = React.useState("");
  const navigate = useNavigate();
  // This code only runs on the client side, to determine the system color preference
  React.useEffect(() => {
    // Check if there is a preferred mode in localStorage
    const savedMode = localStorage.getItem("themeMode");
    if (savedMode) {
      setMode(savedMode);
    } else {
      // If no preference is found, it uses system preference
      const systemPrefersDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      setMode("light");
    }
  }, []);

  const toggleColorMode = () => {
    const newMode = mode === "dark" ? "light" : "dark";
    setMode(newMode);
    localStorage.setItem("themeMode", newMode); // Save the selected mode to localStorage
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  const validateInputs = () => {
    const email = document.getElementById("email");
    const name = document.getElementById("name");

    let isValid = true;

    if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
      setEmailError(true);
      setEmailErrorMessage("Please enter a valid email address.");
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage("");
    }

    if (!name.value || name.value.length < 1) {
      setNameError(true);
      setNameErrorMessage("Name is required.");
      isValid = false;
    } else {
      setNameError(false);
      setNameErrorMessage("");
    }

    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateInputs()) {
      return;
    }

    const data = new FormData(event.currentTarget);
    const userData = {
      name: data.get("name"),
      email: data.get("email"),
      role: data.get("role"),
    };

    try {
      const response = await fetch(
        "https://eduevaluator.com/api/newsLetter/add-news-letter",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        }
      );

      if (!response.ok) {
        toast.error("Failed to add to our new list");
      } else {
        toast.success("You are added to the news list!");
      }
    } catch (error) {
      console.error("Error during registration:", error);
      toast.error("Failed to add to our new list");
    }
  };

  return (
    <Card variant="outlined">
      <ToastContainer />
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <Box
          component="img"
          sx={{
            height: 100, // Restrict the height to avoid enlarging the AppBar
            maxHeight: "100%", // Ensure the logo doesn't exceed the AppBar height
            maxWidth: "150px", // Maintain aspect ratio with a max width
            objectFit: "contain", // Make sure the image is scaled correctly
          }}
          alt="Logo"
          src={logo}
        />
      </Box>
      <Typography
        component="p"
        variant="h4"
        sx={{
          width: "100%",
          fontSize: "clamp(2rem, 1vw, 2.15rem)",
          color: "#4286F0",
        }}
      >
        Software Launch Updates
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ display: "flex", flexDirection: "column", gap: 2 }}
      >
        <FormControl>
          <FormLabel htmlFor="name">Full name</FormLabel>
          <TextField
            autoComplete="name"
            name="name"
            required
            fullWidth
            id="name"
            placeholder="Your Name"
            error={nameError}
            helperText={nameErrorMessage}
            color={nameError ? "error" : "primary"}
          />
        </FormControl>
        <FormControl fullWidth>
          <FormLabel>What is your role?</FormLabel>
          <Select
            error={roleError}
            helperText={roleErrorMessage}
            color={roleError ? "error" : "primary"}
            value={role}
            id="role"
            name="role"
            onChange={(e) => setRole(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Prompt Options" }}
            sx={{ backgroundColor: "white", borderRadius: 2 }}
          >
            <MenuItem value="" disabled>
              Select a role
            </MenuItem>
            <MenuItem value="Administrator">Administrator</MenuItem>
            <MenuItem value="Teacher">Teacher</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="email">Email</FormLabel>
          <TextField
            required
            fullWidth
            id="email"
            placeholder="your@email.com"
            name="email"
            autoComplete="email"
            variant="outlined"
            error={emailError}
            helperText={emailErrorMessage}
            color={passwordError ? "error" : "primary"}
          />
        </FormControl>

        {/*         <FormControlLabel
          control={<Checkbox value="allowExtraEmails" color="primary" />}
          label="I want to receive updates via email."
        />*/}

        <Button
          type="submit"
          fullWidth
          style={{
            backgroundColor: "#4286F0",
            color: "white",
            borderRadius: 20,
          }}
          onClick={validateInputs}
        >
          Get updates
        </Button>
      </Box>
      {/* 
              <Divider>
                <Typography sx={{ color: "text.secondary" }}>or</Typography>
              </Divider>
              */}
      {/* 
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="outlined"
                  onClick={() => alert("Sign up with Google")}
                  startIcon={<GoogleIcon />}
                >
                  Sign up with Google
                </Button>
                <Button
                  type="submit"
                  fullWidth
                  variant="outlined"
                  onClick={() => alert("Sign up with Facebook")}
                  startIcon={<FacebookIcon />}
                >
                  Sign up with Facebook
                </Button>
              </Box>
              */}
    </Card>
  );
}
