import React, { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import Box from "@mui/material/Box";
import axios from "axios";
import Button from "@mui/material/Button";
import MuiCard from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Cookies from "js-cookie";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import toastify CSS

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  [theme.breakpoints.up("sm")]: {
    width: "450px",
  },
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}));

export default function Verify2FA() {
  const { login } = useContext(AuthContext);
  const [codeError, setCodeError] = useState(false);
  const [codeErrorMessage, setCodeErrorMessage] = useState("");
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();

    const twoFactorCode = document.getElementById("code").value;
    const email = Cookies.get("email");

    if (!validateInputs(twoFactorCode)) {
      return;
    }

    const verificationData = { email, twoFactorCode };

    try {
      const response = await fetch(
        "https://eduevaluator.com/api/users/verify-2fa",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(verificationData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to verify");
      }

      const result = await response.json();
      Cookies.set("name", result.user.name);
      Cookies.set("email", result.user.email);
      Cookies.set("id", result.user.id);
      login(result.token);
      navigate("/curriculumEdgeTools");
      // You can show a success message using toast.success if needed
      toast.success("Verification successful!");
    } catch (error) {
      console.error("Error during verification:", error);
      setCodeError(true);
      setCodeErrorMessage("Invalid code or server error.");

      // Show error toast notification
      toast.error("Verification failed. Please try again.");
    }
  };

  const handleResendEmail = async () => {
    try {
      const email = Cookies.get("email");
      const response = await axios.post(
        "https://eduevaluator.com/api/users/resend-2fa",
        {
          email: email,
        }
      ); // Update with the correct email
      toast.success("New code has been sent");
    } catch (err) {
      toast.error("Code cannot be sent at this moment");
    }
  };

  const validateInputs = (code) => {
    let isValid = true;

    if (!code || code.length !== 6) {
      setCodeError(true);
      setCodeErrorMessage("Please enter a valid 6-digit code.");
      isValid = false;

      // Show a toast notification for validation error
      toast.error("Please enter a valid 6-digit code.", {});
    } else {
      setCodeError(false);
      setCodeErrorMessage("");
    }

    return isValid;
  };

  return (
    <Card variant="outlined">
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        {/* Add any icons or logos here */}
      </Box>
      <Typography
        component="h1"
        variant="h4"
        sx={{
          width: "100%",
          fontSize: "clamp(2rem, 10vw, 2.15rem)",
          color: "#4286F0",
        }}
      >
        Verify Login
      </Typography>
      <Typography variant="subtitle">
        Please enter the code sent to your email
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 2 }}
      >
        <FormControl>
          <FormLabel htmlFor="code">Code</FormLabel>
          <TextField
            id="code"
            type="text"
            name="code"
            autoFocus
            required
            fullWidth
            variant="outlined"
            sx={{ ariaLabel: "code" }}
            error={codeError}
            helperText={codeError ? codeErrorMessage : ""}
          />
        </FormControl>

        <Button
          type="submit"
          fullWidth
          style={{
            backgroundColor: "#4286F0",
            borderRadius: 20,
            color: "white",
          }}
        >
          Verify
        </Button>
        <Button
          onClick={handleResendEmail}
          fullWidth
          variant="outlined"
          style={{
            borderRadius: 20,
          }}
        >
          Resend Email
        </Button>
      </Box>

      {/* Toast Container */}
      <ToastContainer />
    </Card>
  );
}
