import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Link from "@mui/material/Link";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Sitemark from "./SitemarkIcon";
import logo from "../Images/logo.png";
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexShrink: 0,
  minHeight: 64, // Set the height of the toolbar
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: "blur(24px)",
  border: "1px solid",
  borderColor: theme.palette.divider,
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  boxShadow: theme.shadows[1],
  padding: "8px 12px",
}));

export default function AppAppBar({ sections }) {
  const [open, setOpen] = React.useState(false);

  const handleScroll = (sectionRef) => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: 0,
        bgcolor: "transparent",
        backgroundImage: "none",
        mt: 10,
      }}
    >
      <Container maxWidth="lg">
        <StyledToolbar variant="dense" disableGutters>
          <Box
            sx={{ flexGrow: 1, display: "flex", alignItems: "center", px: 0 }}
          >
            <Box
              component="img"
              sx={{
                height: 55, // Restrict the height to avoid enlarging the AppBar
                maxHeight: "100%", // Ensure the logo doesn't exceed the AppBar height
                maxWidth: "150px", // Maintain aspect ratio with a max width
                objectFit: "contain", // Make sure the image is scaled correctly
              }}
              alt="Logo"
              src={logo}
            />
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Button
                onClick={() => handleScroll(sections.featuresRef)}
                variant="text"
                color="info"
                size="small"
              >
                Features
              </Button>
              <Button
                onClick={() => handleScroll(sections.testimonialsRef)}
                variant="text"
                color="info"
                size="small"
              >
                Testimonials
              </Button>
              <Button
                onClick={() => handleScroll(sections.aboutUsRef)}
                variant="text"
                color="info"
                size="small"
              >
                About Us
              </Button>
              <Button
                onClick={() => handleScroll(sections.pricingRef)}
                variant="text"
                color="info"
                size="small"
              >
                Pricing
              </Button>
              <Button
                onClick={() => handleScroll(sections.faqRef)}
                variant="text"
                color="info"
                size="small"
                sx={{ minWidth: 0 }}
              >
                FAQ
              </Button>
              <Button
                onClick={() => handleScroll(sections.whyUsRef)}
                variant="text"
                color="info"
                size="small"
                sx={{ minWidth: 0 }}
              >
                Why Us
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              gap: 1,
              alignItems: "center",
            }}
          >
            <Button href="/signIn" color="primary" variant="text" size="small">
              Sign in
            </Button>
            <Button
              href="/signUp"
              sx={{
                backgroundColor: "#4286F0", // Blue background
                color: "white", // White text
                borderRadius: "24px", // More rounded corners
                minWidth: "150px", // Make the button longer
                padding: "8px 16px", // Add padding for larger button
                "&:hover": {
                  backgroundColor: "#357ae8", // Slightly darker blue on hover
                },
              }}
              size="medium" // You can change to "large" for bigger size if needed
            >
              Sign up for free
            </Button>
          </Box>
          <Box sx={{ display: { sm: "flex", md: "none" } }}>
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
              <Box sx={{ p: 2, backgroundColor: "background.default" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <IconButton
                    style={{ marginTop: 40 }}
                    onClick={toggleDrawer(false)}
                  >
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>
                <Divider sx={{ my: 3 }} />
                <MenuItem onClick={() => handleScroll(sections.featuresRef)}>
                  Features
                </MenuItem>
                <MenuItem
                  onClick={() => handleScroll(sections.testimonialsRef)}
                >
                  Testimonials
                </MenuItem>
                <MenuItem
                  onClick={() => handleScroll(sections.aboutUsRef)}
                  variant="text"
                >
                  About Us
                </MenuItem>
                <MenuItem onClick={() => handleScroll(sections.pricingRef)}>
                  Pricing
                </MenuItem>
                <MenuItem onClick={() => handleScroll(sections.faqRef)}>
                  FAQ
                </MenuItem>
                <MenuItem onClick={() => handleScroll(sections.whyUsRef)}>
                  Why Us
                </MenuItem>
                <MenuItem>
                  <Button
                    href="/signUp"
                    fullWidth
                    sx={{
                      backgroundColor: "#4286F0", // Blue background
                      color: "white", // White text
                      borderRadius: "24px", // More rounded corners
                      minWidth: "150px", // Make the button longer
                      padding: "8px 16px", // Add padding for larger button
                      "&:hover": {
                        backgroundColor: "#357ae8", // Slightly darker blue on hover
                      },
                    }}
                    size="medium" // You can change to "large" for bigger size if needed
                  >
                    Sign up
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button
                    href="/signIn"
                    color="primary"
                    style={{ borderRadius: "24px" }}
                    variant="outlined"
                    fullWidth
                  >
                    Sign in
                  </Button>
                </MenuItem>
              </Box>
            </Drawer>
          </Box>
        </StyledToolbar>
      </Container>
    </AppBar>
  );
}
