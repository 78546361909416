import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Box, IconButton, Modal, Typography } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/CloudUpload";
import { DataGrid } from "@mui/x-data-grid"; // Import DataGrid
import DeleteIcon from "@mui/icons-material/Delete";
import { Alert } from "@mui/material";
import Cookies from "js-cookie";
const AttendancePageComponent = () => {
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false); // Close the alert by setting the state to false
  };
  const email = Cookies.get("email");
  const navigate = useNavigate();
  const [students, setStudents] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [howItWorksModalOpen, setHowItWorksModalOpen] = useState(false);

  useEffect(() => {
    fetchStudents();
  }, []);

  const fetchStudents = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `https://eduevaluator.com/api/attendance/get-students`,
        { userEmail: email } // Pass userEmail as query parameter
      );
      setStudents(response.data);
    } catch (error) {
      console.error("Error fetching student data:", error);
      setError("Failed to load student data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async (files) => {
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("userEmail", email); // Add userEmail to formData

    try {
      await axios.post(
        "https://eduevaluator.com/api/attendance/upload",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      fetchStudents(); // Refresh data after upload
    } catch (error) {
      console.error("File upload failed:", error);
      setError("File upload failed. Please try again.");
    }
  };

  const handleDeleteStudent = async (studentId) => {
    try {
      await axios.delete(
        `https://eduevaluator.com/api/attendance/delete/${studentId}`
      );
      setStudents((prevStudents) =>
        prevStudents.filter((student) => student._id !== studentId)
      );
    } catch (error) {
      console.error("Failed to delete student:", error);
      setError("Failed to delete student. Please try again later.");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setSelectedFiles(acceptedFiles);
      handleFileUpload(acceptedFiles);
    },
    multiple: false,
  });

  // Define columns for DataGrid
  const columns = [
    { field: "studentId", headerName: "Student ID", width: 150 },
    { field: "studentName", headerName: "Name", width: 200 },
    { field: "classSection", headerName: "Class", width: 150 },
    { field: "totalPresent", headerName: "Total Present", width: 150 },
    { field: "totalAbsent", headerName: "Total Absent", width: 150 },
    {
      field: "action",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <Button
            style={{
              marginTop: 10,
              marginRight: 7,
              backgroundColor: "#4286F0",
              color: "white",
              borderRadius: 20,
              marginBottom: 10,
            }}
            onClick={() => navigate(`/student/${params.row._id}`)}
          >
            View
          </Button>
          <IconButton
            color="error"
            onClick={() => handleDeleteStudent(params.row._id)}
          >
            <DeleteIcon style={{ color: "red" }} />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <div>
      <Button
        style={{
          marginTop: 10,
          width: 200,
          backgroundColor: "#4286F0",
          color: "white",
          borderRadius: 20,
          marginBottom: 10,
        }}
        onClick={() => setOpenModal(true)}
      >
        Upload Attendance Report
      </Button>

      <Button
        style={{
          marginTop: 10,
          width: 200,
          backgroundColor: "#4CAF50",
          color: "white",
          borderRadius: 20,
          marginBottom: 10,
          marginLeft: 10,
        }}
        onClick={() => setHowItWorksModalOpen(true)}
      >
        How this works ?
      </Button>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          {...getRootProps()}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "white",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <IconButton
            onClick={() => setOpenModal(false)}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>

          <input {...getInputProps()} />
          <FileUploadIcon style={{ fontSize: "50px", color: "#90A4AE" }} />
          <p style={{ color: "#546E7A", fontSize: "16px", fontWeight: "500" }}>
            Drag & drop files here, or click to select files
          </p>
          {open && (
            <Alert
              severity="warning"
              //    onClose={handleClose}
              style={{ marginBottom: 10 }}
            >
              We recommend using general information and ensuring that all data
              shared adheres to your institution's privacy guidelines. All data
              is processed securely, but to protect student privacy, please
              refrain from submitting any information that could compromise
              confidentiality.
            </Alert>
          )}
          <Button
            style={{
              marginTop: 10,
              width: 100,
              backgroundColor: "#4286F0",
              color: "white",
              borderRadius: 20,
            }}
            color="primary"
            onClick={() => setOpenModal(false)}
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </Box>
      </Modal>

      {loading ? (
        <Typography variant="h6" align="center" style={{ marginTop: "20px" }}>
          Loading student data...
        </Typography>
      ) : error ? (
        <Typography
          variant="h6"
          align="center"
          color="error"
          style={{ marginTop: "20px" }}
        >
          {error}
        </Typography>
      ) : students.length === 0 ? (
        <Typography variant="h6" align="center" style={{ marginTop: "20px" }}>
          No student data available
        </Typography>
      ) : (
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={students}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            disableSelectionOnClick
            getRowId={(row) => row._id} // Use _id as unique identifier
          />
        </div>
      )}

      <Modal
        open={howItWorksModalOpen}
        onClose={() => setHowItWorksModalOpen(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "white",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <IconButton
            onClick={() => setHowItWorksModalOpen(false)}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" align="center">
            How This Works
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            1.{" "}
            <a
              href="https://curriculumedgebucket.s3.us-east-2.amazonaws.com/test.xlsx"
              download
              style={{ textDecoration: "underline", color: "#4286F0" }}
            >
              Download
            </a>{" "}
            the template for the correct format.
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            2. Click on "Upload Attendance Report" to upload a file.
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            3. Ensure the uploaded file matches the template format.
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default AttendancePageComponent;
