import React from "react";
import { Routes, Route } from "react-router-dom";
import ReportForms from "./pages/ReportForms";
import MarketingPage from "./pages/MarketingPage";
import SignInSide from "./pages/SignInSide";
import SignUp from "./pages/SignUp";
import Dashboard from "./pages/Dashboard";
import QuizMaker from "./pages/QuizMaker";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import EditFormPage from "./pages/EditFormPage";
import TeacherDashboardPage from "./pages/TeacherDashboardPage";
import Scheduler from "./pages/Scheduler";
import StudentofConcern from "./pages/StudentofConcern";
import HomePage from "./pages/HomePage";
import LessonPlanner from "./pages/LessonPlanner";
import TVETLessonPlanForm from "./components/TVETLessonPlanForm";
import VerifyCard from "./pages/VerifyCard";
import StudentFeeback from "./pages/StudentFeeback";
import ModelGenerator from "./pages/ModelGenerator";
import Feedback from "./pages/Feedback";
import ConceptSimplification from "./pages/ConceptSimplification";
import AICourseCondensor from "./pages/AICourseCondensor";
import AttendancePage from "./pages/AttendancePage";
import AssistiveTechCompanion from "./pages/AssistiveTechCompanion";
import AILessonPlan from "./pages/AILessonPlan";
import WorkSpaceNavigator from "./pages/WorkSpaceNavigator";
import SWOTAnalysis from "./pages/SWOTAnalysis";
import ResetPassword from "./components/ResetPassword";
import SubsituteLessonPlanner from "./pages/SubsituteLessonPlanner";
import SchoolTripPlanner from "./pages/SchoolTripPlanner";
import LearningTheoryGuide from "./pages/LearningTheoryGuide";
import EducationNavigator from "./pages/EducationNavigator";
import CourseContentEvaluator from "./pages/CourseContentEvaluator";
import LeadershipAssist from "./pages/LeadershipAssist";
import VisualPromptWizard from "./pages/VisualPromptWizard";
import LiteracyExploerer from "./pages/LiteracyExploerer";
import EduCrafts from "./pages/EduCrafts";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import GlobalIEP from "./pages/GlobalIEP";
import AIFramework from "./pages/AIFramework";
import TVETLessonPlan from "./pages/TVETLessonPlan";
import Settings from "./pages/Settings";
import SignUpPage from "./pages/SignUpPage";
import NewsLetter from "./pages/NewsLetter";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import CompetencyFrameworks from "./pages/CompetencyFrameworks";
import StudentDetailPage from "./pages/StudentDetailPage";
import SubscriptionPage from "./pages/SubscriptionPage";
import MyLessonPlan from "./pages/MyLessonPlan";
import LessonPlanFeedback from "./pages/LessonPlanFeedback";
import StudentOfConcernHome from "./pages/StudentOfConcernHome";
import LessonPlanFormWebView from "./components/LessonPlanFormWebView";
import InstitutionSystemEvaluation from "./pages/InstitutionSystemEvaluation";
import EduFormsPage from "./pages/EduFormsPage";
import PowerPointCondesorPage from "./pages/PowerPointCondesorPage";
import PowerpointCreatorPage from "./pages/PowerpointCreatorPage";
import '@fontsource/roboto'; // Defaults to weight 400
import getMPTheme from "./theme/getMPTheme";
const AppRoutes = () => {
  const MPTheme = createTheme(getMPTheme("light"));
  return (
    <ThemeProvider theme={MPTheme}>
    <Routes>
      <Route path="/schoolTripPlanner" element={<SchoolTripPlanner />} />
      <Route path="/" element={<MarketingPage />} />
      <Route path="/join-us" element={<NewsLetter />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/powerpointCreator" element={<PowerpointCreatorPage />} />
      <Route path="/powerpointCondesnor" element={<PowerPointCondesorPage />} />
      <Route path="/swot" element={<SWOTAnalysis />} />
      <Route path="/globalIEP" element={<GlobalIEP />} />
      <Route path="/literacyExplorer" element={<LiteracyExploerer />} />
      <Route path="/eduCraft" element={<EduCrafts />} />
      <Route path="/educationNav" element={<EducationNavigator />} />
      <Route path="/learningTheory" element={<LearningTheoryGuide />} />
      <Route path="/visualPrompt" element={<VisualPromptWizard />} />
      <Route path="/aiFramework" element={<AIFramework />} />
      <Route path="/courseContent" element={<CourseContentEvaluator />} />
      <Route path="/workPlaceNav" element={<WorkSpaceNavigator />} />
      <Route path="/leadershipAssistant" element={<LeadershipAssist />} />
      <Route path="/feedback" element={<Feedback />} />
      <Route path="/eduLetters" element={<EduFormsPage />} />

      <Route
        path="/institutionSystemEvaluation"
        element={<InstitutionSystemEvaluation />}
      />
      <Route path="/lessonPlanner/:formId" element={<LessonPlanner />} />
      <Route
        path="/lessonPlanFeedback/:formId"
        element={<LessonPlanFeedback />}
      />

      <Route path="/myLessonPlans" element={<MyLessonPlan />} />

      <Route path="/addReport" element={<ModelGenerator />} />
      <Route path="/signIn" element={<SignInSide />} />
      <Route path="/signUp" element={<SignUpPage />} />
      <Route path="/verify" element={<VerifyCard />} />
      <Route path="/student/:studentId" element={<StudentDetailPage />} />
      <Route path="/attendance" element={<AttendancePage />} />
      <Route path="/subscrption" element={<SubscriptionPage />} />

      <Route
        path="/studentOfConcernForm/:formId"
        element={<StudentofConcern />}
      />
      <Route path="/quizMaker" element={<QuizMaker />} />
      <Route path="/studentOfConcern" element={<StudentOfConcernHome />} />
      <Route
        path="/assistiveTechCompanion"
        element={<AssistiveTechCompanion />}
      />
      <Route path="/aiCourseCondensor" element={<AICourseCondensor />} />
      <Route path="/aiLessonPlan" element={<AILessonPlan />} />
      <Route
        path="/assistiveTechCompanion"
        element={<AssistiveTechCompanion />}
      />
      <Route
        path="/conceptSimplification"
        element={<ConceptSimplification />}
      />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route
        path="/dashboard"
        element={<ProtectedRoute element={Dashboard} />}
      />
      <Route
        path="/curriculumEdgeTools"
        element={<ProtectedRoute element={StudentFeeback} />}
      />
      <Route
        path="/scheduler"
        element={<ProtectedRoute element={Scheduler} />}
      />
      <Route path="/competencyFrameworks" element={<CompetencyFrameworks />} />
      <Route path="/tvetLessonPlan" element={<TVETLessonPlan />} />
      <Route path="/subsituteLessonPlan" element={<SubsituteLessonPlanner />} />
      <Route path="/teacherMenu" element={<TeacherDashboardPage />} />
      <Route path="/report-view" element={<EditFormPage />} />
    </Routes>
    </ThemeProvider>
  );
};

function App() {
  return (
    <AuthProvider>
      <AppRoutes />
    </AuthProvider>
  );
}

export default App;
