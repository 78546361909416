import React, { useState } from "react";
import PptxGenJS from "pptxgenjs";
import {
  Accordion,
  AccordionDetails,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  Chip,
  MenuItem,
  Select,
  Paper,
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
} from "@mui/material";
import OpenAI from "openai";
import ContentCopyIcon from "@mui/icons-material/ContentCopy"; // Import the clipboard icon
import SchoolIcon from "@mui/icons-material/School";
import AssistantIcon from "@mui/icons-material/Assistant";
import { useDropzone } from "react-dropzone";
import LoadingModal from "./LoadingModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Cookies from "js-cookie";
import axios from "axios";
function Powerpointcreator() {
  // State variables
  const [workplacePrompt, setWorkplacePrompt] = useState("");
  const [context, setContext] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [teacherFirstName, setTeacherFirstName] = useState("");
  const [teacherLastName, setTeacherLastName] = useState("");
  const [subject, setSubject] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [levelOfUnderstanding, setLevelOfUnderstanding] = useState("");
  const [subjectDifficulty, setSubjectDifficulty] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false); // State for loading
  const [modalOpen, setModalOpen] = useState(false); // State for modal
  const [summary, setSummary] = useState(""); // State for API response

  // File upload handling
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const fileURLs = acceptedFiles.map((file) => URL.createObjectURL(file));
      setUploadedFiles((prevFiles) => [...prevFiles, ...fileURLs]);
    },
  });

  // Mock handler functions
  const handleTeacherFirstNameChange = (e) =>
    setTeacherFirstName(e.target.value);
  const handleTeacherLastNameChange = (e) => setTeacherLastName(e.target.value);
  const handleSubjectChange = (e) => setSubject(e.target.value);
  const handleGradeLevelChange = (e) => setGradeLevel(e.target.value);
  const handleLevelOfUnderstandingChange = (e) =>
    setLevelOfUnderstanding(e.target.value);
  const handleSubjectDifficultyChange = (e) =>
    setSubjectDifficulty(e.target.value);
  const handleLocationChange = (e) => setLocation(e.target.value);
  const handleDescriptionChange = (e) => setDescription(e.target.value);
  const saveDraft = () => alert("Draft saved!");
  // Function to log all form data
  const handleSubmit = async () => {
    setLoading(true); // Start loading
    try {
      const email = Cookies.get("email");
      const content = `Create a PowerPoint overview that is ${workplacePrompt} slides long. Here's the topic for the PowerPoint: ${context}. Please format the output as follows:
    The return fields need to be exactly as expected with all fieldNames such as SlideContent and BulletPoints togther. Only give the output with no additional text:
      [
        {
          "Slide": 1,
          "SlideContent": {
            "Title": "Introduction to Rocks",
            "BulletPoints": [
              "What are rocks?",
              "Importance of rocks",
              "Types of rocks"
            ],
            "Image": "Rocks in various shapes, sizes, and colors"
          }
        },
        {
          "Slide": 2,
          "SlideContent": {
            "Title": "What are Rocks?",
            "BulletPoints": [
              "Rocks are solid, natural substances",
              "Made up of minerals",
              "Found on Earth's crust"
            ],
            "Image": "Close-up image of a rock"
          }
        },
        {
          "Slide": 3,
          "SlideContent": {
            "Title": "Importance of Rocks",
            "BulletPoints": [
              "Used for construction",
              "Source of metals and precious stones",
              "Important for understanding Earth's history"
            ],
            "Image": "Different uses of rocks from building houses to making jewelry"
          }
        }
      ]`;
      
      const tokenResponse = await axios.post(
        "https://eduevaluator.com/api/users/decreaseToken",
        { email, content }
      );
      if (tokenResponse.data.message === "OpenAI response generated") {
        const summaries = tokenResponse.data.summary;
        let slidesData;
      try {
        
        slidesData = JSON.parse(summaries); // Try parsing the JSON string
        console.log(slidesData);
        createPowerPointSlides(slidesData); // Pass parsed data to PowerPoint creation function
      } catch (error) {
        toast.error("Error parsing summary");
      }

      } else {
        toast.error("You are out of credits");
      }
    } catch (error) {
      console.error("Error while fetching completion:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const processSummary = (summary) => {
  // Find the index of the first '[' character
  const index = summary.indexOf('[');
  
  // If '[' is found, slice the text from that point onwards, otherwise return the original summary
  return index !== -1 ? summary.slice(index) : summary;
};

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(summary)
      .then(() => {
        toast.success("Text copied to clipboard!"); // Optional: Show a message to the user
      })
      .catch((error) => {
        toast.error("Could not copy text: ", error);
      });
  };

  const createPowerPointSlides = (summaries) => {
    console.log("This is the summaries");
    console.log(summaries);

    console.log("This is the default test one");
    const slideData = [
        {
          Slide: 1,
          SlideContent: {
            BulletPoints: [
              "What are rocks?",
              "Importance of rocks"
            ],
            Image: "https://example.com/rocks-image.jpg", // Image can be first
            Title: "Introduction to rocks" // Title is after Image
          }
        },
        {
          Slide: 2,
          // SlideContent is missing here, which will trigger the warning in the console
        }
      ];
      console.log(slideData);
    // Create a new PowerPoint presentation
    const pptx = new PptxGenJS();
  
    // Loop through each slide in the summaries array
    summaries.forEach((summary) => {
      const { Slide, SlideContent } = summary; // Destructure Slide and SlideContent directly
  console.log(Slide);
  console.log(SlideContent);
      // Check if SlideContent exists
      if (SlideContent) {
        // Destructure SlideContent with fallback values for missing properties
        const { BulletPoints = [], Image, Title = "Default Title" } = SlideContent; 
  
        // Create a new slide
        const slide = pptx.addSlide();
  
        // Add Title
        slide.addText(Title, {
          x: 1, y: 0.5, w: '90%', h: 1,
          fontSize: 24, bold: true
        });
  
        // Add Bullet Points
        BulletPoints.forEach((bullet, index) => {
          slide.addText(bullet, {
            x: 1, y: 1.5 + index * 0.5, w: '90%', h: 1,
            fontSize: 18
          });
        });
  
        // Add Image (if any)
        if (Image) {
          slide.addImage({
            x: 1, y: 2 + BulletPoints.length * 0.5, w: '90%', h: 3,
            data: Image, // Assuming the Image is a URL or base64 string
          });
        }
      } else {
        // Log if SlideContent is missing
        console.warn(`Slide ${Slide} is missing SlideContent`);
      }
    });
  
    // Trigger the PowerPoint file to be downloaded automatically
    pptx.writeFile({ fileName: "Generated_Presentation.pptx" }).then(() => {
        toast.success('PowerPoint has been generated and has downloaded!');
    }).catch((error) => {
        toast.error("Error generating PowerPoint file.")
    });
  };

  return (
    <Paper elevation={3} sx={{ padding: 3, borderRadius: 2, width: "100%" }}>
      <ToastContainer />
      <LoadingModal loading={loading} message="Generating response..." />{" "}
      {/* Render loading modal */}
      <Container sx={{ width: "100%", maxWidth: "1200px" }}>
        <Box sx={{ marginTop: 3 }}>
          <Accordion
            expanded={true}
            sx={{
              width: "100%",
              backgroundColor: "white",
              color: "black",
              marginBottom: 2,
              borderRadius: 2,
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              "&:before": { display: "none" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#e0e0e0",
                padding: "16px",
                borderRadius: "4px 4px 0 0",
                color: "black",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              <AssistantIcon sx={{ marginRight: 1 }} />
             PowerPoint Creator
            </Box>
            <Container>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  marginTop: 4,
                  marginBottom: 4,
                  backgroundColor: "#f0f0f0",
                  padding: "16px",
                  borderRadius: "8px",
                }}
              >
                <Chip
                  label="What does this do?"
                  sx={{
                    backgroundColor: "#1976d2", // Blue color
                    color: "#ffffff", // This sets the text color to white
                    "& .MuiChip-label": {
                      color: "#ffffff", // Ensures the label is white
                    },
                    marginRight: "16px",
                  }}
                />
                <Typography variant="body1">
               This tool creates a PowerPoint decks based on given information.
                </Typography>
              </Box>
              <Grid container spacing={2}>
                {/* Full width dropdown for workplace navigator prompts */}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Select an amount of slides to generate</FormLabel>
                    <Select
                      value={workplacePrompt}
                      onChange={(e) => setWorkplacePrompt(e.target.value)}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Workplace Navigator Prompts",
                      }}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    >
                      <MenuItem value="" disabled>
                        Select an amount of slides to generate
                      </MenuItem>
                      {[
                        "1",
                        "2",
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "8",
                        "9",
                        "10",
                        "11",
                        "12",
                        "13",
                      ].map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Full width textarea for Context */}
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormLabel>What should I generate this PowerPoint about?</FormLabel>
                      <textarea
                        rows={4} // Adjust the number of rows as needed
                        value={context}
                        onChange={(e) => setContext(e.target.value)}
                        style={{
                          width: "100%",
                          borderRadius: "8px", // Similar style as Material-UI
                          padding: "10px",
                          border: "1px solid #ccc",
                          boxSizing: "border-box", // Ensures padding is included in width
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Box>
                {uploadedFiles.map((file, index) => (
                  <Chip
                    key={index}
                    label={file}
                    onDelete={() => {
                      setUploadedFiles(
                        uploadedFiles.filter((_, i) => i !== index)
                      );
                    }}
                    sx={{ margin: 1 }}
                  />
                ))}
              </Box>

              <Button
                type="button" // Change this to "button" instead of "submit"
                onClick={handleSubmit} // Call handleSubmit on click
                style={{
                  marginTop: 10,
                  width: 100,
                  backgroundColor: "#4286F0",
                  color: "white",
                  borderRadius: 20,
                }}
              >
                Ask AI
              </Button>
            </Container>
          </Accordion>
        </Box>
        {/* Modal for displaying results */}
        <Dialog
          open={modalOpen}
          onClose={() => {}} // Disable closing by clicking outside
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>AI Response</DialogTitle>
          <DialogContent>
            <pre>{summary}</pre>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCopyToClipboard}
              startIcon={<ContentCopyIcon />}
            />
            <Button onClick={() => setModalOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Paper>
  );
}

export default Powerpointcreator;
