import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Accordion,
  AccordionDetails,
  Button,
  Container,
  Grid,
  FormLabel,
  TextField,
  MenuItem,
  Select,
  Paper,
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  InputAdornment,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import "react-toastify/dist/ReactToastify.css"; // Import toastify CSS
import SettingsIcon from "@mui/icons-material/Settings";

function SettingsForm() {
  const [loading, setLoading] = useState(false);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPasswordToggle = () => setShowPassword((prev) => !prev);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    subscription: "",
  });
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const userEmail = Cookies.get("email");

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          "https://eduevaluator.com/api/users/getUserSettings",
          { email: userEmail }
        );
        const data = response.data;
        console.log(data);
        setUserData({
          firstName: data.name || "",
          lastName: data.lastName || "",
          email: data.email || "",
          role: data.role || "",
          subscriptionStatus: data.subscriptionStatus || "",
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (userEmail) {
      fetchUserData();
    }
  }, [userEmail]);

  const handleOpenPasswordModal = () => setPasswordModalOpen(true);
  const handleClosePasswordModal = () => setPasswordModalOpen(false);

  const handlePasswordChange = (e) => {
    if (e.target.name === "newPassword") setNewPassword(e.target.value);
    else if (e.target.name === "confirmNewPassword")
      setConfirmNewPassword(e.target.value);
  };

  const handlePasswordSubmit = async () => {
    if (newPassword !== confirmNewPassword) {
      toast.error("Passwords do not match!");
      return;
    }
    try {
      await axios.post("https://eduevaluator.com/api/users/update-password", {
        email: userEmail,
        password: newPassword,
      });
      toast.success("Password updated successfully!");
      handleClosePasswordModal();
    } catch (error) {
      toast.error("Error updating password");
      console.error("Error updating password:", error);
    }
  };

  return (
    <Paper elevation={3} sx={{ padding: 3, borderRadius: 2, width: "100%" }}>
      <ToastContainer />
      <Container sx={{ width: "100%", maxWidth: "1200px" }}>
        <Box sx={{ marginTop: 3 }}>
          <Accordion expanded={true} sx={{ width: "100%", marginBottom: 2 }}>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormLabel>Name</FormLabel>
                  <TextField value={userData.firstName} fullWidth disabled />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormLabel>Email</FormLabel>
                  <TextField value={userData.email} fullWidth disabled />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormLabel>Role</FormLabel>
                  <TextField value={userData.role} fullWidth disabled />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormLabel>Subscription Type</FormLabel>
                  <TextField
                    value={userData.subscriptionStatus ? "Active" : "Inactive"}
                    fullWidth
                    disabled
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    color="primary"
                    onClick={handleOpenPasswordModal}
                    style={{
                      marginTop: 10,
                      width: 250,
                      backgroundColor: "#4286F0",
                      color: "white",
                      borderRadius: 20,
                    }}
                  >
                    New Password
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>

        {/* Password Modal */}
        <Dialog
          open={passwordModalOpen}
          onClose={handleClosePasswordModal}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Set New Password</DialogTitle>
          <DialogContent>
            <Grid item xs={12} sm={6}>
              <FormLabel>New Password</FormLabel>
              <TextField
                type={showPassword ? "text" : "password"}
                name="newPassword"
                value={newPassword}
                onChange={handlePasswordChange}
                fullWidth
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPasswordToggle} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormLabel>Confirm New Password</FormLabel>
              <TextField
                type={showPassword ? "text" : "password"}
                name="confirmNewPassword"
                value={confirmNewPassword}
                onChange={handlePasswordChange}
                fullWidth
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPasswordToggle} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePasswordModal}>Cancel</Button>
            <Button onClick={handlePasswordSubmit} color="primary">
              Save Password
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Paper>
  );
}

export default SettingsForm;
