import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { PDFDownloadLink } from "@react-pdf/renderer";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import LessonPlanFormPDF from "./LessonPlanFormPDF"; // Adjust the import path if necessary
import {
  Box,
  Button,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Container,
  Typography,
  Accordion,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
  Chip,
  InputAdornment,
  Autocomplete,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Divider,
  CircularProgress,
} from "@mui/material";
import html2pdf from "html2pdf.js";
import OpenAI from "openai";
import InfoIcon from "@mui/icons-material/Info";
import InfoDialog from "./InfoDialog";
import SchoolIcon from "@mui/icons-material/School";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import LearningObjectivesModal from "./LearningObjectivesModal";

const LessonPlannerPage = () => {
  const openai = new OpenAI({
    apiKey:
      "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", // Store your API key in environment variables for security
  });
  const steps = [
    "Basic Information",
    "Learning Objectives",
    "Start of the Lesson",
    "Middle of the Lesson ",
    "End of the Lesson",
  ];

  const styles = {
    container: {
      fontFamily: "Arial, sans-serif",
      color: "#333",
      width: "700px",
      margin: "40px auto",
      padding: "20px",
      border: "1px solid #ddd",
      backgroundColor: "#f9f9f9",
    },
    header: {
      textAlign: "center",
      marginBottom: "30px",
    },
    headerTitle: {
      fontSize: "24px",
      color: "#333",
      margin: 0,
    },
    headerDate: {
      fontSize: "14px",
      color: "#666",
      margin: 0,
    },
    sectionHeader: {
      backgroundColor: "#000",
      color: "#fff",
      padding: "8px",
      fontSize: "18px",
      marginTop: "20px",
    },
    gridContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: "20px",
      paddingTop: "10px",
    },
    cell: {
      flex: "1 1 45%",
      padding: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      backgroundColor: "#fff",
    },
    cellLabel: {
      fontWeight: "bold",
      color: "#555",
      fontSize: "14px",
      paddingBottom: "4px",
    },
    cellValue: {
      fontSize: "16px",
      color: "#333",
      marginTop: "5px",
    },
    fullWidthCell: {
      flex: "1 1 100%",
    },
  };
  const location = useLocation();
  // Get the form ID.
  const { formId } = useParams(); // Get formId from URL parameters
  const pdfRef = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const [subject, setSubject] = useState("");
  const [shareWithAdmin, setShareWithAdmin] = useState(false);
  const [shareWithFeedBack, setShareWithFeedBack] = useState(false);
  const [gradeLevel, setGradeLevel] = useState("");
  const [lessonTime, setLessonTime] = useState("");
  const [lessonDate, setLessonDate] = useState("");
  const [lessonTitle, setLessonTitle] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [standards, setStandards] = useState("");
  const [materials, setMaterials] = useState("");
  const [keyTerms, setKeyTerms] = useState([]);
  const [introduction, setIntroduction] = useState("");
  const [loading, setLoading] = useState(false);
  const [connectionToPrevious, setConnectionToPrevious] = useState("");
  const [classroomMode, setClassroomMode] = useState("");
  const [timeAllocation, setTimeAllocation] = useState("");
  const [middleInstuction, setMiddleInstruction] = useState("");
  const [guidedPractice, setGuidedPractice] = useState("");
  const [independentPractice, setIndependentPractice] = useState("");
  const [differentiation, setDifferentiation] = useState("");
  const [starterText, setStarterText] = useState([]);
  const [studentsNeedingAccomodations, setStudentsNeedingAccomodations] =
    useState("");
  const [assesmentType, setAssesmentType] = useState("");
  const [assistiveTechnology, setAssistiveTechnology] = useState("");
  const [middleLessonTimeAllocation, setMiddleLessonTimeAllocation] =
    useState("");
  const [summary, setSummary] = useState("");
  const [connectionToFutureContent, setCnnectionToFutureContent] = useState("");
  // Modal Logic
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [summativeAssessment, setSummativeAssessment] = useState("");
  const [reflection, setReflection] = useState("");

  // Enhance with AI
  const enhanceTextWithAI = async (text, field) => {
    setLoading(true);
    try {
      const completion = await openai.chat.completions.create({
        model: "gpt-4", // Use the appropriate model
        messages: [
          {
            role: "user",
            content: `Enhance the text ${text}`,
          },
        ],
        max_tokens: 500,
      });
      const data = completion.choices[0].message.content;
      if (field === "introduction") {
        setIntroduction(data);
      }
      if (field === "connectionToPrevious") {
        setConnectionToPrevious(data);
      }
      if (field === "middleInstruction") {
        setMiddleInstruction(data);
      }
      if (field === "guidedPractice") {
        setGuidedPractice(data);
      }
      if (field === "independentPractice") {
        setIndependentPractice(data);
      }
      if (field === "differentiation") {
        setDifferentiation(data);
      }
      if (field === "assistiveTechnology") {
        setAssistiveTechnology(data);
      }
      if (field === "summary") {
        setSummary(data);
      }
      if (field === "connectionToFutureContent") {
        setCnnectionToFutureContent(data);
      }
      if (field === "reflection") {
        setReflection(data);
      }
    } catch (error) {
      console.error("Error enhancing text:", error);
    } finally {
      setLoading(false);
    }
  };
  const userEmail = Cookies.get("email");
  const navigate = useNavigate();
  // Learning Objectives
  const [open, setOpen] = useState(false);
  const [domain, setDomain] = useState("");
  const [subDomain, setSubDomain] = useState("");
  const [starter, setStarter] = useState("");
  const [startersList, setStartersList] = useState([]);
  const [selectionPath, setSelectionPath] = useState([]);
  const [allformData, setAllFormData] = useState({
    userEmail,
    shareWithAdmin,
    shareWithFeedBack,
    subject,
    gradeLevel,
    lessonTime,
    lessonDate,
    lessonTitle,
    standards,
    materials,
    keyTerms,
    introduction,
    loading,
    connectionToPrevious,
    classroomMode,
    timeAllocation,
    middleInstuction,
    guidedPractice,
    independentPractice,
    differentiation,
    studentsNeedingAccomodations,
    assesmentType,
    assistiveTechnology,
    middleLessonTimeAllocation,
    summary,
    starterText,
    connectionToFutureContent,
    summativeAssessment,
    reflection,
    isDraft: true,
    formId,
  });
  const formData = {
    subject,
    gradeLevel,
    lessonTime,
    lessonDate,
    lessonTitle,
    standards,
    materials,
    keyTerms,
    introduction,
    shareWithAdmin,
    shareWithFeedBack,
    connectionToPrevious,
    classroomMode,
    timeAllocation,
    middleInstuction,
    guidedPractice,
    independentPractice,
    differentiation,
    studentsNeedingAccomodations,
    assesmentType,
    assistiveTechnology,
    middleLessonTimeAllocation,
    summary,
    starterText,
    connectionToFutureContent,
    summativeAssessment,
    reflection,
    isDraft: true,
    formId,
    userEmail,
  };
  const formDataOnSubmit = {
    subject,
    gradeLevel,
    lessonTime,
    lessonDate,
    lessonTitle,
    standards,
    materials,
    keyTerms,
    introduction,
    shareWithAdmin,
    shareWithFeedBack,
    connectionToPrevious,
    classroomMode,
    timeAllocation,
    middleInstuction,
    guidedPractice,
    independentPractice,
    differentiation,
    studentsNeedingAccomodations,
    assesmentType,
    assistiveTechnology,
    middleLessonTimeAllocation,
    summary,
    starterText,
    connectionToFutureContent,
    summativeAssessment,
    reflection,
    isDraft: false,
    formId,
    userEmail,
  };

  const saveDraft = async () => {
    try {
      // Send data to the backend (adjust URL as needed)
      const response = await axios.post(
        "https://eduevaluator.com/api/lessonPlan/save-draft",
        formData
      );
      console.log("Data saved to database:", response.data);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  // Auto-save on page load or when formData changes
  useEffect(() => {
    console.log(formId);
    if (formId) {
      axios
        .get(`https://eduevaluator.com/api/lessonPlan/get-form/${formId}`)
        .then((response) => {
          const formData = response.data.data; // Assuming the returned data is inside 'data'
          console.log(formData.data);

          // Check if form data needs to be updated
          if (formData) {
            setAssistiveTechnology(formData.assistiveTechnology);
            setClassroomMode(formData.classroomMode);
            setCnnectionToFutureContent(formData.connectionToPrevious);
            setDifferentiation(formData.differentiation);
            setGradeLevel(formData.gradeLevel);
            setGuidedPractice(formData.guidedPractice);
            setIndependentPractice(formData.independentPractice);
            setIntroduction(formData.introduction);
            setKeyTerms(formData.keyTerms);
            setLessonDate(formData.lessonDate);
            setLessonTime(formData.lessonTime);
            setLessonTitle(formData.lessonTitle);
            setMaterials(formData.materials);
            setMiddleInstruction(formData.middleInstuction);
            setMiddleLessonTimeAllocation(formData.middleLessonTimeAllocation);
            setReflection(formData.reflection);
            setStandards(formData.standards);
            setStarterText(formData.starterText);
            setStudentsNeedingAccomodations(
              formData.studentsNeedingAccomodations
            );
            setSubject(formData.subject);
            setSummativeAssessment(formData.summativeAssessment);
            setTimeAllocation(formData.timeAllocation);
          }
        })
        .catch((error) => {
          console.error("Error fetching form data:", error);
        });
    }
  }, [formId]); // Only trigger when formId changes
  const handleSubmit = async () => {
    try {
      // Send data to the database
      await axios.post(
        "https://eduevaluator.com/api/lessonPlan/save-draft",
        formDataOnSubmit
      );

      // Trigger PDF download
      pdfRef.current.click();
      navigate("/myLessonPlans");
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const handleLearningObjectivesOpen = () => setOpen(true);
  const handleLearningObjectivesClose = () => setOpen(false);

  const domains = {
    Cognitive: [
      "Remembering",
      "Understanding",
      "Applying",
      "Analyzing",
      "Evaluating",
      "Creating",
    ],
    Affective: [
      "Receiving",
      "Responding",
      "Valuing",
      "Organizing",
      "Characterizing",
    ],
    Psychomotor: [
      "Perception",
      "Set",
      "GuidedResponse",
      "Mechanism",
      "ComplexOvertResponse",
      "Adaptation",
      "Origination",
    ],
  };

  const domainLabels = {
    Remembering: "Remembering",
    Understanding: "Understanding",
    Applying: "Applying",
    Analyzing: "Analyzing",
    Evaluating: "Evaluating",
    Creating: "Creating",
    Receiving: "Receiving",
    Responding: "Responding",
    Valuing: "Valuing",
    Organizing: "Organizing",
    Characterizing: "Characterizing",
    Perception: "Perception",
    Set: "Set",
    GuidedResponse: "Guided Response",
    Mechanism: "Mechanism",
    ComplexOvertResponse: "Complex Overt Response", // Adjusted label
    Adaptation: "Adaptation",
    Origination: "Origination",
  };

  const sentenceStarters = {
    // Cognitive Domain
    Remembering: [
      "Define the term/concept...",
      "List the steps/components of...",
      "Recall the events/details of...",
      "Identify the characteristics of...",
      "Recognize the significance of...",
    ],
    Understanding: [
      "Explain how/why...",
      "Summarize the main ideas of...",
      "Describe the process of...",
      "Interpret the meaning of...",
      "Classify the types/categories of...",
    ],
    Applying: [
      "Apply the principles of...",
      "Demonstrate how to use...",
      "Solve problems using...",
      "Implement strategies for...",
      "Use knowledge of... to...",
    ],
    Analyzing: [
      "Analyze the relationship between...",
      "Differentiate between... and...",
      "Examine the causes/effects of...",
      "Compare and contrast...",
      "Investigate the implications of...",
    ],
    Evaluating: [
      "Evaluate the effectiveness of...",
      "Critique the arguments presented in...",
      "Justify your reasoning for...",
      "Assess the value/impact of...",
      "Defend a position on...",
    ],
    Creating: [
      "Create a plan/model for...",
      "Design an experiment/project that...",
      "Develop a new approach to...",
      "Compose a piece of writing/music that...",
      "Formulate a hypothesis about...",
    ],

    // Affective Domain
    Receiving: [
      "Listen attentively to...",
      "Show awareness of...",
      "Follow instructions regarding...",
      "Acknowledge the importance of...",
    ],
    Responding: [
      "Participate in discussions about...",
      "Answer questions related to...",
      "Assist others in...",
      "Express interest in...",
    ],
    Valuing: [
      "Demonstrate commitment to...",
      "Explain the importance of...",
      "Support the idea of...",
      "Value the contributions of...",
    ],
    Organizing: [
      "Integrate new values into...",
      "Arrange personal beliefs to accommodate...",
      "Formulate a consistent philosophy about...",
      "Organize priorities to include...",
    ],
    Characterizing: [
      "Exhibit behavior consistent with...",
      "Display a commitment to...",
      "Lead others by example in...",
      "Advocate for...",
    ],

    // Psychomotor Domain
    Perception: [
      "Recognize cues needed to...",
      "Detect changes in...",
      "Differentiate between sensory inputs of...",
    ],
    Set: [
      "Prepare instruments/equipment for...",
      "Demonstrate readiness to...",
      "Position oneself appropriately to...",
    ],
    GuidedResponse: [
      "Imitate the demonstrated technique for...",
      "Practice the steps of...",
      "Follow guidelines to perform...",
    ],
    Mechanism: [
      "Assemble components of...",
      "Manipulate tools/equipment to...",
      "Perform tasks with confidence in...",
    ],
    ComplexOvertResponse: [
      "Execute complex movements in...",
      "Demonstrate proficiency in...",
      "Coordinate multiple actions to...",
    ],
    Adaptation: [
      "Modify techniques to suit...",
      "Adapt procedures when...",
      "Adjust equipment to improve...",
    ],
    Origination: [
      "Design a new way to...",
      "Construct an original sequence for...",
      "Create a novel approach to...",
    ],
    // Add other sub-domain starters as needed...
  };

  const handleDomainChange = (selectedDomain) => {
    setDomain(selectedDomain);
    setSubDomain("");
  };

  const handleSubDomainChange = (event) => {
    setSubDomain(event.target.value);
    setStarter("");
  };

  const handleStarterSelect = (starterOption) => {
    setStarter(starterOption);
  };

  const handleAddStarter = () => {
    if (starter) {
      // Add the starter to the starters list
      setStartersList([...startersList, { domain, subDomain, starter }]);
      setStarterText((prev) => [...prev, starter]);

      // Clear the modal selections
      setDomain("");
      setSubDomain("");
      setStarter("");
      setSelectionPath([]);

      handleLearningObjectivesClose();
    }
  };
  // End of learning Objectives
  const handleOpenModal = (message) => {
    setModalMessage(message); // Set the message to be displayed in the dialog
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      setKeyTerms([...keyTerms, inputValue.trim()]);
      setInputValue(""); // Clear the input after adding the key term
      e.preventDefault(); // Prevent form submission (if any)
    }
  };

  const handleDeleteKeyTerm = (keyTermToDelete) => {
    setKeyTerms(keyTerms.filter((keyTerm) => keyTerm !== keyTermToDelete));
  };

  const handleChange = (event, newValue) => {
    // Update keyTerms when the user types and selects terms
    setKeyTerms(newValue);
  };

  const handleDeleteObjective = (index) => {
    // Remove the objective from startersList at the given index
    const updatedStartersList = startersList.filter((_, i) => i !== index);
    setStartersList(updatedStartersList);
  };

  // Define step-specific content
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div>
            <Typography
              variant="subtitle1"
              sx={{ marginBottom: 2, fontWeight: "bold" }}
            >
              General Information
            </Typography>

            <Grid container spacing={2} style={{ marginTop: 10 }}>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Subject</FormLabel>
                  <TextField
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    variant="outlined"
                    sx={{ borderRadius: 2 }}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl>
                  <FormLabel htmlFor="email">Grade Level</FormLabel>
                  <TextField
                    autoFocus
                    style={{ width: "500px" }}
                    required
                    fullWidth
                    variant="outlined"
                    value={gradeLevel}
                    onChange={(event) => setGradeLevel(event.target.value)}
                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormLabel htmlFor="email">Lesson Start Time</FormLabel>
                <TextField
                  id="observed-time"
                  type="time"
                  style={{ width: "500px" }}
                  required
                  fullWidth
                  variant="outlined"
                  value={lessonTime}
                  onChange={(event) => setLessonTime(event.target.value)}
                  sx={{ backgroundColor: "white", borderRadius: 2 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 minutes
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormLabel htmlFor="date">Lesson Date</FormLabel>
                <TextField
                  id="date"
                  type="date"
                  style={{ width: "500px" }}
                  required
                  fullWidth
                  variant="outlined"
                  value={lessonDate}
                  onChange={(event) => setLessonDate(event.target.value)}
                  sx={{ backgroundColor: "white", borderRadius: 2 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl>
                  <FormLabel htmlFor="email">Lesson Title/Topic</FormLabel>
                  <TextField
                    autoFocus
                    style={{ width: "500px" }}
                    required
                    fullWidth
                    variant="outlined"
                    value={lessonTitle}
                    onChange={(event) => setLessonTitle(event.target.value)}
                    sx={{ backgroundColor: "white", borderRadius: 2 }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} style={{ marginBottom: 20 }}>
                <FormControl>
                  <FormLabel htmlFor="email">
                    Key Vocabulary / Concepts
                  </FormLabel>
                  <Box sx={{ width: "100%" }}>
                    <TextField
                      value={inputValue}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                      fullWidth
                      multiline
                      rowsMax={3}
                      variant="outlined"
                      style={{ width: "500px" }}
                      sx={{
                        "& .MuiInputBase-root": {
                          display: "flex",
                          flexWrap: "wrap",
                        },
                        "& input": {
                          flexGrow: 1,
                          minWidth: "500px", // Adjust the minimum width as needed
                        },
                      }}
                    />
                    <Box
                      sx={{ display: "flex", flexWrap: "wrap", marginTop: 1 }}
                    >
                      {keyTerms.map((keyTerm, index) => (
                        <Chip
                          key={index}
                          label={keyTerm}
                          color="primary"
                          onDelete={() => handleDeleteKeyTerm(keyTerm)}
                          sx={{ margin: "2px" }}
                        />
                      ))}
                    </Box>
                  </Box>
                </FormControl>
              </Grid>
              <Divider style={{ color: "red" }} />
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel>
                    Standards{" "}
                    <IconButton
                      color="primary"
                      onClick={() =>
                        handleOpenModal(
                          "List curriculum standards that align with the lesson objectives."
                        )
                      }
                      sx={{
                        marginLeft: 1,
                        padding: 0, // Remove the default padding of the IconButton to make it look like just an icon
                        minWidth: "auto", // Optional: Ensures no extra width is added around the icon
                      }}
                    >
                      <InfoIcon />
                    </IconButton>
                  </FormLabel>
                  <textarea
                    rows={4} // Adjust the number of rows as needed
                    value={standards}
                    onChange={(e) => setStandards(e.target.value)}
                    style={{
                      width: "100%",
                      borderRadius: "8px", // Similar style as Material-UI
                      padding: "10px",
                      border: "1px solid #ccc",
                      boxSizing: "border-box", // Ensures padding is included in width
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 20 }}>
                <FormControl fullWidth>
                  <FormLabel>Materials Needed</FormLabel>
                  <textarea
                    rows={4} // Adjust the number of rows as needed
                    value={materials}
                    onChange={(e) => setMaterials(e.target.value)}
                    style={{
                      width: "100%",
                      borderRadius: "8px", // Similar style as Material-UI
                      padding: "10px",
                      border: "1px solid #ccc",
                      boxSizing: "border-box", // Ensures padding is included in width
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
        );
      case 1:
        return (
          <div style={{ height: "75vh" }}>
            <Button
              style={{
                backgroundColor: "#4286F0",
                color: "white",
                borderRadius: 20,
                marginTop: 5,
              }}
              onClick={handleLearningObjectivesOpen}
            >
              Add Learning Objectives
            </Button>
            <Box sx={{ marginTop: 3 }}>
              <Typography variant="h5">Current Learning Objectives:</Typography>
              <Grid container spacing={2}>
                {startersList.map((item, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <Box
                      sx={{
                        padding: 2,
                        backgroundColor: "#f5f5f5",
                        borderRadius: 2,
                      }}
                    >
                      <Typography variant="h6">
                        {item.domain} Domain - {item.subDomain}
                      </Typography>
                      <Typography variant="body2">{item.starter}</Typography>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleDeleteObjective(index)}
                      >
                        Delete
                      </Button>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Modal open={open} onClose={handleLearningObjectivesClose}>
              <Box
                sx={{
                  padding: 3,
                  backgroundColor: "white",
                  width: "80%", // Make modal wider
                  margin: "auto",
                  marginTop: 10,
                  maxHeight: "80vh",
                  overflowY: "auto",
                  borderRadius: 3,
                }}
              >
                <Typography
                  variant="h4"
                  sx={{ marginBottom: 2, textAlign: "center" }}
                >
                  Select a Domain
                </Typography>

                {/* Domain Buttons */}
                <Grid container spacing={2} justifyContent="center">
                  {["Cognitive", "Affective", "Psychomotor"].map((item) => (
                    <Grid item xs={12} sm={4} key={item}>
                      <Box
                        onClick={() => handleDomainChange(item)}
                        sx={{
                          padding: 3,
                          backgroundColor: "#f0f0f0",
                          borderRadius: 2,
                          textAlign: "center",
                          cursor: "pointer",
                          "&:hover": { backgroundColor: "#e0e0e0" },
                        }}
                      >
                        <Typography variant="h5">{item} Domain</Typography>
                        <Typography
                          variant="body2"
                          sx={{ marginTop: 1, color: "gray" }}
                        >
                          {item === "Cognitive"
                            ? "Knowledge-based learning objectives"
                            : item === "Affective"
                            ? "Attitude-based learning objectives"
                            : "Skills-based learning objectives"}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>

                {domain && (
                  <>
                    {/* Sub-Domain Selection */}
                    <FormControl fullWidth sx={{ marginTop: 3 }}>
                      <FormLabel>Sub-Domain</FormLabel>
                      <Select
                        value={subDomain}
                        onChange={handleSubDomainChange}
                      >
                        {domains[domain]?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {domainLabels[option] || option}{" "}
                            {/* Use the label or fallback */}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {subDomain && (
                      <Box sx={{ marginTop: 2 }}>
                        {sentenceStarters[subDomain]?.map(
                          (starterOption, index) => (
                            <Button
                              key={index}
                              variant="outlined"
                              onClick={() => handleStarterSelect(starterOption)}
                              sx={{ margin: 1 }}
                            >
                              {starterOption}
                            </Button>
                          )
                        )}
                      </Box>
                    )}

                    {starter && (
                      <Box sx={{ marginTop: 2 }}>
                        <FormLabel>Objective Starter</FormLabel>
                        <TextField
                          value={starter}
                          onChange={(e) => setStarter(e.target.value)}
                          fullWidth
                        />
                        <Box sx={{ marginTop: 1 }}>
                          {selectionPath.map((path, index) => (
                            <Chip
                              key={index}
                              label={path}
                              sx={{ margin: 0.5 }}
                            />
                          ))}
                        </Box>
                        <Button
                          onClick={handleAddStarter}
                          style={{
                            backgroundColor: "#4286F0",
                            color: "white",
                            borderRadius: 20,
                            marginTop: 5,
                          }}
                        >
                          Add Objective
                        </Button>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </Modal>
          </div>
        );
      case 2:
        return (
          <div>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <Grid item xs={12} style={{ marginBottom: 20 }}>
                  <FormControl fullWidth>
                    <FormLabel style={{ marginTop: 5 }}>
                      Introduction / Hook
                      <Button
                        variant="outlined"
                        style={{ borderRadius: 20, marginLeft: 10 }}
                        onClick={() =>
                          enhanceTextWithAI(introduction, "introduction")
                        }
                        disabled={loading} // Disable the button while loading
                      >
                        {loading ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          "Enhance using AI"
                        )}
                      </Button>
                    </FormLabel>

                    <textarea
                      rows={4}
                      value={loading ? "Loading..." : introduction}
                      onChange={(e) => setIntroduction(e.target.value)}
                      style={{
                        width: "100%",
                        borderRadius: "8px",
                        padding: "10px",
                        border: "1px solid #ccc",
                        boxSizing: "border-box",
                        backgroundColor: loading ? "#f5f5f5" : "#fff", // Change background during loading
                        color: loading ? "#888" : "black", // Change text color during loading
                        transition: "background-color 0.3s, color 0.3s",
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item sm={12}>
                <FormControl fullWidth>
                  <FormLabel style={{ marginTop: 5 }}>
                    Connection to Previous Content
                    <Button
                      variant="outlined"
                      style={{ borderRadius: 20, marginLeft: 10 }}
                      onClick={() =>
                        enhanceTextWithAI(
                          connectionToPrevious,
                          "connectionToPrevious"
                        )
                      }
                      disabled={loading} // Disable the button while loading
                    >
                      {loading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        "Enhance using AI"
                      )}
                    </Button>
                  </FormLabel>

                  <textarea
                    rows={4}
                    value={loading ? "Loading..." : connectionToPrevious}
                    onChange={(e) => setConnectionToPrevious(e.target.value)}
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      padding: "10px",
                      border: "1px solid #ccc",
                      boxSizing: "border-box",
                      backgroundColor: loading ? "#f5f5f5" : "#fff", // Change background during loading
                      color: loading ? "#888" : "black", // Change text color during loading
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel htmlFor="email">Classroom Setup</FormLabel>
                  <Select
                    labelId="subject-label"
                    value={classroomMode}
                    onChange={(event) => setClassroomMode(event.target.value)}
                    label="Subject"
                    sx={{ borderRadius: 2 }}
                  >
                    <MenuItem value="Online">Online</MenuItem>
                    <MenuItem value="In Person">In Person</MenuItem>
                    <MenuItem value="Hybrid">Hybrid</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} style={{ marginBottom: 10 }}>
                <FormControl fullWidth>
                  <FormLabel htmlFor="email">Time Allocation</FormLabel>
                  <Select
                    labelId="subject-label"
                    value={timeAllocation}
                    onChange={(event) => setTimeAllocation(event.target.value)}
                    label="Subject"
                    sx={{ borderRadius: 2 }}
                  >
                    <MenuItem value="30 mins">30 mins</MenuItem>
                    <MenuItem value="60 mins">60 mins</MenuItem>
                    <MenuItem value="90 mins">90 mins</MenuItem>
                    <MenuItem value="120 mins">120 mins</MenuItem>
                    <MenuItem value="120 + mins">120 + mins</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        );
      case 3:
        return (
          <div>
            {" "}
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ marginBottom: 20 }}>
                <FormControl fullWidth>
                  <FormLabel style={{ marginTop: 5 }}>
                    Instruction / Teaching
                    <Button
                      variant="outlined"
                      style={{ borderRadius: 20, marginLeft: 10 }}
                      onClick={() =>
                        enhanceTextWithAI(middleInstuction, "middleInstruction")
                      }
                      disabled={loading} // Disable the button while loading
                    >
                      {loading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        "Enhance using AI"
                      )}
                    </Button>
                  </FormLabel>

                  <textarea
                    rows={4}
                    value={loading ? "Loading..." : middleInstuction}
                    onChange={(e) => setMiddleInstruction(e.target.value)}
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      padding: "10px",
                      border: "1px solid #ccc",
                      boxSizing: "border-box",
                      backgroundColor: loading ? "#f5f5f5" : "#fff", // Change background during loading
                      color: loading ? "#888" : "black", // Change text color during loading
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 20 }}>
                <FormControl fullWidth>
                  <FormLabel style={{ marginTop: 5 }}>
                    Guided Practice
                    <Button
                      variant="outlined"
                      style={{ borderRadius: 20, marginLeft: 10 }}
                      onClick={() =>
                        enhanceTextWithAI(guidedPractice, "guidedPractice")
                      }
                      disabled={loading} // Disable the button while loading
                    >
                      {loading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        "Enhance using AI"
                      )}
                    </Button>
                  </FormLabel>

                  <textarea
                    rows={4}
                    value={loading ? "Loading..." : guidedPractice}
                    onChange={(e) => setGuidedPractice(e.target.value)}
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      padding: "10px",
                      border: "1px solid #ccc",
                      boxSizing: "border-box",
                      backgroundColor: loading ? "#f5f5f5" : "#fff", // Change background during loading
                      color: loading ? "#888" : "black", // Change text color during loading
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 20 }}>
                <FormControl fullWidth>
                  <FormLabel style={{ marginTop: 5 }}>
                    Independent Practice
                    <Button
                      variant="outlined"
                      style={{ borderRadius: 20, marginLeft: 10 }}
                      onClick={() =>
                        enhanceTextWithAI(
                          independentPractice,
                          "independentPractice"
                        )
                      }
                      disabled={loading} // Disable the button while loading
                    >
                      {loading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        "Enhance using AI"
                      )}
                    </Button>
                  </FormLabel>

                  <textarea
                    rows={4}
                    value={loading ? "Loading..." : independentPractice}
                    onChange={(e) => setIndependentPractice(e.target.value)}
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      padding: "10px",
                      border: "1px solid #ccc",
                      boxSizing: "border-box",
                      backgroundColor: loading ? "#f5f5f5" : "#fff", // Change background during loading
                      color: loading ? "#888" : "black", // Change text color during loading
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 20 }}>
                <FormControl fullWidth>
                  <FormLabel style={{ marginTop: 5 }}>
                    Differentiation / Scaffolding
                    <Button
                      variant="outlined"
                      style={{ borderRadius: 20, marginLeft: 10 }}
                      onClick={() =>
                        enhanceTextWithAI(differentiation, "differentiation")
                      }
                      disabled={loading} // Disable the button while loading
                    >
                      {loading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        "Enhance using AI"
                      )}
                    </Button>
                  </FormLabel>

                  <textarea
                    rows={4}
                    value={loading ? "Loading..." : differentiation}
                    onChange={(e) => setDifferentiation(e.target.value)}
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      padding: "10px",
                      border: "1px solid #ccc",
                      boxSizing: "border-box",
                      backgroundColor: loading ? "#f5f5f5" : "#fff", // Change background during loading
                      color: loading ? "#888" : "black", // Change text color during loading
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 20 }}>
                <FormControl fullWidth>
                  <FormLabel style={{ marginTop: 5 }}>
                    Students Needing Accommodations
                  </FormLabel>

                  <textarea
                    rows={4}
                    value={studentsNeedingAccomodations}
                    onChange={(e) =>
                      setStudentsNeedingAccomodations(e.target.value)
                    }
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      padding: "10px",
                      border: "1px solid #ccc",
                      boxSizing: "border-box",
                      backgroundColor: loading ? "#f5f5f5" : "#fff", // Change background during loading
                      color: loading ? "#888" : "black", // Change text color during loading
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 20 }}>
                <FormControl fullWidth>
                  <FormLabel style={{ marginTop: 5 }}>
                    Assistive Technology
                    <Button
                      variant="outlined"
                      style={{ borderRadius: 20, marginLeft: 10 }}
                      onClick={() =>
                        enhanceTextWithAI(
                          assistiveTechnology,
                          "assistiveTechnology"
                        )
                      }
                      disabled={loading} // Disable the button while loading
                    >
                      {loading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        "Enhance using AI"
                      )}
                    </Button>
                  </FormLabel>

                  <textarea
                    rows={4}
                    value={loading ? "Loading..." : assistiveTechnology}
                    onChange={(e) => setAssistiveTechnology(e.target.value)}
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      padding: "10px",
                      border: "1px solid #ccc",
                      boxSizing: "border-box",
                      backgroundColor: loading ? "#f5f5f5" : "#fff", // Change background during loading
                      color: loading ? "#888" : "black", // Change text color during loading
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} style={{ marginBottom: 10 }}>
                <FormControl fullWidth>
                  <FormLabel htmlFor="email">Assessment / Evaluation</FormLabel>
                  <Select
                    labelId="subject-label"
                    value={assesmentType}
                    onChange={(event) => setAssesmentType(event.target.value)}
                    label="Subject"
                    sx={{ borderRadius: 2 }}
                  >
                    <MenuItem value="Formative Assessment">
                      Formative Assessment
                    </MenuItem>
                    <MenuItem value="Summative Assessment">
                      Summative Assessment
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel htmlFor="email">Time Allocation</FormLabel>
                  <Select
                    labelId="subject-label"
                    value={middleLessonTimeAllocation}
                    onChange={(event) =>
                      setMiddleLessonTimeAllocation(event.target.value)
                    }
                    label="Subject"
                    sx={{ borderRadius: 2 }}
                  >
                    <MenuItem value="30 mins">30 mins</MenuItem>
                    <MenuItem value="35 mins">35 mins</MenuItem>
                    <MenuItem value="40 mins">45 mins</MenuItem>
                    <MenuItem value="50 mins +">50 mins +</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        );
      case 4:
        return (
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Share with Admin</FormLabel>
                  <RadioGroup
                    value={shareWithAdmin ? "yes" : "no"}
                    onChange={(e) =>
                      setShareWithAdmin(e.target.value === "yes")
                    }
                    row // This makes the radio buttons appear next to each other
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {/* Share with Admin for Feedback */}
              <Grid item xs={12} sm={6}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Share with Admin for Feedback
                  </FormLabel>
                  <RadioGroup
                    value={shareWithFeedBack ? "yes" : "no"}
                    onChange={(e) =>
                      setShareWithFeedBack(e.target.value === "yes")
                    }
                    row // This makes the radio buttons appear next to each other
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 20 }}>
                <FormControl fullWidth>
                  <FormLabel style={{ marginTop: 5 }}>
                    Summary / Closing
                    <Button
                      variant="outlined"
                      style={{ borderRadius: 20, marginLeft: 10 }}
                      onClick={() => enhanceTextWithAI(summary, "summary")}
                      disabled={loading} // Disable the button while loading
                    >
                      {loading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        "Enhance using AI"
                      )}
                    </Button>
                  </FormLabel>

                  <textarea
                    rows={4}
                    value={loading ? "Loading..." : summary}
                    onChange={(e) => setSummary(e.target.value)}
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      padding: "10px",
                      border: "1px solid #ccc",
                      boxSizing: "border-box",
                      backgroundColor: loading ? "#f5f5f5" : "#fff", // Change background during loading
                      color: loading ? "#888" : "black", // Change text color during loading
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 20 }}>
                <FormControl fullWidth>
                  <FormLabel style={{ marginTop: 5 }}>
                    Connection to Future Content
                    <Button
                      variant="outlined"
                      style={{ borderRadius: 20, marginLeft: 10 }}
                      onClick={() =>
                        enhanceTextWithAI(
                          connectionToFutureContent,
                          "connectionToFutureContent"
                        )
                      }
                      disabled={loading} // Disable the button while loading
                    >
                      {loading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        "Enhance using AI"
                      )}
                    </Button>
                  </FormLabel>

                  <textarea
                    rows={4}
                    value={loading ? "Loading..." : connectionToFutureContent}
                    onChange={(e) =>
                      setCnnectionToFutureContent(e.target.value)
                    }
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      padding: "10px",
                      border: "1px solid #ccc",
                      boxSizing: "border-box",
                      backgroundColor: loading ? "#f5f5f5" : "#fff", // Change background during loading
                      color: loading ? "#888" : "black", // Change text color during loading
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} style={{ marginBottom: 10 }}>
                <FormControl fullWidth>
                  <FormLabel htmlFor="email">
                    End of Lesson Assessment
                  </FormLabel>
                  <Select
                    labelId="subject-label"
                    value={summativeAssessment}
                    onChange={(event) =>
                      setSummativeAssessment(event.target.value)
                    }
                    label="Subject"
                    sx={{ borderRadius: 2 }}
                  >
                    <MenuItem value="Formative Assessment">
                      Formative Assessment
                    </MenuItem>
                    <MenuItem value="Summative Assessment">
                      Summative Assessment
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 20 }}>
                <FormControl fullWidth>
                  <FormLabel style={{ marginTop: 5 }}>
                    Reflection
                    <Button
                      variant="outlined"
                      style={{ borderRadius: 20, marginLeft: 10 }}
                      onClick={() =>
                        enhanceTextWithAI(reflection, "reflection")
                      }
                      disabled={loading} // Disable the button while loading
                    >
                      {loading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        "Enhance using AI"
                      )}
                    </Button>
                  </FormLabel>

                  <textarea
                    rows={4}
                    value={loading ? "Loading..." : reflection}
                    onChange={(e) => setReflection(e.target.value)}
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      padding: "10px",
                      border: "1px solid #ccc",
                      boxSizing: "border-box",
                      backgroundColor: loading ? "#f5f5f5" : "#fff", // Change background during loading
                      color: loading ? "#888" : "black", // Change text color during loading
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>{" "}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{ padding: 3, borderRadius: 2, minWidth: "100%", minHeight: "100%" }}
    >
      <Stepper activeStep={activeStep} sx={{ marginBottom: 3 }}>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Container>
        <Button
          onClick={saveDraft}
          style={{
            backgroundColor: "#4286F0",
            color: "white",
            borderRadius: 20,
            marginBottom: 10,
          }}
        >
          Save Progress
        </Button>
        <Box sx={{ marginBottom: 3 }}>
          <Accordion
            expanded={true}
            sx={{
              backgroundColor: "white",
              color: "black",
              marginBottom: 2,
              borderRadius: 2,
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#e0e0e0",
                padding: "16px",
                borderRadius: "4px 4px 0 0",
                color: "black",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              <SchoolIcon sx={{ marginRight: 1 }} />
              Lesson Planner
            </Box>
            <Container>
              {/* Render step-specific content */}
              {renderStepContent(activeStep)}
            </Container>
          </Accordion>
        </Box>

        {/* Navigation buttons */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 2,
          }}
        >
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            style={{
              backgroundColor: "#4286F0",
              color: "white",
              borderRadius: 20,
              marginTop: 5,
            }}
            color="secondary"
          >
            Back
          </Button>
          {activeStep === steps.length - 1 ? (
            <Button
              onClick={handleSubmit}
              style={{
                backgroundColor: "#4286F0",
                color: "white",
                borderRadius: 20,
                marginTop: 5,
              }}
            >
              Submit
            </Button>
          ) : (
            <Button
              onClick={handleNext}
              style={{
                backgroundColor: "#4286F0",
                color: "white",
                borderRadius: 20,
                marginTop: 5,
              }}
            >
              Next
            </Button>
          )}
        </Box>
        <InfoDialog
          open={openModal}
          onClose={handleCloseModal}
          message={modalMessage}
        />
      </Container>
      <PDFDownloadLink
        document={<LessonPlanFormPDF formData={formData} />}
        fileName="LessonPlan.pdf"
      >
        {({ loading, blob, url, error }) => (
          <a ref={pdfRef} style={{ display: "none" }} href={url} download>
            Download PDF
          </a>
        )}
      </PDFDownloadLink>
    </Paper>
  );
};

export default LessonPlannerPage;
