import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import { Container, FormLabel } from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import { styled } from "@mui/material/styles";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import RssFeedRoundedIcon from "@mui/icons-material/RssFeedRounded";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import axios from "axios"; // Import axios
import Cookies from "js-cookie"; // Import Cookies for handling cookies

export function Search({ onSearchChange }) {
  return (
    <FormControl sx={{ width: { xs: "100%", md: "25ch" } }} variant="outlined">
      <OutlinedInput
        size="small"
        id="search"
        placeholder="Search…"
        sx={{ flexGrow: 1 }}
        startAdornment={
          <InputAdornment position="start" sx={{ color: "text.primary" }}>
            <SearchRoundedIcon fontSize="small" />
          </InputAdornment>
        }
        inputProps={{
          "aria-label": "search",
        }}
        onChange={(e) => onSearchChange(e.target.value)}
      />
    </FormControl>
  );
}

export default function FeedbackForm() {
  const [tickets, setTickets] = useState([]); // State to hold fetched tickets
  const [ticketType, setTicketType] = useState("");
  const [ticketDetails, setTicketDetails] = useState("");
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = React.useState({
    name: "",
    status: "pending",
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    const email = Cookies.get("email"); // Get email from cookies
    const newTicket = {
      date: new Date().toISOString().split("T")[0], // Today's date in YYYY-MM-DD format
      ticketType,
      ticketDetails,
      email, // Use email from cookies
      status: "pending",
    };

    try {
      await axios.post(
        "https://eduevaluator.com/api/tickets/add-tickets",
        newTicket
      ); // Adjust the URL as needed
      // Optionally, refresh tickets after submission
      fetchTickets();
      handleClose();
    } catch (error) {
      console.error("Error submitting ticket:", error);
    }
  };
  const fetchTickets = async () => {
    const email = Cookies.get("email"); // Get email from cookies

    try {
      const response = await axios.get(
        "https://eduevaluator.com/api/tickets/get-tickets",
        { params: { email } } // Send email as a query parameter
      );
      setTickets(response.data); // Update tickets state with fetched data
    } catch (error) {
      console.error("Error fetching tickets:", error);
    }
  };

  useEffect(() => {
    fetchTickets(); // Fetch tickets on component mount
  }, []);

  const getStatusColor = (status) => {
    switch (status) {
      case "approved":
        return "#4caf50"; // green
      case "pending":
        return "#edbc12"; // yellow
      case "rejected":
        return "#f44336"; // red
      default:
        return "transparent";
    }
  };

  return (
    <Container>
      <div style={{ width: "100%" }}>
        <div>
          <Typography variant="h1" gutterBottom>
            Give Us Your Feedback
          </Typography>
          <Typography>
            We value your input and want to hear from you! Your feedback is
            essential in helping us improve our services. If you have any
            suggestions or if something isn’t right, please don’t hesitate to
            share your thoughts with us. Together, we can enhance your
            experience and ensure that we meet your needs effectively.
          </Typography>
        </div>
        <Box
          sx={{
            display: { xs: "flex", sm: "none" },
            flexDirection: "row",
            gap: 1,
            width: { xs: "100%", md: "fit-content" },
            overflow: "auto",
          }}
        >
          <Search />
          <IconButton size="small" aria-label="RSS feed">
            <RssFeedRoundedIcon />
          </IconButton>
        </Box>

        <Button
          style={{
            marginTop: 10,
            width: 200,
            backgroundColor: "#4286F0",
            color: "white",
            borderRadius: 20,
          }}
          onClick={handleOpen}
          sx={{ mt: 2 }}
        >
          Submit a Ticket
        </Button>

        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Typography variant="h6" component="h2">
              Submit Ticket
            </Typography>

            <FormControl fullWidth margin="normal">
              <FormLabel>Ticket Type</FormLabel>
              <Select
                value={ticketType}
                onChange={(e) => setTicketType(e.target.value)}
                displayEmpty
                inputProps={{ "aria-label": "Learning Style" }}
                sx={{ backgroundColor: "white", borderRadius: 2 }}
              >
                <MenuItem value="" disabled>
                  Select a ticket type
                </MenuItem>
                {["Suggest a tool", "Report a bug"].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <FormLabel>Ticket Request</FormLabel>
              <textarea
                rows={4} // Adjust the number of rows as needed
                value={ticketDetails}
                onChange={(e) => setTicketDetails(e.target.value)}
                style={{
                  width: "100%",
                  borderRadius: "8px", // Similar style as Material-UI
                  padding: "10px",
                  border: "1px solid #ccc",
                  boxSizing: "border-box", // Ensures padding is included in width
                }}
              />
            </FormControl>

            <Button
              style={{
                marginTop: 10,
                width: 100,
                backgroundColor: "#4286F0",
                color: "white",
                borderRadius: 20,
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
        </Modal>

        <Grid container spacing={2} sx={{ mt: 3 }}>
          {tickets.map((ticket) => (
            <Grid item xs={12} key={ticket.id}>
              <Card
                sx={{
                  width: "100%",
                  backgroundColor: "white", // white background
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  padding: 2,
                  borderRadius: 0, // no rounded corners
                  boxShadow: 0, // optional: add some shadow for depth
                }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h5" color="text.secondary">
                    {ticket.ticketType}
                  </Typography>
                  <Typography variant="h6">{ticket._id}</Typography>

                  <Typography variant="body2" color="text.secondary">
                    Date Submitted: {ticket.date}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Ticket Details: {ticket.ticketDetails}
                  </Typography>
                  <Chip
                    label={
                      ticket.status.charAt(0).toUpperCase() +
                        ticket.status.slice(1) || ""
                    }
                    sx={{
                      mt: 1,
                      backgroundColor: getStatusColor(ticket.status),
                      "& .MuiChip-label": {
                        color: "white", // Ensure that the color is applied to the label
                      },
                    }}
                  />
                </CardContent>
                <Box
                  sx={{
                    width: "100%", // full width
                    height: "5px", // adjust height as needed
                    backgroundColor: getStatusColor(ticket.status),
                    mt: 1, // margin top for spacing
                  }}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </Container>
  );
}
