import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function LoadingModal({ loading, message }) {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      open={loading}
    >
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress color="inherit" />
        <Typography variant="h6" sx={{ marginTop: 2 }}>
          {message || "Loading..."}
        </Typography>
      </Box>
    </Backdrop>
  );
}
