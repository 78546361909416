import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  Chip,
  MenuItem,
  Select,
  Paper,
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
} from "@mui/material";
import OpenAI from "openai";
import ContentCopyIcon from "@mui/icons-material/ContentCopy"; // Import the clipboard icon
import SchoolIcon from "@mui/icons-material/School";
import AssistantIcon from "@mui/icons-material/Assistant";
import { useDropzone } from "react-dropzone";
import LoadingModal from "./LoadingModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Cookies from "js-cookie";
import axios from "axios";
function LearningTheoryGuideForm() {
  // State variables
  const [selectedPrompt, setSelectedPrompt] = useState("");
  const [additionalQuestion, setAdditionalQuestion] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [teacherFirstName, setTeacherFirstName] = useState("");
  const [teacherLastName, setTeacherLastName] = useState("");
  const [subject, setSubject] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [levelOfUnderstanding, setLevelOfUnderstanding] = useState("");
  const [subjectDifficulty, setSubjectDifficulty] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false); // State for loading
  const [modalOpen, setModalOpen] = useState(false); // State for modal
  const [summary, setSummary] = useState(""); // State for API response

  // File upload handling
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const fileURLs = acceptedFiles.map((file) => URL.createObjectURL(file));
      setUploadedFiles((prevFiles) => [...prevFiles, ...fileURLs]);
    },
  });

  // Mock handler functions
  const handleTeacherFirstNameChange = (e) =>
    setTeacherFirstName(e.target.value);
  const handleTeacherLastNameChange = (e) => setTeacherLastName(e.target.value);
  const handleSubjectChange = (e) => setSubject(e.target.value);
  const handleGradeLevelChange = (e) => setGradeLevel(e.target.value);
  const handleLevelOfUnderstandingChange = (e) =>
    setLevelOfUnderstanding(e.target.value);
  const handleSubjectDifficultyChange = (e) =>
    setSubjectDifficulty(e.target.value);
  const handleLocationChange = (e) => setLocation(e.target.value);
  const handleDescriptionChange = (e) => setDescription(e.target.value);
  const saveDraft = () => alert("Draft saved!");
  // Function to log all form data
  // Function to log all form data
  const handleSubmit = async () => {
    const formData = {
      subject,
      gradeLevel,
      levelOfUnderstanding,
      subjectDifficulty,
      location,
      description,
    };
    console.log("Form Data:", formData);

    setLoading(true); // Start loading

    try {
      const email = Cookies.get("email");
      const content = `This tool helps to explore education design theories from John Dewey to the present. Provide concise explanations, examples, and applications of theories like Behaviorism, Cognitivism, Constructivism, the ADDIE model, and Bloom's taxonomy. Highlight key figures, principles, and their influence on educational design. Include supporting resources and references. Utilize image generation (DALL·E) to create visuals that enhance understanding when appropriate. Avoid outdated theories without context and ensure responses are accurate and informative, reflecting both historical and contemporary views. Feel free to seek clarification on inquiries, and prioritize responses that align with educational design theories. You can also search the web for additional relevant theories. The question is ${selectedPrompt} with additonal info of ${additionalQuestion}.Don't use more than one image.`;
      const tokenResponse = await axios.post(
        "https://eduevaluator.com/api/users/decreaseToken",
        { email, content }
      );
      if (tokenResponse.data.message === "OpenAI response generated") {
        const summaries = tokenResponse.data.summary;
        setSummary(summaries); // Save summary for modal
        setModalOpen(true); // Open modal
      } else {
        toast.error("You are out of credits");
      }
    } catch (error) {
      console.error("Error while fetching completion:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(summary)
      .then(() => {
        toast.success("Text copied to clipboard!"); // Optional: Show a message to the user
      })
      .catch((error) => {
        toast.error("Could not copy text: ", error);
      });
  };

  const understandingOptions = [
    "Beginner",
    "Intermediate",
    "Developing",
    "Expert",
  ];

  const subjectOptions = [
    "Math",
    "Science",
    "English",
    "History",
    "Art",
    "Music",
    // Add more subjects as needed
  ];

  const gradeOptions = [
    "1st Grade",
    "2nd Grade",
    "3rd Grade",
    "4th Grade",
    "5th Grade",
    "6th Grade",
    "7th Grade",
    "8th Grade",
    "9th Grade",
    "10th Grade",
    "11th Grade",
    "12th Grade",
  ];

  return (
    <Paper elevation={3} sx={{ padding: 3, borderRadius: 2, width: "100%" }}>
      <ToastContainer />
      <LoadingModal loading={loading} message="Generating response..." />{" "}
      {/* Render loading modal */}
      <Container sx={{ width: "100%", maxWidth: "1200px" }}>
        <Box sx={{ marginTop: 3 }}>
          <Accordion
            expanded={true}
            sx={{
              width: "100%",
              backgroundColor: "white",
              color: "black",
              marginBottom: 2,
              borderRadius: 2,
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              "&:before": { display: "none" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#e0e0e0",
                padding: "16px",
                borderRadius: "4px 4px 0 0",
                color: "black",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              <AssistantIcon sx={{ marginRight: 1 }} />
              Learning Theory Guide
            </Box>
            <Container>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  marginTop: 4,
                  marginBottom: 4,
                  backgroundColor: "#f0f0f0",
                  padding: "16px",
                  borderRadius: "8px",
                }}
              >
                <Chip
                  label="What does this do?"
                  sx={{
                    backgroundColor: "#1976d2", // Blue color
                    color: "#ffffff", // This sets the text color to white
                    "& .MuiChip-label": {
                      color: "#ffffff", // Ensures the label is white
                    },
                    marginRight: "16px",
                  }}
                />
                <Typography variant="body1">
                  I explore educational theories, offering concise explanations,
                  examples, and applications.
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormLabel>Prompt Options</FormLabel>
                      <Select
                        value={selectedPrompt}
                        onChange={(e) => setSelectedPrompt(e.target.value)}
                        displayEmpty
                        inputProps={{ "aria-label": "Prompt Options" }}
                        sx={{ backgroundColor: "white", borderRadius: 2 }}
                      >
                        <MenuItem value="" disabled>
                          Select a Prompt
                        </MenuItem>
                        <MenuItem value="Discuss John Dewey's influence on instructional design.">
                          Discuss John Dewey's influence on instructional
                          design.
                        </MenuItem>
                        <MenuItem value="How does the ADDIE model apply to e-learning development?">
                          How does the ADDIE model apply to e-learning
                          development?
                        </MenuItem>
                        <MenuItem value="Describe the role of cognitive load theory in instructional design.">
                          Describe the role of cognitive load theory in
                          instructional design.
                        </MenuItem>
                        <MenuItem value="Behaviorism">Behaviorism</MenuItem>
                        <MenuItem value="Cognitivism">Cognitivism</MenuItem>
                        <MenuItem value="Connectivism">Connectivism</MenuItem>
                        <MenuItem value="Constructivism">
                          Constructivism
                        </MenuItem>
                        <MenuItem value="Critical Pedagogy">
                          Critical Pedagogy
                        </MenuItem>
                        <MenuItem value="Experiential Learning">
                          Experiential Learning
                        </MenuItem>
                        <MenuItem value="Humanistic Learning Theory">
                          Humanistic Learning Theory
                        </MenuItem>
                        <MenuItem value="Multiple Intelligences">
                          Multiple Intelligences
                        </MenuItem>
                        <MenuItem value="Self-Determination Theory">
                          Self-Determination Theory
                        </MenuItem>
                        <MenuItem value="Situated Learning">
                          Situated Learning
                        </MenuItem>
                        <MenuItem value="Social Learning Theory">
                          Social Learning Theory
                        </MenuItem>
                        <MenuItem value="Transformative Learning">
                          Transformative Learning
                        </MenuItem>
                        <MenuItem value="What are the key principles of Bloom's taxonomy?">
                          What are the key principles of Bloom's taxonomy?
                        </MenuItem>
                        <MenuItem value="Ask a question…">
                          Ask a question…
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormLabel>Additional Details</FormLabel>
                      <textarea
                        rows={4} // Adjust the number of rows as needed
                        value={additionalQuestion}
                        onChange={(e) => setAdditionalQuestion(e.target.value)}
                        style={{
                          width: "100%",
                          borderRadius: "8px", // Similar style as Material-UI
                          padding: "10px",
                          border: "1px solid #ccc",
                          boxSizing: "border-box", // Ensures padding is included in width
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Box>
                {uploadedFiles.map((file, index) => (
                  <Chip
                    key={index}
                    label={file}
                    onDelete={() => {
                      setUploadedFiles(
                        uploadedFiles.filter((_, i) => i !== index)
                      );
                    }}
                    sx={{ margin: 1 }}
                  />
                ))}
              </Box>

              <Button
                type="button" // Change this to "button" instead of "submit"
                onClick={handleSubmit} // Call handleSubmit on click
                style={{
                  marginTop: 10,
                  width: 100,
                  backgroundColor: "#4286F0",
                  color: "white",
                  borderRadius: 20,
                }}
              >
                Ask AI
              </Button>
            </Container>
          </Accordion>
        </Box>
        {/* Modal for displaying results */}
        <Dialog
          open={modalOpen}
          onClose={() => {}} // Disable closing by clicking outside
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>AI Response</DialogTitle>
          <DialogContent>
            <pre>{summary}</pre>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCopyToClipboard}
              startIcon={<ContentCopyIcon />}
            />
            <Button onClick={() => setModalOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Paper>
  );
}

export default LearningTheoryGuideForm;
