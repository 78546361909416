import * as React from "react";
import { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SchoolIcon from "@mui/icons-material/School";
import searchBackground from "../Images/searchBackground.png";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import { School, AutoAwesome, AccessTime, Help } from "@mui/icons-material";
import { useTheme } from "@mui/system";

function FrostedGlassCard({ icon, title, bgColor, description }) {
  const [flipped, setFlipped] = useState(false);

  const handleFlip = () => {
    setFlipped(!flipped);
  };

  return (
    <Card
      onClick={handleFlip}
      sx={{
        perspective: "1000px",
        width: "100%", // Ensure the card takes full width of its container
        height: 150,
        cursor: "pointer",
        "&:hover": {
          transform: "scale(1.05)",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          width: "100%", // Full width
          height: "100%",
          position: "relative",
          transformStyle: "preserve-3d",
          transition: "transform 0.6s",
          transform: flipped ? "rotateY(180deg)" : "rotateY(0deg)",
        }}
      >
        {/* Front of Card */}
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: bgColor,
              width: 50,
              height: 50,
              borderRadius: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: `0 4px 12px ${bgColor}`,
              mb: 2,
            }}
          >
            {icon}
          </Box>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
            {title}
          </Typography>
        </CardContent>

        {/* Back of Card */}
        <CardContent
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)",
            borderRadius: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
            backfaceVisibility: "hidden",
            transform: "rotateY(180deg)",
          }}
        >
          <Typography variant="body1" sx={{ color: "black" }}>
            {description}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
}

const FeatureFilter = React.forwardRef((props, ref) => {
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState("");
  const [showAll, setShowAll] = useState(false);

  const cards = [
    {
      icon: <School sx={{ color: "white", fontSize: 30 }} />,
      title: "AI Lesson Plans",
      bgColor: "#4286F0",
      description: "Explore AI-powered lesson plans for teachers.",
    },
    {
      icon: <AutoAwesome sx={{ color: "white", fontSize: 30 }} />,
      title: "Teacher Evaluation",
      bgColor: "#FF6B6B",
      description: "Assist with comprehensive teacher evaluations.",
    },
    {
      icon: <AccessTime sx={{ color: "white", fontSize: 30 }} />,
      title: "Concept Simplification",
      bgColor: "#4CAF50",
      description: "Simplify complex concepts for easier understanding.",
    },
    {
      icon: <Help sx={{ color: "white", fontSize: 30 }} />,
      title: "FlexiPace Course Condensor",
      bgColor: "#FFC107",
      description: "Condense lengthy courses using AI technology.",
    },
    {
      icon: <SchoolIcon sx={{ color: "white", fontSize: 30 }} />,
      title: "School Trip Planner",
      bgColor: "#ee7c11",
      description:
        "Helps plan excursions by suggesting destinations and activities.",
    },
    {
      icon: <SchoolIcon sx={{ color: "white", fontSize: 30 }} />,
      title: "Substitute lesson plan",
      bgColor: "#2cd390",
      description:
        "Expert in designing lesson plans tailored for cover supervisors managing classrooms in the absence of the regular teacher. ",
    },
    {
      icon: <SchoolIcon sx={{ color: "white", fontSize: 30 }} />,
      title: "AI Framework Guide",
      bgColor: "#7525da",
      description:
        "It offers up-to-date information, guidance, and analysis on AI-related topics.",
    },
    {
      icon: <SchoolIcon sx={{ color: "white", fontSize: 30 }} />,
      title: "Global IEP writer ",
      bgColor: "#34b2cb",
      description:
        "This tool provides templates, guidance, and sample goals while ensuring accessibility and compliance with best practices for special education.",
    },
    // Add more cards as needed
  ];

  const filteredCards = cards.filter((card) =>
    card.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div ref={ref}>
      <Box
        sx={{
          minHeight: "100vh",
          backgroundImage: `url(${searchBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container
          sx={{
            pt: { xs: 4, sm: 12 },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: { xs: 3, sm: 6 },
            maxWidth: "100%", // Full width on mobile
            width: "100%", // Ensure full width
          }}
        >
          <Typography
            variant="h4"
            sx={{
              textTransform: "uppercase",
              color: "#f8af05",
              fontWeight: "bold",
            }}
          >
            Our Product Features
          </Typography>

          {/* Card Grid */}
          <Grid container spacing={2} justifyContent="center">
            {filteredCards.length > 0 ? (
              filteredCards
                .slice(0, showAll ? filteredCards.length : 8)
                .map((card, index) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <FrostedGlassCard
                      icon={card.icon}
                      title={card.title}
                      bgColor={card.bgColor}
                      description={card.description}
                    />
                  </Grid>
                ))
            ) : (
              <Typography variant="h6" color="textSecondary">
                No features found.
              </Typography>
            )}
          </Grid>
        </Container>
      </Box>
    </div>
  );
});

export default FeatureFilter;
