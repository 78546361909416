const category_marzano = [
  {
    title: "Classroom Strategies",
    description: "Techniques to engage students and promote learning.",
    categoryDetails: [
      {
        name: "Identifying Critical Information",
        options: {
          Ineffective: [
            "Fails to highlight key concepts",
            "Does not provide summarization",
            "Neglects student input",
          ],
          Developing: [
            "Occasionally identifies important points",
            "Provides some summarization",
            "Engages students minimally",
          ],
          Effective: [
            "Clearly identifies key concepts",
            "Summarizes material effectively",
            "Actively involves students in discussions",
          ],
          HighlyEffective: [
            "Expertly identifies and elaborates on critical information",
            "Incorporates diverse student perspectives",
            "Encourages deeper inquiry and connections",
          ],
        },
      },
      {
        name: "Practicing Skills",
        options: {
          Ineffective: [
            "Limited opportunities for practice",
            "No feedback provided",
            "Skill practice is irrelevant",
          ],
          Developing: [
            "Some practice opportunities are provided",
            "Feedback is inconsistent",
            "Skills practiced lack connection to goals",
          ],
          Effective: [
            "Regular practice opportunities provided",
            "Constructive feedback is given",
            "Skills align well with learning objectives",
          ],
          HighlyEffective: [
            "Multiple, varied practice opportunities offered",
            "Timely and specific feedback supports mastery",
            "Skill practice is integrated with real-world applications",
          ],
        },
      },
      {
        name: "Generating and Testing Hypotheses",
        options: {
          Ineffective: [
            "No hypotheses generated",
            "Tests are vague or irrelevant",
            "Students do not engage in critical thinking",
          ],
          Developing: [
            "Occasional generation of hypotheses",
            "Some testing occurs but lacks rigor",
            "Limited student engagement in thinking",
          ],
          Effective: [
            "Regularly generates relevant hypotheses",
            "Tests hypotheses systematically",
            "Students actively participate in critical thinking",
          ],
          HighlyEffective: [
            "Consistently encourages sophisticated hypothesis generation",
            "Engages students in rigorous testing and analysis",
            "Promotes a culture of inquiry and innovation",
          ],
        },
      },
    ],
  },
  {
    title: "Classroom Environment",
    description: "Creating a positive and productive learning space.",
    categoryDetails: [
      {
        name: "Establishing a Positive Learning Environment",
        options: {
          Ineffective: [
            "Classroom is chaotic and unfocused",
            "Negative interactions are common",
            "Little to no respect among students",
          ],
          Developing: [
            "Some structure in the classroom",
            "Inconsistent positive interactions",
            "Respect is developing among students",
          ],
          Effective: [
            "Classroom is well-organized and focused",
            "Positive interactions are common",
            "Mutual respect is evident",
          ],
          HighlyEffective: [
            "Classroom is a model of organization and focus",
            "High levels of positive interactions and support",
            "Strong sense of community and respect among all",
          ],
        },
      },
      {
        name: "Managing Student Behavior",
        options: {
          Ineffective: [
            "Little to no behavior management strategies",
            "Disruptive behavior is frequent",
            "Students are disengaged",
          ],
          Developing: [
            "Some strategies in place but inconsistently applied",
            "Occasional disruptions occur",
            "Student engagement is variable",
          ],
          Effective: [
            "Clear behavior management strategies implemented",
            "Disruptions are managed effectively",
            "Students are generally engaged",
          ],
          HighlyEffective: [
            "Proactive and effective behavior management strategies",
            "Disruptions are rare and quickly addressed",
            "High levels of student engagement and participation",
          ],
        },
      },
    ],
  },
  {
    title: "Assessment",
    description: "Evaluating student learning and progress.",
    categoryDetails: [
      {
        name: "Formative Assessment",
        options: {
          Ineffective: [
            "No assessment practices in place",
            "Feedback is absent or irrelevant",
            "Student understanding is rarely checked",
          ],
          Developing: [
            "Some formative assessments conducted",
            "Feedback is inconsistent",
            "Limited student involvement in assessment",
          ],
          Effective: [
            "Regular formative assessments provide useful feedback",
            "Students receive timely responses",
            "Assessment informs instruction effectively",
          ],
          HighlyEffective: [
            "Dynamic formative assessments actively engage students",
            "Feedback is personalized and promotes growth",
            "Continuous assessment drives instructional adjustments",
          ],
        },
      },
      {
        name: "Summative Assessment",
        options: {
          Ineffective: [
            "Summative assessments lack clarity",
            "No alignment with learning goals",
            "Feedback is not provided",
          ],
          Developing: [
            "Some alignment with learning objectives",
            "Feedback is minimal",
            "Assessments are infrequent",
          ],
          Effective: [
            "Clear and aligned summative assessments",
            "Detailed feedback helps students reflect",
            "Variety of assessment methods used",
          ],
          HighlyEffective: [
            "Innovative summative assessments encourage creativity",
            "Comprehensive feedback drives student improvement",
            "Assessment results are used to inform future planning",
          ],
        },
      },
    ],
  },
  {
    title: "Curriculum Planning",
    description: "Designing effective learning experiences and curricula.",
    categoryDetails: [
      {
        name: "Aligning Curriculum with Standards",
        options: {
          Ineffective: [
            "No alignment with educational standards",
            "Curriculum lacks coherence",
            "Goals are vague and unclear",
          ],
          Developing: [
            "Some alignment with standards",
            "Curriculum shows limited coherence",
            "Goals are somewhat defined",
          ],
          Effective: [
            "Curriculum is well-aligned with standards",
            "Clear and coherent learning goals",
            "Educational objectives are specific",
          ],
          HighlyEffective: [
            "Curriculum is meticulously aligned with standards and best practices",
            "Innovative and coherent learning goals inspire students",
            "Goals are continuously refined based on feedback",
          ],
        },
      },
      {
        name: "Integrating Cross-Disciplinary Skills",
        options: {
          Ineffective: [
            "No integration of skills across subjects",
            "Learning is isolated to individual subjects",
            "Little relevance to real-world applications",
          ],
          Developing: [
            "Some integration of skills is attempted",
            "Limited cross-disciplinary connections",
            "Relevance to real-world situations is minimal",
          ],
          Effective: [
            "Cross-disciplinary skills are regularly integrated",
            "Connections between subjects are clear",
            "Real-world applications enhance learning",
          ],
          HighlyEffective: [
            "Seamless integration of skills fosters deep learning",
            "Cross-disciplinary connections are rich and engaging",
            "Real-world relevance is consistently emphasized",
          ],
        },
      },
    ],
  },
  {
    title: "Professional Development",
    description: "Continuous growth and learning for educators.",
    categoryDetails: [
      {
        name: "Engagement in Professional Learning Communities",
        options: {
          Ineffective: [
            "No participation in professional learning communities",
            "Limited collaboration with colleagues",
            "No sharing of best practices",
          ],
          Developing: [
            "Some participation in professional learning activities",
            "Collaboration is infrequent",
            "Occasional sharing of resources",
          ],
          Effective: [
            "Active engagement in professional learning communities",
            "Regular collaboration and sharing of practices",
            "Professional growth is prioritized",
          ],
          HighlyEffective: [
            "Deep engagement in a vibrant professional learning community",
            "Collaborative practices are a hallmark of the culture",
            "Continuous improvement is embedded in daily practice",
          ],
        },
      },
      {
        name: "Pursuing Further Education and Training",
        options: {
          Ineffective: [
            "No further education or training pursued",
            "Little interest in personal growth",
            "Resistance to new teaching methods",
          ],
          Developing: [
            "Occasional participation in workshops or training",
            "Some interest in personal development",
            "Limited openness to new methods",
          ],
          Effective: [
            "Regularly pursues further education and training",
            "Actively seeks personal and professional growth",
            "Welcomes new teaching strategies",
          ],
          HighlyEffective: [
            "Proactively engages in advanced education and training opportunities",
            "Cultivates a strong growth mindset",
            "Embraces innovation and change in teaching practices",
          ],
        },
      },
    ],
  },
];

export default category_marzano;
