import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import QuizIcon from "@mui/icons-material/Quiz";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import PaymentIcon from "@mui/icons-material/Payment";
import Cookies from "js-cookie";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import Chip from "@mui/material/Chip";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import LockIcon from "@mui/icons-material/Lock";

const email = Cookies.get("email");
const portalURL = `https://billing.stripe.com/p/login/test_cN2156eyz2Kjbok4gg?prefilled_email=${encodeURIComponent(
  email
)}`;
// PROD const portalURL = `https://billing.stripe.com/p/login/8wMfZE30jagl44o5kk?prefilled_email=${encodeURIComponent(email)}`;

const mainListItems = [
  {
    text: "Evaluation Report",
    icon: <HomeRoundedIcon />,
    route: "/dashboard",
    requiresElite: true,
  },
  {
    text: "Teacher Profile",
    icon: <CastForEducationIcon />,
    route: "/teacherMenu",
    requiresElite: true,
  },
  {
    text: "Evaluation Scheduler",
    icon: <CalendarMonthIcon />,
    route: "/scheduler",
    requiresElite: true,
  },
  {
    text: "Tools",
    icon: <EscalatorWarningIcon />,
    route: "/curriculumEdgeTools",
  },
  {
    text: "Student Attendance",
    icon: <CoPresentIcon />,
    route: "/attendance",
    requiresElite: true,
  },
  {
    text: "Lesson Planner",
    icon: <StickyNote2Icon />,
    route: "/myLessonPlans",
    requiresElite: true,
  },
  {
    text: "Billing",
    icon: <PaymentIcon />,
    url: portalURL,
  },
  // This item is conditionally added
  {
    text: "Subscription",
    icon: <UnsubscribeIcon />,
    route: "/subscrption",
    requiresNotSubscribed: true, // Add custom condition here
  },
  {
    text: "Student of Concern",
    icon: <PriorityHighIcon />,
    route: "/studentOfConcern",
    requiresElite: true,
  },
];

const secondaryListItems = [
  { text: "Settings", icon: <SettingsRoundedIcon />, route: "/settings" },
  { text: "Feedback", icon: <HelpRoundedIcon />, route: "/feedback" },
];

export default function MenuContent() {
  const navigate = useNavigate();
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleNavigation = (route, url) => {
    if (url) {
      // Open the URL in a new window or tab
      window.open(url, "_blank", "noopener,noreferrer");
    } else {
      // Use React Router for internal routes
      navigate(route);
    }
  };

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      try {
        const response = await axios.post(
          "https://eduevaluator.com/api/users/check-admin",
          { email }
        );
        setSubscriptionStatus(response.data.subscriptionStatus);
      } catch (error) {
        console.error("Error fetching subscription status:", error);
        setSubscriptionStatus("Not Subscribed"); // Default to unsubscribed on error
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionStatus();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: "space-between" }}>
      <List dense>
        {mainListItems
          .filter((item) => {
            // Filter out "Subscription" item if the user is subscribed to Basic or Elite
            if (
              item.requiresNotSubscribed &&
              (subscriptionStatus === "Basic Plan" ||
                subscriptionStatus === "Elite Plan")
            ) {
              return false;
            }
            return true;
          })
          .map((item, index) => {
            const isLocked =
              subscriptionStatus !== "Elite Plan" && item.requiresElite;

            return (
              <ListItem key={index} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  disabled={isLocked}
                  onClick={() =>
                    !isLocked && handleNavigation(item.route, item.url)
                  }
                >
                  <ListItemIcon>
                    {isLocked ? <LockIcon /> : item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Stack direction="row" alignItems="center">
                        {item.text}
                        {item.text === "Student Attendance" && (
                          <Chip
                            sx={{
                              backgroundColor: "#4286F0",
                              color: "#FFFFFF",
                              "& .MuiChip-label": {
                                color: "#FFFFFF",
                              },
                              ml: 0,
                            }}
                            label="BETA"
                            size="small"
                          />
                        )}
                      </Stack>
                    }
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
      </List>

      <List dense>
        {secondaryListItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: "block" }}>
            <ListItemButton onClick={() => handleNavigation(item.route)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}
