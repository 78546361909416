import React, { useRef, forwardRef } from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

const dateGenerated = "November 9, 2024";

const styles = StyleSheet.create({
  container: {
    color: "#333",
    width: "100%",
    padding: 20,
    border: "1px solid #ddd",
    backgroundColor: "#f9f9f9",
  },
  header: {
    textAlign: "center",
    marginBottom: 30,
  },
  headerTitle: {
    fontSize: 24,
    color: "#333",
    margin: 0,
  },
  headerDate: {
    fontSize: 14,
    color: "#666",
  },
  sectionHeader: {
    backgroundColor: "#000",
    color: "#fff",
    padding: 8,
    fontSize: 18,
    marginTop: 20,
  },
  paragraph: {
    fontSize: 12,
    marginVertical: 5,
  },
  gridContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 20,
    paddingTop: 10,
  },
  cell: {
    width: "45%", // Adjusts width for 2 cells per row
    padding: 10,
    border: "1px solid #ccc",
    borderRadius: 4,
    backgroundColor: "#fff",
    marginBottom: 10,
  },
  fullWidthCell: {
    width: "100%",
  },
  cellLabel: {
    fontWeight: "bold",
    color: "#555",
    fontSize: 14,
    paddingBottom: 4,
  },
  cellValue: {
    fontSize: 16,
    color: "#333",
    marginTop: 5,
  },
});

const StudentConcernPDF = forwardRef(({ formData }, ref) => {
  return (
    <Document>
      <Page style={styles.container}>
        {/* Report Header */}
        <View style={styles.header}>
          <Text style={styles.headerTitle}>Student Concern Form</Text>
          <Text style={styles.headerDate}>
            Generated on: <Text style={{ color: "#333" }}>{dateGenerated}</Text>
          </Text>
        </View>

        {/* General Information Section */}
        <View style={{ marginBottom: "20px" }}>
          <Text style={styles.sectionHeader}>General Information</Text>
          <View style={styles.gridContainer}>
            <View style={styles.cell}>
              <Text style={styles.cellLabel}>Subject</Text>
              <Text style={styles.cellValue}>{formData.subject}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellLabel}>Grade Level</Text>
              <Text style={styles.cellValue}>{formData.gradeLevel}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellLabel}>Student First Name</Text>
              <Text style={styles.cellValue}>{formData.studentFirstName}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellLabel}>Student Last Name</Text>
              <Text style={styles.cellValue}>{formData.studentLastName}</Text>
            </View>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>General Comments</Text>
              <Text style={styles.cellValue}>{formData.generalComments}</Text>
            </View>
          </View>
        </View>

        {/* Physical and Mental Health Section */}
        <View style={{ marginBottom: "20px" }}>
          <Text style={styles.sectionHeader}>Physical and Mental Health</Text>
          <Text style={styles.paragraph}>
            Chronic health conditions, mental health challenges, or lack of
            access to healthcare that impacts learning.
          </Text>
          <View style={styles.gridContainer}>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Instructor Evaluation</Text>
              <Text style={styles.cellValue}>
                {formData.physicalAndMentalHealthEvaluation}
              </Text>
            </View>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Evidence/Notes</Text>
              <Text style={styles.cellValue}>
                {formData.physicalAndMentalHealthEvidence}
              </Text>
            </View>
          </View>
        </View>

        {/* Repeat similar structure for each section below */}
        <View style={{ marginBottom: "20px" }}>
          <Text style={styles.sectionHeader}>
            Social and Family Support Networks
          </Text>
          <Text style={styles.paragraph}>
            Limited family support, caring for dependents, or balancing family
            responsibilities that compete with studies.
          </Text>
          <View style={styles.gridContainer}>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Instructor Evaluation</Text>
              <Text style={styles.cellValue}>
                {formData.socialandFamilySupportNetworksEvaluation}
              </Text>
            </View>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Evidence/Notes</Text>
              <Text style={styles.cellValue}>
                {formData.setsocialandFamilySupportNetworksEvidence}
              </Text>
            </View>
          </View>
        </View>

        <View style={{ marginBottom: "20px" }}>
          <Text style={styles.sectionHeader}>
            Community and Social Integration
          </Text>
          <Text style={styles.paragraph}>
            Limited connection with peers, mentors, or local community
            resources; reports feelings of isolation.
          </Text>
          <View style={styles.gridContainer}>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Instructor Evaluation</Text>
              <Text style={styles.cellValue}>
                {formData.communitySocialIntegrationEvaluation}
              </Text>
            </View>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Evidence/Notes</Text>
              <Text style={styles.cellValue}>
                {formData.communitySocialIntegrationEvidence}
              </Text>
            </View>
          </View>
        </View>

        <View style={{ marginBottom: "20px" }}>
          <Text style={styles.sectionHeader}>
            Learning Engagement and Motivation
          </Text>
          <Text style={styles.paragraph}>
            Low participation in class, reluctance to seek help, disinterest in
            assignments, or difficulty staying motivated.
          </Text>
          <View style={styles.gridContainer}>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Instructor Evaluation</Text>
              <Text style={styles.cellValue}>
                {formData.learningEngagementMotivationEvaluation}
              </Text>
            </View>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Evidence/Notes</Text>
              <Text style={styles.cellValue}>
                {formData.learningEngagementMotivationEvidence}
              </Text>
            </View>
          </View>
        </View>

        <View style={{ marginBottom: "20px" }}>
          <Text style={styles.sectionHeader}>Attendance and Punctuality</Text>
          <Text style={styles.paragraph}>
            Frequent absences or tardiness; inconsistency in attendance that
            could impact progression and comprehension.
          </Text>
          <View style={styles.gridContainer}>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Instructor Evaluation</Text>
              <Text style={styles.cellValue}>
                {formData.attendancePunctualityEvaluation}
              </Text>
            </View>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Evidence/Notes</Text>
              <Text style={styles.cellValue}>
                {formData.attendancePunctualitynEvidence}
              </Text>
            </View>
          </View>
        </View>

        <View style={{ marginBottom: "20px" }}>
          <Text style={styles.sectionHeader}>
            Financial Stability and Resources
          </Text>
          <Text style={styles.paragraph}>
            Financial hardships affecting ability to afford program costs,
            materials, or time management between work/study.
          </Text>
          <View style={styles.gridContainer}>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Instructor Evaluation</Text>
              <Text style={styles.cellValue}>
                {formData.financialStabilityResourcesEvaluation}
              </Text>
            </View>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Evidence/Notes</Text>
              <Text style={styles.cellValue}>
                {formData.financialStabilityResourcesEvidence}
              </Text>
            </View>
          </View>
        </View>

        <View style={{ marginBottom: "20px" }}>
          <Text style={styles.sectionHeader}>
            Academic Progress and Performance
          </Text>
          <Text style={styles.paragraph}>
            Falling behind in assignments, poor grades, or requiring frequent
            remedial support to meet program standards.
          </Text>
          <View style={styles.gridContainer}>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Instructor Evaluation</Text>
              <Text style={styles.cellValue}>
                {formData.academicProgressPerformanceEvaluation}
              </Text>
            </View>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Evidence/Notes</Text>
              <Text style={styles.cellValue}>
                {formData.academicProgressPerformanceEvidence}
              </Text>
            </View>
          </View>
        </View>

        <View style={{ marginBottom: "20px" }}>
          <Text style={styles.sectionHeader}>
            Career Goals and Program Alignment
          </Text>
          <Text style={styles.paragraph}>
            Lack of clarity on career goals or perceived disconnect between
            program content and career aspirations.
          </Text>
          <View style={styles.gridContainer}>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Instructor Evaluation</Text>
              <Text style={styles.cellValue}>
                {formData.careerGoalsEvaluation}
              </Text>
            </View>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Evidence/Notes</Text>
              <Text style={styles.cellValue}>
                {formData.careerGoalsEvidence}
              </Text>
            </View>
          </View>
        </View>

        <View style={{ marginBottom: "20px" }}>
          <Text style={styles.sectionHeader}>
            Time Management and Organizational Skills
          </Text>
          <Text style={styles.paragraph}>
            Difficulty balancing coursework with personal responsibilities,
            deadlines, or maintaining personal organization.
          </Text>
          <View style={styles.gridContainer}>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Instructor Evaluation</Text>
              <Text style={styles.cellValue}>
                {formData.timeManagementEvaluation}
              </Text>
            </View>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Evidence/Notes</Text>
              <Text style={styles.cellValue}>
                {formData.timeManagementEvidence}
              </Text>
            </View>
          </View>
        </View>

        <View style={{ marginBottom: "20px" }}>
          <Text style={styles.sectionHeader}>Digital Literacy and Access</Text>
          <Text style={styles.paragraph}>
            Limited digital skills or lack of access to required technology,
            hindering online participation and assignments.
          </Text>
          <View style={styles.gridContainer}>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Instructor Evaluation</Text>
              <Text style={styles.cellValue}>
                {formData.digitalLiteracyEvaluation}
              </Text>
            </View>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Evidence/Notes</Text>
              <Text style={styles.cellValue}>
                {formData.digitalLiteracyEvidence}
              </Text>
            </View>
          </View>
        </View>

        <View style={{ marginBottom: "20px" }}>
          <Text style={styles.sectionHeader}>
            Problem-Solving and Resilience
          </Text>
          <Text style={styles.paragraph}>
            Struggles to navigate setbacks or lacks persistence when faced with
            challenges.
          </Text>
          <View style={styles.gridContainer}>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Instructor Evaluation</Text>
              <Text style={styles.cellValue}>
                {formData.problemSolvingEvaluation}
              </Text>
            </View>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Evidence/Notes</Text>
              <Text style={styles.cellValue}>
                {formData.problemSolvingEvidence}
              </Text>
            </View>
          </View>
        </View>

        <View style={{ marginBottom: "20px" }}>
          <Text style={styles.sectionHeader}>
            Cultural and Language Considerations
          </Text>
          <Text style={styles.paragraph}>
            Cultural barriers or language proficiency issues that impact
            understanding, confidence, or participation.
          </Text>
          <View style={styles.gridContainer}>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Instructor Evaluation</Text>
              <Text style={styles.cellValue}>
                {formData.languageConsiderationsEvaluation}
              </Text>
            </View>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Evidence/Notes</Text>
              <Text style={styles.cellValue}>
                {formData.languageConsiderationsEvidence}
              </Text>
            </View>
          </View>
        </View>

        <View style={{ marginBottom: "20px" }}>
          <Text style={styles.sectionHeader}>Self-Efficacy and Confidence</Text>
          <Text style={styles.paragraph}>
            Low confidence in academic abilities or doubts about success in the
            program, which could impact persistence.
          </Text>
          <View style={styles.gridContainer}>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Instructor Evaluation</Text>
              <Text style={styles.cellValue}>
                {formData.selfEfficacyEvaluation}
              </Text>
            </View>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Evidence/Notes</Text>
              <Text style={styles.cellValue}>
                {formData.selfEfficacyEvidence}
              </Text>
            </View>
          </View>
        </View>

        {/* Add more sections following the same format for other categories */}
      </Page>
    </Document>
  );
});
export default StudentConcernPDF;
