const categories_montessori = [
  {
    title: "Domain 1: Prepared Environment",
    description:
      "Create and maintain a learning environment that embodies Montessori principles, facilitating independent learning and exploration.",
    categoryDetails: [
      {
        name: "1a: Organization of the Classroom",
        options: {
          Ineffective: [
            "The classroom is disorganized, with materials misplaced or inaccessible to children.",
            "Learning areas are cluttered, causing confusion and limiting movement.",
            "The environment does not reflect Montessori principles, hindering independent exploration.",
          ],
          Developing: [
            "The classroom shows some organization, but materials are not consistently accessible.",
            "Learning areas are partially defined but may not fully support self-directed activities.",
            "Montessori materials are present but not effectively arranged to encourage use.",
          ],
          Effective: [
            "The classroom is well-organized, with all materials accessible and in their designated places.",
            "Learning areas are clearly defined, supporting a variety of independent activities.",
            "The environment reflects Montessori principles, promoting autonomy and exploration.",
          ],
          HighlyEffective: [
            "The teacher anticipates children's needs, rotating materials to sustain engagement.",
            "Learning areas are thoughtfully arranged to facilitate seamless transitions between activities.",
            "The environment is meticulously prepared, inspiring deep and meaningful exploration.",
          ],
        },
      },
      {
        name: "1b: Preparation of Materials",
        options: {
          Ineffective: [
            "Materials are incomplete, damaged, or inappropriate for the children's developmental levels.",
            "The teacher fails to prepare or present materials effectively.",
            "Children show little interest due to poor material preparation.",
          ],
          Developing: [
            "Materials are available but may not be fully complete or appropriately challenging.",
            "The teacher prepares materials inconsistently, affecting engagement.",
            "Children engage with materials but require frequent guidance.",
          ],
          Effective: [
            "Materials are complete, well-maintained, and appropriate for developmental stages.",
            "The teacher effectively prepares and presents materials to the children.",
            "Children are engaged and use materials independently.",
          ],
          HighlyEffective: [
            "Materials are expertly prepared with extensions to meet individual needs.",
            "The teacher innovates with materials to deepen understanding and interest.",
            "Children demonstrate high levels of mastery and enthusiasm with the materials.",
          ],
        },
      },
      // Add more categories as needed
    ],
  },
  {
    title: "Domain 2: Instruction",
    description:
      "Implement effective Montessori instructional strategies to foster children's growth and love of learning.",
    categoryDetails: [
      {
        name: "2a: Knowledge of Montessori Philosophy",
        options: {
          Ineffective: [
            "Demonstrates limited understanding of Montessori principles and practices.",
            "Fails to implement Montessori methods in daily instruction.",
            "Instruction lacks alignment with Montessori educational philosophy.",
          ],
          Developing: [
            "Shows basic knowledge of Montessori principles but applies them inconsistently.",
            "Occasionally incorporates Montessori methods without depth.",
            "Instruction partially reflects Montessori philosophy, leading to mixed outcomes.",
          ],
          Effective: [
            "Demonstrates solid understanding and consistent application of Montessori principles.",
            "Effectively integrates Montessori methods into daily instruction.",
            "Instruction aligns with Montessori philosophy, supporting children's development.",
          ],
          HighlyEffective: [
            "Exhibits profound understanding, seamlessly integrating Montessori philosophy in all aspects.",
            "Innovatively adapts Montessori methods to enhance learning experiences.",
            "Instruction exemplifies Montessori ideals, inspiring a deep love of learning.",
          ],
        },
      },
      {
        name: "2b: Individualized Instruction",
        options: {
          Ineffective: [
            "Does not consider individual children's needs or learning styles.",
            "Fails to adjust instruction based on observations.",
            "Provides uniform instruction without differentiation.",
          ],
          Developing: [
            "Attempts to consider individual needs but lacks consistency.",
            "Occasionally adjusts instruction based on observations.",
            "Differentiation is minimal and not always effective.",
          ],
          Effective: [
            "Consistently considers individual needs and learning styles in planning.",
            "Regularly adjusts instruction based on careful observations.",
            "Provides differentiated instruction that effectively meets diverse needs.",
          ],
          HighlyEffective: [
            "Expertly tailors instruction to each child's unique needs and interests.",
            "Proactively anticipates and addresses individual learning paths.",
            "Creates a highly personalized learning environment fostering optimal growth.",
          ],
        },
      },
      // Add more categories as needed
    ],
  },
  {
    title: "Domain 3: Observation and Assessment",
    description:
      "Use observation and assessment to inform instruction and support each child's development.",
    categoryDetails: [
      {
        name: "3a: Systematic Observation",
        options: {
          Ineffective: [
            "Rarely observes children, missing key insights into their development.",
            "Observations are informal and not documented.",
            "Does not use observations to inform instructional decisions.",
          ],
          Developing: [
            "Observes children but lacks a systematic approach.",
            "Documentation of observations is inconsistent.",
            "Uses observations occasionally to adjust instruction.",
          ],
          Effective: [
            "Regularly conducts systematic observations of each child.",
            "Maintains accurate records to track development.",
            "Uses observations effectively to inform and adapt instruction.",
          ],
          HighlyEffective: [
            "Implements comprehensive observation strategies to gain deep insights.",
            "Maintains detailed, ongoing records that guide individualized instruction.",
            "Uses observational data to anticipate needs and drive curriculum development.",
          ],
        },
      },
      {
        name: "3b: Assessment for Learning",
        options: {
          Ineffective: [
            "Does not assess children's progress or understanding.",
            "Fails to provide feedback to children on their learning.",
            "Assessment methods are not aligned with Montessori practices.",
          ],
          Developing: [
            "Uses basic assessment methods but inconsistently.",
            "Provides limited feedback that may not guide improvement.",
            "Assessment occasionally informs instruction but lacks depth.",
          ],
          Effective: [
            "Employs appropriate assessment techniques to monitor progress.",
            "Provides constructive feedback that supports growth.",
            "Integrates assessment results into instructional planning.",
          ],
          HighlyEffective: [
            "Utilizes innovative assessment methods to gain comprehensive understanding.",
            "Offers insightful feedback that empowers children to take ownership of learning.",
            "Seamlessly integrates assessment with instruction for continuous improvement.",
          ],
        },
      },
      // Add more categories as needed
    ],
  },
  {
    title: "Domain 4: Professional Responsibilities",
    description:
      "Engage in continuous professional development and contribute to the Montessori community.",
    categoryDetails: [
      {
        name: "4a: Reflective Practice and Growth",
        options: {
          Ineffective: [
            "Does not reflect on teaching practices or seek improvement.",
            "Is resistant to feedback and professional learning opportunities.",
            "Shows little awareness of personal teaching strengths and weaknesses.",
          ],
          Developing: [
            "Occasionally reflects on practices but lacks consistent application.",
            "Accepts feedback but implementation is limited.",
            "Identifies areas for growth but lacks actionable steps.",
          ],
          Effective: [
            "Regularly reflects on teaching practices to enhance effectiveness.",
            "Actively seeks and applies feedback for professional growth.",
            "Sets and works towards professional development goals.",
          ],
          HighlyEffective: [
            "Engages in continuous reflection leading to significant improvements.",
            "Seeks advanced learning opportunities and shares knowledge with peers.",
            "Demonstrates leadership in professional development initiatives.",
          ],
        },
      },
      {
        name: "4b: Collaboration and Community Engagement",
        options: {
          Ineffective: [
            "Does not collaborate with colleagues or participate in community events.",
            "Communication with families is minimal or ineffective.",
            "Fails to build relationships that support children's learning.",
          ],
          Developing: [
            "Collaborates occasionally but not proactively.",
            "Communicates with families inconsistently.",
            "Participates in community events when required.",
          ],
          Effective: [
            "Actively collaborates with colleagues to enhance teaching and learning.",
            "Maintains open and regular communication with families.",
            "Engages with the community to support children's development.",
          ],
          HighlyEffective: [
            "Leads collaborative efforts to improve educational practices.",
            "Builds strong partnerships with families, fostering a supportive community.",
            "Initiates and organizes community events that enrich the learning environment.",
          ],
        },
      },
      // Add more categories as needed
    ],
  },
  {
    title: "Domain 5: Classroom Management",
    description:
      "Establish and maintain a respectful and productive classroom environment.",
    categoryDetails: [
      {
        name: "5a: Establishing Norms and Expectations",
        options: {
          Ineffective: [
            "Does not establish clear behavioral expectations.",
            "Inconsistently enforces rules, leading to confusion.",
            "Classroom environment is chaotic and unproductive.",
          ],
          Developing: [
            "Establishes basic expectations but may not consistently uphold them.",
            "Addresses misbehavior reactively rather than proactively.",
            "Classroom environment is somewhat orderly but improvements are needed.",
          ],
          Effective: [
            "Sets clear, consistent expectations understood by all children.",
            "Proactively manages behavior, fostering a positive environment.",
            "Maintains a respectful and productive classroom atmosphere.",
          ],
          HighlyEffective: [
            "Empowers children to take ownership of classroom norms.",
            "Uses restorative practices to address conflicts and build community.",
            "Cultivates an environment where children self-regulate and support peers.",
          ],
        },
      },
      {
        name: "5b: Encouraging Independence and Responsibility",
        options: {
          Ineffective: [
            "Does not promote independent problem-solving or decision-making.",
            "Overly directs children's activities, limiting autonomy.",
            "Children rely heavily on the teacher for guidance.",
          ],
          Developing: [
            "Allows for some independence but often intervenes prematurely.",
            "Provides limited opportunities for children to take responsibility.",
            "Children show some initiative but depend on teacher direction.",
          ],
          Effective: [
            "Encourages children to make choices and solve problems independently.",
            "Provides opportunities for children to take responsibility for their learning.",
            "Fosters a classroom culture of autonomy and self-motivation.",
          ],
          HighlyEffective: [
            "Inspires a high level of independence, with children initiating and leading activities.",
            "Children take collective responsibility for the learning environment.",
            "Empowers children to be self-directed learners and critical thinkers.",
          ],
        },
      },
    ],
  },
];
export default categories_montessori;
