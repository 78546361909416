import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  Chip,
  MenuItem,
  Select,
  Paper,
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
} from "@mui/material";
import OpenAI from "openai";
import ContentCopyIcon from "@mui/icons-material/ContentCopy"; // Import the clipboard icon
import SchoolIcon from "@mui/icons-material/School";
import AssistantIcon from "@mui/icons-material/Assistant";
import { useDropzone } from "react-dropzone";
import LoadingModal from "./LoadingModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Cookies from "js-cookie";
import axios from "axios";
function TVETLessonPlanForm() {
  // State variables
  const [topic, setTopic] = useState("");
  const [learnerLevel, setLearnerLevel] = useState("");
  const [lessonDuration, setLessonDuration] = useState("");
  const [learningObjectives, setLearningObjectives] = useState("");
  const [assessmentMethod, setAssessmentMethod] = useState("");
  const [subject, setSubject] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [levelOfUnderstanding, setLevelOfUnderstanding] = useState("");
  const [subjectDifficulty, setSubjectDifficulty] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false); // State for loading
  const [modalOpen, setModalOpen] = useState(false); // State for modal
  const [summary, setSummary] = useState(""); // State for API response
  const [additionalQuestion, setAdditionalQuestion] = useState("");
  const [teacherNotes, setTeacherNotes] = useState("");
  // File upload handling
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const fileURLs = acceptedFiles.map((file) => URL.createObjectURL(file));
      setUploadedFiles((prevFiles) => [...prevFiles, ...fileURLs]);
    },
  });

  // Mock handler functions

  const handleSubjectChange = (e) => setSubject(e.target.value);
  const handleGradeLevelChange = (e) => setGradeLevel(e.target.value);
  const handleLevelOfUnderstandingChange = (e) =>
    setLevelOfUnderstanding(e.target.value);
  const handleSubjectDifficultyChange = (e) =>
    setSubjectDifficulty(e.target.value);
  const handleLocationChange = (e) => setLocation(e.target.value);
  const handleDescriptionChange = (e) => setDescription(e.target.value);
  const saveDraft = () => alert("Draft saved!");

  // Function to log all form data
  // Function to log all form data
  const handleSubmit = async () => {
    const formData = {
      topic,
      learnerLevel,
      lessonDuration,
      learningObjectives,
      learningObjectives,
    };
    console.log("Form Data:", formData);

    setLoading(true); // Start loading

    try {
      const email = Cookies.get("email");
      const content = `Please create a TVET plan based on the following details: 
            - Topic or Skill: ${formData.topic}
            - Level of Learners: ${formData.learnerLevel}
            - Duration of Lesson: ${formData.lessonDuration}
            - Learning Objectives: ${formData.learningObjectives}
            - Assessment Method: ${formData.learningObjectives}
            - Additional details ${additionalQuestion}
            I need teacher notes on ${teacherNotes}`;
      const tokenResponse = await axios.post(
        "https://eduevaluator.com/api/users/decreaseToken",
        { email, content }
      );

      if (tokenResponse.data.message === "OpenAI response generated") {
        const summaries = tokenResponse.data.summary;
        setSummary(summaries); // Save summary for modal
        setModalOpen(true); // Open modal
      } else {
        toast.error("You are out of credits");
      }
    } catch (error) {
      console.error("Error while fetching completion:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(summary)
      .then(() => {
        toast.success("Text copied to clipboard!"); // Optional: Show a message to the user
      })
      .catch((error) => {
        toast.error("Could not copy text: ", error);
      });
  };
  return (
    <Paper elevation={3} sx={{ padding: 3, borderRadius: 2, width: "100%" }}>
      <ToastContainer />
      <LoadingModal loading={loading} message="Generating response..." />{" "}
      {/* Render loading modal */}
      <Container sx={{ width: "100%", maxWidth: "1200px" }}>
        <Box sx={{ marginTop: 3 }}>
          <Accordion
            expanded={true}
            sx={{
              width: "100%",
              backgroundColor: "white",
              color: "black",
              marginBottom: 2,
              borderRadius: 2,
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              "&:before": { display: "none" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#e0e0e0",
                padding: "16px",
                borderRadius: "4px 4px 0 0",
                color: "black",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              <AssistantIcon sx={{ marginRight: 1 }} />
              TVET Lesson Plan Creator
            </Box>
            <Container>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  marginTop: 4,
                  marginBottom: 4,
                  backgroundColor: "#f0f0f0",
                  padding: "16px",
                  borderRadius: "8px",
                }}
              >
                <Chip
                  label="What does this do?"
                  sx={{
                    backgroundColor: "#1976d2", // Blue color
                    color: "#ffffff", // This sets the text color to white
                    "& .MuiChip-label": {
                      color: "#ffffff", // Ensures the label is white
                    },
                    marginRight: "16px",
                  }}
                />
                <Typography variant="body1">
                  I help teachers create and tailor TVET lesson plans by guiding
                  them to input specific course details.
                </Typography>
              </Box>

              <Grid container spacing={2}>
                {/* Topic or Skill */}

                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Topic or Skill</FormLabel>
                    <TextField
                      value={topic}
                      onChange={(e) => setTopic(e.target.value)}
                      variant="outlined"
                      sx={{ borderRadius: 2 }}
                    />
                  </FormControl>
                </Grid>

                {/* Level of Learners */}
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Level of Learners</FormLabel>
                    <Select
                      value={learnerLevel}
                      onChange={(e) => setLearnerLevel(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Level of Learners" }}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    >
                      <MenuItem value="" disabled>
                        Select Level of Learners
                      </MenuItem>
                      {["Beginner", "Intermediate", "Advanced"].map(
                        (option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Duration of Lesson */}
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Duration of Lesson</FormLabel>
                    <Select
                      value={lessonDuration}
                      onChange={(e) => setLessonDuration(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Duration of Lesson" }}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    >
                      <MenuItem value="" disabled>
                        Select Duration
                      </MenuItem>
                      {["1 hour", "2 hours", "Full-day session"].map(
                        (option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Learning Objectives */}
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Learning Objectives</FormLabel>
                    <TextField
                      value={learningObjectives}
                      onChange={(e) => setLearningObjectives(e.target.value)}
                      variant="outlined"
                      sx={{ borderRadius: 2 }}
                    />
                  </FormControl>
                </Grid>

                {/* Assessment Method */}
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Assessment Method</FormLabel>
                    <Select
                      value={assessmentMethod}
                      onChange={(e) => setAssessmentMethod(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Assessment Method" }}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    >
                      <MenuItem value="" disabled>
                        Select Assessment Method
                      </MenuItem>
                      {[
                        "Practical Assessment",
                        "Written Test",
                        "Project-Based Assessment",
                        "Peer Assessment",
                      ].map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel> I need teachers' notes on</FormLabel>
                    <Select
                      value={teacherNotes}
                      onChange={(e) => setTeacherNotes(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Assessment Method" }}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    >
                      <MenuItem value="" disabled>
                        Select Topic For Notes
                      </MenuItem>
                      {[
                        "Expand information for a detailed lesson plan",
                        "Provide worksheets that I can use with this content.",
                        "Align the lesson with the 2030 sustainable development Goals.",
                      ].map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Additional Details</FormLabel>
                    <textarea
                      rows={4} // Adjust the number of rows as needed
                      value={additionalQuestion}
                      onChange={(e) => setAdditionalQuestion(e.target.value)}
                      style={{
                        width: "100%",
                        borderRadius: "8px", // Similar style as Material-UI
                        padding: "10px",
                        border: "1px solid #ccc",
                        boxSizing: "border-box", // Ensures padding is included in width
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Container>

            <Box>
              {uploadedFiles.map((file, index) => (
                <Chip
                  key={index}
                  label={file}
                  onDelete={() => {
                    setUploadedFiles(
                      uploadedFiles.filter((_, i) => i !== index)
                    );
                  }}
                  sx={{ margin: 1 }}
                />
              ))}
            </Box>

            <Button
              type="button" // Change this to "button" instead of "submit"
              onClick={handleSubmit} // Call handleSubmit on click
              style={{
                marginTop: 10,
                width: 100,
                backgroundColor: "#4286F0",
                color: "white",
                borderRadius: 20,
              }}
            >
              Ask AI
            </Button>
          </Accordion>
        </Box>
        {/* Modal for displaying results */}
        <Dialog
          open={modalOpen}
          onClose={() => {}} // Disable closing by clicking outside
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>AI Response</DialogTitle>
          <DialogContent>
            <pre>{summary}</pre>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCopyToClipboard}
              startIcon={<ContentCopyIcon />}
            />
            <Button onClick={() => setModalOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Paper>
  );
}

export default TVETLessonPlanForm;
