import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    padding: 30,
    fontFamily: "Helvetica",
    fontSize: 12,
  },
  header: {
    marginBottom: 20,
    textAlign: "center",
  },
  headerTitle: {
    fontSize: 18,
    fontWeight: "bold",
  },
  sectionHeader: {
    marginTop: 20,
    marginBottom: 10,
    fontSize: 14,
    fontWeight: "bold",
  },
  question: {
    marginBottom: 10,
    fontWeight: "bold", // Bold style for the question text
  },
  option: {
    marginLeft: 10,
  },
  answerSection: {
    marginTop: 20,
  },
});

const QuizMakerPDF = ({ questions, answers }) => (
  <Document>
    {/* Quiz Page */}
    <Page style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.headerTitle}>Quiz</Text>
      </View>
      {questions.map((question, index) => (
        <View key={index} style={{ marginBottom: 20 }}>
          <Text style={styles.question}>
            {index + 1}. {question.question}
          </Text>
          {question.options.map((option, optIndex) => (
            <Text key={optIndex} style={styles.option}>
              ☐ {option}
            </Text>
          ))}
        </View>
      ))}
    </Page>

    {/* Answer Page */}
    <Page style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.headerTitle}>Answers</Text>
      </View>
      <View style={styles.answerSection}>
        {answers.map((answer, index) => (
          <View key={index} style={{ marginBottom: 10 }}>
            <Text>
              {index + 1}. {questions[index].text}
            </Text>
            <Text style={{ marginLeft: 10 }}>Answer: {answer}</Text>
          </View>
        ))}
      </View>
    </Page>
  </Document>
);

export default QuizMakerPDF;
