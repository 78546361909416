const category_danielson = [
  {
    title: "Domain 1: Planning and Preparation",
    description:
      "Prepare your lessons effectively to maximize learning outcomes.",
    categoryDetails: [
      {
        name: "1a: Demonstrating Knowledge of Content and Pedagogy",
        options: {
          Ineffective: [
            "The lesson plan fails to include key concepts of the subject matter, leading to gaps in students' understanding.",
            "The teacher's explanations are often inaccurate, causing confusion among students.",
            "Content is presented without considering different pedagogical approaches, making the lesson less effective.",
          ],
          Developing: [
            "The lesson plan covers essential content but lacks depth in some areas, affecting the quality of instruction.",
            "The teacher's explanations are generally accurate but occasionally lack clarity or detail.",
            "Pedagogical strategies are used but may not always be the most effective for the lesson objectives.",
          ],
          Effective: [
            "The lesson plan is detailed and covers all relevant content accurately, with appropriate pedagogical approaches.",
            "The teacher provides clear and accurate explanations that help students grasp the material effectively.",
            "Instructional methods are well-chosen and support the content being taught, leading to successful learning outcomes.",
          ],
          HighlyEffective: [
            "Instructional methods are well-chosen and support the content being taught, leading to successful learning outcomes.",
            "The teacher's explanations are not only clear and accurate but also provide deep insights that enhance student understanding.",
            "Pedagogical strategies are expertly applied, making the lesson highly engaging and effective for diverse learning needs.",
          ],
        },
      },
      {
        name: "1b: Demonstrating Knowledge of Students",
        options: {
          Ineffective: [
            "The teacher does not consider students' prior knowledge or learning styles when planning lessons.",
            "There is little to no adaptation of instruction based on students' needs or backgrounds.",
            "The lesson does not address the diverse needs of students, leading to disengagement.",
          ],
          Developing: [
            "The teacher makes some effort to understand students' backgrounds, but this understanding is not consistently reflected in the lesson planning.",
            "Instruction is occasionally adapted to meet the needs of different students, but this is not done systematically.",
            "The teacher considers students' needs to some extent, but there are still gaps in addressing individual differences.",
          ],
          Effective: [
            "The teacher demonstrates a good understanding of students' backgrounds and needs, and this understanding is evident in the lesson planning.",
            "Instruction is regularly adapted to accommodate diverse learning styles and needs, resulting in increased student engagement.",
            "The teacher uses knowledge of students to tailor instruction effectively, making adjustments as needed to support learning.",
          ],
          HighlyEffective: [
            "The teacher has an in-depth understanding of each student’s needs, interests, and backgrounds, which is reflected in highly personalized instruction.",
            "Instruction is dynamically adapted based on ongoing assessment and student feedback, ensuring that all students are effectively supported.",
            "The teacher anticipates students' needs and proactively adjusts instruction to meet diverse learning requirements, fostering an inclusive learning environment.",
          ],
        },
      },
      {
        name: "1c: Setting Instructional Outcomes",
        options: {
          Ineffective: [
            "The instructional outcomes are unclear and do not specify what students should learn or achieve.",
            "Outcomes are not aligned with curriculum standards or are unrealistic in terms of what can be achieved in the given time.",
            "There is no clear connection between the instructional outcomes and the activities or assessments planned.",
          ],
          Developing: [
            "Instructional outcomes are somewhat clear but may lack specific details or alignment with curriculum standards.",
            "Outcomes are generally achievable but might be too broad or not fully aligned with lesson objectives.",
            "The connection between outcomes and planned activities is present but not always explicit or well-integrated.",
          ],
          Effective: [
            "Instructional outcomes are clear, measurable, and aligned with curriculum standards, providing a solid framework for the lesson.",
            "Outcomes are realistic and achievable within the lesson timeframe, with clear links to instructional activities and assessments.",
            "The teacher effectively communicates the outcomes to students, ensuring they understand what is expected and why it matters.",
          ],
          HighlyEffective: [
            "Instructional outcomes are highly specific, challenging, and precisely aligned with curriculum standards, promoting deep learning.",
            "Outcomes are carefully crafted to stretch students’ abilities and are integrated seamlessly with instructional activities and assessments.",
            "The teacher skillfully communicates the outcomes, connecting them to real-world applications and helping students see the relevance of their learning.",
          ],
        },
      },
      {
        name: "1d: Demonstrating Knowledge of Resources",
        options: {
          Ineffective: [
            "The teacher uses outdated or irrelevant resources that do not support the lesson objectives.",
            "Resources are poorly integrated into the lesson, resulting in limited student engagement or support.",
            "There is a lack of variety in resources, which does not address different learning needs or preferences.",
          ],
          Developing: [
            "The teacher uses some relevant resources, but they are not always well-integrated or utilized effectively.",
            "Resources support the lesson to a degree but may not fully enhance student understanding or engagement.",
            "There is some variety in resources, but they may not always be well-suited to different learning styles or needs.",
          ],
          Effective: [
            "The teacher selects and uses appropriate and relevant resources that support the lesson objectives and enhance student learning.",
            "Resources are well-integrated into the lesson and effectively support the instructional goals.",
            "A variety of resources is used to address different learning needs and preferences, making the lesson more engaging.",
          ],
          HighlyEffective: [
            "The teacher expertly selects and uses a wide range of high-quality resources that significantly enhance the lesson and support diverse learning needs.",
            "Resources are seamlessly integrated into instruction, enriching the learning experience and effectively addressing multiple learning styles.",
            "The teacher anticipates the need for various resources and uses them innovatively to deepen student understanding and engagement.",
          ],
        },
      },
      {
        name: "1e: Designing Coherent Instruction",
        options: {
          Ineffective: [
            "The lesson lacks clear organization and coherence, with disjointed activities that do not build on each other",
            "There is no logical sequence in the lesson, making it difficult for students to follow and understand.",
            "Instructional components are poorly aligned with lesson goals, resulting in a fragmented learning experience.",
          ],
          Developing: [
            "The lesson is somewhat organized but may have areas where the flow between activities is unclear or inconsistent.",
            "There is a basic sequence of instruction, but some activities may not fully support the overall lesson goals.",
            "Instructional components are generally aligned with the lesson goals, but there may be occasional gaps in coherence.",
          ],
          Effective: [
            "The lesson is well-organized with a clear and logical flow, where activities build on each other to support the lesson goals.",
            "Instruction is coherently designed, making it easy for students to follow and understand the connections between activities.",
            "Each component of the lesson is thoughtfully aligned with the instructional goals, leading to a cohesive learning experience.",
          ],
          HighlyEffective: [
            "The lesson is exceptionally well-organized, with a seamless flow that enhances student understanding and engagement.",
            "Activities are meticulously designed to build upon one another, with clear and purposeful connections that support deep learning.",
            "The lesson demonstrates a high degree of coherence, integrating all components in a way that maximizes instructional impact and student learning.",
          ],
        },
      },
      {
        name: "1f: Designing Student Assessments",
        options: {
          Ineffective: [
            "Assessments are poorly designed, with unclear criteria and no alignment with lesson objectives.",
            "Feedback is infrequent and not helpful, leaving students unsure about their performance or how to improve.",
            "Assessments do not effectively measure student understanding or progress, leading to limited insights for both students and teacher.",
          ],
          Developing: [
            "Assessments are somewhat aligned with lesson objectives but may lack clarity or detail in how student performance is evaluated.",
            "Feedback is provided but may not always be timely or specific, offering limited guidance for improvement.",
            "Assessments provide some measure of student understanding but may not fully capture the depth of learning.",
          ],
          Effective: [
            "Assessments are well-designed and clearly aligned with lesson objectives, providing a good measure of student understanding.",
            "Feedback is timely and constructive, helping students understand their performance and how to improve.",
            "Assessments effectively measure student progress and provide valuable insights that inform instruction.",
          ],
          HighlyEffective: [
            "Assessments are expertly designed, offering comprehensive measures of student understanding and progress aligned with high standards.",
            "Feedback is highly specific, actionable, and provided promptly, significantly supporting student growth and development.",
            "Assessments are varied and innovative, effectively capturing different aspects of learning and providing deep insights into student performance.",
          ],
        },
      },
      // Add more categories as needed
    ],
  },
  {
    title: "Domain 2: Classroom Environment",
    description: "Create an engaging and safe environment for all students.",
    categoryDetails: [
      {
        name: "2a: Creating an Environment of Respect and Rapport",
        options: {
          Ineffective: [
            "Interactions between the teacher and students are often negative, with little respect or rapport established.",
            "The classroom environment is characterized by frequent conflicts or lack of mutual respect.",
            "The teacher does not model or reinforce respectful behavior, leading to a lack of trust and rapport with students.",
          ],
          Developing: [
            "Interactions are generally respectful, but there are occasional instances of discord or lack of rapport.",
            "The teacher makes efforts to build a respectful environment, though it may not be consistently effective.",
            "Respectful behavior is modeled at times, but there is variability in how well rapport is maintained with all students.",
          ],
          Effective: [
            "The teacher consistently demonstrates respect and builds positive rapport with students, fostering a supportive classroom environment.",
            "Interactions are consistently respectful and contribute to a positive classroom climate where students feel valued.",
            "The teacher effectively models and reinforces respectful behavior, creating a trusting and supportive atmosphere.",
          ],
          HighlyEffective: [
            "The teacher excels in creating an environment where mutual respect and rapport are deeply embedded in all interactions.",
            "The classroom atmosphere is highly supportive, with strong, positive relationships between the teacher and students.",
            "Respectful behavior is a cornerstone of the classroom culture, with the teacher skillfully fostering a sense of trust and connection among all students.",
          ],
        },
      },
      {
        name: "2b: Establishing a Culture for Learning",
        options: {
          Ineffective: [
            "The classroom culture does not emphasize the importance of learning, leading to disengagement and lack of motivation.",
            "Students are frequently uninterested and lack motivation due to an unclear or undervalued emphasis on learning.",
            "There is minimal reinforcement of the importance of learning, resulting in a low-value culture surrounding academic achievement.",
          ],
          Developing: [
            "The classroom culture supports learning to some extent but may not fully engage all students or clearly convey the value of learning.",
            "Students show some interest and motivation, but the emphasis on learning could be more consistently reinforced.",
            "There is an attempt to establish a culture of learning, though it may not be fully effective in motivating all students.",
          ],
          Effective: [
            "The teacher establishes a strong culture for learning where students are motivated and understand the importance of academic achievement.",
            "Students are generally engaged and show a positive attitude towards learning, supported by clear expectations and reinforcement.",
            "The classroom culture consistently emphasizes the value of learning, leading to increased student motivation and engagement.",
          ],
          HighlyEffective: [
            "The teacher creates an exceptional culture for learning where students are highly motivated and fully understand the significance of their academic work.",
            "Students exhibit a strong commitment to learning and demonstrate high levels of engagement and enthusiasm.",
            "The classroom environment fosters an intense focus on academic achievement, with a strong culture that celebrates and supports student learning.",
          ],
        },
      },
      {
        name: "2c: Managing Classroom Procedures",
        options: {
          Ineffective: [
            "Classroom procedures are chaotic and disorganized, leading to frequent disruptions and confusion.",
            "The teacher struggles to implement or maintain procedures, resulting in a lack of structure and efficiency.",
            "Transitions between activities are poorly managed, causing significant loss of instructional time.",
          ],
          Developing: [
            "Classroom procedures are somewhat organized, but there are occasional disruptions or inefficiencies in their implementation.",
            "The teacher manages procedures with some effectiveness, though there are areas where improvement is needed to ensure smooth operation.",
            "Transitions are generally smooth, but there may be occasional delays or disruptions affecting instructional time.",
          ],
          Effective: [
            "Classroom procedures are well-managed and contribute to an orderly and efficient learning environment.",
            "The teacher effectively implements procedures, leading to minimal disruptions and a smooth flow of activities.",
            "Transitions between activities are well-organized, with clear procedures that help maintain instructional time.",
          ],
          HighlyEffective: [
            "Classroom procedures are expertly managed, leading to a highly organized and efficient learning environment.",
            "The teacher's procedures are seamlessly integrated into the lesson, resulting in a smooth and highly effective classroom operation.",
            "Transitions are flawlessly executed, with procedures that optimize instructional time and maintain a focused learning atmosphere.",
          ],
        },
      },
      {
        name: "2d: Managing Student Behavior*",
        options: {
          Ineffective: [
            "Behavior management strategies are inconsistent or ineffective, leading to frequent disruptions and lack of control.",
            "The teacher does not address behavioral issues effectively, resulting in ongoing disruptions and a lack of discipline.",
            "There is minimal structure or support for managing student behavior, impacting the overall classroom environment.",
          ],
          Developing: [
            "Behavior management is generally effective but may lack consistency or fail to address all issues promptly.",
            "The teacher addresses behavioral issues to some extent, though there are occasional lapses in maintaining control or consistency.",
            "There is some structure in place for managing behavior, but effectiveness may vary depending on the situation.",
          ],
          Effective: [
            "Behavior management strategies are consistently applied, leading to a well-maintained classroom environment with minimal disruptions.",
            "The teacher effectively addresses behavioral issues with fair and consistent practices, creating a conducive learning environment.",
            "There is a clear structure for managing student behavior, contributing to a positive and respectful classroom atmosphere.",
          ],
          HighlyEffective: [
            "Behavior management is highly effective, with proactive strategies that prevent disruptions and maintain a positive classroom climate.",
            "The teacher expertly addresses and resolves behavioral issues, creating a highly supportive environment where students exhibit self-regulation.",
            "Behavior management practices are seamlessly integrated into the classroom culture, resulting in exceptional student behavior and engagement.",
          ],
        },
      },
      {
        name: "2e: Organizing Physical Space",
        options: {
          Ineffective: [
            "The physical layout of the classroom is disorganized and hinders effective instruction and student movement.",
            "Classroom materials and resources are cluttered or inaccessible, impacting student learning and engagement.",
            "The physical space is poorly arranged, leading to difficulties in managing classroom activities and student interactions.",
          ],
          Developing: [
            "The physical layout is somewhat organized but may have areas that are not optimally arranged for effective learning.",
            "Materials and resources are generally accessible but may not be efficiently organized to support all instructional activities.",
            "The classroom arrangement supports some aspects of instruction but may need adjustments to enhance overall effectiveness.",
          ],
          Effective: [
            "The physical space is well-organized and conducive to effective instruction, with a layout that supports student learning and movement.",
            "Materials and resources are easily accessible, contributing to an efficient and organized learning environment.",
            "The classroom arrangement is thoughtfully designed to support instructional activities and facilitate student interaction.",
          ],
          HighlyEffective: [
            "The physical space is expertly organized to maximize instructional effectiveness and student engagement, with an optimal layout for learning.",
            "Materials and resources are meticulously arranged, enhancing accessibility and contributing to a highly efficient classroom environment.",
            "The classroom arrangement is innovative and highly effective, supporting a dynamic and engaging learning experience for all students.",
          ],
        },
      },
      // Add more categories as needed
    ],
  },
  {
    title: "Domain 3: Instruction",
    description: "Create an engaging and safe environment for all students.",
    categoryDetails: [
      {
        name: "3a: Communicating with Students",
        options: {
          Ineffective: [
            "Instructions are often unclear or confusing, leading to frequent misunderstandings among students.",
            "The teacher’s explanations are inconsistent or lack detail, making it difficult for students to grasp key concepts.",
            "Communication with students is sporadic or ineffective, resulting in a lack of clarity about lesson objectives and expectations.",
          ],
          Developing: [
            "Instructions are generally clear but may lack some detail or consistency, occasionally leading to confusion.",
            "Explanations are usually understandable but might not always be detailed or thoroughly communicated.",
            "Communication is adequate but could be improved in terms of providing clearer instructions and more consistent feedback.",
          ],
          Effective: [
            "Instructions are clear and effective, enabling students to understand what is expected and how to complete tasks.",
            "The teacher provides detailed explanations that enhance student understanding and support learning objectives.",
            "Communication with students is consistent and effective, helping to clarify lesson goals and expectations.",
          ],
          HighlyEffective: [
            "Instructions are exceptionally clear and engaging, facilitating a deep understanding of tasks and concepts among students.",
            "Explanations are delivered with clarity and insight, significantly enhancing student comprehension and learning.",
            "Communication is highly effective, with thoughtful and detailed feedback that supports and motivates students throughout the lesson.",
          ],
        },
      },
      {
        name: "3b: Using Questioning and Discussion Techniques",
        options: {
          Ineffective: [
            "Questioning is limited and does not encourage meaningful student participation or critical thinking.",
            "Discussions are infrequent or poorly managed, leading to minimal student engagement or contribution.",
            "Questions are often too broad or not effectively used to gauge student understanding.",
          ],
          Developing: [
            "Questioning techniques are somewhat effective but may lack variety or depth in promoting student engagement.",
            "Discussions occur but may not consistently involve all students or foster deep exploration of topics.",
            "Questions are generally effective but may sometimes fail to fully challenge students or encourage critical thinking.",
          ],
          Effective: [
            "Questioning techniques are well-developed, promoting student participation and encouraging critical thinking.",
            "Discussions are engaging and effectively managed,involving most students and leading to meaningful dialogue about the lesson content.",
            "Questions are used strategically to assess understanding and stimulate thoughtful discussion, enhancing student learning.",
          ],
          HighlyEffective: [
            "Questioning is highly effective, utilizing a range of techniques that deeply engage students and promote higher-order thinking.",
            "Discussions are dynamic and well-facilitated, with all students actively participating and contributing to insightful conversations.",
            "Questions are expertly designed to challenge students and provoke deep reflection, significantly enriching the learning experience.",
          ],
        },
      },
      {
        name: "3c: Engaging Students in Learning",
        options: {
          Ineffective: [
            "Student engagement is low, with limited use of strategies to capture or maintain interest throughout the lesson.",
            "The lesson lacks interactive or motivating elements, resulting in passive participation from students.",
            "Students show little enthusiasm or involvement in the learning activities, affecting overall lesson effectiveness.",
          ],
          Developing: [
            "Some strategies are used to engage students, but they may not be consistently effective in maintaining interest.",
            "The lesson includes some interactive elements, but student participation may still be uneven or limited.",
            "Students are somewhat engaged but may require more dynamic or varied approaches to sustain their interest.",
          ],
          Effective: [
            "The teacher uses effective strategies to engage students, maintaining their interest and participation throughout the lesson.",
            "Interactive and motivating elements are incorporated, resulting in active student involvement and enthusiasm.",
            "Students are engaged and show interest in the lesson, contributing positively to the learning environment.",
          ],
          HighlyEffective: [
            "The teacher employs highly innovative and effective strategies that captivate and sustain student interest throughout the lesson.",
            "Students are deeply engaged and motivated, demonstrating exceptional participation and enthusiasm for the learning activities.",
            "The lesson is highly interactive and stimulating, creating an environment where students are fully involved and invested in their learning.",
          ],
        },
      },
      {
        name: "3d: Using Assessment in Instruction",
        options: {
          Ineffective: [
            "Assessment is rarely used to inform instruction, with minimal or no feedback provided to students.",
            "The teacher does not monitor student progress effectively, leading to a lack of understanding of individual learning needs.",
            "Assessment results are not used to adjust instruction, resulting in static and ineffective teaching.",
          ],
          Developing: [
            "Assessment is occasionally used to inform instruction, but feedback may not always be timely or detailed.",
            "The teacher monitors student progress to some extent but may not consistently use this information to adjust teaching.",
            "Assessment results are used sporadically to make instructional adjustments, but effectiveness may vary.",
          ],
          Effective: [
            "Assessment is regularly used to inform instruction, with timely and constructive feedback that supports student learning.",
            "The teacher effectively monitors student progress and makes adjustments to instruction based on assessment data.",
            "Assessment results are integrated into the teaching process, leading to meaningful adjustments that enhance learning.",
          ],
          HighlyEffective: [
            "Assessment is seamlessly integrated into instruction, with dynamic adjustments made based on real-time student performance and feedback.",
            "Feedback is highly specific and actionable, driving significant improvements in student learning and progress.",
            "The teacher uses assessment data innovatively to continuously refine instruction, optimizing learning outcomes for all students.",
          ],
        },
      },
      {
        name: "3e: Demonstrating Flexibility and Responsiveness",
        options: {
          Ineffective: [
            "The teacher is inflexible and does not adapt instruction based on student needs or classroom dynamics.",
            "Instruction remains rigid despite evident student difficulties or changing circumstances.",
            "The teacher does not respond to feedback or changing conditions, resulting in ineffective teaching.",
          ],
          Developing: [
            "The teacher demonstrates some flexibility but may not fully adapt instruction to meet all student needs or circumstances.",
            "There are occasional adjustments to instruction, but they may not always address the underlying issues effectively.",
            "The teacher responds to some feedback and changing conditions, but flexibility may be inconsistent.",
          ],
          Effective: [
            "The teacher is flexible and responsive to student needs and changing classroom dynamics, making appropriate instructional adjustments.",
            "Instruction is adjusted effectively based on student feedback and emerging needs, leading to improved learning outcomes.",
            "The teacher demonstrates a strong ability to adapt and respond to various conditions, enhancing the overall instructional effectiveness.",
          ],
          HighlyEffective: [
            "The teacher exhibits exceptional flexibility and responsiveness, dynamically adjusting instruction to optimize learning for all students.",
            "Instruction is continuously adapted based on real-time feedback and changing circumstances, resulting in highly effective teaching.",
            "The teacher anticipates and proactively responds to student needs and classroom dynamics, significantly enhancing the learning experience.",
          ],
        },
      },

      // Add more categories as needed
    ],
  },
  {
    title: "Domain 4: Professional Responsibilities",
    description: "Create an engaging and safe environment for all students.",
    categoryDetails: [
      {
        name: "4a: Reflecting on Teaching",
        options: {
          Ineffective: [
            "The teacher does not engage in reflection about their teaching practice or consider areas for improvement.",
            "There is no evidence of self-assessment or attempts to understand the effectiveness of instructional strategies.",
            "Reflection on teaching practice is minimal or non-existent, leading to stagnant teaching practices.",
          ],
          Developing: [
            "The teacher reflects on their practice occasionally but may not always use insights to make meaningful changes.",
            "Self-assessment is present but lacks depth, leading to limited improvements in instructional strategies.",
            "Reflection is sporadic and may not consistently inform or enhance teaching practices.",
          ],
          Effective: [
            "The teacher regularly reflects on their teaching practice and uses insights gained to make thoughtful improvements.",
            "Self-assessment is thorough, leading to meaningful adjustments and enhancements in instructional strategies.",
            "Reflection is an integral part of practice, contributing to ongoing improvement and effectiveness in teaching.",
          ],
          HighlyEffective: [
            "The teacher engages in deep and insightful reflection on their practice, driving continuous and significant improvements.",
            "Self-assessment is highly detailed and proactive, leading to innovative changes that enhance instructional effectiveness.",
            "Self-assessment is highly detailed and proactive, leading to innovative changes that enhance instructional effectiveness.",
          ],
        },
      },
      {
        name: "4b: Maintaining Accurate Records",
        options: {
          Ineffective: [
            "Records are incomplete or disorganized, leading to difficulties in tracking student performance and progress.",
            "There is frequent inaccuracy or delay in updating records, impacting the ability to monitor student achievement effectively.",
            "The teacher struggles to maintain accurate and accessible records, resulting in ineffective use of data for instruction.",
          ],
          Developing: [
            "Records are generally maintained but may lack organization or completeness, leading to occasional difficulties in tracking student performance.",
            "There are some discrepancies or delays in updating records, but overall information is somewhat accurate and accessible.",
            "The teacher maintains records with some degree of accuracy, though organization and timeliness could be improved.",
          ],
          Effective: [
            "The teacher maintains accurate and well-organized records, providing a clear and reliable account of student performance and progress.",
            "Records are consistently updated and accessible, supporting effective monitoring of student achievement and instructional planning.",
            "The teacher uses well-maintained records to effectively track and support student progress, contributing to instructional effectiveness.",
          ],
          HighlyEffective: [
            "Records are meticulously maintained and organized, providing comprehensive and highly accurate information about student performance.",
            "The teacher's records are exemplary, supporting detailed and effective monitoring of student progress and facilitating precise instructional planning.",
            "Records are used innovatively to support student achievement and enhance instructional strategies, demonstrating a high level of professionalism.",
          ],
        },
      },
      {
        name: "4c: Communicating with Families",
        options: {
          Ineffective: [
            "Communication with families is infrequent and lacks meaningful content, leading to disengagement from the educational process.",
            "The teacher does not provide regular updates or respond to family inquiries in a timely manner.",
            "Engagement with families is minimal, and there is little effort to build positive relationships or share important information.",
          ],
          Developing: [
            "Communication with families is somewhat regular but may lack depth or consistency, affecting the quality of engagement.",
            "Updates are provided, but there may be delays or limited responsiveness to family inquiries.",
            "The teacher makes some effort to engage with families but could improve in providing meaningful and timely information.",
          ],
          Effective: [
            "The teacher communicates regularly and effectively with families, providing meaningful updates and engaging in productive discussions.",
            "Families receive timely and relevant information about student progress and are engaged in supporting the learning process.",
            "The teacher builds positive relationships with families, facilitating open communication and collaboration to support student success.",
          ],
          HighlyEffective: [
            "The teacher excels in communication with families, providing comprehensive and timely updates that foster strong partnerships.",
            "Engagement with families is highly productive, with the teacher proactively sharing information and collaborating to support student achievement.",
            "Communication is exemplary, with the teacher building and maintaining strong, positive relationships with families to enhance student learning and development.",
          ],
        },
      },
      {
        name: "4d: Participating in a Professional Community",
        options: {
          Ineffective: [
            "The teacher shows minimal involvement in the professional community, with little contribution to collaborative efforts.",
            "Participation in professional development or school activities is infrequent and lacks engagement.",
            "There is limited effort to collaborate with colleagues or contribute to the overall improvement of the school community.",
          ],
          Developing: [
            "The teacher participates in the professional community to some extent but may not be highly active or engaged in collaborative efforts.",
            "Involvement in professional development is present but may be limited in scope or frequency.",
            "The teacher contributes to the professional community occasionally, with some effort to collaborate with colleagues and support school initiatives.",
          ],
          Effective: [
            "The teacher is actively involved in the professional community, contributing to collaborative efforts and supporting school improvement.",
            "Participation in professional development is regular and focused, leading to personal growth and enhanced teaching practices.",
            "The teacher collaborates effectively with colleagues and engages in activities that positively impact the school community.",
          ],
          HighlyEffective: [
            "The teacher is a leader in the professional community, actively driving collaborative efforts and contributing significantly to school improvement.",
            "Participation in professional development is highly proactive, with the teacher seeking out opportunities for growth and sharing knowledge with peers.",
            "The teacher demonstrates exceptional collaboration and leadership, making a substantial impact on the professional community and school environment.",
          ],
        },
      },
      {
        name: "4e: Growing and Developing Professionally",
        options: {
          Ineffective: [
            "The teacher shows little interest in professional growth or development, resulting in stagnant teaching practices.",
            "There is minimal effort to seek out or engage in professional development opportunities.",
            "Professional growth is limited, with little evidence of applying new knowledge or skills to improve teaching.",
          ],
          Developing: [
            "The teacher engages in some professional development but may not consistently apply new learning to practice.",
            "There is occasional effort to seek out growth opportunities, though the impact on teaching practice may be limited.",
            "Professional growth is evident but may need more focus or application to significantly enhance teaching effectiveness.",
          ],
          Effective: [
            "The teacher actively pursues professional development and effectively integrates new learning into teaching practices.",
            "There is a consistent effort to grow professionally, with evident application of new skills and knowledge to improve instruction.",
            "Professional growth is a priority, leading to meaningful enhancements in teaching practice and student outcomes.",
          ],
          HighlyEffective: [
            "The teacher is a proactive and reflective learner, seeking out advanced professional development opportunities and applying new insights innovatively.",
            "Professional growth is continuously pursued with a focus on transformative practices that significantly enhance teaching effectiveness.",
            "The teacher demonstrates exceptional commitment to professional development, leading to groundbreaking improvements in teaching and a strong impact on student success.",
          ],
        },
      },
    ],
  },
];
export default category_danielson;
