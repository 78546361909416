import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import frontDark from "../Images/front_dark.png";
import { visuallyHidden } from "@mui/utils";
import { styled } from "@mui/material/styles";
import homeHeroImage from "../Images/childrenInHall.png";
const StyledBox = styled("div")(({ theme }) => ({
  alignSelf: "center",
  width: "100%",
  height: "100%",
  borderRadius: theme.shape.borderRadius,
  outline: "6px solid",
  outlineColor: "hsla(220, 25%, 80%, 0.2)",
  border: "1px solid",
  borderColor: theme.palette.grey[200],
  boxShadow: "0 0 12px 8px hsla(220, 25%, 80%, 0.2)",
  backgroundImage: `url(${frontDark})`,
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  ...theme.applyStyles("dark", {
    boxShadow: "0 0 24px 12px hsla(210, 100%, 25%, 0.2)",
    backgroundImage: `url(${frontDark})`,
    outlineColor: "hsla(220, 20%, 42%, 0.1)",
    borderColor: theme.palette.grey[700],
  }),
}));

const AboutUsYellow = React.forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <Box
        id="hero"
        sx={(theme) => ({
          width: "100%",
        })}
      >
        <Container sx={{ pt: { xs: 14, sm: 20 }, pb: { xs: 2, sm: 2 } }}>
          <Grid container spacing={4}>
            {/* Text Section */}
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="center">
                <img
                  src={homeHeroImage}
                  alt="Example"
                  style={{ width: "100%", maxWidth: "500px" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant="h4" // You can adjust the variant as needed
                sx={{
                  textTransform: "uppercase", // Makes the text all caps
                  color: "#f8af05",
                  fontWeight: "bold", // Optional: make the text bold
                }}
              >
                ABOUT US
              </Typography>
              <Typography
                variant="h1"
                sx={{
                  color: "#4286F0",
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: "center",
                  fontSize: "clamp(2rem, 7vw, 3rem)",
                }}
              >
                Transforming Education Together
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "text.secondary", mb: { xs: 2, sm: 4 } }}
              >
                At Curriculum Edge, we are passionate about empowering those in
                the education sector. We understand your unique challenges and
                are committed to providing innovative solutions. Our platform
                offers intuitive reporting and supportive tools that streamline
                your workflow and enhance the learner experience. Together, we
                aim to create a more supportive and effective educational
                environment for everyone involved while actively working towards
                enhancing inclusion for all learners in educational spaces.
              </Typography>
              <Grid container spacing={2}>
                <Grid item sm={4}>
                  <Button
                    href="/signUp"
                    sx={{
                      marginTop: 2,
                      backgroundColor: "#FCCC5B", // Blue background
                      color: "white", // White text
                      borderRadius: "24px", // More rounded corners
                      minWidth: "150px", // Make the button longer
                      padding: "8px 16px", // Add padding for larger button
                      "&:hover": {
                        backgroundColor: "#357ae8", // Slightly darker blue on hover
                      },
                    }}
                    size="medium" // You can change to "large" for bigger size if needed
                  >
                    Learn More
                  </Button>
                </Grid>

                <Grid item sm={4}></Grid>
              </Grid>
            </Grid>

            {/* Image Section */}
          </Grid>
        </Container>
      </Box>
    </div>
  );
});
export default AboutUsYellow;
