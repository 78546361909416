import React, { useState, useEffect, forwardRef } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import axios from "axios";

const LessonPlanFormPDF = forwardRef(({ formData }, ref) => {
  const [newFormData, setNewFormData] = useState(formData);
  // Fetch function to get form data by ID using axios
  const fetchFormData = async (formId) => {
    console.log("we do this");
    try {
      const response = await axios.get(
        `https://eduevaluator.com/api/lessonPlan/get-form/${formId}`
      );
      return response.data.data; // Assuming the response has `data` field containing the form data
    } catch (error) {
      console.error("Error fetching form data:", error);
      return null; // Return null if there's an error
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (formData && formData._id) {
        const fetchedData = await fetchFormData(formData._id);
        if (fetchedData) {
          setNewFormData(fetchedData); // Update newFormData with the fetched data
        }
      }
    };

    fetchData();
  }, [formData.id]);

  const dateGenerated = new Date().toLocaleDateString();
  console.log(formData);
  const styles = StyleSheet.create({
    container: {
      color: "#333",
      padding: 20,
    },
    header: {
      textAlign: "center",
      marginBottom: 30,
    },
    headerTitle: {
      fontSize: 24,
      color: "#333",
    },
    headerDate: {
      fontSize: 14,
      color: "#666",
    },
    sectionHeader: {
      backgroundColor: "#000",
      color: "#fff",
      padding: 8,
      fontSize: 10,
      marginTop: 20,
    },
    gridContainer: {
      flexDirection: "row",
      flexWrap: "wrap",
      gap: 20,
    },
    cell: {
      width: "45%",
      padding: 5,
      border: "1px solid #ccc",
      borderRadius: 0,
      backgroundColor: "#fff",
    },
    cellLabel: {
      fontWeight: "bold",
      color: "black",
      fontSize: 10,
      paddingBottom: 4,
    },
    cellValue: {
      fontSize: 8,
      color: "#333",
    },
    fullWidthCell: {
      width: "100%",
    },
  });

  return (
    <Document>
      <Page style={styles.container}>
        <View style={styles.header}>
          <Text style={styles.headerTitle}>Lesson Plan</Text>
          <Text style={styles.headerDate}>
            Generated on: <Text style={{ color: "#333" }}>{dateGenerated}</Text>
          </Text>
        </View>

        {/* General Information Section */}
        <View style={{ marginBottom: "20px" }}>
          <Text style={styles.sectionHeader}>General Information</Text>
          <View style={styles.gridContainer}>
            <View style={styles.cell}>
              <Text style={styles.cellLabel}>Subject</Text>
              <Text style={styles.cellValue}>{newFormData.subject}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellLabel}>Grade Level</Text>
              <Text style={styles.cellValue}>{newFormData.gradeLevel}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellLabel}>Lesson Start Time</Text>
              <Text style={styles.cellValue}>{newFormData.lessonTime}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellLabel}>Lesson Date</Text>
              <Text style={styles.cellValue}>{newFormData.lessonDate}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellLabel}>Lesson Title/Topic</Text>
              <Text style={styles.cellValue}>{newFormData.lessonTitle}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellLabel}>Key Vocabulary / Concepts</Text>
              <Text style={styles.cellValue}>
                {Array.isArray(newFormData.keyTerms) &&
                newFormData.keyTerms.length > 0
                  ? newFormData.keyTerms.join(", ")
                  : ""}
              </Text>
            </View>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Standards</Text>
              <Text style={styles.cellValue}>{newFormData.standards}</Text>
            </View>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Materials Needed</Text>
              <Text style={styles.cellValue}>{newFormData.materials}</Text>
            </View>
          </View>
        </View>

        {/* Current Learning Objectives Section */}
        <View style={{ marginBottom: "20px" }}>
          <Text style={styles.sectionHeader}>Current Learning Objectives</Text>
          <View style={[styles.cell, styles.fullWidthCell]}>
            <Text style={styles.cellLabel}>Learning Objectives</Text>
            <Text style={styles.cellValue}>
              {Array.isArray(newFormData.starterText) &&
              newFormData.starterText.length > 0
                ? newFormData.starterText.join(", ")
                : ""}
            </Text>
          </View>
        </View>

        {/* Start of the Lesson Section */}
        <View style={{ marginBottom: "20px" }}>
          <Text style={styles.sectionHeader}>Start of the Lesson</Text>
          <View style={styles.gridContainer}>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Introduction / Hook</Text>
              <Text style={styles.cellValue}>{newFormData.introduction}</Text>
            </View>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>
                Connection to Previous Content
              </Text>
              <Text style={styles.cellValue}>
                {newFormData.connectionToPrevious}
              </Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellLabel}>Classroom Setup</Text>
              <Text style={styles.cellValue}>{newFormData.classroomMode}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellLabel}>Time Allocation</Text>
              <Text style={styles.cellValue}>
                {newFormData.middleLessonTimeAllocation}
              </Text>
            </View>
          </View>
        </View>

        {/* Middle of the Lesson Section */}
        <View style={{ marginBottom: "20px" }}>
          <Text style={styles.sectionHeader}>Middle of the Lesson</Text>
          <View style={styles.gridContainer}>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Instruction / Teaching</Text>
              <Text style={styles.cellValue}>
                {newFormData.middleInstuction}
              </Text>
            </View>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Guided Practice</Text>
              <Text style={styles.cellValue}>{newFormData.guidedPractice}</Text>
            </View>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Independent Practice</Text>
              <Text style={styles.cellValue}>
                {newFormData.independentPractice}
              </Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellLabel}>
                Differentiation / Scaffolding
              </Text>
              <Text style={styles.cellValue}>
                {newFormData.differentiation}
              </Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellLabel}>
                Students Needing Accommodations
              </Text>
              <Text style={styles.cellValue}>
                {newFormData.studentsNeedingAccomodations}
              </Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellLabel}>Assistive Technology</Text>
              <Text style={styles.cellValue}>
                {newFormData.assistiveTechnology}
              </Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellLabel}>Assessment / Evaluation</Text>
              <Text style={styles.cellValue}>{newFormData.assesmentType}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellLabel}>Time Allocation</Text>
              <Text style={styles.cellValue}>
                {newFormData.middleLessonTimeAllocation}
              </Text>
            </View>
          </View>
        </View>

        {/* End of the Lesson Section */}
        <View style={{ marginBottom: "20px" }}>
          <Text style={styles.sectionHeader}>End of the Lesson</Text>
          <View style={styles.gridContainer}>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Summary / Closing</Text>
              <Text style={styles.cellValue}>{newFormData.summary}</Text>
            </View>
            <View style={[styles.cell, styles.fullWidthCell]}>
              <Text style={styles.cellLabel}>Connection to Future Content</Text>
              <Text style={styles.cellValue}>
                {newFormData.connectionToFutureContent}
              </Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellLabel}>End of Lesson Assessment</Text>
              <Text style={styles.cellValue}>
                {newFormData.summativeAssessment}
              </Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellLabel}>Reflection</Text>
              <Text style={styles.cellValue}>{newFormData.reflection}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
});

export default LessonPlanFormPDF;
