import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  Chip,
  MenuItem,
  Select,
  Paper,
  TextareaAutosize,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import OpenAI from "openai";
import AssistantIcon from "@mui/icons-material/Assistant";
import { useDropzone } from "react-dropzone";
import LoadingModal from "./LoadingModal";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function AIlessonPlanComponent() {
  // State variables
  const [activeStep, setActiveStep] = useState(0);
  const [teacherFirstName, setTeacherFirstName] = useState("");
  const [lessonPlanDuration, setLessonPlanDuration] = useState("");
  const [teacherLastName, setTeacherLastName] = useState("");
  const [subject, setSubject] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [courseAssessments, setCourseAssessments] = useState("");
  const [instructionType, setInstructionType] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false); // State for loading
  const [modalOpen, setModalOpen] = useState(false); // State for modal
  const [summary, setSummary] = useState(""); // State for API response

  // File upload handling
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const fileURLs = acceptedFiles.map((file) => URL.createObjectURL(file));
      setUploadedFiles((prevFiles) => [...prevFiles, ...fileURLs]);
    },
  });

  // Mock handler functions
  const handleSubjectChange = (e) => setSubject(e.target.value);
  const handleGradeLevelChange = (e) => setGradeLevel(e.target.value);
  const handleCourseAssessmentsChange = (e) =>
    setCourseAssessments(e.target.value);
  const handleInstructionTypeChange = (e) => setInstructionType(e.target.value);
  const handleLocationChange = (e) => setLocation(e.target.value);
  const handleDescriptionChange = (e) => setDescription(e.target.value);
  const saveDraft = () => alert("Draft saved!");

  const handleSubmit = async () => {
    const formData = {
      subject,
      gradeLevel,
      courseAssessments,
      instructionType,
      location,
      description,
    };
    console.log("Form Data:", formData);

    setLoading(true); // Start loading

    try {
      const email = Cookies.get("email");
      const content = `Given this subject:${formData.subject}, this grade level ${formData.gradeLevel}, this number of required course assesments ${formData.courseAssessments}, and the course being ${instructionType} from ${formData.location} create for me a lesson plan.Here are additonal details ${formData.description}. The lesson plan should be generated for ${lessonPlanDuration}`;
      const tokenResponse = await axios.post(
        "https://eduevaluator.com/api/users/decreaseToken",
        { email, content }
      );
      if (tokenResponse.data.message === "OpenAI response generated") {
        const summaries = tokenResponse.data.summary;
        setSummary(summaries); // Save summary for modal
        setModalOpen(true); // Open modal
      } else {
        toast.error("You are out of credits");
      }
    } catch (error) {
      console.error("Error while fetching completion:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(summary)
      .then(() => {
        toast.success("Text copied to clipboard!");
      })
      .catch((error) => {
        toast.error("Could not copy text: ", error);
      });
  };

  const subjectOptions = [
    "Math",
    "Science",
    "English",
    "History",
    "Art",
    "Music",
    // Add more subjects as needed
  ];

  const gradeOptions = [
    "1st Grade",
    "2nd Grade",
    "3rd Grade",
    "4th Grade",
    "5th Grade",
    "6th Grade",
    "7th Grade",
    "8th Grade",
    "9th Grade",
    "10th Grade",
    "11th Grade",
    "12th Grade",
    "University",
  ];

  const assessmentOptions = [
    "1 Assessment",
    "2 Assessments",
    "3 Assessments",
    "4 Assessments",
    "5 Assessments",
  ];

  const instructionTypeOptions = ["Online", "In-Person"];

  return (
    <Paper elevation={3} sx={{ padding: 3, borderRadius: 2, width: "100%" }}>
      <ToastContainer />
      <LoadingModal loading={loading} message="Generating response..." />
      <Container sx={{ width: "100%", maxWidth: "1200px" }}>
        <Box sx={{ marginTop: 3 }}>
          <Accordion
            expanded={true}
            sx={{
              width: "100%",
              backgroundColor: "white",
              color: "black",
              marginBottom: 2,
              borderRadius: 2,
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              "&:before": { display: "none" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#e0e0e0",
                padding: "16px",
                borderRadius: "4px 4px 0 0",
                color: "black",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              <AssistantIcon sx={{ marginRight: 1 }} />
              AI Lesson Plans
            </Box>
            <Box
              display="flex"
              alignItems="center"
              sx={{
                marginTop: 4,
                marginBottom: 4,
                backgroundColor: "#f0f0f0",
                padding: "16px",
                borderRadius: "8px",
              }}
            >
              <Chip
                label="What does this do?"
                sx={{
                  backgroundColor: "#1976d2", // Blue color
                  color: "#ffffff", // This sets the text color to white
                  "& .MuiChip-label": {
                    color: "#ffffff", // Ensures the label is white
                  },
                  marginRight: "16px",
                }}
              />
              <Typography variant="body1">
                I align objectives, assessments, and online presence, integrate
                AI, update weekly with the latest models, and create workable
                lesson plans.
              </Typography>
            </Box>

            <Container>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Subject</FormLabel>
                    <TextField
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      variant="outlined"
                      sx={{ borderRadius: 2 }}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Grade Level</FormLabel>
                    <Select
                      value={gradeLevel}
                      onChange={handleGradeLevelChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    >
                      <MenuItem value="" disabled>
                        Select Grade
                      </MenuItem>
                      {gradeOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth style={{ marginTop: 5 }}>
                    <FormLabel>Number of Course Assessments</FormLabel>
                    <Select
                      value={courseAssessments}
                      onChange={handleCourseAssessmentsChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    >
                      <MenuItem value="" disabled>
                        Select Course Assessments
                      </MenuItem>
                      {assessmentOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth style={{ marginTop: 5 }}>
                    <FormLabel>Type of Instruction</FormLabel>
                    <Select
                      value={instructionType}
                      onChange={handleInstructionTypeChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    >
                      <MenuItem value="" disabled>
                        Select Instruction Type
                      </MenuItem>
                      {instructionTypeOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth style={{ marginTop: 5 }}>
                    <FormLabel>Location</FormLabel>
                    <TextField
                      value={location}
                      onChange={handleLocationChange}
                      variant="outlined"
                      sx={{ borderRadius: 2 }}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel> Lesson Plan Duration</FormLabel>
                    <Select
                      value={lessonPlanDuration}
                      onChange={(e) => setLessonPlanDuration(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Subject" }}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    >
                      <MenuItem value="" disabled>
                        Select a lesson plan duration
                      </MenuItem>
                      {["30 mins", "45 min", "60 mins", "90 mins"].map(
                        (option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth style={{ marginTop: 5 }}>
                    <FormLabel>Additional Details</FormLabel>
                    <TextareaAutosize
                      minRows={3}
                      value={description}
                      onChange={handleDescriptionChange}
                      style={{
                        width: "100%",
                        borderRadius: "4px",
                        padding: "8px",
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Container>

            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button
                    type="button" // Change this to "button" instead of "submit"
                    onClick={handleSubmit} // Call handleSubmit on click
                    style={{
                      marginTop: 10,
                      width: 100,
                      backgroundColor: "#4286F0",
                      color: "white",
                      borderRadius: 20,
                    }}
                  >
                    Ask AI
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Dialog
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>Lesson Plan Summary</DialogTitle>
          <DialogContent>
            <pre>{summary}</pre>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCopyToClipboard}>Copy to Clipboard</Button>
            <Button onClick={() => setModalOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Paper>
  );
}

export default AIlessonPlanComponent;
