import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import FormLabel from "@mui/material/FormLabel";
import {
  Paper,
  Stepper,
  Step,
  StepLabel,
  Container,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ReportView = () => {
  const location = useLocation();
  const { rowData } = location.state || {}; // Destructure the passed data
  console.log(location.state);
  console.log(rowData);

  // Removed "Artifacts" step
  const steps = ["Teacher Info", "Class Info"];
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedOptions, setSelectedOptions] = useState({});

  const handleSelectChange = (event, itemIndex) => {
    const newSelectedOptions = {
      ...selectedOptions,
      [itemIndex]: event.target.value,
    };
    setSelectedOptions(newSelectedOptions); // Update the selected option for the current item
  };

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  const renderAccordionDetails = (data) => {
    if (!data || !Array.isArray(data.categories)) {
      return <Typography>No data available</Typography>;
    }

    return data.categories.map((category, categoryIndex) => (
      <Accordion
        key={categoryIndex}
        expanded={true}
        sx={{
          minWidth: "100%",
          backgroundColor: "white",
          color: "black",
          marginBottom: 2,
          borderRadius: 2,
          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
          "&:before": { display: "none" },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#e0e0e0",
            padding: "16px",
            borderRadius: "4px 4px 0 0",
            color: "black",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          <SchoolIcon sx={{ marginRight: 1 }} />
          Category {categoryIndex + 1} {/* Or another title if available */}
        </AccordionSummary>
        <AccordionDetails>
          {category.length > 0 ? (
            category.map((item, itemIndex) => (
              <Box key={itemIndex} sx={{ marginBottom: 2 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  {item.name}
                </Typography>
                <Typography variant="subtitle1">
                  Rating: {item.rating}
                </Typography>
                <Typography variant="subtitle1" style={{ marginBottom: 10 }}>
                  Context: {item.context}
                </Typography>
                {/* Render secondDropdown if it has items */}
                {item.secondDropdown && item.secondDropdown.length > 0 && (
                  <FormControl fullWidth size="small">
                    <InputLabel id={`secondDropdown-label-${itemIndex}`}>
                      Additional Info
                    </InputLabel>
                    <Select
                      labelId={`secondDropdown-label-${itemIndex}`}
                      value={item.secondDropdown[0]} // Set the first item as the default value
                      onChange={(e) => console.log(e.target.value)} // You can handle the value change here if needed
                    >
                      {item.secondDropdown.map((option, optionIndex) => (
                        <MenuItem key={optionIndex} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Box>
            ))
          ) : (
            <Typography>No items available</Typography>
          )}
        </AccordionDetails>
      </Accordion>
    ));
  };

  return (
    <Paper elevation={3} sx={{ padding: 3, borderRadius: 2 }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel>{step}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Container sx={{ marginTop: 3, maxWidth: "800px" }}>
        <Box sx={{ width: "100%" }}>
          {activeStep === 0 ? (
            <Accordion
              expanded={true}
              sx={{
                minWidth: "100%",
                height: "60vh",
                backgroundColor: "white",
                color: "black",
                marginBottom: 2,
                borderRadius: 2,
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                "&:before": { display: "none" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#e0e0e0",
                  padding: "16px",
                  borderRadius: "4px 4px 0 0",
                  color: "black",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                <SchoolIcon sx={{ marginRight: 1 }} />
                Report Information
              </Box>
              <Container>
                <Typography
                  variant="subtitle1"
                  sx={{ marginBottom: 2, fontWeight: "bold" }}
                >
                  Teacher Information
                </Typography>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <FormControl>
                      <FormLabel htmlFor="email">Teacher First Name</FormLabel>
                      <TextField
                        style={{ width: "500px" }}
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={rowData.teacherFirstName || ""}
                        sx={{ backgroundColor: "white", borderRadius: 2 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl>
                      <FormLabel htmlFor="email">Teacher Last Name</FormLabel>
                      <TextField
                        variant="outlined"
                        style={{ width: "500px" }}
                        size="small"
                        fullWidth
                        value={rowData.teacherLastName || ""}
                        sx={{ backgroundColor: "white", borderRadius: 2 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl>
                      <FormLabel htmlFor="email">Grade Level</FormLabel>
                      <TextField
                        variant="outlined"
                        style={{ width: "500px" }}
                        size="small"
                        fullWidth
                        value={rowData.gradeLevel || ""}
                        sx={{ backgroundColor: "white", borderRadius: 2 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl>
                      <FormLabel htmlFor="email">School Name</FormLabel>
                      <TextField
                        style={{ width: "500px" }}
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={rowData.schoolName || ""}
                        sx={{ backgroundColor: "white", borderRadius: 2 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl>
                      <FormLabel htmlFor="email">Subject</FormLabel>
                      <TextField
                        style={{ width: "500px" }}
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={rowData.subject || ""}
                        sx={{ backgroundColor: "white", borderRadius: 2 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl>
                      <FormLabel htmlFor="email">Period</FormLabel>
                      <TextField
                        style={{ width: "500px" }}
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={rowData.period || ""}
                        sx={{ backgroundColor: "white", borderRadius: 2 }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Container>
            </Accordion>
          ) : (
            <Container style={{ minWidth: "100%" }}>
              {renderAccordionDetails(rowData)}
            </Container>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 2,
            }}
          >
            <Button
              style={{
                backgroundColor: "#4286F0",
                color: "white",
                borderRadius: 20,
              }}
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              Back
            </Button>
            <Button
              color="primary"
              onClick={handleNext}
              style={{
                backgroundColor: "#4286F0",
                color: "white",
                borderRadius: 20,
              }}
              disabled={activeStep === steps.length - 1}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Container>
    </Paper>
  );
};

export default ReportView;
