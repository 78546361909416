import * as React from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
import Stack from "@mui/material/Stack";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import LogoutIcon from "@mui/icons-material/Logout";
import NavbarBreadcrumbs from "./NavbarBreadcrumbs";
import MenuButton from "./MenuButton";
import { useNavigate } from "react-router-dom";
import Search from "./Search";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Divider,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Cookies from "js-cookie";
import axios from "axios";

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notifications, setNotifications] = React.useState([
    { id: 1, message: "New report submitted", time: "2 minutes ago" },
    { id: 2, message: "New report added", time: "10 minutes ago" },
  ]);

  // Function to handle logout
  const handleLogout = () => {
    Cookies.remove("email"); // Remove the email cookie
    Cookies.remove("id");
    Cookies.remove("name");
    // Remove other cookies as necessary
    // Cookies.remove("otherCookieName"); // Example for removing another cookie

    navigate("/"); // Redirect to the home page
  };

  const handleNotificationClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setAnchorEl(null);
  };

  const handleAddLessonPlan = async () => {
    const email = Cookies.get("email");

    const formData = {
      isDraft: true,
      userEmail: email,
    };

    try {
      const response = await axios.post(
        "https://eduevaluator.com/api/lessonPlan/save-first-draft",
        formData
      );

      const newFormId = response.data.formID; // Ensure this matches your response structure
      console.log("Draft saved successfully with ID:", newFormId);

      // Navigate directly to the new URL with the formId
      navigate(`/lessonPlanner/${newFormId}`);
      newFormId = null;
    } catch (error) {
      console.error(
        "Error saving draft:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleAddStudentOfConcern = async () => {
    const email = Cookies.get("email");

    const formData = {
      isDraft: true,
      email: email,
    };

    try {
      const response = await axios.post(
        "https://eduevaluator.com/api/studentConcern/save-draft",
        formData
      );

      const newFormId = response.data.formID; // Ensure this matches your response structure
      console.log("Draft saved successfully with ID:", newFormId);

      // Navigate directly to the new URL with the formId
      navigate(`/studentOfConcernForm/${newFormId}`);
      newFormId = null;
    } catch (error) {
      console.error(
        "Error saving draft:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleAddReportClick = async () => {
    const email = Cookies.get("email");

    const formData = {
      isDraft: true,
      userEmail: email,
    };

    try {
      const response = await axios.post(
        "https://eduevaluator.com/api/form/save-original-draft",
        formData
      );

      const formId = response.data._id;
      console.log("Draft saved successfully with ID:", formId);

      navigate("/addReport", {
        state: {
          formId,
          pageType: "add",
        },
      });
    } catch (error) {
      console.error(
        "Error saving draft:",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <Stack
      direction="row"
      sx={{
        display: { xs: "none", md: "flex" },
        width: "100%",
        alignItems: { xs: "flex-start", md: "center" },
        justifyContent: "space-between",
        maxWidth: { sm: "100%", md: "1700px" },
        pt: 1.5,
      }}
      spacing={2}
    >
      <NavbarBreadcrumbs />
      <Stack direction="row" sx={{ gap: 4 }}>
        {/* Notification button */}
        <Tooltip title="Log Out">
          <IconButton
            aria-label="Log Out"
            onClick={handleLogout}
            color="inherit"
          >
            <LogoutIcon />
          </IconButton>
        </Tooltip>

        {/* Notification menu */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleNotificationClose}
          PaperProps={{
            elevation: 3,
            sx: {
              width: 300,
              maxHeight: 300,
              mt: 1.5,
            },
          }}
        >
          {notifications.map((notification) => (
            <MenuItem key={notification.id} onClick={handleNotificationClose}>
              <Stack direction="column" spacing={0.5}>
                <Typography variant="body2">{notification.message}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {notification.time}
                </Typography>
              </Stack>
            </MenuItem>
          ))}
          {notifications.length > 0 && <Divider />}
          <MenuItem onClick={handleNotificationClose}>
            <Typography variant="body2" color="primary">
              View All Notifications
            </Typography>
          </MenuItem>
        </Menu>

        {/* Conditionally render the Add Report button */}
        {location.pathname === "/dashboard" && (
          <Button
            type="submit"
            fullWidth
            style={{
              backgroundColor: "#4286F0",
              color: "white",
              width: 200,
              borderRadius: 20,
            }}
            onClick={handleAddReportClick}
            startIcon={<AddIcon />}
          >
            Add Evaluation Report
          </Button>
        )}
        {location.pathname === "/myLessonPlans" && (
          <Button
            type="submit"
            fullWidth
            style={{
              backgroundColor: "#4286F0",
              color: "white",
              width: 200,
              borderRadius: 20,
            }}
            onClick={handleAddLessonPlan}
            startIcon={<AddIcon />}
          >
            Add Lesson Plan
          </Button>
        )}
        {location.pathname === "/studentOfConcern" && (
          <Button
            type="submit"
            fullWidth
            style={{
              backgroundColor: "#4286F0",
              color: "white",
              width: 200,
              borderRadius: 20,
            }}
            onClick={handleAddStudentOfConcern}
            startIcon={<AddIcon />}
          >
            Add Concern Form
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
