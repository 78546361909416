import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Box,
  Grid,
  Typography,
  FormLabel,
  TextField,
  IconButton,
  Accordion,
  AccordionDetails,
  LinearProgress,
  Container,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import SchoolIcon from "@mui/icons-material/School";
import Cookies from "js-cookie";
import CloseIcon from "@mui/icons-material/Close";

const getRatingColor = (rating) => {
  switch (rating) {
    case "Ineffective":
      return { value: 25, color: "#d32f2f" }; // Red
    case "Developing":
      return { value: 50, color: "#fbc02d" }; // Yellow
    case "Effective":
      return { value: 75, color: "#388e3c" }; // Green
    case "HighlyEffective":
      return { value: 100, color: "#388e3c" }; // Full Green
    default:
      return { value: 0, color: "#d32f2f" }; // Default red for undefined
  }
};

export default function TeacherDatGrid() {
  const [rows, setRows] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState(null); // For storing the selected teacher's data
  const [open, setOpen] = useState(false); // Modal control
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedFormIndex1, setSelectedFormIndex1] = useState(0); // Tracks the first selected form
  const [selectedFormIndex2, setSelectedFormIndex2] = useState(0); // Tracks the second selected form for comparison
  const [selectedRow, setSelectedRow] = useState(null);
  const userEmail = Cookies.get("email");

  const handleOpen = (teacherId) => {
    fetchTeacherDetails(teacherId); // Fetch teacher details on button click
    setOpen(true);
  };

  const handleDeleteClick = (rowData) => {
    setSelectedRow(rowData); // Store the row to be deleted
    setOpenDeleteModal(true); // Open the confirmation dialog
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(
        `https://eduevaluator.com/api/teachers/deleteTeacher/${selectedRow._id}`
      );
      if (response.status === 200) {
        setRows(rows.filter((row) => row._id !== selectedRow._id)); // Update the table to remove deleted row
        setOpenDeleteModal(false); // Close the modal
        setSelectedRow(null); // Clear the selected row
      } else {
        console.error("Error deleting form:", response.data.message);
      }
    } catch (error) {
      console.error("Error deleting form:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedFormIndex1(0); // Reset form indices when closing
    setSelectedFormIndex2(0);
  };

  const fetchTeacherDetails = async (teacherId) => {
    try {
      const response = await axios.get(
        `https://eduevaluator.com/api/teachers/getOneTeacher/${teacherId}`
      );
      setSelectedTeacher(response.data); // Store the fetched reports
    } catch (error) {
      console.error("Error fetching reports:", error);
    }
  };

  const handleFormChange1 = (event) => {
    setSelectedFormIndex1(event.target.value); // Update first form selection
  };

  const handleCloseDelete = () => {
    setOpenDeleteModal(false); // Close the modal without deleting
    setSelectedRow(null); // Clear the selected row
  };

  const handleFormChange2 = (event) => {
    setSelectedFormIndex2(event.target.value); // Update second form selection for comparison
  };

  const columns = [
    { field: "firstName", headerName: "First Name", flex: 1 },
    { field: "lastName", headerName: "Last Name", flex: 1 },
    {
      field: "details",
      headerName: "Teacher Details",
      flex: 1,
      renderCell: (params) => (
        <div>
          <Button
            style={{
              backgroundColor: "#4286F0",
              color: "white",
              width: 150,
              borderRadius: 20,
            }}
            onClick={() => handleOpen(params.row._id)} // Pass the teacher ID
          >
            View Details
          </Button>
          <IconButton
            style={{ marginLeft: 10 }}
            color="error"
            onClick={() => handleDeleteClick(params.row)}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://eduevaluator.com/api/teachers/get-teachers-by-email/${userEmail}`
        );
        const data = response.data.map((item, index) => ({
          id: index + 1,
          _id: item._id,
          firstName: item.firstName,
          lastName: item.lastName,
        }));
        setRows(data);
      } catch (error) {
        console.error("Error fetching teacher data:", error);
      }
    };

    // Only fetch if userEmail exists
    if (userEmail) {
      fetchData();
    }
  }, [userEmail]);

  return (
    <div style={{ width: "100%" }}>
      <Dialog open={openDeleteModal} onClose={handleCloseDelete}>
        <DialogTitle>{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this teacher profile? This action
            cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete} color="primary">
            Cancel
          </Button>
          <Button
            style={{ color: "red" }}
            onClick={handleConfirmDelete}
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <DataGrid
        autoHeight
        rows={rows}
        columns={columns}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        initialState={{
          pagination: { paginationModel: { pageSize: 20 } },
        }}
        pageSizeOptions={[10, 20, 50]}
        disableColumnResize
        density="compact"
        rowHeight={80}
      />

      {/* Modal for showing teacher details */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100vw",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Accordion
            expanded={true}
            sx={{
              width: "100%",
              overflow: "auto",
              backgroundColor: "white",
              color: "black",
              marginBottom: 2,
              borderRadius: 2,
              maxHeight: "90vh", // Set the maximum height for the accordion
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              "&:before": { display: "none" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#e0e0e0",
                padding: "16px",
                borderRadius: "4px 4px 0 0",
                color: "black",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              <SchoolIcon sx={{ marginRight: 1 }} />
              Teacher Summary
            </Box>
            <Container>
              <IconButton
                style={{ position: "absolute", right: 8, top: 8 }}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>

              {selectedTeacher ? (
                <>
                  <Typography variant="h6">
                    {selectedTeacher.firstName} {selectedTeacher.lastName}
                  </Typography>

                  {selectedTeacher.forms && selectedTeacher.forms.length > 0 ? (
                    <Box mt={2}>
                      <Grid container spacing={2}>
                        {/* First Select Dropdown and Form */}
                        <Grid item sm={6} xs={12}>
                          <FormControl fullWidth>
                            <InputLabel id="form-select-label1">
                              Select Form 1
                            </InputLabel>

                            <Select
                              labelId="form-select-label1"
                              value={selectedFormIndex1}
                              onChange={handleFormChange1}
                            >
                              {selectedTeacher.forms.map((form, index) => (
                                <MenuItem key={index} value={index}>
                                  {new Date(
                                    form.createdAt
                                  ).toLocaleDateString()}{" "}
                                  - {form.observationTime} to{" "}
                                  {form.observationTimeEnd}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormLabel>Framework Selected</FormLabel>
                          <TextField
                            value={
                              selectedTeacher.forms[selectedFormIndex1]
                                .selectedFramework || ""
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                            InputProps={{
                              sx: {
                                bgcolor:
                                  selectedTeacher.forms[selectedFormIndex1]
                                    .selectedFramework ===
                                  selectedTeacher.forms[selectedFormIndex2]
                                    .selectedFramework
                                    ? "lightgreen"
                                    : "lightcoral",
                              },
                            }}
                          />
                          <Typography variant="subtitle1" mt={2}>
                            Selected Report 1:
                          </Typography>
                          <Typography variant="body2">
                            <strong>Observation Date:</strong>{" "}
                            {new Date(
                              selectedTeacher.forms[
                                selectedFormIndex1
                              ].evaluationDate
                            ).toLocaleDateString()}
                          </Typography>
                          <Grid container spacing={2}>
                            {selectedTeacher.forms[
                              selectedFormIndex1
                            ].categories.map((categoryArray, index) => (
                              <Grid item sm={12} key={index} mt={1}>
                                {categoryArray.map((category, catIndex) => {
                                  const { value, color } = getRatingColor(
                                    category.rating
                                  );
                                  return (
                                    <Box key={catIndex} mb={2}>
                                      <Typography variant="subtitle1">
                                        <strong>{category.name}</strong>:{" "}
                                        {category.rating}
                                      </Typography>
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        mt={1}
                                      >
                                        <LinearProgress
                                          variant="determinate"
                                          value={value}
                                          sx={{
                                            height: 10,
                                            width: "100%",
                                            backgroundColor: "#e0e0e0",
                                            "& .MuiLinearProgress-bar": {
                                              backgroundColor: color,
                                            },
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  );
                                })}
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>

                        {/* Second Select Dropdown and Form for comparison */}
                        <Grid item sm={6} xs={12}>
                          <FormControl fullWidth>
                            <InputLabel id="form-select-label2">
                              Select Form 2
                            </InputLabel>
                            <Select
                              labelId="form-select-label2"
                              value={selectedFormIndex2}
                              onChange={handleFormChange2}
                            >
                              {selectedTeacher.forms.map((form, index) => (
                                <MenuItem key={index} value={index}>
                                  {new Date(
                                    form.createdAt
                                  ).toLocaleDateString()}{" "}
                                  - {form.observationTime} to{" "}
                                  {form.observationTimeEnd}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormLabel>Framework Selected</FormLabel>
                          <TextField
                            value={
                              selectedTeacher.forms[selectedFormIndex2]
                                .selectedFramework || ""
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                            InputProps={{
                              sx: {
                                bgcolor:
                                  selectedTeacher.forms[selectedFormIndex1]
                                    .selectedFramework ===
                                  selectedTeacher.forms[selectedFormIndex2]
                                    .selectedFramework
                                    ? "lightgreen"
                                    : "lightcoral",
                              },
                            }}
                          />
                          <Typography variant="subtitle1" mt={2}>
                            Selected Report 2:
                          </Typography>
                          <Typography variant="body2">
                            <strong>Observation Date:</strong>{" "}
                            {new Date(
                              selectedTeacher.forms[
                                selectedFormIndex2
                              ].evaluationDate
                            ).toLocaleDateString()}
                          </Typography>
                          <Grid container spacing={2}>
                            {selectedTeacher.forms[
                              selectedFormIndex2
                            ].categories.map((categoryArray, index) => (
                              <Grid item sm={12} key={index} mt={1}>
                                {categoryArray.map((category, catIndex) => {
                                  const { value, color } = getRatingColor(
                                    category.rating
                                  );
                                  return (
                                    <Box key={catIndex} mb={2}>
                                      <Typography variant="subtitle1">
                                        <strong>{category.name}</strong>:{" "}
                                        {category.rating}
                                      </Typography>
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        mt={1}
                                      >
                                        <LinearProgress
                                          variant="determinate"
                                          value={value}
                                          sx={{
                                            height: 10,
                                            width: "100%",
                                            backgroundColor: "#e0e0e0",
                                            "& .MuiLinearProgress-bar": {
                                              backgroundColor: color,
                                            },
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  );
                                })}
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    <Typography>No reports found for this teacher.</Typography>
                  )}
                </>
              ) : (
                <Typography>Loading...</Typography>
              )}
            </Container>
          </Accordion>
        </Box>
      </Modal>
    </div>
  );
}
