import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import FacebookIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/X";
import { Modal } from "@mui/material";

function Copyright() {
  return (
    <Typography variant="body2" sx={{ color: "white", mt: 1 }}>
      {"Copyright © "}
      <Link color="white">Curriculum Edge</Link>
      &nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  // State to control modal visibility
  const [openPrivacyModal, setOpenPrivacyModal] = useState(false);
  const [openTermsModal, setOpenTermsModal] = useState(false);

  // Handlers to open/close the modals
  const handleOpenPrivacyModal = () => setOpenPrivacyModal(true);
  const handleClosePrivacyModal = () => setOpenPrivacyModal(false);
  const handleOpenTermsModal = () => setOpenTermsModal(true);
  const handleCloseTermsModal = () => setOpenTermsModal(false);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    height: 700,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "16px", // Rounded corners
    outline: "none", // No outline
    p: 4,
  };
  const scrollableTextStyle = {
    maxHeight: "500px", // Set the height of the scroll area
    overflowY: "auto", // Enable vertical scrolling
    padding: "10px",
  };

  return (
    <div style={{ backgroundColor: "#4286F0" }}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 4, sm: 8 },
          py: { xs: 3, sm: 3 },
          textAlign: { sm: "center", md: "left" },
        }}
      >
        <div>
          <Link color="white" variant="body2" onClick={handleOpenPrivacyModal}>
            Privacy Policy
          </Link>
          <Typography sx={{ display: "inline", mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="white" variant="body2" onClick={handleOpenTermsModal}>
            Terms of Service
          </Link>
          <Copyright />
        </div>
        <Stack
          direction="row"
          spacing={1}
          useFlexGap
          sx={{ justifyContent: "left", color: "white" }}
        >
          {/*}
          <IconButton
            color="inherit"
            size="small"
            href="https://github.com/mui"
            aria-label="GitHub"
            sx={{ alignSelf: "center" }}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="https://x.com/MaterialUI"
            aria-label="X"
            sx={{ alignSelf: "center" }}
          >
            <TwitterIcon />
          </IconButton>
          */}
          <IconButton
            color="inherit"
            size="small"
            href="https://www.linkedin.com/company/curriculum-edge/about/?viewAsMember=true"
            aria-label="LinkedIn"
            sx={{ alignSelf: "center" }}
          >
            <LinkedInIcon />
          </IconButton>
        </Stack>
      </Container>

      {/* Privacy Policy Modal */}
      <Modal
        open={openPrivacyModal}
        onClose={handleClosePrivacyModal}
        aria-labelledby="privacy-policy-title"
        aria-describedby="privacy-policy-description"
      >
        <Box sx={modalStyle}>
          <Typography id="privacy-policy-title" variant="h6" component="h2">
            Privacy Policy for CurriculumEdge
          </Typography>
          <Box sx={scrollableTextStyle}>
            <Typography variant="body2" component="p">
              <strong>Effective Date: [Insert Date]</strong>
              <br />
              <br />
              <strong>Introduction</strong>
              <br />
              Welcome to CurriculumEdge! At CurriculumEdge, we are committed to
              protecting your privacy and ensuring a safe and secure experience
              as you use our AI-supported applications. This Privacy Policy
              outlines the types of information we collect, how we use it, and
              the measures we take to safeguard your data. By using our
              services, you agree to the terms outlined in this Privacy Policy.
              <br />
              <br />
              <strong>Information We Collect</strong>
              <br />
              <strong>Personal Information</strong>
              <ul>
                <li>
                  Name, email address, and other contact details provided during
                  registration.
                </li>
                <li>
                  Payment information for premium services (processed securely
                  through third-party payment gateways).
                </li>
              </ul>
              <br />
              <strong>Usage Data</strong>
              <ul>
                <li>
                  Information about how you use our services, including the
                  features you access and the time spent on the platform.
                </li>
                <li>
                  Device information such as IP address, browser type, and
                  operating system.
                </li>
              </ul>
              <br />
              <strong>Generated Content</strong>
              <ul>
                <li>
                  Content created or processed using our AI applications,
                  including text, images, or other outputs.
                </li>
              </ul>
              <br />
              <strong>Cookies and Tracking Technologies</strong>
              <ul>
                <li>
                  Cookies, web beacons, and similar technologies to enhance user
                  experience, monitor website performance, and track user
                  preferences.
                </li>
              </ul>
              <br />
              <strong>AI-Generated Content and Data Processing</strong>
              <br />
              Our AI-supported applications use external language models to
              process and generate content. Please note:
              <ul>
                <li>
                  <strong>External Data Processing:</strong> Information
                  provided to or processed by the AI language model is handled
                  externally by third-party AI providers.
                </li>
                <li>
                  <strong>Private Information:</strong> Users should avoid
                  sharing private, sensitive, or personally identifiable
                  information when interacting with the AI-supported
                  applications, as CurriculumEdge cannot control how external
                  providers manage such data.
                </li>
              </ul>
              <br />
              <strong>How We Use Your Information</strong>
              <br />
              We use the information we collect to:
              <ul>
                <li>
                  Provide, operate, and improve our AI-supported applications.
                </li>
                <li>
                  Personalize user experience and recommend content based on
                  preferences.
                </li>
                <li>
                  Respond to user inquiries, provide customer support, and
                  process transactions.
                </li>
                <li>
                  Analyze website performance and usage trends to enhance
                  functionality.
                </li>
                <li>
                  Comply with legal requirements and enforce our Terms of
                  Service.
                </li>
              </ul>
              <br />
              <strong>How We Share Your Information</strong>
              <br />
              We do not sell, rent, or share your personal information with
              third parties for their direct marketing purposes. However, we may
              share your data with:
              <ul>
                <li>
                  <strong>Service Providers:</strong> Third-party vendors that
                  help us operate our website, process payments, or deliver
                  services.
                </li>
                <li>
                  <strong>Legal Compliance:</strong> Authorities when required
                  to comply with legal obligations or to protect our rights and
                  users.
                </li>
              </ul>
              <br />
              <strong>Data Security</strong>
              <br />
              We take data security seriously and implement industry-standard
              measures to protect your information from unauthorized access,
              alteration, or destruction. However, no online service is
              completely secure, and we cannot guarantee absolute security.
              <br />
              <br />
              <strong>Your Choices</strong>
              <ul>
                <li>
                  <strong>Access and Update:</strong> You may access, correct,
                  or update your personal information through your account
                  settings.
                </li>
                <li>
                  <strong>Cookies:</strong> You can adjust your browser settings
                  to refuse cookies or notify you when a cookie is being sent.
                </li>
                <li>
                  <strong>Opt-Out:</strong> You may opt out of receiving
                  promotional communications by following the instructions in
                  the emails we send.
                </li>
              </ul>
              <br />
              <strong>Children’s Privacy</strong>
              <br />
              CurriculumEdge is not intended for use by individuals under the
              age of 18. We do not knowingly collect personal information from
              children.
              <br />
              <br />
              <strong>International Users</strong>
              <br />
              By using CurriculumEdge, you acknowledge that your information may
              be processed in countries outside your own, where data protection
              laws may differ.
              <br />
              <br />
              <strong>Updates to This Privacy Policy</strong>
              <br />
              We may update this Privacy Policy from time to time. Changes will
              be effective immediately upon posting to the website, and your
              continued use of our services constitutes acceptance of the
              updated policy.
              <br />
              <br />
              <strong>Contact Us</strong>
              <br />
              If you have any questions or concerns about this Privacy Policy,
              please contact us at:
              <ul>
                <li>
                  <strong>CurriculumEdge Support Team</strong>
                </li>
                <li>[Insert Email Address]</li>
                <li>[Insert Phone Number]</li>
                <li>[Insert Physical Address]</li>
              </ul>
              <br />
              Thank you for trusting CurriculumEdge. We are committed to
              providing you with a secure and enriching experience!
            </Typography>
          </Box>

          <Button
            fullWidth
            style={{
              backgroundColor: "#4286F0",
              color: "white",
              borderRadius: 20,
            }}
            onClick={handleClosePrivacyModal}
          >
            Close
          </Button>
        </Box>
      </Modal>

      {/* Terms of Service Modal */}
      <Modal
        open={openTermsModal}
        onClose={handleCloseTermsModal}
        aria-labelledby="terms-of-service-title"
        aria-describedby="terms-of-service-description"
      >
        <Box sx={modalStyle}>
          <Typography id="terms-of-service-title" variant="h6" component="h2">
            Terms of Service
          </Typography>
          <Box sx={scrollableTextStyle}>
            <Typography variant="body2" component="p">
              The education applications provided by CurriculumEdge rely on
              Artificial Intelligence (AI) to generate content and support
              educational experiences. While our tools are designed to enhance
              teaching and learning, CurriculumEdge makes no guarantees
              regarding the accuracy, completeness, or suitability of
              AI-generated content for specific educational purposes.
              <br />
              <br />
              By using our applications, you acknowledge and accept the
              following:
              <br />
              <br />
              <strong>User Responsibility:</strong> Educators and users are
              responsible for reviewing, validating, and adapting AI-generated
              content to ensure it aligns with their curriculum objectives,
              educational standards, and institutional policies. These tools are
              intended to supplement—not replace—professional judgment and
              instructional planning.
              <br />
              <br />
              <strong>Liability Limitation:</strong> CurriculumEdge is not
              liable for any errors, omissions, or inaccuracies in the generated
              content or for any outcomes resulting from the use or reliance on
              the applications, including but not limited to instructional
              misalignment or assessment challenges.
              <br />
              <br />
              <strong>No Professional Educational Advice:</strong> The outputs
              provided by our applications are not substitutes for professional
              teaching or curriculum development advice. Educators and users
              should consider these tools as part of a broader instructional
              strategy.
              <br />
              <br />
              <strong>Dynamic Nature of AI:</strong> AI-generated content may
              reflect limitations or biases inherent in the algorithms or data
              used for training. Users are encouraged to critically evaluate all
              outputs for fairness, inclusivity, and appropriateness in diverse
              educational settings.
              <br />
              <br />
              <strong>Privacy and Security:</strong> Users must adhere to
              institutional privacy policies and applicable regulations when
              using CurriculumEdge applications, especially when handling
              sensitive or student-related data.
              <br />
              <br />
              By using these applications, you agree to release and hold
              harmless CurriculumEdge from any claims, liabilities, or damages
              arising from the use of our tools in educational settings.
              <br />
              <br />
              Note: Your use of CurriculumEdge applications constitutes
              acceptance of this disclaimer.
            </Typography>
          </Box>

          <Button
            fullWidth
            style={{
              backgroundColor: "#4286F0",
              color: "white",
              borderRadius: 20,
            }}
            onClick={handleCloseTermsModal}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
