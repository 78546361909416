import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";

import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

const tiers = [
  {
    title: "Free",
    price: "0",
    description: [
      "1 users included",
      "Help center access",
      "15 tokens a month",
    ],
    buttonText: "Sign up for free",
    buttonVariant: "outlined",
    buttonColor: "primary",
  },
  {
    title: "Basic Plan",
    price: "9.99",
    description: [
      "1 users included",
      "Unlimited usage of our quick tools",
      "Help center access",
      "Email support",
    ],
    buttonText: "Sign up for free",
    buttonVariant: "outlined",
    buttonColor: "primary",
  },
  {
    title: "Elite",
    subheader: "Recommended",
    price: "29.99",
    description: [
      "1 users included",
      "Help center access",
      "Curriculum Edge full fuit",
      "Access to training and new features",
      "Priority email support",
    ],
    buttonText: "Start now",
    buttonVariant: "contained",
    buttonColor: "#FCCC5B",
  },
  {
    title: "Enterprise",
    price: "N/A",
    description: [
      "Platform customizations for schools",
      "Discounts for full schools",
      "Everything in the other tiers ",
      "Phone & email support",
      "Unlimited AI generated reports",
    ],
    buttonText: "Contact us",
    buttonVariant: "outlined",
    buttonColor: "primary",
  },
];

const Pricing = React.forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <Container
        id="pricing"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              textTransform: "uppercase",
              color: "#f8af05",
              fontWeight: "bold",
            }}
          >
            Pricing
          </Typography>
          <Typography variant="body1" sx={{ color: "text.secondary" }}>
            We believe in providing transparent and flexible pricing options
            that cater to the diverse needs of educators and stakeholders in the
            education sector. Our plans are designed to ensure that you get the
            most value for your investment, whether you’re an individual
            teacher, a school, or an entire district. Each tier offers a range
            of features to enhance your experience and support your goals.
            Explore our pricing options below to find the perfect plan that fits
            your needs and start transforming your educational practices today!
          </Typography>
          <b>
            Each user receives 15 free tokens every month to explore and enjoy a
            variety of our AI tools.
          </b>
        </Box>
        <Grid
          container
          spacing={3}
          sx={{ alignItems: "center", justifyContent: "center", width: "100%" }}
        >
          {tiers.map((tier) => (
            <Grid
              size={{ xs: 12, sm: tier.title === "Elite" ? 12 : 6, md: 4 }}
              key={tier.title}
            >
              <Card
                sx={[
                  {
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    gap: 4,
                  },
                  tier.title === "Elite" &&
                    ((theme) => ({
                      border: "none",
                      backgroundColor: "#4286F0",
                      boxShadow: `0 8px 12px hsla(220, 20%, 42%, 0.2)`,
                    })),
                ]}
              >
                <CardContent>
                  <Box
                    sx={[
                      {
                        mb: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 2,
                      },
                      tier.title === "Elite "
                        ? { color: "grey.100" }
                        : { color: "" },
                    ]}
                  >
                    <Typography component="h3" variant="h6">
                      {tier.title}
                    </Typography>
                    {tier.title === "Elite" && (
                      <Chip icon={<AutoAwesomeIcon />} label={tier.subheader} />
                    )}
                  </Box>
                  <Box
                    sx={[
                      {
                        display: "flex",
                        alignItems: "baseline",
                      },
                      tier.title === "Elite"
                        ? { color: "grey.50" }
                        : { color: null },
                    ]}
                  >
                    {tier.title !== "Enterprise" && (
                      <>
                        <Typography component="h3" variant="h2">
                          ${tier.price}
                        </Typography>
                        <Typography component="h3" variant="h6">
                          &nbsp; per month
                        </Typography>
                      </>
                    )}
                  </Box>
                  <Divider
                    sx={{ my: 2, opacity: 0.8, borderColor: "divider" }}
                  />
                  {tier.description.map((line) => (
                    <Box
                      key={line}
                      sx={{
                        py: 1,
                        display: "flex",

                        alignItems: "center",
                      }}
                    >
                      <CheckCircleRoundedIcon
                        sx={[
                          {
                            width: 20,
                          },
                          tier.title === "Elite"
                            ? { color: "#FCCC5B" }
                            : { color: "#4286F0" },
                        ]}
                      />
                      <Typography
                        variant="subtitle2"
                        component={"span"}
                        sx={[
                          tier.title === "Elite"
                            ? { color: "grey.50" }
                            : { color: null },
                        ]}
                      >
                        {line}
                      </Typography>
                    </Box>
                  ))}
                </CardContent>
                <CardActions>
                  <Button
                    href="/signUp"
                    fullWidth
                    sx={{
                      backgroundColor:
                        tier.title === "Elite" ? "#FCCC5B" : "null", // Yellow background for Professional
                      color: tier.title === "Elite " ? "grey" : null, // Set text color to black for contrast
                      "&:hover": {
                        backgroundColor:
                          tier.title === "Elite" ? "#F0B72E" : null, // Slightly darker yellow on hover
                      },
                    }}
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
});
export default Pricing;
