import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import { School, AutoAwesome, AccessTime, Help } from "@mui/icons-material";
import { useTheme } from "@mui/system";

const whiteLogos = [
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628e8573c43893fe0ace_Sydney-white.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d520d0517ae8e8ddf13_Bern-white.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f46794c159024c1af6d44_Montreal-white.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e891fa22f89efd7477a_TerraLight.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a09d1f6337b1dfed14ab_colorado-white.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5caa77bf7d69fb78792e_Ankara-white.svg",
];

const darkLogos = [
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628889c3bdf1129952dc_Sydney-black.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d4d8b829a89976a419c_Bern-black.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f467502f091ccb929529d_Montreal-black.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e911fa22f2203d7514c_TerraDark.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a0990f3717787fd49245_colorado-black.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5ca4e548b0deb1041c33_Ankara-black.svg",
];

const logoStyle = {
  width: "64px",
  opacity: 0.3,
};

function FrostedGlassCard({ icon, title, subtitle, bgColor }) {
  return (
    <Card
      sx={{
        backdropFilter: "blur(10px)",
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)",
        borderRadius: 2,
        transition: "transform 0.3s ease",
        "&:hover": {
          transform: "scale(1.05)",
        },
        width: 250,
      }}
    >
      <CardContent>
        <Box
          sx={{
            backgroundColor: bgColor,
            width: 50,
            height: 50,
            borderRadius: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: `0 4px 12px ${bgColor}`,
            mb: 2,
          }}
        >
          {icon}
        </Box>
        <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
          {title}
        </Typography>
        <Typography variant="subtitle1" sx={{ opacity: 0.8 }}>
          {subtitle}
        </Typography>
      </CardContent>
    </Card>
  );
}

const WhyChooseUs = React.forwardRef((props, ref) => {
  const theme = useTheme();
  const logos = theme.palette.mode === "light" ? darkLogos : whiteLogos;

  return (
    <div ref={ref}>
      <Container
        id="testimonials"
        sx={{
          pt: { xs: 4, sm: 12 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
          width: "100%", // Ensure full width
          maxWidth: "100%", // Allow it to stretch across all screen sizes
        }}
      >
        <Box
          sx={{
            width: "100%", // Full width on all screen sizes
            textAlign: "center", // Center text on mobile and desktop
          }}
        >
          <Typography
            variant="h4" // You can adjust the variant as needed
            sx={{
              textTransform: "uppercase", // Makes the text all caps
              color: "#f8af05",
              fontWeight: "bold", // Optional: make the text bold
            }}
          >
            Why Choose Us
          </Typography>
          <Typography
            variant="h2"
            sx={{
              color: "#4286F0",
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              fontSize: "30px",
              justifyContent: "center", // Center text content
            }}
          >
            AI-powered tools to save you time and enhance your teaching. With
            research-based starters to maximize critical thinking.
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1, padding: 2 }}>
          <Grid
            container
            spacing={2}
            justifyContent="center" // Center the cards
            alignItems="center" // Center the items within the grid
          >
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <FrostedGlassCard
                icon={<School sx={{ color: "white", fontSize: 30 }} />}
                title="Enhance productivity"
                subtitle="Automate repetitive tasks."
                bgColor="#4286F0"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <FrostedGlassCard
                icon={<AutoAwesome sx={{ color: "white", fontSize: 30 }} />}
                title="Empower your lesson"
                subtitle="Empower your lesson with AI."
                bgColor="#FF6B6B"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <FrostedGlassCard
                icon={<AccessTime sx={{ color: "white", fontSize: 30 }} />}
                title="Personalized learning"
                subtitle="Customize learning activities."
                bgColor="#4CAF50"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <FrostedGlassCard
                icon={<Help sx={{ color: "white", fontSize: 30 }} />}
                title="Support"
                subtitle="24/7 assistance for teachers."
                bgColor="#FFC107"
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
});
export default WhyChooseUs;
