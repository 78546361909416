import React, { useState, useEffect } from "react";
import axios from "axios";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import Cookies from "js-cookie"; // Import Cookies for handling cookies
export default function CardAlert() {
  const [tokensRemaining, setTokensRemaining] = useState(null);
  const [planType, setPlanType] = useState(""); // For storing the plan type
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTokenData = async () => {
      try {
        setIsLoading(true);
        setError(null);

        const email = Cookies.get("email"); // Replace with dynamic user email
        const response = await axios.get(
          "https://eduevaluator.com/api/users/tokenCount",
          {
            params: { email },
          }
        );

        const { message, tokenCounter } = response.data;

        if (message === "Basic Plan" || message === "Elite Plan") {
          setPlanType(message); // Store plan type
          setTokensRemaining(null); // Hide tokens
        } else {
          setPlanType(""); // Reset plan type
          setTokensRemaining(tokenCounter ?? 0); // Show tokens
        }
      } catch (err) {
        setError("Failed to fetch token count.");
        console.error("Error fetching token data:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTokenData();
  }, []);

  return (
    <Card variant="outlined" sx={{ m: 1.5, p: 1.5 }}>
      <CardContent>
        <AutoAwesomeRoundedIcon fontSize="small" />
        <Typography gutterBottom sx={{ fontWeight: 600 }}>
          Track Your Plan
        </Typography>
        <Typography variant="body2" sx={{ mb: 2, color: "text.secondary" }}>
          Enjoy 15 free credits a month
        </Typography>
        {error && (
          <Typography
            variant="body2"
            color="error"
            sx={{ mb: 2, textAlign: "center" }}
          >
            {error}
          </Typography>
        )}
        <Button
          fullWidth
          style={{
            backgroundColor:
              tokensRemaining === 0 || tokensRemaining === null
                ? "#ccc"
                : "#4286F0",
            color: "white",
            borderRadius: 20,
          }}
          disabled={tokensRemaining === 0 || isLoading}
        >
          {isLoading
            ? "Loading..."
            : error
            ? "Error loading data"
            : planType
            ? planType // Only display the plan type
            : `Tokens Remaining: ${tokensRemaining}`}
        </Button>
      </CardContent>
    </Card>
  );
}
