import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  Chip,
  MenuItem,
  Select,
  Paper,
  TextareaAutosize,
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
  Checkbox,
  ListItemText,
} from "@mui/material";

import OpenAI from "openai";
import ContentCopyIcon from "@mui/icons-material/ContentCopy"; // Import the clipboard icon
import SchoolIcon from "@mui/icons-material/School";
import AssistantIcon from "@mui/icons-material/Assistant";
import { useDropzone } from "react-dropzone";
import LoadingModal from "./LoadingModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import axios from "axios";
import Cookies from "js-cookie";
function AiCourseCondesnorComponent() {
  // State variables
  const [selectedFiles, setSelectedFiles] = useState([]); // Store uploaded files
  const [activeStep, setActiveStep] = useState(0);
  const [teacherFirstName, setTeacherFirstName] = useState("");
  const [teacherLastName, setTeacherLastName] = useState("");
  const [subject, setSubject] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [timeToCondense, setTimeToCondense] = useState("");
  const [subjectDifficulty, setSubjectDifficulty] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [techniques, setTechniques] = useState([]);
  const [loading, setLoading] = useState(false); // State for loading
  const [modalOpen, setModalOpen] = useState(false); // State for modal
  const [summary, setSummary] = useState(""); // State for API response
  const [topic, setTopic] = useState(""); // State for API response
  const [weeksToCondense, setWeeksToCondense] = useState("");
  // File upload handling
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setSelectedFiles(acceptedFiles);
      handleFileUpload(acceptedFiles);
    },
  });

  // Mock handler functions
  const handleTeacherFirstNameChange = (e) =>
    setTeacherFirstName(e.target.value);
  const handleTeacherLastNameChange = (e) => setTeacherLastName(e.target.value);
  const handleSubjectChange = (e) => setSubject(e.target.value);
  const handleGradeLevelChange = (e) => setGradeLevel(e.target.value);
  const handleTimeToCondense = (e) => setTimeToCondense(e.target.value);
  const handleSubjectDifficultyChange = (e) =>
    setSubjectDifficulty(e.target.value);
  const handleLocationChange = (e) => setLocation(e.target.value);
  const handleDescriptionChange = (e) => setDescription(e.target.value);
  const saveDraft = () => alert("Draft saved!");
  const handleChange = (event) => {
    const selectedValues = event.target.value;

    // If there are more than 3 selections, do not allow further selections
    if (techniques.length <= 1) {
      setTechniques(selectedValues);
    }
  };

  const onDrop = (acceptedFiles) => {
    setSelectedFiles(acceptedFiles);
    handleFileUpload(acceptedFiles);
  };

  const handleFileUpload = async (files) => {
    const formData = new FormData();
    formData.append("file", files[0]); // Assuming single file upload at a time

    try {
      const response = await axios.post(
        "https://eduevaluator.com/api/s3/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        const uploadedFile = response.data.s3FilePath;
        setUploadedFiles((prev) => {
          const updatedFiles = [...prev, uploadedFile];
          console.log("Updated Files: ", updatedFiles); // Log the correct updated state here
          return updatedFiles;
        });
      }
    } catch (error) {
      console.error("File upload failed:", error);
    }
  };

  // Function to log all form data
  // Function to log all form data
  const handleSubmit = async () => {
    const formData = {
      subject,
      gradeLevel,
      timeToCondense,
      subjectDifficulty,
      location,
      description,
    };
    console.log("Form Data:", formData);

    setLoading(true); // Start loading

    try {
      const email = Cookies.get("email");
      const content = `Please condense my course that is ${subject} : ${topic} to ${weeksToCondense}: The course details are ${description} . Implement these selected Strategies ${techniques}`;
      const tokenResponse = await axios.post(
        "https://eduevaluator.com/api/users/decreaseToken",
        { email, content }
      );
      if (tokenResponse.data.message === "OpenAI response generated") {
        const summaries = tokenResponse.data.summary;
        setSummary(summaries); // Save summary for modal
        setModalOpen(true); // Open modal
      } else {
        toast.error("You are out of credits");
      }
    } catch (error) {
      console.error("Error while fetching completion:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(summary)
      .then(() => {
        toast.success("Text copied to clipboard!"); // Optional: Show a message to the user
      })
      .catch((error) => {
        toast.error("Could not copy text: ", error);
      });
  };

  const timeToCondenseOptions = ["14 weeks", "8 weeks", "3 weeks"];

  const options = [
    "Define core objectives",
    "Use a modular approach",
    "Integrate technology for efficiency",
    "Combine synchronous and asynchronous learning",
    "Adopt flipped learning.",
    "Streamline assessments.",
    "Encourage collaborative learning.",
    "Implement microlearning techniques.",
    "Use data to identify and eliminate underperforming content.",
    "Focus on real-world applications and case studies.",
    "Leverage adaptive learning technologies.",
    "Consolidate similar topics or concepts.",
  ];

  const subjectOptions = [
    "Math",
    "Science",
    "English",
    "History",
    "Art",
    "Music",
    // Add more subjects as needed
  ];

  const subjectDifficultyOptions = [
    "Beginner",
    "Intermediate",
    "Advanced",
    "Expert",
  ];
  const gradeOptions = [
    "1st Grade",
    "2nd Grade",
    "3rd Grade",
    "4th Grade",
    "5th Grade",
    "6th Grade",
    "7th Grade",
    "8th Grade",
    "9th Grade",
    "10th Grade",
    "11th Grade",
    "12th Grade",
  ];

  return (
    <Paper elevation={3} sx={{ padding: 3, borderRadius: 2, width: "100%" }}>
      <ToastContainer />
      <LoadingModal loading={loading} message="Generating response..." />{" "}
      {/* Render loading modal */}
      <Container sx={{ width: "100%", maxWidth: "1200px" }}>
        <Box sx={{ marginTop: 3 }}>
          <Accordion
            expanded={true}
            sx={{
              width: "100%",
              backgroundColor: "white",
              color: "black",
              marginBottom: 2,
              borderRadius: 2,
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              "&:before": { display: "none" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#e0e0e0",
                padding: "16px",
                borderRadius: "4px 4px 0 0",
                color: "black",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              <AssistantIcon sx={{ marginRight: 1 }} />
              FlexiPace Course Condenser
            </Box>
            <Container>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  marginTop: 4,
                  marginBottom: 4,
                  backgroundColor: "#f0f0f0",
                  padding: "16px",
                  borderRadius: "8px",
                }}
              >
                <Chip
                  label="What does this do?"
                  sx={{
                    backgroundColor: "#1976d2", // Blue color
                    color: "#ffffff", // This sets the text color to white
                    "& .MuiChip-label": {
                      color: "#ffffff", // Ensures the label is white
                    },
                    marginRight: "16px",
                  }}
                />
                <Typography variant="body1">
                  FlexiPace Course Condenser is an advanced GPT tool designed to
                  adjust course durations by condensing materials into shorter
                  formats or expanding them for in-depth study.
                </Typography>
              </Box>

              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Subject</FormLabel>
                    <TextField
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      variant="outlined"
                      sx={{ borderRadius: 2 }}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Course</FormLabel>
                    <TextField
                      value={topic}
                      onChange={(e) => setTopic(e.target.value)}
                      variant="outlined"
                      sx={{ borderRadius: 2 }}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel> Weeks to condense to</FormLabel>
                    <Select
                      value={weeksToCondense}
                      onChange={(e) => setWeeksToCondense(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Subject" }}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    >
                      <MenuItem value="" disabled>
                        Weeks to condense to
                      </MenuItem>
                      {["3 weeks", "8 weeks", "16 weeks", "24 weeks"].map(
                        (option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Select Strategies</FormLabel>
                    <Select
                      multiple
                      value={techniques}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Subject" }}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    >
                      <MenuItem value="" disabled>
                        Select a maximum of 2
                      </MenuItem>
                      {options.map((option) => (
                        <MenuItem key={option} value={option}>
                          <Checkbox checked={techniques.indexOf(option) > -1} />
                          <ListItemText primary={option} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth style={{ marginTop: 7 }}>
                    <FormLabel>Course Content</FormLabel>
                    <TextareaAutosize
                      minRows={4} // Minimum number of rows
                      value={description}
                      onChange={handleDescriptionChange}
                      style={{
                        backgroundColor: "white",
                        borderRadius: 4,
                        padding: 8,
                        width: "100%",
                        border: "1px solid #ccc", // Optional: Add border styling
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Box>
                {uploadedFiles.map((file, index) => (
                  <Chip
                    key={index}
                    label={file}
                    onDelete={() => {
                      setUploadedFiles(
                        uploadedFiles.filter((_, i) => i !== index)
                      );
                    }}
                    sx={{ margin: 1 }}
                  />
                ))}
              </Box>

              <Button
                type="button" // Change this to "button" instead of "submit"
                onClick={handleSubmit} // Call handleSubmit on click
                style={{
                  marginTop: 10,
                  width: 100,
                  backgroundColor: "#4286F0",
                  color: "white",
                  borderRadius: 20,
                }}
              >
                Ask AI
              </Button>
            </Container>
          </Accordion>
        </Box>
        {/* Modal for displaying results */}
        <Dialog
          open={modalOpen}
          onClose={() => {}} // Disable closing by clicking outside
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>AI Response</DialogTitle>
          <DialogContent>
            <pre>{summary}</pre>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCopyToClipboard}
              startIcon={<ContentCopyIcon />}
            />
            <Button onClick={() => setModalOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Paper>
  );
}

export default AiCourseCondesnorComponent;
