import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  Chip,
  MenuItem,
  Select,
  Paper,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import OpenAI from "openai";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AssistantIcon from "@mui/icons-material/Assistant";
import { useDropzone } from "react-dropzone";
import LoadingModal from "./LoadingModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import axios from "axios";
function VisualPromptWizardForm() {
  // State variables
  const [imageStyle, setImageStyle] = useState("");
  const [imageOr, setImageOr] = useState("");
  const [additionalQuestion, setAdditionalQuestion] = useState("");
  const [summary, setSummary] = useState(""); // State for API response
  const [modalOpen, setModalOpen] = useState(false); // State for modal
  const [loading, setLoading] = useState(false); // State for loading
  const [imageUrl, setImageUrl] = useState(""); // State for the generated image URL
  const handleSubmit = async () => {
    setLoading(true); // Start loading
    try {
      const email = Cookies.get("email");
      const content = `Generate an image with style: ${imageStyle} and description: ${additionalQuestion}`;
      const tokenResponse = await axios.post(
        "https://eduevaluator.com/api/users/decreaseToken-images",
        { email, content }
      );
      if (tokenResponse.data.message === "OpenAI response generated") {
        const summaries = tokenResponse.data.summary;
        setImageUrl(summaries); // Save summary for modal
        setModalOpen(true); // Open modal
      } else {
        toast.error("You are out of credits");
      }
    } catch (error) {
      console.error("Error while fetching completion:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Function to parse the image URL from the response text
  const parseImageUrl = (responseText) => {
    const urlPattern = /(https?:\/\/[^\s]+)/g; // Regex pattern to find URLs
    const matches = responseText.match(urlPattern);
    return matches ? matches[0] : null; // Return the first match or null
  };
  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(summary)
      .then(() => {
        toast.success("Text copied to clipboard!"); // Optional: Show a message to the user
      })
      .catch((error) => {
        toast.error("Could not copy text: ", error);
      });
  };

  return (
    <Paper elevation={3} sx={{ padding: 3, borderRadius: 2, width: "100%" }}>
      <ToastContainer />
      <LoadingModal loading={loading} message="Generating response..." />
      <Container sx={{ width: "100%", maxWidth: "1200px" }}>
        <Box sx={{ marginTop: 3 }}>
          <Accordion
            expanded={true}
            sx={{
              width: "100%",
              backgroundColor: "white",
              color: "black",
              marginBottom: 2,
              borderRadius: 2,
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              "&:before": { display: "none" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#e0e0e0",
                padding: "16px",
                borderRadius: "4px 4px 0 0",
                color: "black",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              <AssistantIcon sx={{ marginRight: 1 }} />
              Visual Prompt Wizard
            </Box>
            <Container>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  marginTop: 4,
                  marginBottom: 4,
                  backgroundColor: "#f0f0f0",
                  padding: "16px",
                  borderRadius: "8px",
                }}
              >
                <Chip
                  label="What does this do?"
                  sx={{
                    backgroundColor: "#1976d2", // Blue color
                    color: "#ffffff", // This sets the text color to white
                    "& .MuiChip-label": {
                      color: "#ffffff", // Ensures the label is white
                    },
                    marginRight: "16px",
                  }}
                />
                <Typography variant="body1">
                  I create images for class resources.
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Style of Image</FormLabel>
                    <Select
                      value={imageStyle}
                      onChange={(e) => setImageStyle(e.target.value)}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Style of Image
                      </MenuItem>
                      {["Realistic", "Illustrative", "Minimalist"].map(
                        (option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Image Orientation</FormLabel>
                    <Select
                      value={imageOr}
                      onChange={(e) => setImageOr(e.target.value)}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Image Orientation
                      </MenuItem>
                      {["Horizontal", "Vertical"].map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Additional Details</FormLabel>
                    <textarea
                      rows={4}
                      value={additionalQuestion}
                      onChange={(e) => setAdditionalQuestion(e.target.value)}
                      style={{
                        width: "100%",
                        borderRadius: "8px",
                        padding: "10px",
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Button
                onClick={handleSubmit}
                style={{
                  marginTop: 10,
                  width: 100,
                  backgroundColor: "#4286F0",
                  color: "white",
                  borderRadius: 20,
                }}
              >
                Ask AI
              </Button>
            </Container>
          </Accordion>
        </Box>

        {/* Modal for displaying results */}
        <Dialog
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>AI Response</DialogTitle>
          <DialogContent>
            <pre>{summary}</pre>
            {imageUrl && (
              <img
                src={imageUrl}
                alt="Generated"
                style={{ width: "100%", height: "auto", marginTop: 20 }}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModalOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Paper>
  );
}

export default VisualPromptWizardForm;
