import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  Chip,
  MenuItem,
  Select,
  Paper,
  TextareaAutosize,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import OpenAI from "openai";
import AssistantIcon from "@mui/icons-material/Assistant";
import { useDropzone } from "react-dropzone";
import LoadingModal from "./LoadingModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import axios from "axios";
function AssistiveTechCompanionForm() {
  // State variables
  const [activeStep, setActiveStep] = useState(0);
  const [teacherFirstName, setTeacherFirstName] = useState("");
  const [teacherLastName, setTeacherLastName] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [courseAssessments, setCourseAssessments] = useState("");
  const [instructionType, setInstructionType] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false); // State for loading
  const [modalOpen, setModalOpen] = useState(false); // State for modal
  const [summary, setSummary] = useState(""); // State for API response
  const [subject, setSubject] = useState("");
  const [skills, setSkills] = useState("");
  const [learningStyle, setLearningStyle] = useState("");
  const [tools, setTools] = useState("");
  const [accessibilityNeeds, setAccessibilityNeeds] = useState("");
  const [planType, setPlanType] = useState("");
  const [goalTimeframe, setGoalTimeframe] = useState("");
  const [learningType, setLearningType] = useState("");
  const [realLifeApplication, setRealLifeApplication] = useState("");
  const [additionalDetails, setAdditionalDetails] = useState("");
  // File upload handling
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const fileURLs = acceptedFiles.map((file) => URL.createObjectURL(file));
      setUploadedFiles((prevFiles) => [...prevFiles, ...fileURLs]);
    },
  });

  // Mock handler functions
  const handleSubjectChange = (e) => setSubject(e.target.value);
  const handleGradeLevelChange = (e) => setGradeLevel(e.target.value);
  const handleCourseAssessmentsChange = (e) =>
    setCourseAssessments(e.target.value);
  const handleInstructionTypeChange = (e) => setInstructionType(e.target.value);
  const handleLocationChange = (e) => setLocation(e.target.value);
  const handleDescriptionChange = (e) => setDescription(e.target.value);
  const saveDraft = () => alert("Draft saved!");
  const handleSubmit = async () => {
    const formData = {
      subject,
      skills,
      learningStyle,
      tools,
      accessibilityNeeds,
      planType,
      goalTimeframe,
      learningType,
      realLifeApplication,
      additionalDetails,
    };
    console.log("Form Data:", formData);

    setLoading(true); // Start loading

    try {
      const email = Cookies.get("email");
      const content = `For the subject of ${
        formData.subject
      }, with a focus on ${
        formData.skills
      }, I would like assistance in creating a learning resources. The content should be accessible for ${
        formData.learningStyle
      } learners, using tools like ${
        formData.tools
      }, and accommodating accessibility needs such as ${
        formData.accessibilityNeeds
      }. The lesson plan should be structured as a ${
        formData.planType
      } and geared towards ${
        formData.goalTimeframe
      } goals. Additionally, I prefer a ${
        formData.learningType
      } approach, with real-life application scenarios set to ${
        formData.realLifeApplication ? "Yes" : "No"
      }. Please consider these additional details: ${
        formData.additionalDetails
      }.`;
      const tokenResponse = await axios.post(
        "https://eduevaluator.com/api/users/decreaseToken",
        { email, content }
      );

      if (tokenResponse.data.message === "OpenAI response generated") {
        const summaries = tokenResponse.data.summary;
        setSummary(summaries); // Save summary for modal
        setModalOpen(true); // Open modal
      } else {
        toast.error("You are out of credits");
      }
    } catch (error) {
      console.error("Error while fetching completion:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(summary)
      .then(() => {
        toast.success("Text copied to clipboard!"); // Optional: Show a message to the user
      })
      .catch((error) => {
        toast.error("Could not copy text: ", error);
      });
  };

  const subjectOptions = [
    "Math",
    "Science",
    "English",
    "History",
    "Art",
    "Music",
    // Add more subjects as needed
  ];

  const gradeOptions = [
    "1st Grade",
    "2nd Grade",
    "3rd Grade",
    "4th Grade",
    "5th Grade",
    "6th Grade",
    "7th Grade",
    "8th Grade",
    "9th Grade",
    "10th Grade",
    "11th Grade",
    "12th Grade",
  ];

  const assessmentOptions = [
    "1 Assessment",
    "2 Assessments",
    "3 Assessments",
    "4 Assessments",
    "5 Assessments",
  ];

  const instructionTypeOptions = ["Online", "In-Person"];

  return (
    <Paper elevation={3} sx={{ padding: 3, borderRadius: 2, width: "100%" }}>
      <ToastContainer />
      <LoadingModal loading={loading} message="Generating response..." />
      <Container sx={{ width: "100%", maxWidth: "1200px" }}>
        <Box sx={{ marginTop: 3 }}>
          <Accordion
            expanded={true}
            sx={{
              width: "100%",
              backgroundColor: "white",
              color: "black",
              marginBottom: 2,
              borderRadius: 2,
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              "&:before": { display: "none" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#e0e0e0",
                padding: "16px",
                borderRadius: "4px 4px 0 0",
                color: "black",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              <AssistantIcon sx={{ marginRight: 1 }} />
              Assistive Tech Companion
            </Box>
            <Box
              display="flex"
              alignItems="center"
              sx={{
                marginTop: 4,
                marginBottom: 4,
                backgroundColor: "#f0f0f0",
                padding: "16px",
                borderRadius: "8px",
              }}
            >
              <Chip
                label="What does this do?"
                sx={{
                  backgroundColor: "#1976d2", // Blue color
                  color: "#ffffff", // This sets the text color to white
                  "& .MuiChip-label": {
                    color: "#ffffff", // Ensures the label is white
                  },
                  marginRight: "16px",
                }}
              />
              <Typography variant="body1">
                I make recommendations for the implementation of assistive
                technology in lessons
              </Typography>
            </Box>

            <Container>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Subject</FormLabel>
                    <TextField
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      variant="outlined"
                      sx={{ borderRadius: 2 }}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Specific Skills or Concepts</FormLabel>
                    <TextField
                      value={skills}
                      onChange={(e) => setSkills(e.target.value)}
                      variant="outlined"
                      sx={{ borderRadius: 2 }}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Learning Styles</FormLabel>
                    <Select
                      value={learningStyle}
                      onChange={(e) => setLearningStyle(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Learning Style" }}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    >
                      <MenuItem value="" disabled>
                        Select Learning Style
                      </MenuItem>
                      {[
                        "Visual",
                        "Text-based explanations",
                        "Audio",
                        "Interactive exercises",
                      ].map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Tools or Applications</FormLabel>
                    <Select
                      value={tools}
                      onChange={(e) => setTools(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Tools" }}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    >
                      <MenuItem value="" disabled>
                        Select Tool
                      </MenuItem>
                      {["Google Docs", "PDFs", "Quizlet", "Screen Readers"].map(
                        (option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Accessibility Needs</FormLabel>
                    <Select
                      value={accessibilityNeeds}
                      onChange={(e) => setAccessibilityNeeds(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Accessibility Needs" }}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    >
                      <MenuItem value="" disabled>
                        Select Accessibility Need
                      </MenuItem>
                      {[
                        "Easy-to-read fonts",
                        "Text-to-speech support",
                        "Sensory-friendly materials",
                        "Simplified language",
                      ].map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Type of Plan</FormLabel>
                    <Select
                      value={planType}
                      onChange={(e) => setPlanType(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Plan Type" }}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    >
                      <MenuItem value="" disabled>
                        Select Plan Type
                      </MenuItem>
                      {[
                        "Daily schedule",
                        "Checklist",
                        "Flexible resources",
                      ].map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Goal Timeframe</FormLabel>
                    <Select
                      value={goalTimeframe}
                      onChange={(e) => setGoalTimeframe(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Goal Timeframe" }}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    >
                      <MenuItem value="" disabled>
                        Select Goal Timeframe
                      </MenuItem>
                      {["Short-term objectives", "Long-term objective"].map(
                        (option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Learning Type</FormLabel>
                    <Select
                      value={learningType}
                      onChange={(e) => setLearningType(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Learning Type" }}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    >
                      <MenuItem value="" disabled>
                        Select Learning Type
                      </MenuItem>
                      {["Gamified Learning", "Traditional Approach"].map(
                        (option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Real-Life Applications</FormLabel>
                    <Select
                      value={realLifeApplication}
                      onChange={(e) => setRealLifeApplication(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Real-Life Applications" }}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    >
                      <MenuItem value="" disabled>
                        Select Real-Life Application
                      </MenuItem>
                      {["Yes", "No"].map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Additional Details</FormLabel>
                    <TextareaAutosize
                      minRows={3}
                      value={additionalDetails}
                      onChange={(e) => setAdditionalDetails(e.target.value)}
                      style={{
                        width: "100%",
                        borderRadius: "4px",
                        padding: "8px",
                        backgroundColor: "white",
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Container>

            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button
                    type="button" // Change this to "button" instead of "submit"
                    onClick={handleSubmit} // Call handleSubmit on click
                    style={{
                      marginTop: 10,
                      width: 100,
                      backgroundColor: "#4286F0",
                      color: "white",
                      borderRadius: 20,
                    }}
                  >
                    Ask AI
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Dialog
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>Lesson Plan Summary</DialogTitle>
          <DialogContent>
            <pre>{summary}</pre>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCopyToClipboard}>Copy to Clipboard</Button>
            <Button onClick={() => setModalOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Paper>
  );
}

export default AssistiveTechCompanionForm;
