import React, { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Cookies from "js-cookie";
import Modal from "@mui/material/Modal";
import Checkbox from "@mui/material/Checkbox";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import logo from "../Images/logo.png";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { Select, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MuiCard from "@mui/material/Card";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import getSignInSideTheme from "../theme/getSignInSideTheme";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import toastify CSS
import {
  GoogleIcon,
  FacebookIcon,
  SitemarkIcon,
} from "../components/CustomIcons";
import TemplateFrame from "../TemplateFrame";

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: "auto",
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  [theme.breakpoints.up("sm")]: {
    width: "450px",
  },
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  height: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "16px", // Rounded corners
  outline: "none", // No outline
  p: 4,
};

const scrollableTextStyle = {
  maxHeight: "500px", // Set the height of the scroll area
  overflowY: "auto", // Enable vertical scrolling
  padding: "10px",
};

const SignUpContainer = styled(Stack)(({ theme }) => ({
  height: "100%",
  padding: 4,
  backgroundImage:
    "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
  backgroundRepeat: "no-repeat",
  ...theme.applyStyles("dark", {
    backgroundImage:
      "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))",
  }),
}));

export default function SignUp() {
  const { login } = useContext(AuthContext);
  const [mode, setMode] = React.useState("light");
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const defaultTheme = createTheme({ palette: { mode } });
  const SignUpTheme = createTheme(getSignInSideTheme(mode));
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState("");
  const [referralCode, setReferralCode] = React.useState("");
  const [role, setRole] = React.useState("");
  const [isTermsChecked, setIsTermsChecked] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState("");
  const [nameError, setNameError] = React.useState(false);
  const [nameErrorMessage, setNameErrorMessage] = React.useState("");
  const navigate = useNavigate();
  // This code only runs on the client side, to determine the system color preference
  React.useEffect(() => {
    // Check if there is a preferred mode in localStorage
    const savedMode = localStorage.getItem("themeMode");
    if (savedMode) {
      setMode(savedMode);
    } else {
      // If no preference is found, it uses system preference
      const systemPrefersDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      setMode("light");
    }
  }, []);

  const toggleColorMode = () => {
    const newMode = mode === "dark" ? "light" : "dark";
    setMode(newMode);
    localStorage.setItem("themeMode", newMode); // Save the selected mode to localStorage
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  const validateInputs = () => {
    const email = document.getElementById("email");
    const password = document.getElementById("password");
    const name = document.getElementById("name");

    let isValid = true;

    if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
      setEmailError(true);
      setEmailErrorMessage("Please enter a valid email address.");
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage("");
    }

    if (!isTermsChecked) {
      isValid = false;
      toast.error("You must agree to the terms and conditions.");
      return false;
    }

    if (!password.value || password.value.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage("Password must be at least 6 characters long.");
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage("");
    }

    if (!name.value || name.value.length < 1) {
      setNameError(true);
      setNameErrorMessage("Name is required.");
      isValid = false;
    } else {
      setNameError(false);
      setNameErrorMessage("");
    }

    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateInputs()) {
      return;
    }

    const data = new FormData(event.currentTarget);
    console.log(data.get("referralCode"));
    const userData = {
      name: data.get("name"),
      email: data.get("email"),
      password: data.get("password"),
      role: data.get("role"),
      referralCode: data.get("referralCode"),
    };

    try {
      const response = await fetch(
        "https://eduevaluator.com/api/users/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to register");
      }

      const result = await response.json();
      // Set the names
      Cookies.set("name", result.user.name);
      Cookies.set("email", result.user.email);
      Cookies.set("id", result.user.id);
      navigate("/verify");
      login(result.token); // Use the login function from AuthContext to store the token
    } catch (error) {
      console.error("Error during registration:", error);
      // Handle error display here if needed
    }
  };

  return (
    <Card variant="outlined">
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <Box
          component="img"
          sx={{
            height: 100, // Restrict the height to avoid enlarging the AppBar
            maxHeight: "100%", // Ensure the logo doesn't exceed the AppBar height
            maxWidth: "150px", // Maintain aspect ratio with a max width
            objectFit: "contain", // Make sure the image is scaled correctly
          }}
          alt="Logo"
          src={logo}
        />
      </Box>
      <Typography
        component="h1"
        variant="h4"
        sx={{
          width: "100%",
          fontSize: "clamp(2rem, 10vw, 2.15rem)",
          color: "#4286F0",
        }}
      >
        Sign up
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ display: "flex", flexDirection: "column", gap: 2 }}
      >
        <FormControl>
          <FormLabel htmlFor="name">Full name</FormLabel>
          <TextField
            autoComplete="name"
            name="name"
            required
            fullWidth
            id="name"
            placeholder="Jon Snow"
            error={nameError}
            helperText={nameErrorMessage}
            color={nameError ? "error" : "primary"}
          />
        </FormControl>
        <FormControl fullWidth>
          <FormLabel>What is your role?</FormLabel>
          <Select
            value={role}
            id="role"
            name="role"
            onChange={(e) => setRole(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Prompt Options" }}
            sx={{ backgroundColor: "white", borderRadius: 2 }}
          >
            <MenuItem value="" disabled>
              Select a role
            </MenuItem>
            <MenuItem value="Administrator">Administrator</MenuItem>
            <MenuItem value="Teacher">Teacher</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="email">Email</FormLabel>
          <TextField
            required
            fullWidth
            id="email"
            placeholder="your@email.com"
            name="email"
            autoComplete="email"
            variant="outlined"
            error={emailError}
            helperText={emailErrorMessage}
            color={passwordError ? "error" : "primary"}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="password">Password</FormLabel>
          <TextField
            required
            fullWidth
            name="password"
            placeholder="••••••"
            type="password"
            id="password"
            autoComplete="new-password"
            variant="outlined"
            error={passwordError}
            helperText={passwordErrorMessage}
            color={passwordError ? "error" : "primary"}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="referralCode">Referral Code</FormLabel>
          <TextField
            fullWidth
            id="referralCode"
            name="referralCode"
            variant="outlined"
          />
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={isTermsChecked}
              onChange={(e) => setIsTermsChecked(e.target.checked)}
              color="primary"
              required
            />
          }
          label={
            <Typography
              component="span"
              onClick={() => setOpenModal(true)}
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
                color: "primary.main",
              }}
            >
              I agree to terms and conditions
            </Typography>
          }
        />

        <Button
          type="submit"
          fullWidth
          style={{
            backgroundColor: "#4286F0",
            color: "white",
            borderRadius: 20,
          }}
          onClick={validateInputs}
        >
          Sign up
        </Button>
        <Typography sx={{ textAlign: "center" }}>
          Already have an account?{" "}
          <span>
            <Link href="/signIn" variant="body2" sx={{ alignSelf: "center" }}>
              Sign in
            </Link>
          </span>
        </Typography>
      </Box>
      {/* 
              <Divider>
                <Typography sx={{ color: "text.secondary" }}>or</Typography>
              </Divider>
              */}
      {/* 
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="outlined"
                  onClick={() => alert("Sign up with Google")}
                  startIcon={<GoogleIcon />}
                >
                  Sign up with Google
                </Button>
                <Button
                  type="submit"
                  fullWidth
                  variant="outlined"
                  onClick={() => alert("Sign up with Facebook")}
                  startIcon={<FacebookIcon />}
                >
                  Sign up with Facebook
                </Button>
              </Box>
              */}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="terms-and-conditions-title"
        aria-describedby="terms-and-conditions-description"
      >
        <Box sx={modalStyle}>
          <Typography
            id="terms-and-conditions-title"
            variant="h6"
            component="h2"
          >
            Terms and Conditions
          </Typography>
          <Box sx={scrollableTextStyle}>
            <Typography variant="body2" component="p">
              The education applications provided by CurriculumEdge rely on
              Artificial Intelligence (AI) to generate content and support
              educational experiences. While our tools are designed to enhance
              teaching and learning, CurriculumEdge makes no guarantees
              regarding the accuracy, completeness, or suitability of
              AI-generated content for specific educational purposes.
              <br />
              <br />
              By using our applications, you acknowledge and accept the
              following:
              <br />
              <br />
              <strong>User Responsibility:</strong> Educators and users are
              responsible for reviewing, validating, and adapting AI-generated
              content to ensure it aligns with their curriculum objectives,
              educational standards, and institutional policies. These tools are
              intended to supplement—not replace—professional judgment and
              instructional planning.
              <br />
              <br />
              <strong>Liability Limitation:</strong> CurriculumEdge is not
              liable for any errors, omissions, or inaccuracies in the generated
              content or for any outcomes resulting from the use or reliance on
              the applications, including but not limited to instructional
              misalignment or assessment challenges.
              <br />
              <br />
              <strong>No Professional Educational Advice:</strong> The outputs
              provided by our applications are not substitutes for professional
              teaching or curriculum development advice. Educators and users
              should consider these tools as part of a broader instructional
              strategy.
              <br />
              <br />
              <strong>Dynamic Nature of AI:</strong> AI-generated content may
              reflect limitations or biases inherent in the algorithms or data
              used for training. Users are encouraged to critically evaluate all
              outputs for fairness, inclusivity, and appropriateness in diverse
              educational settings.
              <br />
              <br />
              <strong>Privacy and Security:</strong> Users must adhere to
              institutional privacy policies and applicable regulations when
              using CurriculumEdge applications, especially when handling
              sensitive or student-related data.
              <br />
              <br />
              By using these applications, you agree to release and hold
              harmless CurriculumEdge from any claims, liabilities, or damages
              arising from the use of our tools in educational settings.
              <br />
              <br />
              Note: Your use of CurriculumEdge applications constitutes
              acceptance of this disclaimer.
            </Typography>
          </Box>

          <Button
            type="submit"
            sty
            fullWidth
            style={{
              backgroundColor: "#4286F0",
              color: "white",
              borderRadius: 20,
            }}
            onClick={() => setOpenModal(false)}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </Card>
  );
}
